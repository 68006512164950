import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { $router, organizationsServicePurchases } from '@/main';
import Organization from '@/models/CRM/Organization';
import Vue from 'vue';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class ErrorComponent extends PageRender {
    public organizations: Organization[] = [];
    public allOrganizations: Organization[] = [];
    public filterCountry: { text: string; value: string } = null;
    public countries: { text: string; value: string }[] = [];
    public organizationColumns: GridColumnProps[] = [
        { field: 'organizationId', title: 'Id', width: 125, editable: false },
        { field: 'name', title: 'Naam', editable: false, cell: this.renderLink },
        { field: 'coCNumber', title: 'Chamber of Commerce #', editable: false },
        { field: 'country', title: 'Country', editable: false },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['name', 'coCNumber', 'country'],
    });

    public async mounted() {
        await this.loadOrganizations();

        const countries = this.allOrganizations.map((x) => ({ text: x.country, value: x.country }));
        countries.forEach((x) => {
            if (!this.countries.find((y) => y.value === x.value)) {
                this.countries.push(x);
            }
        });

        this.isLoading = false;
    }

    public get filteredOrganizations() {
        if (!this.filterCountry) {
            return this.organizations;
        }

        return this.organizations.filter((x) => x.country === this.filterCountry.value);
    }

    public get countriesPlaceholder() {
        return `Select country (${this.countries.length})`;
    }

    public async loadOrganizations() {
        const organizations = await organizationsServicePurchases.getOrganizations();
        this.allOrganizations = this.organizations = organizations.items;
    }

    public createOrganizationSideAction() {
        this.$sideActions.push('create-organization-side-action', { data: true }, (result) => {
            this.setOrganization(result);
        });
    }

    public mergeOrganizationSideAction() {
        this.$sideActions.push('merge-organizations-side-action', { organizations: this.organizations }, async () => {

            this.showSuccess('MERGE_ORGANIZATIONS_SUCCESS');
            await this.loadOrganizations();
        });
    }

    public setOrganization(result) {
        this.organizations.push(result);
    }

    public async goToOrganization(e) {
        const organizationId = e.dataItem.organizationId;
        await this.$router.push({ name: 'purchase-organization', params: { organizationId } });
    }

    public renderActions(h, _, row) {
        const actions = [
            // {
            //     title: 'Copy deal',
            //     function: this.copyDeal,
            // },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'purchase-organization',
            params: { organizationId: row.dataItem.organizationId },
        });

        const props = { text: row.dataItem.name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }
}
