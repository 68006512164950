import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { productsServiceCRM, teamHelper } from '@/main';
import Product from '@/models/CRM/Product';
import Vue from 'vue';
import to from 'await-to-js';
import { orderBy } from '@progress/kendo-data-query';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class ErrorComponent extends PageRender {
    public products: Product[] = [];
    public allProducts: Product[] = [];

    public productsColumns: GridColumnProps[] = [
        { field: 'productId', title: 'Id', width: 125, editable: false },
        { field: 'name', title: 'Naam', editable: false },
        { field: 'price', title: 'Price', editable: false, width: 125 },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['defaultDepartment', 'name', 'defaultProject', 'price'],
    });

    public teamSearch: GridWrapperSearch = new GridWrapperSearch({
        properties: ['defaultDepartment', 'name', 'defaultProject', 'price'],
    });

    public async mounted() {
        await this.loadProducts();
        this.isLoading = false;
    }

    public async goToProduct(e) {
        const productId = e.dataItem.productId;
        await this.$router.push({ name: 'crm-product', params: { productId } });
    }

    public async loadProducts() {
        const products = await productsServiceCRM.getProducts();
        this.allProducts = this.products = products.items;
    }

    public updateTeamGridData(dataItems) {
        this.products = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public createProductSideAction() {
        this.$sideActions.push('create-product-side-action', {}, (result) => {
            this.setProduct(result);
        });
    }

    public setProduct(result: Product) {
        this.products.push(result);
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Archive product',
                function: this.archiveProduct,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async archiveProduct(item: Product) {
        this.showPending('ARCHIVE_PRODUCT_PENDING');
        const [err] = await to(productsServiceCRM.archiveProduct(item));
        if (err) {
            return this.clearAndShowError('ARCHIVE_PRODUCT_FAILED', err);
        }

        this.clearAndShowSuccess('ARCHIVE_PRODUCT_SUCCESS');

        this.products = this.products.filter((x) => x.productId !== item.productId);
    }
}
