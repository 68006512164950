import Vue from 'vue';
import { BaseService } from './baseService';
import Subscription from '@/models/CRM/Subscription';
import { to } from 'await-to-js';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';

export default class SubscriptionsService extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}teams/:team/subscriptions`;
    private ecoEndpoint = `${Vue.$env().crmApiEndpoint}/subscriptions`;
    // private crmEndpoint = `${Vue.$env().crmApiEndpoint}`;

    public async getSubscription(subscriptionId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:subscriptionId`, [['subscriptionId', subscriptionId]]));

        if (err) {
            this.clearAndShowError(`LOAD_SUBSCRIPTION_FAILED`, err);
            return new Subscription();
        }

        return new Subscription(response.data);
    }

    public async getSubscriptions(ecoScope: boolean = true, organizationId?: number): Promise<Subscription[]> {
        const orgString = `?OrganizationId=${organizationId}`;
        const [err, response] = await to(this.get(`${ecoScope ? this.ecoEndpoint : this.endpoint}${organizationId ? orgString : ''}`));

        if (err) {
            this.clearAndShowError(`LOAD_SUBSCRIPTIONS_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new Subscription(x));
    }

    public createSubscription(subscription: Subscription) {
        const org = subscription.organization as Organization;
        const contact = subscription.contact as Contact;
        const administrativeContact = subscription.administrativeContact as Contact;
        const model = {
            lines: subscription.lines.map((x) => {
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project: x.projectObj !== null ? x.projectObj.name : null,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : x.taxRateId ? x.taxRateId : null,
                };
            }),
            organization: org.organizationId ? org.organizationId : org.id,
            contact: contact.contactId ? contact.contactId : contact.id,
            administrativeContact:
                administrativeContact && administrativeContact.contactId
                    ? administrativeContact.contactId
                    : administrativeContact
                    ? administrativeContact.id
                    : null,
            name: subscription.name,
            owner: subscription.owner,
            currency: subscription.currency,
            clientReference: subscription.clientReference,
            template: subscription.template,
            schedule: subscription.schedule,
            invoiceDirectly: subscription.invoiceDirectly,
        };

        return this.post(this.endpoint, model);
    }

    public updateSubscription(subscription: Subscription) {
        const org = subscription.organization as Organization;
        const contact = subscription.contact as Contact;
        const administrativeContact = subscription.administrativeContact as Contact;
        const model = {
            lines: subscription.lines.map((x) => {
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project: x.projectObj !== null ? x.projectObj.name : null,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : x.taxRateId ? x.taxRateId : null,
                };
            }),
            organization: org.organizationId ? org.organizationId : org.id,
            contact: contact.contactId ? contact.contactId : contact.id,
            administrativeContact:
                administrativeContact && administrativeContact.contactId
                    ? administrativeContact.contactId
                    : administrativeContact
                    ? administrativeContact.id
                    : null,
            name: subscription.name,
            owner: subscription.owner,
            currency: subscription.currency,
            clientReference: subscription.clientReference,
            invoiceDate: subscription.invoiceDate,
            template: subscription.template,
            schedule: subscription.schedule,
            invoiceDirectly: subscription.invoiceDirectly,
        };

        return this.put(`${this.endpoint}/${subscription.id}`, model);
    }

    public cloneSubscription(subscription: Subscription) {
        return this.post(`${this.endpoint}/${subscription.id}/clone`, {
            name: `${subscription.name} copy`,
        });
    }

    public deleteSubscription(subscriptionId: number) {
        return this.delete(`${this.endpoint}/${subscriptionId}`);
    }

    // public changeSubscriptionState(subscriptionId: number, newState: number) {
    //     return this.post(`${this.endpoint}/${subscriptionId}/change-state`, {
    //         newSubscriptionState: newState,
    //     });
    // }

    // public cloneSubscription(subscription: Subscription) {
    //     return this.post(`${this.endpoint}/${subscription.id}/clone`, {
    //         name: `${subscription.name} copy`,
    //     });
    // }

    // public moveSubscription(subscriptionId: number, newTeamId: number) {
    //     return this.post(`${this.endpoint}/${subscriptionId}/move`, {
    //         newTeamId,
    //     });
    // }

    // public archiveSubscription(subscription: Subscription) {
    //     return this.delete(`${this.endpoint}/${subscription.id}`);
    // }
}
