import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { inboxDocumentService } from '@/main';
import InboxDocument from '@/models/Costs/InboxDocument';

@Component
export default class PurchaseComponent extends PageRender {
    public document: InboxDocument = new InboxDocument();

    public async mounted() {
        const documentId = parseInt(this.$route.params.inboxDocumentId);
        await this.loadDocument(documentId);

        if (this.document && !this.document.id) {
            await this.$router.push({ name: 'inbox' });
        }

        this.isLoading = false;
    }

    public async loadDocument(documentId: number) {
        this.document = await inboxDocumentService.getInboxDocument(documentId);
    }
}
