import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { clientPurchasesApprovalService, inboxDocumentService } from '@/main';
import to from 'await-to-js';
import { orderBy } from '@progress/kendo-data-query';
import { DocumentStatus, DocumentType } from '@/models/Costs/InboxDocument';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { translateModule } from '@/store/modules/translate';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import moment from 'moment';

@Component
export default class InboxComponent extends PageRender {
    public allDocuments: any[] = [];
    public documents: any[] = [];
    public defaultSort = [{ field: 'purchaseInvoiceDate', dir: 'asc' }];

    public documentColumns: GridColumnProps[] = [
        { field: 'documentType', title: translateModule.value('DOCUMENT_TYPE', 'GRID'), width: 100, editable: false, cell: this.renderTypeIcon },
        { field: 'name', title: translateModule.value('DOCUMENT_NAME', 'GRID'), editable: false, cell: this.renderNameWithLink },
        { field: 'documentStatus', title: translateModule.value('STATUS', 'GRID'), width: 120, editable: false },
        { field: 'purchaseInvoiceDate', title:translateModule.value('DATE', 'GRID'), width: 120, editable: false, cell: this.renderDate },
        { cell: this.renderActions, title: translateModule.value('Actions', 'GRID'), width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['receiverEmailAddress', 'name', 'approvalState'],
    });

    public async mounted() {
        await this.loadDeclarations();
        this.isLoading = false;
    }

    public async showUploadMultipleSideAction() {
        this.$sideActions.push('multi-upload-side-action', {}, async () => {
            await this.loadDeclarations();
            this.refreshGrid++;
        });
    }

    public async goToDocument(e) {
        const inboxDocumentId = e.dataItem.id;
        if (e.event.ctrlKey || e.event.metaKey) {
            const route = this.$router.resolve({ name: 'inbox-item', params: { inboxDocumentId } });
            return window.open(route.href, '_blank');
        }

        await this.$router.push({ name: 'inbox-item', params: { inboxDocumentId } });
    }

    public async loadDeclarations() {
        const states = [DocumentStatus.Approved, DocumentStatus.Submitted];
        this.allDocuments = this.documents = await inboxDocumentService.getInboxDocuments(states);
    }

    public updateOtherGridData(dataItems) {
        this.documents = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public async goToDocumentItem(item) {
        const inboxDocumentId = item.id;
        await this.$router.push({ name: 'inbox-item', params: { inboxDocumentId } });
    }

    public renderDate(h, _, row) {
        if(!row.dataItem.purchaseInvoiceDate){
            return h('td', '-');
        }
        return h('td', [moment(row.dataItem.purchaseInvoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Show details',
                function: this.goToDocumentItem,
            },
            // {
            //     title: 'Decline',
            //     function: this.declineApproval,
            // },
            // {
            //     title: 'Accept',
            //     function: this.acceptApproval,
            // },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderTypeIcon(h, _, row) {
        const value =
            row.dataItem.documentType === DocumentType.Declaration.toLowerCase()
                ? '<span class="icon-shopping-cart text-success"></span>'
                : '<span class="icon-mail text-primary"></span>';

        const props = { value: value };
        return h(Vue.component('grid-html-renderer'), { props });
    }

    public async declineApproval(item: any) {
        this.showPending('DECLINE_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.declineApproval(item.purchase.purchaseInvoiceId, 'Because!'));
        if (err) {
            return this.clearAndShowError('DECLINE_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('DECLINE_APPROVAL_SUCCESS');
    }

    public async acceptApproval(item: any) {
        this.showPending('ACCEPT_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.approveApproval(item.purchase.purchaseInvoiceId));
        if (err) {
            return this.clearAndShowError('ACCEPT_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('ACCEPT_APPROVAL_SUCCESS');
    }

    public renderNameWithLink(h, _, row) {
        const item = row.dataItem;
        const inboxDocumentId = item.id;

        const route = this.$router.resolve({ name: 'inbox-item', params: { inboxDocumentId } });
        const props = { text: item.name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }
}
