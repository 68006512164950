import Project from '@/models/Project';
import Group from '../Group';
import Product, { ProductActivity } from '../Purchases/Product';

export default class DocumentLine {
    public lineNumber: number = 1;

    public description: string = '';
    public amount: number | string = 1;
    public price: number = null;
    public inclPrice: number = null;

    public department: string = null;
    public departmentObj: any = null;
    public project: string = null;
    public projectObj: any = null;
    public projects: Project[] = [];

    public product: Product = { productId: null, name: '' } as Product;
    public products: Product[] = [];
    public productId: any = 0;

    public attachmentId: string;
    public budget: any = { available: true, visible: false, text: '' };

    public open?: boolean = false;
    public index?: number;
    public connectedActivity: string | ProductActivity = null;
    public productHasConnectedActivities?: boolean = false;

    constructor(obj?: Partial<DocumentLine>) {
        if (obj) {
            Object.assign(this, obj);

            if (obj.product) {
                this.product = new Product(obj.product);
            }
        }
    }
}
