import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { crmLedgerService } from '@/main';
import { required } from 'vuelidate/lib/validators';
import Ledger from '@/models/Finance/Ledger';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';

@Component({
    validations: {
        _ledger: {
            name: { required },
            value: { required },
        },
    },
} as any)
export default class CreateOrEditLedgerComponent extends PageRender {
    @Prop({ default: () => new Ledger() }) public ledger: Ledger;
    public _ledger: Ledger;
    public isEdit: boolean = false;
    public async created() {
        this._ledger = this.ledger;
        this.isEdit = this.ledger && this.ledger.ledgerId > 0;
    }

    public async mounted() {
        this.isLoading = false;
    }

    public resetLedger() {
        this._ledger = new Ledger();
    }

    public async createLedger() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_OR_UPDATE_LEDGER_INVALID`);
            return;
        }

        this.showPending('CREATE_OR_UPDATE_LEDGER_PENDING');

        const [err, response] = await to(crmLedgerService.createLedger(this.ledger));
        if (err) {
            return this.clearAndShowError('CREATE_OR_UPDATE_LEDGER_FAILED', err);
        }

        const ledger = new Ledger(response.data);

        this.$emit('finished', ledger);
        this.clearNotifications();
    }

    public async updateLedger() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_OR_UPDATE_LEDGER_INVALID`);
            return;
        }

        this.showPending('CREATE_OR_UPDATE_LEDGER_PENDING');

        const [err] = await to(crmLedgerService.updateLedgers(this.ledger));
        if (err) {
            return this.clearAndShowError('CREATE_OR_UPDATE_LEDGER_FAILED', err);
        }

        this.clearAndShowSuccess('CREATE_OR_UPDATE_LEDGER_SUCCESS');
        this.$emit('finished', this.ledger);
    }
}
