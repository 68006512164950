import { render, staticRenderFns } from "./deals.vue?vue&type=template&id=82866ede"
import script from "./deals.ts?vue&type=script&lang=js&external"
export * from "./deals.ts?vue&type=script&lang=js&external"
import style0 from "./deals.vue?vue&type=style&index=0&id=82866ede&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports