import Attachment from '../Attachment';
import Contact from './Contact';
import { DealLine } from './DealLine';
import Organization from './Organization';

export default class Subscription {
    public id: number = null;
    public organization: number | Organization = null;
    public name: string = '';
    public contact: Contact | number = null;
    public administrativeContact: Contact | number = null;
    public lines: DealLine[] = [];
    public subscriptionValue: string = '';
    public clientReference: string = '';
    public currency: string = 'EUR';
    public owner: string = null;
    public attachments: Attachment[] = [];
    public dealValue: number = 0;
    public invoiceNumber: string = '';
    public invoiceDate: string;
    public template?: number;
    // tslint:disable-next-line: no-use-before-declare
    public schedule: SubscriptionSchedule = new SubscriptionSchedule();
    public invoiceDirectly: boolean = false;

    constructor(obj?: Partial<Subscription>) {
        if (obj) {
            Object.assign(this, obj);
        }

        if (this.lines.length > 0) {
            this.lines = [];

            for (const line of obj.lines) {
                this.lines.push(new DealLine(line));
            }
        }

        if (!this.currency) {
            this.currency = 'EUR';
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class SubscriptionSchedule {
    public dayOfMonth: number = 1;
    public monthOfYear: number = 1;
    public endDate: string;
    public interval: string = 'Monthly';
    public startDate: string;
    public output: string = 'Concept';
}
