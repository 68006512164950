import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { dealsService, ecosystemHelper, teamHelper, organizationsServiceCRM, countryService } from '@/main';
import Deal from '@/models/CRM/Deal';
import Vue from 'vue';
import to from 'await-to-js';
import moment from 'moment';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { BModal } from 'bootstrap-vue';
import { profileModule } from '@/store/modules/profile';
import Organization from '@/models/CRM/Organization';
import { DealStateType } from '@/models/CRM/DealStateType';
import DealToInvoiceModalComponent from '@/components/modals/deal-to-invoice-modal.vue';
import { orderBy } from '@progress/kendo-data-query';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { translateModule } from '@/store/modules/translate';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import PaymentStatus, { PaymentStatusEnum } from '@/models/PaymentStatus';
import RemindDealComponent from '@/components/modals/send-reminder-deal-modal';

function requiredIfDealIsOpen() {
    // tslint-disable-next-line
    return this.deal.dealState.name !== 'Lead' || (this.deal.dealState.name === 'Lead' && this.deal.lines.length > 0);
}
@Component({
    validations: {
        deal: {
            name: { required },
            organization: { required },
            contact: { required },
            lines: {
                requiredIf: requiredIfDealIsOpen,
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
    },
} as any)
export default class DealsComponent extends PageRender {
    public isEcoScope: boolean = true;
    public deals: Deal[] = [];
    public highlightedDealId: number = 0;
    public organizations: Organization[] = [];
    public owners: { name: string; id: string }[] = [];
    public allDeals: Deal[] = [];
    public deal: Deal = new Deal();
    public newTeam: any = null;
    public selectedLanguage: string = 'NL';
    public selectedLanguageObj: any = null;
    public selectedDate: Date = new Date();
    public filterDate: boolean = true;
    public filterDateDays: number = 60;
    public filterOrganization: Organization = null;
    public filterOwner: { name: string; id: string } = null;
    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: [
            'clientReference',
            'name',
            'contact.firstName',
            'contact.lastName',
            'organization.name',
            'owner',
            'dealState.name',
            'dealValue',
            'currency',
        ],
    });
    public dealsColumns: GridColumnProps[] = [
        { field: 'id', title: 'Id', width: 75, editable: false },
        { field: 'name', title: 'Naam', editable: false, cell: this.renderNameWithLink },
        { field: 'owner', title: 'OWNER', editable: false },
        { field: 'organization.name', title: 'ORGANIZATION', editable: false },
        { field: 'dealDate', title: 'Datum', editable: false, cell: this.renderDate, width: 125 },
        { field: 'dealState.name', title: 'Status', editable: false, cell: this.renderStatus, width: 150 },
        { field: 'dealValue', title: 'Value', editable: false, cell: this.renderValue, width: 150 },
        { field: 'paymentStatus', title: 'Payment status', editable: false, cell: this.renderPaymentStatus, width: 200 },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public groups: any = [
        { id: 4, visible: true, name: 'LEAD', items: [], order: 0 },
        { id: 1, visible: true, name: 'OPEN', items: [], order: 1 },
        { id: 2, visible: true, name: 'WON', items: [], order: 2 },
        { id: 6, visible: true, name: 'NEEDS_TO_BE_INVOICED', items: [], order: 4 },
        { id: 7, visible: true, name: 'INVOICE_SENT', items: [], order: 5 },
        { id: 8, visible: true, name: 'INVOICE_PAID', items: [], order: 6 },
        { id: 3, visible: true, name: 'ARCHIVE', items: [], order: 99 },
    ];

    public options: any = {
        dropzoneSelector: '.drag-inner-list',
        draggableSelector: '.drag-item',
        onDragend: this.onDealDragend,
    };

    public settings = {
        tableView: false,
        disabledColumns: [],
    };

    public $refs!: {
        invoiceInfoModal: DealToInvoiceModalComponent;
        moveDealModal: BModal;
        remindDealModel: RemindDealComponent;
    };

    private _currentTargetGroup: number = null;
    private _currentDealId: number = null;
    private _currentOwnerGroupId: number = null;

    public get totalAmount() {
        return (items: Deal[]) => {
            let total = 0;
            items.forEach((item: Deal) => {
                total += item.dealValue;
            });
            return total;
        };
    }

    public get orgPlaceholder() {
        return translateModule.value('PLACEHOLDER_ORGANIZATION');
    }

    public get ownerPlaceholder() {
        return translateModule.value('PLACEHOLDER_OWNER');
    }

    public get exportDealsTooltip(): string {
        return translateModule.value('EXPORT_DEALS');
    }

    public dealActions(item: Deal) {
        const actions = [
            {
                title: 'Go to deal',
                function: this.goToDeal,
            },
            {
                title: 'Copy deal',
                function: this.copyDeal,
            },
        ];

        if (item.dealState.dealStateId === 7 && item.paymentStatus === PaymentStatusEnum.Open.toLowerCase()) {
            actions.unshift({
                title: 'Send reminder',
                function: this.showRemindDealModal,
            });
        }

        if (item.dealState.dealStateId < 7) {
            actions.push({
                title: 'Archive deal',
                function: this.archiveDeal,
            });
        }

        if (this.otherTeams.length > 0) {
            actions.push({
                title: 'Move deal',
                function: this.showMoveDealModal,
            });
        }

        return actions;
    }

    public get languages() {
        return countryService.getLanguages();
    }

    public get otherTeams(): any[] {
        const profile = profileModule.brightProfile;
        if (!profile || !profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)) {
            return [];
        }

        return profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id && x.id !== teamHelper.currentTeam.id);
    }

    public get filteredDeals() {
        const now = moment();
        return this.deals.filter((deal: Deal) => {
            const dealDate = moment(deal.dealDate);
            const dealOrgId = (deal.organization as Organization).organizationId;

            if (this.filterOrganization && dealOrgId !== this.filterOrganization.organizationId) {
                return false;
            }

            if(this.filterOwner && deal.owner !== this.filterOwner.id) {
                return false;
            }

            if (this.filterDate) {
                return (
                    (deal.dealState.name !== DealStateType.InvoiceSent && deal.dealState.name !== DealStateType.Archive) ||
                    now.diff(dealDate, 'days') <= this.filterDateDays
                );
            }

            return true;
        });
    }

    public get orderedGroups() {
        return this.groups
            .filter((x) => x.visible)
            .sort((current, previous) => {
                return current.order - previous.order;
            });
    }

    public created() {
        const tableView = localStorage.getItem('tableView');
        if (tableView != null) {
            this.settings.tableView = JSON.parse(tableView);
        }

        const columnSettings = localStorage.getItem('hiddenColumns');
        if (columnSettings != null) {
            this.settings.disabledColumns = JSON.parse(columnSettings);
            this.settings.disabledColumns.forEach((id) => {
                this.groups.find((x) => x.id === id).visible = false;
            });
        }

        this.isEcoScope = !this.$route.params.teamId;
        if (this.isEcoScope) {
            this.dealsColumns.splice(1, 0, { field: 'teamId', title: 'TEAM', width: 150, cell: this.renderTeam });
        }
    }

    public async mounted() {
        await Promise.all([this.loadDeals(), this.loadStructure(), this.loadTaxRates()]);

        this.loadOrganizations();
        this.loadOwners();
        this.initDealsInGroup();

        this.highlightedDealId = parseInt(localStorage.getItem('crm-deal-created'));
        localStorage.removeItem('crm-deal-created');

        this.isLoading = false;
    }

    public async onDealDragend(e) {
        const dealId = parseInt(e.items[0].dataset.id);
        const targetGroupId = parseInt(e.droptarget.dataset.id);
        const ownerGroupId = parseInt(e.owner.dataset.id);

        if (ownerGroupId === targetGroupId) {
            return;
        }

        await this.loadDeal(dealId);

        if (this.deal.id > 0 && ownerGroupId !== 4 && targetGroupId === 7) {
            // update state as won when from lead or open
            this._currentTargetGroup = targetGroupId;
            this._currentDealId = this.deal.id;
            this._currentOwnerGroupId = ownerGroupId;

            await this.showInvoiceInfoModal();
            return;
        } else if (this.deal.id > 0 && targetGroupId === 3) {
            await this.archiveDeal(this.deal);
            this.groups.find((x) => x.id === targetGroupId).items = [];
            return;
        } else if (this.deal.id > 0 && targetGroupId !== 7 && ownerGroupId !== 4 && ownerGroupId !== 7) {
            const success = await this.setDealToStatus(targetGroupId);
            if (success) {
                return;
            }
        } else if (this.deal.id > 0 && targetGroupId === 1 && ownerGroupId === 4) {
            const success = await this.setDealToOpen();
            if (success) {
                return;
            }

            this.groups.find((x) => x.id === targetGroupId).items = this.groups
                .find((x) => x.id === targetGroupId)
                .items.filter((x) => x.id !== dealId);
            this.groups.find((x) => x.id === ownerGroupId).items.push(this.deal);

            return;
        // } else if (targetGroupId === 6) {
        //     this.$sideActions.push('split-deal-action', { deal: this.deal }, (result) => {
        //         console.log(result);
        //     });

        //     return;
        } else if (targetGroupId === 8) {
            this.showInfo('The payment status of a deal will automatically be synced when an invoice is paid');

            this.groups.find((x) => x.id === targetGroupId).items = this.groups
                .find((x) => x.id === targetGroupId)
                .items.filter((x) => x.id !== dealId);
            this.groups.find((x) => x.id === 7).items.unshift(this.deal);

            return;
        }

        this.groups.find((x) => x.id === targetGroupId).items = this.groups.find((x) => x.id === targetGroupId).items.filter((x) => x.id !== dealId);
        this.groups.find((x) => x.id === ownerGroupId).items.push(this.deal);

        this.showWarning('MUTATION_NOT_ALLOWED');
    }

    public async loadDeals() {
        const deals = await dealsService.getDeals(this.isEcoScope);
        this.allDeals = this.deals = deals.items;
    }

    public async loadDeal(dealId) {
        const deal = await dealsService.getDeal(dealId);
        deal.lines.forEach((x) => {
            if (x.department) {
                x.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === x.department.toLowerCase());
            }

            if (x.department && x.departmentObj && x.project) {
                x.projectObj = x.departmentObj.groups.find((y) => y.name.toLowerCase() === x.project.toLowerCase());
            }

            if (x.taxRateId) {
                x.taxRate = this.taxRates.find((y) => y.id === x.taxRateId);
            }
        });

        return (this.deal = deal);
    }

    public async goToDeal(e) {
        const dealId = e.dataItem ? e.dataItem.id : e.id;
        const teamId = e.dataItem ? e.dataItem.teamId : e.teamId;
        let params;

        if (this.isEcoScope && teamId) {
            const team = teamHelper.getTeam(teamId);
            if (!team) {
                return this.showWarning(`You're not part of this team. Can't navigate you to this deal`);
            }

            params = {
                dealId,
                teamId: team.id,
                teamKey: team.key,
            };
        } else {
            params = { dealId };
        }

        if (e.event && (e.event.ctrlKey || e.event.metaKey)) {
            const route = this.$router.resolve({ name: 'crm-deal', params });
            return window.open(route.href, '_blank');
        }

        await this.$router.push({ name: 'crm-deal', params });
    }

    public async goToAllDeals() {
        await this.$router.push({ name: 'crm-all-deals' });
    }

    public exportDeals() {
        saveExcel({
            data: this.filteredDeals,
            fileName: `Deals-${moment().format('YYYY-MM-DD HH:mm:')}`,
            columns: this.dealsColumns,
        });
    }

    public async createDeal() {
        await this.$router.push({ name: 'crm-create-deal' });
    }

    public async copyDeal(item: Deal) {
        const [err, response] = await to(dealsService.cloneDeal(item));
        if (err) {
            return this.clearAndShowError('CLONE_DEAL_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_DEAL_SUCCESS');
        const deal = new Deal(response.data);
        await this.$router.push({ name: 'crm-deal', params: { dealId: deal.id.toString() } });
    }

    public async moveDeal() {
        const [err] = await to(dealsService.moveDeal(this._currentDealId, this.newTeam.id));
        if (err) {
            return this.clearAndShowError('MOVE_DEAL_FAILED', err);
        }

        this.clearAndShowSuccess('MOVE_DEAL_SUCCESS');
        this.$refs.moveDealModal.hide();
        this.deals = this.deals.filter((x) => x.id !== this._currentDealId);
    }

    public async archiveDeal(item: Deal) {
        this.showPending('ARCHIVE_DEAL_PENDING');
        const [err] = await to(dealsService.archiveDeal(item));
        if (err) {
            return this.clearAndShowError('ARCHIVE_DEAL_FAILED', err);
        }

        this.clearAndShowSuccess('ARCHIVE_DEAL_SUCCESS');
        this.deals = this.deals.filter((x) => x.id !== item.id);
        this.initDealsInGroup();
        this.refreshGrid++;
    }

    public getDealRoute(item: Deal) {
        return {
            name: 'crm-deal',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystemId,
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: item.teamId,
                teamKey: teamHelper.getTeam(item.teamId).key,
                dealId: item.id,
            },
        };
    }

    public renderStatus(h, _, row) {
        return h('td', row.dataItem.dealState.name);
    }

    public renderDate(h, _, row) {
        return h('td', moment(row.dataItem.dealDate).format('DD-MM-YYYY'));
    }

    public resetDeal(canceled: boolean) {
        if (canceled && this.deal.id > 0 && this._currentTargetGroup === 7) {
            this.groups.find((x) => x.id === this._currentTargetGroup).items = this.groups
                .find((x) => x.id === this._currentTargetGroup)
                .items.filter((x) => x.id !== this._currentDealId);
            this.groups.find((x) => x.id === this._currentOwnerGroupId).items.push(this.deal);
        }
    }

    public async showInvoiceInfoModal() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('UPDATE_DEAL_INVALID');

            if (this.deal.id > 0 && this._currentTargetGroup === 7) {
                this.groups.find((x) => x.id === this._currentTargetGroup).items = this.groups
                    .find((x) => x.id === this._currentTargetGroup)
                    .items.filter((x) => x.id !== this._currentDealId);
                this.groups.find((x) => x.id === this._currentOwnerGroupId).items.push(this.deal);
            }

            return;
        }

        await this.$refs.invoiceInfoModal.init(this.deal);
    }

    public updateGridData(dataItems) {
        this.deals = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);

        this.initDealsInGroup();
        this.refreshGrid++;
    }

    public async showMoveDealModal(deal: Deal) {
        this._currentDealId = deal.id;
        this.$refs.moveDealModal.show();
    }

    public async showRemindDealModal(deal: Deal) {
        await this.loadDeal(deal.id);

        await this.$refs.remindDealModel.init(this.deal);
    }

    public renderValue(h, _, row) {
        const deal = new Deal(row.dataItem);
        return h('td', [Vue.filter('numberFormat')(deal.dealValue)]);
    }

    public renderActions(h, _, row) {
        const actions = this.dealActions(row.dataItem);
        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async setDealToStatus(targetGroupId) {
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`DEAL_WON_INVALID`);
            return false;
        }

        this.showPending('DEAL_STATUS_CHANGE');

        const [err] = await to(dealsService.changeDealState(this.deal.id, targetGroupId));
        if (err) {
            return this.clearAndShowError('DEAL_STATUS_CHANGE_FAILED', err);
        }

        this.deal.dealState.name = this.groups.find((x) => x.id === targetGroupId).name;
        this.deal.dealState.dealStateId = this.groups.find((x) => x.id === targetGroupId).id;
        this.clearAndShowSuccess('DEAL_STATUS_CHANGE_SUCCESS');

        return true;
    }

    public async setDealToOpen() {
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`DEAL_OPEN_INVALID`);
            return false;
        }

        this.showPending('DEAL_OPEN_PENDING');

        const [err] = await to(dealsService.changeDealState(this.deal.id, 1));
        if (err) {
            return this.clearAndShowError('DEAL_OPEN_FAILED', err);
        }

        this.deal.dealState.name = 'Open';
        this.deal.dealState.dealStateId = this.groups.find((x) => x.id === 1).id;
        this.clearAndShowSuccess('DEAL_OPEN_SUCCESS');

        return true;
    }

    public async setDealAsLead() {
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`DEAL_LEAD_INVALID`);
            return false;
        }

        this.showPending('DEAL_LEAD_PENDING');

        const [err] = await to(dealsService.changeDealState(this.deal.id, 4));
        if (err) {
            return this.clearAndShowError('DEAL_LEAD_FAILED', err);
        }

        this.deal.dealState.name = 'Lead';
        this.deal.dealState.dealStateId = this.groups.find((x) => x.id === 4).id;
        this.clearAndShowSuccess('DEAL_LEAD_SUCCESS');
    }

    public async updateDeal() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('UPDATE_DEAL_INVALID');
            return;
        }

        const taxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);

        this.deal.lines.forEach((x) => {
            x.taxRateId = taxRate ? taxRate.taxRate : null;
        });

        if (this.deal.invoiceDate) {
            this.deal.invoiceDate = moment(this.deal.invoiceDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00[Z]');
        }

        this.showPending('UPDATE_DEAL_PENDING');
        const [err] = await to(dealsService.updateDeal(this.deal));
        if (err) {
            return this.clearAndShowError('UPDATE_DEAL_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_DEAL_SUCCESS');
    }

    public renderNonAccessibleDeals(_h, tr, _row, item) {
        if (this.isEcoScope) {
            const isAccessible = teamHelper.getTeam(item.dataItem.teamId);
            tr.data.class += !isAccessible ? ' bg-secondary' : '';
        }
        return tr;
    }

    public renderPaymentStatus(h, _row, item) {
        if (item.dataItem.dealState.dealStateId !== 7) {
            return h('td', 'N/A');
        }

        return h('td', item.dataItem.paymentStatus);
    }

    public dealsInGroup(state: number) {
        if (state === 8) {
            const deals = this.filteredDeals.filter((deal: Deal) => {
                return deal.dealState.dealStateId === 7 && deal.paymentStatus === PaymentStatusEnum.Full.toLowerCase();
            });

            return orderBy(deals, [{ field: 'invoiceDate', dir: 'asc' }]);
        } else if (state === 7) {
            const orgFilteredDeals = this.deals.filter((deal: Deal) => {
                const dealOrgId = (deal.organization as Organization).organizationId;
                if (this.filterOrganization && dealOrgId !== this.filterOrganization.organizationId) {
                    return false;
                }

                return true;
            });

            const ownerFilteredDeals = orgFilteredDeals.filter((deal: Deal) => {
                if (this.filterOwner && deal.owner !== this.filterOwner.id) {
                    return false;
                }

                return true;
            });

            const deals = ownerFilteredDeals.filter((deal: Deal) => {
                return deal.dealState.dealStateId === 7 && deal.paymentStatus !== PaymentStatusEnum.Full.toLowerCase();
            });

            return orderBy(deals, [{ field: 'invoiceDate', dir: 'asc' }]);
        }

        return this.filteredDeals.filter((deal: Deal) => {
            return deal.dealState.dealStateId === state;
        });
    }

    public initDealsInGroup() {
        this.groups.forEach((group) => {
            group.items = this.dealsInGroup(group.id);
        });
    }

    public hideColumn(group) {
        const columnSettings = localStorage.getItem('hiddenColumns');
        if (columnSettings) {
            const columnArray = JSON.parse(columnSettings);
            columnArray.push(group.id);
            localStorage.setItem('hiddenColumns', JSON.stringify(columnArray));
        } else {
            localStorage.setItem('hiddenColumns', JSON.stringify([group.id]));
        }
        group.visible = false;
        this.settings.disabledColumns.push(group.id);
    }

    public showAllColumns() {
        localStorage.removeItem('hiddenColumns');
        this.settings.disabledColumns = [];
        this.groups.forEach((element) => {
            element.visible = true;
        });
    }

    public setTableView() {
        localStorage.setItem('tableView', JSON.stringify(this.settings.tableView));
    }

    public renderTeam(h, _, row) {
        const team = teamHelper.getTeam(row.dataItem.teamId);
        return h('td', team ? team.name : '-');
    }

    public renderNameWithLink(h, _, row) {
        const item = row.dataItem;
        let params = {};
        const dealId = item.id;

        if (this.isEcoScope && item.teamId) {
            const team = teamHelper.getTeam(item.teamId);
            if (!team) {
                return this.showWarning(`You're not part of this team. Can't navigate you to this deal`);
            }

            params = {
                dealId,
                teamId: team.id,
                teamKey: team.key,
            };
        } else {
            params = { dealId };
        }

        const route = this.$router.resolve({ name: 'crm-deal', params });
        const props = { text: item.name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    private loadOrganizations() {
        const organizations = this.deals.filter((x) => x.organization).map((x) => x.organization as Organization);

        organizations.forEach((x) => {
            if (!this.organizations.find((y) => y.organizationId === x.organizationId)) {
                this.organizations.push(x);
            }
        });
    }

    private loadOwners() {
        const owners = this.deals
            .filter((x) => x.owner)
            .map((x) => {
                return { name: x.owner, id: x.owner };
            });

        owners.forEach((x) => {
            if (!this.owners.find((y) => y.id === x.id)) {
                this.owners.push(x);
            }
        });
    }
}
