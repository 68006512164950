import { PaymentStatusEnum } from '../PaymentStatus';
import Contact from './Contact';
import DealInvoice from './DealInvoice';
import { DealLine } from './DealLine';
import { DealState } from './DealState';
import Organization from './Organization';

export default class Deal {
    public id: number = null;
    public teamId: number = null;
    public organization: number | Organization = null;
    public name: string = '';
    public note: string = '';
    public contact: Contact | number = null;
    public administrativeContact: Contact | number = null;
    public lines: DealLine[] = [];
    public dealState: DealState = new DealState();
    public dealDate: string = '';
    public clientReference: string = '';
    public currency: string = null;
    public owner: string = null;
    public attachments: any[] = [];
    public dealValue: number = 0;
    public invoiceNumber: string = '';
    public invoiceDate: string;
    public paymentStatus?: PaymentStatusEnum;
    public template?: number;
    public externalInvoice?: DealInvoice;

    constructor(obj?: Partial<Deal>) {
        if (obj) {
            Object.assign(this, obj);
        }

        if (this.lines.length > 0) {
            this.lines = [];

            for (const line of obj.lines) {
                this.lines.push(new DealLine(line));
            }
        }

        if (!this.currency) {
            this.currency = 'EUR';
        }
    }
}
