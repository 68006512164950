import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { purchasesService, productsServicePurchases, teamHelper, footprintTypeServicePurchases } from '@/main';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import Product from '@/models/CRM/Product';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { DealLine } from '@/models/CRM/DealLine';
import { FootprintType } from '@/models/Purchases/FootprintProduct';
import { translateModule } from '@/store/modules/translate';

@Component({
    validations: {
        line: {
            amount: { required },
            price: { required },
            product: {
                requiredIf: requiredIf((form) => {
                    return form.description === null;
                }),
                productId: { minValue: minValue(1) },
            },
            description: {
                requiredIf: requiredIf((form) => {
                    return form.product === null;
                }),
            },
            departmentObj: { required },
            projectObj: { required },
        },
    },
} as any)
export default class CreatePurchaseComponent extends PageRender {
    @Prop({ default: () => new DealLine() }) public line: DealLine;
    @Prop({ default: 0 }) public purchaseId: number;
    public products: Product[] = [];
    public structure: any = null;
    public tableRerender: number = 0;
    public isEdit: boolean = true;

    public attachments: any[] = [];
    public showInc: boolean = false;

    public footprintTypes: FootprintType[] = [];
    public selectedAttachment = null;

    public currentLineIndex: number = 0;

    public async mounted() {
        this.isEdit = !!this.line;

        await Promise.all([this.loadStructure(), this.loadTaxRates(), this.loadProducts(), this.loadFootprintTypes()]);

        if (this.isEdit) {
            if (this.line.department) {
                this.line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === this.line.department.toLowerCase());
                this.line.projects = await this.getProjects(this.line.departmentObj);
            }

            if (this.line.department && this.line.departmentObj && this.line.project) {
                this.line.projectObj = this.line.projects.find((y) => y.name && y.name.toLowerCase() === this.line.project.toLowerCase());;
            }

            if (this.line.taxRateId) {
                this.line.taxRate = this.taxRates.find((y) => y.id === this.line.taxRateId);
            }

            if (this.line.price) {
                await this.getAndSetInclPrice(this.line);
            }

            for (let i = 0; i < this.footprintTypes.length; i++) {
                const element = this.footprintTypes[i];
                const metaData = this.line.metaData.find((y) => y.footPrintTypeId === element.footprintTypeId);
                if (!metaData) {
                    this.line.metaData.push({ amount: 0, footPrintTypeId: element.footprintTypeId, label: element.label, name: element.name });
                }
            }

            if (this.line.product) {
                this.line.product = await productsServicePurchases.getProduct(this.line.product.productId);
            }

            await this.loadAttachments();
        }

        this.isLoading = false;
    }

    public async loadProducts() {
        const products = await productsServicePurchases.getProducts();
        const teamProducts = [];

        this.products = [...teamProducts, { $isDisabled: true, name: '---------------', productId: -1 } as any, ...products.items];
    }

    public async loadFootprintTypes() {
        const types = await footprintTypeServicePurchases.getFootprintTypes();
        this.footprintTypes = types.items;
    }

    public async loadAttachments() {
        const attachments = await purchasesService.getAttachments(this.purchaseId);
        this.attachments = attachments;
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public saveLine() {
        this.$emit('finished', this.line);
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            this.showPending('REMOVE_ATTACHMENT_PENDING');

            const line = this.line;
            if (line) {
                line.attachmentId = null;
            }

            const [err] = await to(purchasesService.deleteAttachment(this.purchaseId, attachment.fileId));
            if (err) {
                return this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public previewAttachment(attachment) {
        this.selectedAttachment = attachment;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public async selectProduct(product: Product) {
        const productObject = await productsServicePurchases.getProduct(product.productId);
        if (!this.line.price || this.line.price === 0) {
            this.line.price = productObject.price;
        }
        this.line.product = productObject;
        this.line.taxRateId = productObject.taxRateId;
        this.line.metaData.forEach((x) => {
            x.amount = 0;
        });
    }

    public async selectDepartment(department) {
        this.line.project = null;
        this.line.projects = await this.getProjects(department);
    }

    public getProductTaxRate(line: DealLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return '';
            }
            return taxRate.description;
        }

        return '';
    }

    public getProductTaxValue(line: DealLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return 0;
            }
            return line.price - line.price / ((100 + taxRate.percentage) / 100);
        }

        return 0;
    }

    public uploadAttachmentToLineSideAction(lineIndex: number) {
        this.$sideActions.push(
            'upload-attachment-side-action',
            { purchaseId: this.purchaseId, lineIndex, isCrm: false, team: teamHelper.currentTeam.id },
            async (result) => {
                await this.addAttachmentToLine(result);
            },
        );
    }

    public async addAttachmentToLine(result) {
        this.line.attachmentId = result.fileId;
    }

    public downloadAttachmentForLine(attachmentId) {
        const attachment = this.attachments.find((x) => x.fileId === attachmentId);
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public productHasFootprintProduct(line: DealLine) {
        if (line.productId) {
            const product = this.products.find((x) => x.productId === line.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        if (line.product) {
            const product = this.products.find((x) => x.productId === line.product.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        return false;
    }

    public async deletePurchase() {
        this.showPending('DELETE_PURCHASE_PENDING');
        const success = await purchasesService.deletePurchase(this.purchaseId);
        if (success) {
            this.clearAndShowSuccess('DELETE_PURCHASE_SUCCESS');
            await this.$router.push({ name: 'purchases' });
        } else {
            this.clearAndShowError('DELETE_PURCHASE_FAILED', null);
        }
    }

    public selectProject() {
        this.tableRerender++;
    }
}
