import Component from 'vue-class-component';
import LegalEntity, { BankAccount, InvoiceData, InvoiceEmailTemplate, InvoiceTemplate, Translation } from '@/models/LegalEntity';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { countryService, legalEntityService } from '@/main';
import to from 'await-to-js';
import MailContent from '@/models/Purchases/MailContent';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        invoiceData: {
            vatNumber: { minLength: minLength(14), maxLength: maxLength(14) },
            emailAddress: { required },
            registeredCompanyName: { required },
            // bankAccounts: { minLength: minLength(15), maxLength: maxLength(32) },
            coCNumber: { required },
            phoneNumber: { required },
            houseNumber: { required },
            streetName: { required },
            postalCode: { required },
            city: { required },
            country: {required},
            primaryColor: { required },
            secondaryColor: { required },
        },
    },
} as any)
export default class LegalEntityPage extends SideActionBase<EditInvoiceDataOptions> {
    public invoiceData: InvoiceData;
    public legalEntity: LegalEntity = new LegalEntity();
    public editMode: boolean = true;
    public dirty: boolean = false;
    public isLoading: boolean = true;
    public hideCurrentLogo: boolean = false;
    public isTemplate: boolean = false;
    public template: InvoiceTemplate = new InvoiceTemplate();
    public isEdit: boolean = true;

    public templateTexts = [
        // { name: 'WON_DEAL', property: 'wonDealText' },
        { name: 'SEND_INVOICE', property: 'newInvoice', language: 'NL', mail: { masterTemplate: '', subject: '', body: '' } as MailContent },
        { name: 'SEND_INVOICE', property: 'newInvoice', language: 'EN', mail: { masterTemplate: '', subject: '', body: '' } as MailContent },
        { name: 'SEND_REMINDER', property: 'reminder', language: 'NL', mail: { masterTemplate: '', subject: '', body: '' } as MailContent },
        { name: 'SEND_REMINDER', property: 'reminder', language: 'EN', mail: { masterTemplate: '', subject: '', body: '' } as MailContent },
        // { name: 'SEND_APPROVAL', property: 'sendApprovalText' },
    ];

    public async mounted() {
        if (!this.options.isTemplate) {
            await this.loadLegalEntity();
            this.invoiceData = this.legalEntity.invoiceData;
        } else if (this.options.legalEntity.legalEntityId && this.options.isTemplate) {
            if (this.options.templateId) {
                await this.loadLegalEntityTemplate();
            } else {
                this.invoiceData = new InvoiceData();
                this.isEdit = false;
            }
            this.legalEntity.legalEntityId = this.options.legalEntity.legalEntityId;
            this.isTemplate = true;
        }

        if (!this.isTemplate) {
            this.templateTexts.forEach((x) => {
                if (this.legalEntity.invoiceData && this.legalEntity.invoiceData.invoiceEmailTemplates) {
                    const mail = this.legalEntity.invoiceData.invoiceEmailTemplates.find(
                        (y) => y.emailType === x.property && y.language === x.language,
                    );
                    if (mail) {
                        x.mail.masterTemplate = mail.masterTemplate;
                        x.mail.body = mail.body;
                        x.mail.subject = mail.subject;
                    }
                }
            });
        } else {
            this.templateTexts.forEach((x) => {
                if (this.template.invoiceData && this.template.invoiceData.invoiceEmailTemplates) {
                    const mail = this.template.invoiceData.invoiceEmailTemplates.find((y) => y.emailType === x.property && y.language === x.language);
                    if (mail) {
                        x.mail.masterTemplate = mail.masterTemplate;
                        x.mail.body = mail.body;
                        x.mail.subject = mail.subject;
                    }
                }
            });
        }

        this.isLoading = false;
    }

    public async loadLegalEntity() {
        const [err, response] = await to(legalEntityService.getLegalEntity(this.options.legalEntity.legalEntityId));
        if (err) {
            this.clearAndShowError('Failed to load legal entity details', err);
            return;
        }

        return (this.legalEntity = new LegalEntity(response.data));
    }

    public async loadLegalEntityTemplate() {
        const template = await legalEntityService.getTemplate(this.options.legalEntity.legalEntityId, this.options.templateId);

        this.template = template;
        return (this.invoiceData = template.invoiceData);
    }

    public async updateInvoiceData(e) {
        this.options.legalEntity.invoiceData = this.invoiceData;

        this.options.legalEntity.invoiceData.invoiceEmailTemplates = [];

        this.templateTexts.forEach((x) => {
            if (x.mail.body && x.mail.subject && x.mail.masterTemplate) {
                this.options.legalEntity.invoiceData.invoiceEmailTemplates.push(
                    new InvoiceEmailTemplate({
                        body: x.mail.body,
                        subject: x.mail.subject,
                        masterTemplate: x.mail.masterTemplate,
                        language: x.language,
                        emailType: x.property,
                    }),
                );
            }
        });

        delete this.options.legalEntity.invoiceData.invoiceLogo;
        const [err, response] = await to(legalEntityService.updateLegalEntity(this.options.legalEntity));
        if (err) {
            return this.clearAndShowError('Failed to update invoice data');
        }

        this.finished(response);
    }

    public async updateTemplate(e) {
        this.template.invoiceData = this.invoiceData;
        delete this.template.invoiceData.invoiceLogo;

        this.template.invoiceData.invoiceEmailTemplates = [];

        this.templateTexts.forEach((x) => {
            if (x.mail.body && x.mail.subject && x.mail.masterTemplate) {
                this.template.invoiceData.invoiceEmailTemplates.push(
                    new InvoiceEmailTemplate({
                        body: x.mail.body,
                        subject: x.mail.subject,
                        masterTemplate: x.mail.masterTemplate,
                        language: x.language,
                        emailType: x.property,
                    }),
                );
            }
        });

        const result = await legalEntityService.updateTemplate(this.options.legalEntity.legalEntityId, this.template);

        if (result) {
            this.finished(e);
        } else {
            return this.clearAndShowError('Failed to update template');
        }
    }

    public async createTemplate(e) {
        this.template.invoiceData = this.invoiceData;
        delete this.template.invoiceData.invoiceLogo;

        this.templateTexts.forEach((x) => {
            if (x.mail.body && x.mail.subject && x.mail.masterTemplate) {
                this.template.invoiceData.invoiceEmailTemplates.push(
                    new InvoiceEmailTemplate({
                        body: x.mail.body,
                        subject: x.mail.subject,
                        masterTemplate: x.mail.masterTemplate,
                        language: x.language,
                        emailType: x.property,
                    }),
                );
            }
        });

        const result = await legalEntityService.createTemplate(this.options.legalEntity.legalEntityId, this.template);

        if (result) {
            this.finished(e);
        } else {
            return this.clearAndShowError('Failed to create template');
        }
    }

    public removeBankAccount(index) {
        this.invoiceData.bankAccounts.splice(index, 1);
    }

    public addBankAccount() {
        this.invoiceData.bankAccounts.push(new BankAccount());
    }

    public newLanguage() {
        this.invoiceData.translations.push(new Translation({ language: 'Pick language' }));
    }

    public removeLanguage(index) {
        this.invoiceData.translations.splice(index, 1);
    }

    public selectLanguage(language, index) {
        this.invoiceData.translations[index].language = language.code.toUpperCase();
    }

    public get languages() {
        return countryService.getLanguages();
    }
}

export class EditInvoiceDataOptions {
    public isTemplate: boolean;
    public legalEntity: LegalEntity;
    public templateId?: number;
}
