import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper } from '@/main';
import { notificationModule } from '@/store/modules/notification';

@Component
export default class BarComponent extends Vue {
    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    public toggleNotification() {
        notificationModule.toggleVisibility();
    }
}
