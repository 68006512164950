import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Split extends Vue {
    @Prop({ type: Boolean }) public split: boolean;
    @Prop({ type: Number, default: 2000 }) public minWidth: number;
    public fit: boolean = false;

    public get splitActive() {
        return this.split && this.fit;
    }

    public mounted() {
        window.addEventListener('resize', this.checkSize);
        this.checkSize();
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.checkSize);
    }

    public close() {
        this.$emit('update:split', false);
    }

    private checkSize() {
        this.fit = window.innerWidth >= this.minWidth;
    }
}
