import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import {
    purchasesService,
    teamHelper,
    inboxDocumentService,
    productsServicePurchases,
    organizationsServicePurchases,
    brightService,
    ecosystemHelper,
    roleHelper,
} from '@/main';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import Organization from '@/models/CRM/Organization';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import TaxRate from '@/models/CRM/TaxRate';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';
import InboxDocument, { DocumentStatus, DocumentType } from '@/models/Costs/InboxDocument';
import DocumentLine from '@/models/Costs/DocumentLine';
import { translateModule } from '@/store/modules/translate';
import Vue from 'vue';
import parseFormat from 'moment-parseformat';
import { PaymentMethodOptions } from '@/models/PaymentMethod';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import PurchaseOrganizationSettings from '@/models/Organizations/PurchaseOrganizationSettings';
import DateHelper from '@/helpers/dateHelper';
import PurchaseDatePicker from './components/date-picker.vue';
import DocumentPredictionLinesActionOptions from '@/models/Purchases/DocumentPredictionLinesActionOptions';
import PredictionLine from '@/models/Purchases/PredictionLine';
import DocumentPrediction from '@/models/Purchases/DocumentPrediction';
import Attachment from '@/models/Attachment';
import { Purchase } from '@/models/Purchases/Purchase';
import Product, { ProductActivity } from '@/models/Purchases/Product';
import Project, { ProjectType } from '@/models/Project';

@Component({
    validations: {
        document: {
            name: { required },
            paymentMethod: { required },
            currency: { required },
            documentLines: {
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        required,
                        productId: { minValue: minValue(1) },
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
    },
    components: {
        PurchaseDatePicker: PurchaseDatePicker,
    },
} as any)
export default class CreateInboxDocumentComponent extends PageRender {
    @Prop({ default: () => new InboxDocument() }) public document: InboxDocument;
    public organizations: Organization[] = [];
    public selectedOrganization: Organization = null;
    public selectedOrgSettings = new PurchaseOrganizationSettings();
    public products: Product[] = [];
    public orgProducts: Product[] = [];
    public fetchedProducts: Product[] = [];
    public isEdit: boolean = false;
    public allowedToEdit: boolean = true;
    public organizationSelected: number = 0;
    public uploaded: number = 999;
    public tableRerender: number = 0;
    public invalidCombinationOrgRef: boolean = false;
    public documentPredictions: DocumentPrediction[] = [];
    public invoiceDateSelected: boolean = false;
    public showFullNote: boolean = false;

    public attachments: Attachment[] = [];
    public projects: any[] = [];
    public approvals: any[] = [];
    public approvers: any[] = [];
    public availableCurrencies = [
        { text: 'Euro', value: 'EUR', sign: '€' },
        { text: 'US Dollar', value: 'USD', sign: '$' },
        { text: 'Pound sterling', value: 'GBP', sign: '£' },
        { text: 'Saudi riyal', value: 'SAR', sign: 'SAR' },
        { text: 'Swiss franc', value: 'CHF', sign: 'CHF' },
    ];
    public selectedCurrency = { text: 'Euro', value: 'EUR', sign: '€' };
    public selectedDate: string = moment().format('DD-MM-YYYY');
    public selectedDateChanged: boolean = false;
    public showInc: boolean = false;

    public isSplitted: boolean = false;
    public selectedAttachment: Attachment = null;

    public selectedTeam: StructureTeam = new StructureTeam();
    public teamChanged: boolean = false;

    public currentLineIndex: number = 0;
    public teamSelectTabIndex: number = 101;
    public currencySelectTabIndex: number = 102;
    public orgSelectTabIndex: number = 103;

    public openPicker: boolean = false;
    public expectedTotal: string = '0';
    public reRenderButtons: number = 99;

    public invoices: Purchase[] = [];
    public selectedPreviousInvoice: Purchase = { lines: [] } as Purchase;
    public previousInvoice: Purchase = null;
    public historicalLines: DocumentLine[] = [];
    public key: number = 123123123;
    public foundOrganizations: Organization[] = [];
    public showRawPredictions: boolean = false;
    public selectedDocumentPrediction: DocumentPrediction = null;
    public initialDocumentLines: boolean = false;
    public invalidOrganization = false;
    public loadingHistoricalInvoices: boolean = false;

    public $refs!: {
        approvalModal: BModal;
        finalizeApprovalModal: BModal;
        lineMergeModal: BModal;
    };

    public get hasOpenLines() {
        return this.document.documentLines.length > 0 && this.document.documentLines.filter((x) => x.open).length > 0;
    }

    public get hasCrossLegalReference(): boolean {
        if (!this.selectedTeam || !this.selectedTeam.legalEntityReference) {
            return true;
        }

        const legalEntityRef = this.selectedTeam.legalEntityReference.toLowerCase();
        return (
            this.document.documentLines.filter((line: DocumentLine) => {
                if (!line.department) {
                    return true;
                }

                const departmentObj = this.getDepartments().find((team: StructureTeam) => {
                    return team.name.toLowerCase() === line.department.toLowerCase();
                });
                const departmentLegalRef =
                    departmentObj && departmentObj.legalEntityReference ? departmentObj.legalEntityReference.toLowerCase() : null;
                return departmentLegalRef !== legalEntityRef;
            }).length > 0
        );
    }

    public get numericExpectedTotal() {
        return parseFloat(this.expectedTotal);
    }

    public get predictedOrganizationName(): string {
        if (this.selectedDocumentPrediction && this.selectedDocumentPrediction.organization) {
            return this.selectedDocumentPrediction.organization.name.value;
        }
        return '';
    }

    public get newAlias(): boolean {
        if (!this.selectedOrganization || !this.selectedOrganization.organizationId) {
            return false;
        }

        return this.selectedOrgSettings.aliases.indexOf(this.predictedOrganizationName) < 0;
    }

    public get hasPayment(): boolean {
        return this.document.paymentMethod && this.document.paymentMethod.id ? true : false;
    }

    public get newPayment(): boolean {
        if (
            !this.selectedOrganization ||
            !this.selectedOrganization.organizationId ||
            !this.document.paymentMethod ||
            !this.document.paymentMethod.id
        ) {
            return false;
        }

        return this.document.paymentMethod.id !== this.selectedOrgSettings.defaultPaymentMethod.id;
    }

    public get dateInPast() {
        return DateHelper.daysAgo(moment(this.selectedDate, 'DD-MM-YYYY')) > 90;
    }

    public get hasApprovals() {
        return (
            (this.document.documentStatus === 'submitted' ||
                this.document.documentStatus === 'approved' ||
                this.document.documentStatus === 'declined') &&
            this.document.approvals.length
        );
    }

    public async created() {
        this.isEdit = this.document && this.document.id > 0;

        await Promise.all([
            this.loadStructure(this.document.purchaseInvoiceDate),
            this.loadTaxRates(),
            this.loadOrganizations(),
            this.loadProducts(),
        ]);

        this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());

        if (this.isEdit) {
            await this.loadAttachments();

            if (this.document.documentLines.length === 0 || this.document.documentType === DocumentType.Declaration.toLowerCase()) {
                await this.loadPredictions();
            } else {
                this.initialDocumentLines = true;
            }

            if (this.document.teamId) {
                this.selectedTeam = this.structure.teams.find((y) => y.teamId === this.document.teamId);
            }

            if (this.document.organizationId) {
                const [err, response] = await to(organizationsServicePurchases.getOrganizationProducts(this.document.organizationId));
                if (err) {
                    console.log(err);
                }

                this.orgProducts = this.products.filter((x) => response.data.find((y) => y.productId === x.productId));
            }

            for (let i = 0; i < this.document.documentLines.length; i++) {
                const line = this.document.documentLines[i];
                if (line.department) {
                    line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
                    line.projects = await this.getProjects(line.departmentObj);
                }

                if (line.department && line.departmentObj && line.project) {
                    let project = line.projects.find((y) => y.name && y.name.toLowerCase() === line.project.toLowerCase());
                    line.projectObj = project;
                }

                if (line.productId) {
                    let product = this.fetchedProducts.find((x) => x.productId === line.productId);

                    if (!product) {
                        product = await productsServicePurchases.getProduct(line.productId);
                        this.fetchedProducts.push(product);
                    }

                    line.product = product;
                }

                if (line.price) {
                    await this.exclPriceInput(line.price, line);
                }

                if (line.attachmentId) {
                    const attachment = this.attachments.find((y) => y.fileId === line.attachmentId);
                    if (!attachment) {
                        line.attachmentId = null;
                    }
                }

                if (this.orgProducts.length) {
                    const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                    line.products = [...products, { $isDisabled: true, name: 'Limited by organization allow products', productId: -1 } as any];
                } else {
                    line.products = [...this.products];
                }
            }

            if (this.document.currency) {
                this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.document.currency.toUpperCase());
            }
            this.selectCurrency();

            if (this.document.purchaseInvoiceDate) {
                this.selectedDate = moment(this.document.purchaseInvoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
            }

            if (this.document.organizationId) {
                this.selectedOrganization = this.organizations.find((x) => x.organizationId === this.document.organizationId);
            }

            if (
                this.document.documentStatus === DocumentStatus.Submitted ||
                this.document.documentStatus === DocumentStatus.Pending ||
                this.document.documentStatus === DocumentStatus.Approved
            ) {
                this.approvals = await inboxDocumentService.getApprovals(this.document.id);
            }

            if (this.document.organizationId && this.document.documentLines.length === 0) {
                await this.loadOrgInvoices();
            }
        }

        this.isLoading = false;
    }

    public async loadOrgInvoices() {
        this.loadingHistoricalInvoices = true;
        const purchases = await purchasesService.getPurchases(true, this.document.organizationId);
        this.invoices = purchases.items;

        this.invoices.sort(function (a, b) {
            return new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();
        });

        const structureTeam = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeamId);
        if (structureTeam) {
            const teamIds = this.structure.teams.filter((x) => x.legalEntityReference === structureTeam.legalEntityReference).map((x) => x.teamId);
            const legalEntityInvoices = this.invoices.filter((x) => teamIds.includes(x.teamId));
            if (legalEntityInvoices.length > 0) {
                await this.loadPreviousInvoiceLines(legalEntityInvoices[0]);
            } else if (this.invoices.length > 0) {
                await this.loadPreviousInvoiceLines(this.invoices[0]);
            }
        } else if (this.invoices.length > 0) {
            await this.loadPreviousInvoiceLines(this.invoices[0]);
        }

        this.loadingHistoricalInvoices = false;
    }

    public async loadPurchase(purchaseId: number, teamId: number) {
        return await purchasesService.getPurchase(purchaseId, teamId);
    }

    public invoiceLabel(invoice) {
        if (invoice.teamId) {
            const team = ecosystemHelper.structure.teams.find((x) => x.teamId === invoice.teamId);

            return `${team ? team.name : 'Unknown'} - ${invoice.name} - ${Vue.filter('formatDate')(invoice.purchaseDate)} -  value: ${invoice.purchaseValue
                }`;
        }

        return `${invoice.name}`;
    }

    public predictionInvoiceLabel(prediction) {
        if (prediction.lines) {
            const total = prediction.lines.reduce((prev, cur) => {
                return prev + this.parsePriceAmount(cur.amount ? cur.amount.value : 0);
            }, 0);

            return `${prediction.documentName} - predicted value: ${total} - # of lines: ${prediction.lines.length}`;
        }

        return prediction.documentName;
    }

    public openPurchaseNewWindow() {
        if (this.previousInvoice && this.previousInvoice.teamId) {
            const team = teamHelper.teams.find((x) => x.id === this.previousInvoice.teamId);
            const route = this.$router.resolve({
                name: 'purchase',
                params: { teamId: this.previousInvoice.teamId.toString(), teamKey: team.key, purchaseId: this.previousInvoice.id.toString() },
            });
            window.open(route.href);
        }
    }

    public getPendingApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'pending').length;
    }

    public getDeclinedApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'declined').length;
    }

    public getApprovedApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'approved').length;
    }

    public async loadOrganizations() {
        const organizations = await organizationsServicePurchases.getOrganizations();
        this.organizations = organizations.items;
    }

    public async loadProducts() {
        const products = await productsServicePurchases.getProducts();
        const teamProducts = [];

        this.products = [...teamProducts, { $isDisabled: true, name: '---------------', productId: -1 } as any, ...products.items];
    }

    public openAddInboxDocumentLineSideAction(index) {
        this.currentLineIndex = index;

        this.$sideActions.push('edit-purchase-line-side-action', { line: this.document.documentLines[index] }, (result) => {
            this.document.documentLines[index] = result;
            this.tableRerender++;
        });
    }

    public async loadAttachments() {
        const attachments = await inboxDocumentService.getAttachments(this.document.id);
        this.attachments = attachments;
    }

    public async loadPredictions() {
        this.documentPredictions = await inboxDocumentService.getInboxDocumentPredictions(this.document.id);

        if (this.documentPredictions.length > 0) {
            this.selectedDocumentPrediction = this.documentPredictions[0];

            if (this.selectedDocumentPrediction.reference && !this.document.reference) {
                this.document.reference = this.selectedDocumentPrediction.reference.value;
            }

            if (this.selectedDocumentPrediction.currency && !this.document.currency) {
                const currency = this.availableCurrencies.find(
                    (x) =>
                        x.sign === this.selectedDocumentPrediction.currency.value ||
                        x.text === this.selectedDocumentPrediction.currency.value ||
                        x.value === this.selectedDocumentPrediction.currency.value,
                );

                if (currency) {
                    this.document.currency = currency.value;
                    this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.document.currency.toUpperCase());
                } else {
                    this.selectedCurrency = this.availableCurrencies.find((x) => x.value === 'EUR');
                    this.document.currency = this.selectedCurrency.value;
                }
            }

            if (this.selectedDocumentPrediction.organization && this.selectedDocumentPrediction.organization.name && !this.document.organizationId) {
                const orgName = this.selectedDocumentPrediction.organization.name.value;
                const foundOrganizations = [];

                const directOrgMatch = this.organizations.find((org: Organization) => {
                    if (org.aliases) {
                        return org.aliases.indexOf(orgName) > -1;
                    }
                    return false;
                });

                if (directOrgMatch) {
                    this.selectOrganization(directOrgMatch);
                }

                this.organizations.forEach((y) => {
                    const score = this.stringSimilarity(orgName, y.name);
                    y.score = score;
                    if (score > 0.2) {
                        foundOrganizations.push(y);
                    }
                });

                if (foundOrganizations.length > 0) {
                    this.foundOrganizations = [
                        ...new Set(
                            foundOrganizations
                                .sort((a, b) => b.score - a.score)
                                .filter((x) => x.score / foundOrganizations[0].score > 0.5)
                                .slice(0, 5),
                        ),
                    ];
                }
            }
        }
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            let updateInboxDocument = false;
            this.showPending('REMOVE_ATTACHMENT_PENDING');

            const line = this.document.documentLines.find((x) => x.attachmentId === attachment.fileId);
            if (line) {
                line.attachmentId = null;
                updateInboxDocument = true;
            }

            const [err] = await to(inboxDocumentService.deleteAttachment(this.document.id, attachment.fileId));
            if (err) {
                this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            if (updateInboxDocument) {
                await this.updateInboxDocument();
            }
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public previewAttachment(attachment) {
        this.showPending('LOAD_BLOB');
        this.selectedAttachment = attachment;
        this.isSplitted = true;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public async selectProduct(product: Product, purchaseLineIndex) {
        if (product.productId <= 0) {
            return;
        }

        const productObject = await productsServicePurchases.getProduct(product.productId);
        if (
            productObject &&
            productObject.price &&
            (!this.document.documentLines[purchaseLineIndex].price || this.document.documentLines[purchaseLineIndex].price === 0)
        ) {
            this.document.documentLines[purchaseLineIndex].price = productObject.price;
        }
        this.document.documentLines[purchaseLineIndex].product = productObject;

        const [err, response] = await to(productsServicePurchases.getProductActivities(product.productId));
        this.document.documentLines[purchaseLineIndex].product.connectedActivities = response.data;

        await this.getAndSetInclPrice(this.document.documentLines[purchaseLineIndex]);

        this.openLine(purchaseLineIndex, true);
    }

    public async selectActivity(activity: ProductActivity, purchaseLineIndex: number) {
        this.document.documentLines[purchaseLineIndex].department = activity.teamName;
        this.document.documentLines[purchaseLineIndex].departmentObj = this.structure.teams.find((x) => x.name === activity.teamName);
        this.document.documentLines[purchaseLineIndex].projects = await this.getProjects(
            this.document.documentLines[purchaseLineIndex].departmentObj,
        );

        this.document.documentLines[purchaseLineIndex].project = activity.activityName;
        this.document.documentLines[purchaseLineIndex].projectObj = this.document.documentLines[purchaseLineIndex].projects.find(
            (x) => x.name.toLowerCase() === activity.activityName.toLowerCase(),
        );
    }

    public async selectDepartment(department, purchaseLineIndex: number) {
        this.document.documentLines[purchaseLineIndex].department = department.name;
        this.document.documentLines[purchaseLineIndex].projectObj = this.document.documentLines[purchaseLineIndex].project = null;
        this.document.documentLines[purchaseLineIndex].projects = await this.getProjects(department);
        this.document.documentLines[purchaseLineIndex].connectedActivity = 'manual';

        this.key = new Date().getTime();
    }

    public clearActivity(dealLineIndex: number) {
        this.document.documentLines[dealLineIndex].project = null;
        this.document.documentLines[dealLineIndex].projectObj = null;
        this.document.documentLines[dealLineIndex].department = null;
        this.document.documentLines[dealLineIndex].departmentObj = null;
    }

    public getProductTaxRatePercentage(line: DocumentLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return '';
            }
            return taxRate.percentage;
        }

        return '';
    }

    public getProductTaxRate(line: DocumentLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return '';
            }
            return taxRate.description;
        }

        return '';
    }

    public getProductTaxValue(line: DocumentLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return 0;
            }
            return line.price - line.price / ((100 + taxRate.percentage) / 100);
        }

        return 0;
    }

    public async selectProject(activity, index) {
        this.document.documentLines[index].project = activity.name;
        const teamName = this.document.documentLines[index].department;
        const connectedActivityProducts = await productsServicePurchases.getProductActivities(null, teamName, activity.name);

        const connectedProducts = this.products.filter((x) => connectedActivityProducts.data.find((y) => y.productId === x.productId));
        const notConnectedProducts = this.products.filter((x) => !connectedProducts.find((y) => y.productId === x.productId));

        this.document.documentLines[index].products = [
            ...connectedProducts,
            { $isDisabled: true, name: '--- Not connected products ---', productId: -1 } as any,
            ...notConnectedProducts,
        ];

        if (connectedProducts.length === 1 && this.document.documentLines[index].product === null) {
            this.selectProduct(connectedProducts[0], index);
            this.document.documentLines[index].connectedActivity = connectedActivityProducts.data.find(
                (x) => x.productId === connectedProducts[0].productId,
            );
        }

        if (roleHelper.isBEFinanceEmployee()) {
            // tslint:disable-next-line: no-floating-promises
            this.checkBudget(activity, index);
        }
        this.tableRerender++;
    }

    public async connectActivity(dealLineIndex: number) {
        const productId = this.document.documentLines[dealLineIndex].product.productId;

        const activity = new ProductActivity({
            activityName: this.document.documentLines[dealLineIndex].project,
            activityObj: this.document.documentLines[dealLineIndex].projectObj,
            teamObj: this.document.documentLines[dealLineIndex].departmentObj,
            teamName: this.document.documentLines[dealLineIndex].department,
        });
        const [err] = await to(productsServicePurchases.saveConnectedActivity(productId, activity));
        if (err) {
            return this.clearAndShowError('CONNECT_CONNECTED_ACTIVITY_FAILED', err);
        }

        if (this.document.documentLines[dealLineIndex].product.connectedActivities) {
            this.document.documentLines[dealLineIndex].product.connectedActivities.push(activity);
        } else {
            this.document.documentLines[dealLineIndex].product.connectedActivities = [activity];
        }

        this.document.documentLines[dealLineIndex].connectedActivity = activity;
    }

    public async checkBudget(project, index) {
        const line = this.document.documentLines[index];
        const lineValue = line.price * +line.amount;
        let budget;

        const isGroup = line.departmentObj.groups.find((x) => x.groupId === project.id && x.name.toLowerCase() === project.name.toLowerCase());
        if (!isGroup) {
            budget = await brightService.getPersonBudget(project.id, line.departmentObj.teamId);
        } else {
            budget = await brightService.getActivityBudget(project.id);
        }

        line.budget = {
            available: budget.budget > 0,
            visible: true,
            text: `Budget of ${project.name} in ${line.department} is <b>${Vue.filter('numberFormat')(budget.budget)}</b> and will be <b>${Vue.filter(
                'numberFormat',
            )(budget.budget - lineValue)}</b>`,
        };
    }

    public selectCurrency() {
        this.document.currency = this.selectedCurrency.value;
    }

    public resetInboxDocument() {
        this.document = this.document = new InboxDocument();
    }

    public setAmounts(incToEx: boolean = false) {
        if (!incToEx) {
            this.document.documentLines.forEach((line) => {
                line.inclPrice = line.price;
                this.inclPriceInput(line.price, line);
            });
        } else {
            this.document.documentLines.forEach((line) => {
                line.price = line.inclPrice;
                this.exclPriceInput(line.inclPrice, line);
            });
        }
    }

    public getTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal + this.getSubtotal();
    }

    public getTaxTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal;
    }

    public hasAnyProducts(tax) {
        return (
            this.document.documentLines.filter(
                (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
            ).length > 0
        );
    }

    public getTaxForType(tax: TaxRate) {
        const lines = this.document.documentLines.filter(
            (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
        );

        if (lines.length > 0) {
            const totalIncl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.inclPrice;
            }, 0);

            const totalExcl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.price;
            }, 0);

            return totalIncl - totalExcl;
        }

        return 0;
    }

    public getSubtotal() {
        let subTotal = 0;
        this.document.documentLines.forEach((line) => {
            subTotal += parseFloat(line.amount.toString()) * line.price;
        });

        return subTotal;
    }

    public openCO2CalculatorAction(lineIndex: number) {
        this.$sideActions.push(
            'co2-calculator-side-action',
            { purchaseId: this.document.id, lineIndex, isCrm: false, team: teamHelper.currentTeam.id },
            async (result) => {
                await this.addAttachmentToLine(result);
            },
        );
    }

    public uploadAttachmentToLineSideAction(lineIndex: number) {
        this.$sideActions.push(
            'upload-attachment-side-action',
            { purchaseId: this.document.id, lineIndex, isCrm: false, team: teamHelper.currentTeam.id },
            async (result) => {
                await this.addAttachmentToLine(result);
            },
        );
    }

    public async addAttachmentToLine(result) {
        this.document.documentLines[result.lineIndex].attachmentId = result.fileId;

        await this.updateInboxDocument();
    }

    public downloadAttachmentForLine(attachmentId) {
        const attachment = this.attachments.find((x) => x.fileId === attachmentId);
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public getLineNumberText(attachment) {
        if (attachment) {
            const index = this.document.documentLines.findIndex((x) => x.attachmentId === attachment.fileId);
            const indexNumber = index + 1;
            if (index > -1) {
                return `(line: ${indexNumber})`;
            }
        }

        return '';
    }

    public findOrderLineLineNumber(index: number) {
        return this.document.documentLines[index].lineNumber;
    }

    public productHasFootprintProduct(line: DocumentLine) {
        if (line.productId) {
            const product = this.products.find((x) => x.productId === line.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        if (line.product) {
            const product = this.products.find((x) => x.productId === line.product.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        return false;
    }

    public async updateInboxDocument() {
        this.submitted = true;

        if (this.document.documentLines.length > 0) {
            this.document.documentLines = this.document.documentLines.filter(
                (line) => line.price && typeof line.price !== 'undefined' && line.price !== 0,
            );
        }

        const isValid = await this.isValidProjectDepartmentCombinations();
        if ((this as any).$v.$invalid || !isValid) {
            this.showWarning(`UPDATE_PURCHASE_INVALID`);
            for (let i = 0; i < this.document.documentLines.length; i++) {
                const line = this.document.documentLines[i];
                const line2 = (this as any).$v.document.documentLines.$each[i];

                line.open = line2.$invalid;
            }

            return;
        }

        this.showPending('UPDATE_PURCHASE_PENDING');

        if (this.teamChanged || !this.document.teamId) {
            this.document.teamId = this.selectedTeam.teamId;
        }

        if (this.selectedDate) {
            this.document.purchaseInvoiceDate = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if (this.selectedOrganization) {
            this.document.organizationId = this.selectedOrganization.organizationId;
        }

        this.document.documentLines.forEach((line, index) => {
            line.lineNumber = index;
        });

        if (this.document.reference && this.document.organizationId && this.document.organizationId > 0) {
            const duplicate = await this.checkReferenceOrgCombination(false);

            if (duplicate) {
                const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('DUPLICATE_REF_ORG_TEXT'), {
                    title: translateModule.value('DUPLICATE_REF_ORG_TITLE'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'warning',
                    okTitle: translateModule.value('YES'),
                    cancelTitle: translateModule.value('NO'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                });

                if (!answer) {
                    return;
                }
            }
        }

        if (!this.document.reference) {
            this.document.reference = this.document.id.toString();
        }

        const [err] = await to(inboxDocumentService.updateDocument(this.document));
        if (err) {
            return this.clearAndShowError('UPDATE_PURCHASE_FAILED', err);
        }

        // await this.checkTeamChange();
        // const purchase = new InboxDocument(response.data);
        this.reRenderButtons++;
        this.clearAndShowSuccess('UPDATE_PURCHASE_SUCCESS');
        // await this.$router.push({ name: 'purchase', params: { purchaseId: purchase.id.toString() } });
    }

    public async forceCreatePurchase(book: boolean = false) {
        const result = await this.$bvModal.msgBoxConfirm(
            `This document isn't approved by all approvers, do you really want to force create a purchase?`,
            {
                title: 'Force create a purchase',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Ok',
                cancelTitle: 'Cancel',
                hideHeaderClose: false,
                centered: true,
            },
        );

        if (result) {
            await this.finalizeDocument(book);
        }
    }

    public async finalizeDocument(book: boolean = false, askForApproval: boolean = false) {
        this.submitted = true;

        if (this.document.documentLines.length > 0) {
            this.document.documentLines = this.document.documentLines.filter(
                (line) => line.price && typeof line.price !== 'undefined' && line.price !== 0,
            );
        }

        if ((this as any).$v.$invalid) {
            this.showWarning(`FINALIZE_DOCUMENT_INVALID`);

            for (let i = 0; i < this.document.documentLines.length; i++) {
                const line = this.document.documentLines[i];
                const line2 = (this as any).$v.document.documentLines.$each[i];

                line.open = line2.$invalid;
            }

            return;
        }

        if (this.hasCrossLegalReference) {
            const confirmExternal = await this.confirmCrossLegalEntity();
            if (!confirmExternal) {
                return;
            }
        }

        this.invalidOrganization = (book || askForApproval) && !this.document.organizationId;
        if (this.invalidOrganization) {
            this.showWarning(`FINALIZE_DOCUMENT_INVALID_ORG`);
            return;
        }

        this.showPending('FINALIZE_DOCUMENT_PENDING');
        await this.updateInboxDocument();

        const [err] = await to(inboxDocumentService.finalizeDocument(this.document.id, book, askForApproval));
        if (err) {
            return this.clearAndShowError('FINALIZE_DOCUMENT_FAILED', err);
        }

        this.clearAndShowSuccess('FINALIZE_DOCUMENT_SUCCESS');
        await this.$router.push({ name: 'inbox' });
    }

    public async isValidProjectDepartmentCombinations() {
        let isValid = true;

        for (let i = 0; i < this.document.documentLines.length; i++) {
            const line: DocumentLine = this.document.documentLines[i];

            if (line.projectObj && line.departmentObj) {
                const department = this.structure.teams.find((x) => x.teamId === line.departmentObj.teamId);
                if (department) {
                    const projects = await this.getProjects(line.departmentObj);
                    const project = projects.find((x) => x.id === line.projectObj.id);
                    if (!project) {
                        line.open = true;
                        isValid = false;
                    }
                } else {
                    line.open = true;
                    isValid = false;
                }
            }
        }

        return isValid;
    }

    public async checkTeamChange() {
        if (this.teamChanged) {
            const [err] = await to(purchasesService.movePurchase(this.document.id, this.selectedTeam.teamId));
            if (!err) {
                this.clearAndShowSuccess('UPDATE_DEAL_SUCCESS');

                teamHelper.setTeamByTeamId(this.selectedTeam.teamId);

                const route = this.$route;
                if (typeof route.params.teamId === 'undefined' && typeof route.params.teamKey === 'undefined') {
                    // no routing needed, team isn't part of route.
                    return;
                }

                const params = Object.assign({}, route.params);
                params.teamKey = this.selectedTeam.teamKey;
                params.teamId = this.selectedTeam.teamId.toString();

                return this.$router.push({
                    name: route.name,
                    params,
                });
            } else {
                return this.clearAndShowError('MOVE_DEAL_FAILED', err);
            }
        }
    }

    public async selectOrganization(organization: Organization) {
        this.selectedOrganization = organization;
        this.document.organizationId = organization.organizationId;

        this.selectedOrgSettings = await organizationsServicePurchases.getSettings(organization.organizationId);
        if (
            this.selectedOrgSettings.defaultPaymentMethod &&
            this.selectedOrgSettings.defaultPaymentMethod.id > 0 &&
            ((!this.hasPayment && this.document.documentType === DocumentType.Declaration.toLowerCase()) ||
                this.document.documentType === DocumentType.Incoming.toLowerCase())
        ) {
            this.document.paymentMethod = PaymentMethodOptions.find((option) => option.id === this.selectedOrgSettings.defaultPaymentMethod.id);
        }

        const [err, response] = await to(organizationsServicePurchases.getOrganizationProducts(organization.organizationId));
        if (err) {
            console.log(err);
        } else {
            this.orgProducts = this.products.filter((x) => response.data.find((y) => y.productId === x.productId));
        }

        await this.loadOrgInvoices();

        await this.checkReferenceOrgCombination(true);
    }

    public async checkReferenceOrgCombination(showWarning: boolean = false) {
        if (!this.document.organizationId || !this.document.reference) {
            return;
        }

        this.clearNotifications();
        const purchases = await purchasesService.getPurchases(true, this.document.organizationId, this.document.reference);

        // remove yourself
        purchases.items = purchases.items.filter((x) => x.id !== this.document.id);
        if (purchases && purchases.items && purchases.items.length > 0) {
            if (showWarning) {
                this.showWarning('Combination of reference and organization al ready present. Are you sure you want to continue with this purchase?');
            }
            return (this.invalidCombinationOrgRef = true);
        }

        return (this.invalidCombinationOrgRef = false);
    }

    public removeInboxDocumentLine(index: number) {
        this.document.documentLines.splice(index, 1);
    }

    public getPaymentMethodOptions() {
        return PaymentMethodOptions;
    }

    public async deleteInboxDocument() {
        const result = await this.$bvModal.msgBoxConfirm(`Do you really want to delete this document?`, {
            title: 'Delete document',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Ok',
            cancelTitle: 'Cancel',
            hideHeaderClose: false,
            centered: true,
        });

        if (result) {
            this.showPending('DELETE_INBOX_DOCUMENT_PENDING');
            const success = await inboxDocumentService.deleteDocument(this.document.id);
            if (success) {
                this.clearAndShowSuccess('DELETE_INBOX_DOCUMENT_SUCCESS');
                await this.$router.push({ name: 'inbox' });
            } else {
                this.clearAndShowError('DELETE_INBOX_DOCUMENT_FAILED', null);
            }
        }
    }

    public async addLineModal(linesToAdd: PredictionLine[]) {
        const firstLineToAdd = linesToAdd.length > 0 ? linesToAdd[0] : null;
        this.$sideActions.push(
            'prediction-lines-side-action',
            {
                lines: linesToAdd,
                exampleLine: this.document.documentLines.length ? this.document.documentLines[0] : firstLineToAdd,
                products: this.orgProducts.length ? [...this.orgProducts, { $isDisabled: true, name: 'Limited by organization allow products', productId: -1 } as any] : this.products,
                team: this.selectedTeam,
            } as DocumentPredictionLinesActionOptions,
            async (param: { lines: DocumentLine[]; clearFirst: boolean }) => {
                if (param.clearFirst && this.document.documentLines.length === 1) {
                    this.document.documentLines = [];
                }

                for (let i = 0; i < param.lines.length; i++) {
                    const line = param.lines[i];

                    if (line.index != null) {
                        await this.mergeLineInPurchase(line.index, line, false);
                    } else {
                        await this.addLineToDocument(param.lines[i], false);
                    }
                }

                this.key++;
            },
        );
    }

    public async mergeLineInPurchase(index, line: DocumentLine, checkTeam: boolean = true) {
        if (!this.document.documentLines[index]) {
            index = this.document.documentLines.length;
            this.document.documentLines.push(new DocumentLine(line));
        }

        this.document.documentLines[index] = line;

        if (((line.departmentObj && line.departmentObj.teamId !== this.selectedTeam.teamId) || !line.departmentObj) && checkTeam) {
            this.document.documentLines[index].department = this.selectedTeam.name;
            this.document.documentLines[index].departmentObj = this.selectedTeam;
            this.document.documentLines[index].projects = await this.getProjects(this.document.documentLines[index].departmentObj);
            this.document.documentLines[index].projectObj = null;
            this.document.documentLines[index].project = null;
        } else {
            this.document.documentLines[index].department = line.department;
            this.document.documentLines[index].departmentObj = line.departmentObj;
            this.document.documentLines[index].projects = await this.getProjects(this.document.documentLines[index].departmentObj);
        }

        if (!this.document.documentLines[index].departmentObj) {
            this.document.documentLines[index].departmentObj = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeam.id);
            if (this.document.documentLines[index].departmentObj) {
                this.document.documentLines[index].department = this.document.documentLines[index].departmentObj.name;
                this.document.documentLines[index].projects = await this.getProjects(this.document.documentLines[index].departmentObj);
            }
        } else {
            this.document.documentLines[index].department = this.document.documentLines[index].departmentObj.name;
            this.document.documentLines[index].projects = await this.getProjects(this.document.documentLines[index].departmentObj);
        }

        if (line.projectObj) {
            this.document.documentLines[index].projectObj = line.projectObj;
            this.document.documentLines[index].project = line.project;
        }

        if (line.product && line.product.productId > 0) {
            this.document.documentLines[index].product = line.product;
            this.document.documentLines[index].productId = line.product.productId;
        } else {
            this.document.documentLines[index].product = null;
            this.document.documentLines[index].productId = null;
        }

        if (this.orgProducts.length) {
            const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
            this.document.documentLines[index].products = [...products, { $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 } as any];
        } else {
            this.document.documentLines[index].products = [...this.products];
        }

        this.openLine(index, true);
    }

    public async addLineToDocument(lineToAdd?: DocumentLine, checkTeam: boolean = true) {
        if (this.document.documentLines.length > 0 && !lineToAdd) {
            const line = Object.assign({}, this.document.documentLines[this.document.documentLines.length - 1]);
            line.open = true;
            line.attachmentId = null;

            this.document.documentLines.push(line);
            this.openLine(this.document.documentLines.length - 1, true);
        } else {
            const newLine = new DocumentLine(lineToAdd);
            if (!newLine.departmentObj) {
                let team = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeam.id);
                if (team.teamId !== this.selectedTeam.teamId && checkTeam) {
                    team = this.selectedTeam;
                }

                newLine.departmentObj = team;
                if (newLine.departmentObj) {
                    newLine.department = newLine.departmentObj.name;
                    newLine.projects = await this.getProjects(newLine.departmentObj);
                }
            } else if (newLine.departmentObj.teamId !== this.selectedTeam.teamId && checkTeam) {
                if (newLine.departmentObj) {
                    newLine.department = newLine.departmentObj.name;
                    newLine.projects = await this.getProjects(newLine.departmentObj);
                }
            }

            if (newLine.product && newLine.product.productId) {
                const [err, response] = await to(productsServicePurchases.getProductActivities(newLine.product.productId));
                newLine.product.connectedActivities = response.data;

                if (newLine.product.connectedActivities && newLine.product.connectedActivities.length) {
                    newLine.connectedActivity = 'manual';
                }
            }

            if (this.orgProducts.length) {
                const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                newLine.products = [...products, { $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 } as any];
            } else {
                newLine.products = [...this.products];
            }

            if (newLine.price) {
                await this.exclPriceInput(newLine.price, newLine);
            }

            this.document.documentLines.push(newLine);
            this.openLine(this.document.documentLines.length - 1, true);
        }

        this.key++;
    }

    public IsAnOrgProduct(product: Product) {
        return this.orgProducts && this.orgProducts.length && this.orgProducts.find((x) => x.productId === product.productId);
    }

    public useOrganizationFunction(organization: string) {
        this.$sideActions.push(
            'prediction-organization-side-action',
            {
                organization,
            },
            async (organizationId) => {
                this.selectedOrganization = this.organizations.find((x) => x.organizationId === organizationId);
                await this.selectOrganization(this.selectedOrganization);
            },
        );
    }

    public useInvoiceDateFunction(date: string) {
        this.selectedDate = date;
        this.selectedDateChanged = true;
    }

    public useReferenceFunction(ref) {
        this.document.reference = ref;
    }

    public useCurrencyFunction(currency: string) {
        this.selectedCurrency = this.availableCurrencies.find((x) => x.value === currency.toUpperCase() || x.sign === currency);
        if (!this.selectedCurrency) {
            return this.showError('UNKNOWN_CURRENCY');
        }
        this.document.currency = this.selectedCurrency.value;
    }

    public selectTeam() {
        this.teamChanged = true;
    }

    public openPendingApprovalsModal() {
        this.$refs.approvalModal.show();
    }

    public async finalizeAndShowApprovalModal() {
        await this.getApprovalsForLines();
        this.$refs.finalizeApprovalModal.show();
    }

    public async finalizeAndAskApprovals() {
        this.$refs.finalizeApprovalModal.hide();
        this.finalizeDocument(false, true);
    }

    public async getApprovalsForLines() {
        this.approvers = [];
        for (let i = 0; i < this.document.documentLines.length; i++) {
            const x = this.document.documentLines[i];
            if (x.department) {
                x.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === x.department.toLowerCase());
                x.projects = await this.getProjects(x.departmentObj);
            }

            if (x.department && x.departmentObj && x.project) {
                let project: Project = x.projects.find((y) => y.name && y.name.toLowerCase() === x.project.toLowerCase());
                x.projectObj = project;

                if (this.approvers.indexOf(x.project) === -1 && x.projectObj.type !== ProjectType.Activity) {
                    this.approvers.push(x.project);
                }
            }

            if (x.department && x.departmentObj && x.projectObj && !x.project) {
                let project2 = x.projects.find((y) => y.name.toLowerCase() === x.projectObj.name.toLowerCase());
                if (project2) {
                    x.project = project2.name;
                }

                if (this.approvers.indexOf(x.project) === -1 && x.projectObj.type !== ProjectType.Activity) {
                    this.approvers.push(x.project);
                }
            }

            if (x.projectObj && x.projectObj.type === ProjectType.Activity) {
                const group = await brightService.getGroup(x.projectObj.id);
                if (group) {
                    x.projectObj.groupLead = group.groupLead;
                    if (x.projectObj.groupLead && this.approvers.indexOf(x.projectObj.groupLead.emailAddress) === -1) {
                        this.approvers.push(x.projectObj.groupLead.emailAddress);
                    }
                }
            }
        }

        return this.approvers;
    }

    public openLine(index, open: boolean) {
        let productOnLineHasConnectedActivities = false;
        if (this.document.documentLines[index]) {
            this.document.documentLines[index].open = open;
            productOnLineHasConnectedActivities =
                this.document.documentLines[index].product &&
                this.document.documentLines[index].product.connectedActivities &&
                !!this.document.documentLines[index].product.connectedActivities.length;
        }
        if (this.selectedDocumentPrediction && this.selectedDocumentPrediction.lines && this.selectedDocumentPrediction.lines[index]) {
            this.selectedDocumentPrediction.lines[index].open = open;
            this.selectedDocumentPrediction.lines[index].productHasConnectedActivities = productOnLineHasConnectedActivities;
        }
        if (this.historicalLines[index]) {
            this.historicalLines[index].open = open;
            this.historicalLines[index].productHasConnectedActivities = productOnLineHasConnectedActivities;
        }
    }

    public stringSimilarity(str1, str2, gramSize = 2) {
        function getNGrams(s, len) {
            s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
            const v = new Array(s.length - len + 1);
            for (let i = 0; i < v.length; i++) {
                v[i] = s.slice(i, i + len);
            }
            return v;
        }

        if (!str1 || !str1.length || !str2 || !str2.length) {
            return 0.0;
        }

        const s1 = str1.length < str2.length ? str1 : str2;
        const s2 = str1.length < str2.length ? str2 : str1;

        const pairs1 = getNGrams(s1, gramSize);
        const pairs2 = getNGrams(s2, gramSize);
        const set = new Set<string>(pairs1);

        const total = pairs2.length;
        let hits = 0;
        for (const item of pairs2) {
            if (set.delete(item)) {
                hits++;
            }
        }
        return hits / total;
    }

    public async getOrderedHistoricalInvoiceLines() {
        this.historicalLines = [];

        const declarationLine =
            this.document.documentType === DocumentType.Declaration.toLowerCase() && this.document.documentLines.length
                ? this.document.documentLines[0]
                : null;
        this.document.documentLines = [];
        const matches: string[] = [];

        if (this.selectedDocumentPrediction && this.selectedDocumentPrediction.lines) {
            for (let i = 0; i < this.selectedDocumentPrediction.lines.length; i++) {
                const line = this.selectedDocumentPrediction.lines[i];

                let index = -1;
                const scores = [];
                const invoiceLines = this.selectedPreviousInvoice.lines.filter((x) => !x.matched);

                invoiceLines.forEach((y, lineIndex) => {
                    if (line && line.description && line.description.value) {
                        const score = this.stringSimilarity(line.description.value, y.description);
                        if (score > 0.5) {
                            scores.push({ score, lineIndex });
                        }
                    }
                });

                if (scores.length) {
                    index = scores.sort((a, b) => b.score - a.score)[0].lineIndex;
                    invoiceLines[index].matched = true;
                }

                if (index > -1) {
                    const invoiceLine = invoiceLines[index];

                    this.historicalLines.push(Object.assign({}, invoiceLines[index]));

                    invoiceLine.price = line.amount && line.amount.value ? this.parsePriceAmount(line.amount.value) : 0;
                    invoiceLine.lineNumber = i;
                    await this.exclPriceInput(invoiceLine.price, invoiceLine);

                    if (line.description && line.description.value) {
                        invoiceLine.description = line.description.value;
                    }

                    if (invoiceLine.department) {
                        invoiceLine.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === invoiceLine.department.toLowerCase());
                        invoiceLine.projects = await this.getProjects(invoiceLine.departmentObj);
                    }

                    if (invoiceLine.department && invoiceLine.departmentObj && invoiceLine.project) {
                        invoiceLine.projectObj = invoiceLine.projects.find(
                            (y) => y.name && y.name.toLowerCase() === invoiceLine.project.toLowerCase(),
                        );
                    }

                    if (this.orgProducts.length) {
                        const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                        invoiceLine.products = [...products, { $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 } as any];
                    } else {
                        invoiceLine.products = [...this.products];
                    }

                    this.document.documentLines.push(invoiceLine);
                    matches.push(invoiceLine.description);
                } else {
                    const invoiceLine = this.selectedPreviousInvoice.lines[index];
                    this.historicalLines.push(Object.assign({}, new DocumentLine(invoiceLine)));

                    if (typeof this.document.documentLines[i] === 'undefined') {
                        if (typeof declarationLine !== 'undefined' && declarationLine !== null) {
                            if (this.orgProducts.length) {
                                const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                                declarationLine.products = [...products, { $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 } as any];
                            } else {
                                declarationLine.products = [...this.products];
                            }

                            this.document.documentLines.push(new DocumentLine(declarationLine));
                        } else {
                            let products = []
                            if (this.orgProducts.length) {
                                products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                                products.push({ $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 })
                            } else {
                                products = [...this.products];
                            }

                            this.document.documentLines.push(new DocumentLine({ lineNumber: i, productId: null, product: null, products }));
                        }
                    }
                }

                this.openLine(i, false);
            }
        }

        this.selectedPreviousInvoice.lines.forEach(async (line) => {
            if (matches.indexOf(line.description) === -1) {
                if (line.department) {
                    line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
                    line.projects = await this.getProjects(line.departmentObj);
                }

                if (line.department && line.departmentObj && line.project) {
                    line.projectObj = line.projects.find((y) => y.name.toLowerCase() === line.project.toLowerCase());
                }

                this.historicalLines.push(line);
            }
        });

        return this.historicalLines;
    }

    public async usePrediction(index, line: PredictionLine) {
        line.index = index;
        await this.addLineModal([line]);
    }

    public async useHistoricalLines() {
        this.selectedPreviousInvoice.lines.forEach(async (line) => {
            await this.addLineToDocument(Object.assign({}, line) as DocumentLine, false);
        });
    }

    public async useHistoricalLine(index, line) {
        if (index >= this.document.documentLines.length - 1) {
            return await this.addLineToDocument(Object.assign({}, line), false);
        }
        this.document.documentLines[index].description = line.description;
        this.document.documentLines[index].price = line.price;
        await this.exclPriceInput(line.price, this.document.documentLines[index]);

        this.openLine(index, true);
        this.key++;
    }

    public async useAllPredictions() {
        this.selectedDocumentPrediction.lines.forEach((line, index) => {
            line.index = index;
        });

        await this.addLineModal(this.selectedDocumentPrediction.lines);
    }

    public createOrganizationSideAction() {
        const organization = new Organization();
        if (this.selectedDocumentPrediction && this.selectedDocumentPrediction.organization) {
            if (this.selectedDocumentPrediction.organization.name) {
                organization.name = this.selectedDocumentPrediction.organization.name.value;
            }
        }

        this.$sideActions.push(
            'create-organization-side-action',
            { data: true, organization, existingOrganizations: this.organizations },
            async (result) => {
                await this.setOrganization(result);
            },
        );
    }

    public viewOrganization() {
        const organizationId = this.selectedOrganization.organizationId.toString();
        this.$router.push({ name: 'purchase-organization', params: { organizationId } });
    }

    public async setOrganization(result) {
        await this.loadOrganizations();

        Vue.set(this.document, 'organization', result);
        Vue.set(this.document, 'organizationId', result.organizationId);
        this.selectedOrganization = result;
        await this.selectOrganization(this.selectedOrganization);
    }

    public getTeamName(teamId) {
        const team = this.structure.teams.find((x) => x.teamId === teamId);

        return team ? team.name : 'Unknown';
    }

    public setTeam(teamId) {
        const team = this.structure.teams.find((x) => x.teamId === teamId);
        this.selectedTeam = team;
        this.selectTeam();

        this.showSuccess('TEAM_CHANGED');
    }

    public toggleLines(bool: boolean) {
        this.document.documentLines.forEach((_element, index) => {
            this.openLine(index, !bool);
        });

        if (this.selectedPreviousInvoice && this.selectedPreviousInvoice.lines) {
            this.selectedPreviousInvoice.lines.forEach((_element, index) => {
                this.openLine(index, !bool);
            });
        }
    }

    public useInvoiceDate(date: string) {
        const format = parseFormat(date, {
            preferredOrder: {
                '/': 'DMY',
                '.': 'DMY',
                '-': 'DMY',
            },
        });
        const parsedDate = moment(date, format);

        if (!parsedDate.isValid()) {
            this.showError('FAILED_TO_PARSE_DATE');
        }

        this.useInvoiceDateFunction(parsedDate.format('DD-MM-YYYY'));

        this.showSuccess('INVOICE_DATE_ADDED_TO_DOCUMENT');
        this.invoiceDateSelected = true;
    }

    public async addPredictedOrganizationToAlias() {
        const organization = this.selectedOrganization;
        const alias = this.predictedOrganizationName;
        this.showPending('Adding alias');
        this.selectedOrgSettings.aliases.push(alias);
        await organizationsServicePurchases.saveSettings(organization.organizationId, this.selectedOrgSettings);
        this.clearAndShowSuccess(`Successfully added '${this.predictedOrganizationName}' to '${organization.name}'`);
    }

    public async setAsDefaultPaymentMethod() {
        const organization = this.selectedOrganization;
        this.selectedOrgSettings.defaultPaymentMethod = this.document.paymentMethod;
        await organizationsServicePurchases.saveSettings(organization.organizationId, this.selectedOrgSettings);
        this.clearAndShowSuccess(`Successfully set '${this.document.paymentMethod.name}' as default payment method.`);
    }

    public startUpload() {
        this.$sideActions.push('document-upload-action', { data: true, document: this.document }, async () => {
            await this.loadAttachments();
        });
    }

    private async loadPreviousInvoiceLines(invoice) {
        this.previousInvoice = invoice;
        const invoiceLoaded = await this.loadPurchase(invoice.id, invoice.teamId);
        this.selectedPreviousInvoice.lines = invoiceLoaded.lines;

        await this.getOrderedHistoricalInvoiceLines();
    }

    private confirmCrossLegalEntity() {
        return this.$bvModal.msgBoxConfirm(translateModule.value('CONFIRM_CROSS_LEGAL_ENTITY', 'MODALS'), {
            title: translateModule.value('CONFIRM_CROSS_LEGAL_ENTITY_TITLE', 'MODALS'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Go ahead',
            cancelTitle: 'Cancel',
            hideHeaderClose: false,
            centered: true,
        });
    }
}
