import Ledger from '@/models/Finance/Ledger';
import to from 'await-to-js';
import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';

export default class LedgerService extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}ledgers`;

    public async getLedger(ledgerId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/${ledgerId}`));

        if (err) {
            this.clearAndShowError(`LOAD_LEDGER_FAILED`, err);
            return new Ledger();
        }

        return new Ledger(response.data);
    }

    public async getLedgers() {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_LEDGERS_FAILED`, err);
            return new Range<Ledger>();
        }

        return new Range<Ledger>(response.data);
    }

    public createLedger(ledger: Ledger) {
        return this.post(this.endpoint, ledger);
    }

    public updateLedgers(ledger: Ledger) {
        return this.put(`${this.endpoint}/${ledger.ledgerId}`, ledger);
    }

    public deleteLedger(ledgerId: number) {
        return this.delete(`${this.endpoint}/${ledgerId}`);
    }
}
