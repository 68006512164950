import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { crmLedgerService, productsServiceCRM, teamHelper } from '@/main';
import { required } from 'vuelidate/lib/validators';
import Product, { ProductActivity } from '@/models/CRM/Product';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import Ledger from '@/models/Finance/Ledger';

@Component({
    validations: {
        localProduct: {
            name: { required },
            price: { required },
        },
    },
} as any)
export default class CreateOrEditProductComponent extends PageRender {
    @Prop({ default: () => new Product() }) public product: Product;
    public localProduct: Product = null;
    public isEdit: boolean = false;
    public ledgers: Ledger[] = [];
    public ledgerSelected: number = 0;

    public get optionTeams() {
        return this.getDepartments().map((team) => {
            return { id: team.teamId, name: team.name, activities: team.groups };
        });
    }

    public async created() {
        await this.loadStructure();
        await this.loadLedgers();

        this.localProduct = this.product;
        this.isEdit = this.product && this.product.productId > 0;

        if (this.isEdit) {
            await this.loadConnectedActivities();
        }

        this.isLoading = false;
    }

    public resetProduct() {
        this.localProduct = new Product();
    }

    public async loadLedgers() {
        const ledgers = await crmLedgerService.getLedgers();
        this.ledgers = ledgers.items;
    }

    public getGroups() {
        return [];
    }

    public async createProduct() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_PRODUCT_INVALID`);
            return;
        }

        this.showPending('CREATE_PRODUCT_PENDING');

        const [err, response] = await to(productsServiceCRM.createProduct(this.product));
        if (err) {
            return this.clearAndShowError('CREATE_PRODUCT_FAILED', err);
        }

        const product = new Product(response.data);

        this.$emit('finished', product);
        this.clearNotifications();
    }

    public async updateProduct() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_PRODUCT_INVALID`);
            return;
        }

        this.showPending('UPDATE_PRODUCT_PENDING');

        if (this.product.connectedActivities.length > 0) {
            await this.saveConnectedActivities(this.product.connectedActivities);
        }

        const [err] = await to(productsServiceCRM.updateProduct(this.product));
        if (err) {
            return this.clearAndShowError('UPDATE_PRODUCT_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_PRODUCT_SUCCESS');
    }

    public async saveConnectedActivities(connectedActivities: ProductActivity[]) {
        connectedActivities.forEach(async (activity) => {
            if (activity.activityObj && activity.teamObj) {
                const [err] = await to(productsServiceCRM.saveConnectedActivity(this.product.productId, activity));
                if (err) {
                    return this.clearAndShowError('SAVE_CONNECTED_ACTIVITY_FAILED', err);
                }
            }
        });
    }

    public addActivity() {
        const currentTeam = this.getDepartments().find((x) => x.teamId === teamHelper.currentTeam.id);

        this.localProduct.connectedActivities.push(
            new ProductActivity({
                teamName: teamHelper.currentTeam.name,
                teamObj: { id: currentTeam.teamId, name: currentTeam.name, activities: currentTeam.groups },
            }),
        );
    }

    public async loadConnectedActivities() {
        const [err, response] = await to(productsServiceCRM.getProductActivities(this.product.productId));
        if (err) {
            return this.clearAndShowError('LOAD_CONNECTED_ACTIVITIES_FAILED', err);
        }

        if (response.data) {
            this.localProduct.connectedActivities = response.data.map((x) => new ProductActivity(x));
            this.localProduct.connectedActivities.forEach((activity) => {
                const team = this.getDepartments().find((x) => x.name.toLowerCase() === activity.teamName.toLowerCase());

                activity.teamObj = {
                    id: team.teamId,
                    name: team.name,
                    activities: team.groups,
                };

                activity.activityObj = {
                    id: team.groups.find((x) => x.name.toLowerCase() === activity.activityName.toLowerCase()).groupId,
                    name: activity.activityName,
                };
            });
        }
    }

    public async removeActivity(index: number) {
        const connectedActivity = this.localProduct.connectedActivities[index];
        if (connectedActivity.productActivityId) {
            await this.deleteConnectedActivity(connectedActivity.productActivityId);
        }

        this.localProduct.connectedActivities.splice(index, 1);
    }

    public async deleteConnectedActivity(productActivityId: number) {
        const [err] = await to(productsServiceCRM.deleteConnectedActivity(productActivityId));
        if (err) {
            return this.clearAndShowError('DELETE_CONNECTED_ACTIVITY_FAILED', err);
        }
    }
}
