import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';
import { Purchase } from '@/models/Purchases/Purchase';

export default class ClientPurchasesApprovalService extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}purchase-approval`;
    private purchaseEndpoint = `${Vue.$env().purchasesApiEndpoint}purchases`;

    public async getPersonalPending() {
        const [err, response] = await to(this.get(`${this.endpoint}/personal-pending`));

        if (err) {
            this.clearAndShowError(`LOAD_APPROVALS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async getPurchase(purchaseId: number) {
        const [err, response] = await to(this.get(`${this.purchaseEndpoint}/:purchaseId`, [['purchaseId', purchaseId]]));

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASE_FAILED`, err);
            return new Purchase();
        }

        return new Purchase(response.data);
    }

    public async approveApproval(purchaseId: number) {
        return this.post(`${this.endpoint}/${purchaseId}/approve`);
    }

    public async declineApproval(purchaseId: number, note: string) {
        return this.post(`${this.endpoint}/${purchaseId}/decline`, {
            note,
        });
    }
}
