import { contactsServiceCRM, organizationsServiceCRM } from '@/main';
import Contact from '@/models/CRM/Contact';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Component } from 'vue-property-decorator';

@Component
export default class ContactActionComponent extends SideActionBase<any> {
    public contacts: Contact[] = [];
    public selectedContact: Contact = null;

    public sort: SortDescriptor[] = [{ field: 'lastName', dir: 'asc' }];
    public relationType: RelationType = { value: 'default', text: 'Default' };

    public async mounted() {
        await this.loadContacts();
    }

    public async loadContacts() {
        const contacts = await contactsServiceCRM.getContacts();
        this.contacts = orderBy(contacts, this.sort);
    }

    public async selectContact(contact) {
        const success = await organizationsServiceCRM.coupleContact(this.options.organization.organizationId, contact, this.relationType.value);
        contact.contactType = this.relationType.value;
        this.finished({ contact, success });
    }

    public relationOptions() {
        return [
            { value: 'default', text: 'Default' },
            { value: 'administrative', text: 'Administrative' },
        ];
    }
}

export class RelationType {
    public value: string;
    public text: string;
}
