export default class COCOrg {
    public activiteitomschrijving: string = '';
    public verblijfsobjectgebruiksdoel: string = '';
    public vbo_id: string = '';
    public locatie: { lon: string; lat: string; } = { lon: '', lat: '' };
    public rechtsvormCode: string = '';
    public vestigingsnummer: string = '';
    public huidigeHandelsNamen: string[] = [];
    public activiteiten: { omschrijving: string; code: string; hoofdactiviteit: boolean; }[] = [];
    public vestiging: boolean;
    public naam: string = '';
    public bezoeklocatie: { plaats: string; straat: string; huisnummer: string; postcode: string; } = { plaats: '', straat: '', huisnummer: '', postcode: '' };
    public kvknummer: string = '';
    public pand_id: string = '';
    public updated_at: string = '';
    public actief: boolean;
    public postlocatie: any[] = [];
    public oudeHandelsnamen: string[] = [];
    public rechtsvormOmschrijving: string = '';
    public sbi: string[] = [];
    public inschrijvingstype: string = '';
    public slug: string = '';
    public _links: { self: { href: string; }; } = { self: { href: '' } };

    constructor(data?: Partial<COCOrg>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

