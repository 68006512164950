import { ecosystemHelper, teamHelper } from '@/main';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import { profileModule } from '@/store/modules/profile';
import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class EcosystemSwitcherModalComponent extends BaseModal {
    public currentEcosystem: number = null;

    public optionListEcosystems() {
        return profileModule.brightProfile.ecosystemMemberships
            .filter((x) => x.id !== ecosystemHelper.currentEcosystem.id)
            .map((ship) => {
                return { value: ship.id, text: ship.name };
            });
    }

    public async switchToAnotherEcosystem(ecosystemId: number) {
        const profile = profileModule.brightProfile;
        const ecosystem = profile.ecosystemMemberships.find((x) => x.id === ecosystemId);

        let team = profile.teamMemberships
            .filter((x) => x.ecosystem === ecosystem.id)
            .find((t) => {
                if (profile.primaryTeam) {
                    return t.id === profile.primaryTeam;
                }
            });

        if (!team) {
            team = profile.teamMemberships.filter((x) => x.ecosystem === ecosystem.id)[0];
        }

        this.$refs.modal.hide();

        this.showPending('Gathering info for switching between ecosystem..');

        ecosystemHelper.setEcosystemByEcosystemId(ecosystem.id);
        teamHelper.setTeam(team);
        await ecosystemHelper.loadStructure();
        await legalEntityModule.fetch();
        await legalEntityModule.init();

        this.currentEcosystem = null;

        this.clearNotifications();

        const route = this.$router.resolve(this.$route.fullPath);
        if (route && route.resolved) {
            const ecoId = ecosystem.id.toString();
            const teamId = team.id.toString();
            const ecosystemKey = ecosystem.key;
            const teamKey = team.key;

            route.route.params.teamId = teamId;
            route.route.params.teamKey = teamKey;
            route.route.params.ecosystemKey = ecosystemKey;
            route.route.params.ecosystemId = ecoId;

            ecosystemHelper.setEcosystemByEcosystemId(parseInt(ecoId));
            teamHelper.setTeamByTeamId(parseInt(teamId));

            await this.$router.push({ name: route.route.name, params: route.route.params, query: route.route.query });
        } else {
            return this.$router.push({
                name: 'crm-deals',
                params: {
                    ecosystemId: ecosystem.id.toString(),
                    ecosystemKey: ecosystem.key,
                    teamId: team.id.toString(),
                    teamKey: team.key,
                },
            });
        }
    }

    public setLegalEntity() {
        if (this.$route.params.legalEntity) {
            const legalEntity = legalEntityModule.find(this.$route.params.legalEntity);
            legalEntityModule.setCurrentLegalEntity(legalEntity);
        }
    }
}
