import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import InboxDocument from '@/models/Costs/InboxDocument';
import DocumentLine from '@/models/Costs/DocumentLine';
import to from 'await-to-js';
import { inboxDocumentService, teamHelper } from '@/main';
import { Membership } from '@/models/Interfaces';
import DeclineApprovalsModal from '@/components/modals/decline-approval-modal.vue';
import { translateModule } from '@/store/modules/translate';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import Structure from '@/models/Ecosystem/Structure';
import Attachment from '@/models/Attachment';

@Component
export default class ApproveInboxDocumentComponent extends PageRender {
    public document: InboxDocument = new InboxDocument();
    public isNew: boolean = false;
    public attachments: Attachment[] = [];
    public isSplitted: boolean = false;
    public selectedAttachment = null;
    public selectedTeam: StructureTeam = new StructureTeam();
    public teamChanged: boolean = false;

    public note: string = '';

    public $refs!: {
        declineApprovalModal: DeclineApprovalsModal;
    };

    public async created() {
        this.isNew = typeof this.$route.params.documentId === 'undefined';

        this.document = await this.loadDocument(this.$route.params.documentId);
        await this.loadStructure(this.document.purchaseInvoiceDate);
        this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());

        if (!this.document.teamId) {
            this.selectedTeam = this.structure.teams.find((y) => y.teamId === this.document.teamId);
        }

        const line = this.document.documentLines[0];

        if (line.department) {
            line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
            line.projects = await this.getProjects(line.departmentObj);
        } else {
            this.document.documentLines[0].departmentObj = this.selectedTeam;
            this.document.documentLines[0].department = this.selectedTeam.name;
            await this.selectDepartment(this.selectedTeam);
        }

        if (line.department && line.departmentObj && line.project) {
            let project = line.projects.find((y) => y.name && y.name.toLowerCase() === line.project.toLowerCase());
            line.projectObj = project;
        }

        if (!this.document.paymentMethod) {
            this.document.paymentMethod = this.getPaymentMethodOptions().find((x) => x.id === 2);
        }

        await this.loadAttachments();

        this.isLoading = false;
    }

    public async loadDocument(documentId) {
        const [err, document] = await to(inboxDocumentService.getInboxDocument(documentId));
        if (err) {
            return;
        }
        return document;
    }

    public async approveInboxDocument() {
        this.showPending('APPROVE_DOCUMENT_PENDING');
        const [errSubmit] = await to(inboxDocumentService.approveInboxDocument(this.document.id));
        if (errSubmit) {
            return this.clearAndShowError('APPROVE_DOCUMENT_FAILED', errSubmit);
        }

        this.clearAndShowSuccess('APPROVE_DOCUMENT_SUCCESS');
        await this.$router.push({ name: 'approvals', params: { teamId: teamHelper.currentTeamId.toString(), teamKey: teamHelper.currentTeam.key } });
    }

    public async showDeclineModal() {
        this.$refs.declineApprovalModal.show();
    }

    public async declineInboxDocument(id: number, reason: string) {
        this.showPending('DECLINE_DOCUMENT_PENDING');
        const [errSubmit] = await to(inboxDocumentService.declineInboxDocument(id, reason));
        if (errSubmit) {
            return this.clearAndShowError('DECLINE_DOCUMENT_FAILED', errSubmit);
        }

        this.clearAndShowSuccess('DECLINE_DOCUMENT_SUCCESS');
        await this.$router.push({ name: 'approvals', params: { teamId: teamHelper.currentTeamId.toString(), teamKey: teamHelper.currentTeam.key } });
    }

    public async submitInboxDocument() {
        const [errSubmit] = await to(inboxDocumentService.submitInboxDocument(this.document.id));
        if (errSubmit) {
            return this.clearAndShowError('SUBMIT_DOCUMENT_FAILED', errSubmit);
        }
        await this.$router.push({ name: 'declarations' });
    }

    public async loadAttachments() {
        const attachments = await inboxDocumentService.getAttachments(this.document.id);
        this.attachments = attachments;
    }

    public previewAttachment(attachment) {
        this.selectedAttachment = attachment;
        this.isSplitted = true;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            let updatePurchase = false;
            this.showPending('REMOVE_ATTACHMENT_PENDING');

            const line = this.document.documentLines.find((x) => x.attachmentId === attachment.fileId);
            if (line) {
                line.attachmentId = null;
                updatePurchase = true;
            }

            const [err] = await to(inboxDocumentService.deleteAttachment(this.document.id, attachment.fileId));
            if (err) {
                this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            if (updatePurchase) {
                await this.updateInboxDocument();
            }
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async updateInboxDocument() {
        this.submitted = true;
        // if ((this as any).$v.$invalid) {
        //     this.showWarning(`UPDATE_PURCHASE_INVALID`);
        //     return;
        // }

        this.showPending('UPDATE_PURCHASE_PENDING');

        const [err] = await to(inboxDocumentService.updateDocument(this.document));
        if (err) {
            return this.clearAndShowError('UPDATE_PURCHASE_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_PURCHASE_SUCCESS');
    }

    public async selectTeam() {
        this.teamChanged = true;
    }

    public async addAttachmentToLine(result) {
        this.document.documentLines.push(new DocumentLine());
        this.document.documentLines[0].attachmentId = result[0].fileId;

        await this.updateInboxDocument();
    }

    public async selectDepartment(department) {
        this.document.documentLines[0].projectObj = this.document.documentLines[0].project = null;
        this.document.documentLines[0].projects = await this.getProjects(department);
    }

    public getPaymentMethodOptions() {
        return [
            { id: 1, name: 'Private paid in advance' },
            { id: 2, name: 'Still to be paid via bank' },
            { id: 3, name: 'Credit card' },
            { id: 4, name: 'Direct debit' },
        ];
    }
}
