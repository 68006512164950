import { Person } from '../models/Person';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import { ecosystemHelper, teamHelper } from '@/main';
import to from 'await-to-js';

export class TeamService extends BaseService {
    private endpoint = `${Vue.$env().brightApiEndpoint}teams`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getMembers(teamId?: number): Promise<Person[]> {
        const monthString = `0${new Date().getMonth() + 1}`.slice(-2);
        const config = {
            headers: {
                'x-period': `${new Date().getFullYear()}-${monthString}`,
                'x-ecosystem': ecosystemHelper.currentEcosystemId,
            },
        };
        if (!teamId) {
            teamId = teamHelper.currentTeam.id;
        }

        const [err, response] = await to(this.get<Person[]>(`${this.endpoint}/${teamId}/members`, null, config));
        if (err) {
            this.showFailedResponse('Failed to load members', null);
            return [];
        }

        return response.data.map((x) => new Person(x));
    }
}
