import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
import { settingsService } from '@/main';
import TeamFinanceSettings from '@/models/Finance/TeamFinanceSettings';
import TeamSettings from '@/models/Finance/TeamSettings';
@Module
class SettingsModule extends VuexModule {
    public failed: boolean = false;
    private teamSettings: TeamSettings[] = [];

    public get findTeamFinanceSettings() {
        return (teamId: number) => {
            return this.teamSettings[teamId];
        };
    }

    @Action
    public async fetchTeamSettingsIfNeeded(teamId: number) {
        if (this.teamSettings.length === 0 || typeof this.teamSettings[teamId] === 'undefined' || this.teamSettings[teamId] === null) {
            return this.fetchTeamSettings(teamId);
        }
    }

    @Action
    public async fetchTeamSettings(teamId: number) {
        const [err, response] = await to(settingsService.getTeamSettings(teamId));
        if (err) {
            this.failTeamSettings(teamId);
        }

        const items = response ? response : new TeamFinanceSettings();
        this.setTeamSettings({ items, teamId });
    }

    @Mutation
    private failTeamSettings(teamId) {
        this.teamSettings.splice(teamId, 1);
        this.failed = true;
    }

    @Mutation
    private setTeamSettings(payload) {
        this.teamSettings[payload.teamId] = payload.items;
        this.failed = false;
    }
}

export const settingsModule = new SettingsModule({ store, name: 'settings' });
