import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import LegalEntity from '@/models/LegalEntity';
import Vue from 'vue';

@Component
export default class LegalEntitiesPage extends PageRender {
    public legalEntities: LegalEntity[] = [];
    public legalEntitiesColumns = [
        { field: 'name', title: 'NAME',  },
        { field: 'legalEntityReference', title: 'REFERENCE',  },
        { field: 'invoiceNumberPrefix', title: 'PREFIX',  },
        { field: 'action', title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public async mounted() {
        this.legalEntities = legalEntityModule.all;
        this.isLoading = false;
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Edit invoice data',
                function: this.editInvoiceData,
            },
            {
                title: 'Edit legal entity',
                function: this.editLegalEntity,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public createLegalEntitySideAction() {
        this.$sideActions.push('create-or-edit-legal-entity-action', {}, async (result) => {
            this.legalEntities.push(result);
            await legalEntityModule.fetch();
        });
    }

    public editInvoiceData(item) {
        this.$sideActions.push('create-or-edit-legal-entity-invoice-data', { legalEntity: item }, (result) => {
            console.log(result);
        });
    }

    public editLegalEntity(item) {
        this.$sideActions.push('create-or-edit-legal-entity-action', { legalEntity: item }, (result) => {
            console.log(result);
        });
    }

    public editLegalEntityDataRow(row) {
        this.$sideActions.push('create-or-edit-legal-entity-action', { legalEntity: row.dataItem }, (result) => {
            console.log(result);
        });
    }
}
