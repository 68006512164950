export default {
    // Nav
    DEALS: '.deals',
    PRODUCTS: '.products',
    ORGANIZATIONS: '.organizations',
    SETTINGS: '.settings',

    CRM: 'CRM',
    CRM_APP: 'CRM App',
    PURCHASES_APP: 'Purchases App',
    INVOICES_APP: 'Invoices App',
    COSTS_APP: '.costs',
    DIRECTORY: '.directory',
    FINANCES: '.finances',
    ANALYTICS: '.analytics',
    OVERVIEW: '.overview',
    SUBSCRIPTIONS: '.subscriptions',
    GRAPH: '.graph',
    SETTINGS_LANGUAGE: '.translate',
    NAV_INVOICES_SALES: '.sales',
    NAV_PURCHASES_SALES: '.purchases',
    NAV_ECO_DEALS: '.ecosystem deals',
    NAV_ECO_SUBSCRIPTIONS: '.ecosystem subscriptions',
    NAV_DEALS: '.deals',
    NAV_DEAL: '.deal',
    NAV_CONTACTS: '.contacts',
    NAV_CREATE_DEAL: '.new deal',
    NAV_PRODUCTS: '.products',
    NAV_PRODUCT: '.products',
    NAV_CREATE_PRODUCT: '.new product',
    NAV_ORGANIZATIONS: '.organizations',
    NAV_CREATE_ORGANIZATIONS: '.create organization',
    NAV_ORGANIZATION: '.organization',
    NAV_CREATE_CONTACT: '.create contact',
    NAV_CONTACT: '.contact',
    NAV_PURCHASES: '.purchases',
    NAV_CREATE_PURCHASE: '.create purchase',
    NAV_PURCHASE: '.purchase',
    NAV_LEDGERS: '.ledgers',
    NAV_SALES: '.sales',
    NAV_LEGAL_ENTITIES: '.legal entities',
    NAV_FOOTPRINT_PRODUCTS: '.footprint',
    NAV_SUBSCRIPTIONS: '.subscriptions',
    NAV_SUBSCRIPTION: '.subscription',
    NAV_INBOX: '.inbox',
    NAV_DECLARATIONS: '.declarations',
    NAV_APPROVALS: '.approvals',
    NAV_APPROVAL: '.approval',
    NAV_DOCUMENT: '.document',
    NAV_TEAM_SETTINGS: '.team settings',
    TEAM_SETTINGS: '.team settings',
    LEGAL_ENTITIES: 'Legal entities',

    // Me
    SWITCH_TEAM: 'Switch to team',
    PROFILE: 'Profile',
    ME_GROUPS: 'Your activities',
    ME_MEMBERS: 'Your team members',
    ENERGY_DEDICATION_SPENT: '',
    EXPLORE_ALL_GROUPS: 'Explore all activities',
    EXPLORE_ALL_MEMBERS: 'Explore all members of my team',

    // Team
    ECO_LEDGER_ACCOUNTS: 'Ledger accounts',
    ECO_CROSS_LEGAL_TRANSACTIONS: 'Cross legal transactions',
    CROSS_LEGAL_TRANSACTIONS_FAILED: 'Failed to load cross legal entity transactions',

    // General
    ADD_LEDGER_ACCOUNT: 'Add ledger account',
    ADD_TEAM: 'Add team',
    ADD_COMPENSATION: 'Add compensation',
    ADD_LINE: '+ add another line',
    ADD_PHONE_NUMBER: '+ add another phone number',
    ADD_EMAIL_ADDRESS: '+ add another email address',
    ALL_TEAMS_OF: 'All teams of ',
    ALL_GROUPS_OF: 'All activities of ',
    ALL_MEMBERS_OF: 'All members of ',
    ALL_TEAMS: 'All teams',
    ALL_GROUPS: 'All activities',
    ALL_MEMBERS: 'All members',
    ADD_BANKACCOUNT: 'Add bank account',
    ATTACHMENTS: 'Attachments',
    ATTACHED_FILES: 'Attached files',
    AMOUNT: 'Amount',
    BOOK_PURCHASE: 'Book purchase',
    BROWSER_DOESNT_SUPPORT: 'Unfortunately your browser does not support this feature.',
    CURRENCY: 'Currency',
    NOTE: 'Note',
    CONTACT: 'Contact',
    CONTACTS: 'Contacts',
    CONTACT_REQUIRED: 'A contact is required',
    CREATE_NEW_DEAL: 'Create deal',
    CREATE_NEW_LEAD: 'Create as lead',
    CREATE_NEW_PRODUCT: 'Create new product',
    CREATE_NEW_FOOTPRINT_PRODUCT: 'Create new footprint',
    CREATE_NEW_CONTACT: 'Create new contact',
    MERGE_CONTACTS: 'Merge contacts',
    ADD_CONTACT: 'Add contact',
    CREATE_NEW_ORGANIZATION: 'Create new organization',
    MERGE_ORGANIZATIONS: 'Merge organizations',
    VIEW_ORGANIZATION: 'View organization',
    CREATE_NEW_PURCHASE: 'Create new purchase',
    CREATE_NEW_LEDGER: 'Create new ledger',
    CREATE_NEW_LEAD_PURCHASE: 'Create as lead',
    CREATE_INVOICE: 'Create invoice',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    CREATE_GROUP: 'Create new activity',
    COMPENSATIONS: 'Compensations',
    COMPENSATE_ALL_MEMBERS: 'Compensate all members',
    DISTRIBUTE: 'Distribute',
    DESCRIPTION: 'Description',
    DEALS_TEXT: 'deals',
    DROP_DEAD_RATIO: 'Drop dead ratio',
    DEAL_PRODUCTS: 'Products',
    DAYS: 'days',
    EDIT_MAP: 'Edit position and radius',
    EXPENSES: 'Expenses',
    EXTRAS: 'Extras',
    ECOSYSTEM_DEALS: 'Ecosystem deals',
    EDIT_DEAL: 'Edit deal',
    EDIT_ORGANIZATION: 'Edit organization',
    EDIT_PRODUCT: 'Edit product',
    EDIT_CONTACT: 'Edit contact',
    EDIT_PURCHASE: 'Edit purchase',
    EMAIL_ADDRESS: 'Email address',
    EMAIL_ADDRESSES: 'Email addresses (selected is primary mail)',
    FIRST_NAME: 'First name',
    FINANCES_RESULT: 'Total result ecosystem',
    FINANCES_BALANCE: 'Total balance ecosystem',
    FINANCE_EXTERNAL_REVENUE: 'Total external revenue ecosystem',
    FILTER_DAYS: 'Filter invoice sent by last',
    FULLY_PAID: 'Fully paid',
    GROUP_RESULT: 'Activity result',
    GROUP: 'Activity',
    ITEM: 'Item',
    INCOMING: 'Incoming',
    INVOICES_SALES: 'Sales invoices',
    INVOICES_PURCHASES: 'Purchase invoices',
    INVOICES: 'Invoices',
    LEDGERS: 'Ledgers',
    LEDGER: 'Ledger',
    LEDGER_NAME: 'Ledger name',
    LEDGER_VALUE: 'Ledger value',
    LAST_NAME: 'Last name',
    LABEL: 'Label',
    MONTHLY: 'Monthly',
    MEMBER_COMPENSATIONS: 'Member compensations',
    MAKE_TRANSFER: 'Make transfer',
    NEW_DEAL: 'New deal',
    NEW_PURCHASE: 'New purchase',
    NAME_DEAL: 'Name deal',
    TEAM_DEAL: 'Team',
    EXPORT_DEAL: 'Export deal',
    EXPORT_DEALS: 'Export deals',
    COPY_DEAL: 'Copy deal',
    COPY_SUBSCRIPTION: 'Copy',
    ARCHIVE_SUBSCRIPTION: 'Archive',
    COPY_PURCHASE: 'Copy',
    NAME_IS_REQUIRED: 'Name is a required field',
    OWNER: 'Owner',
    ORGANIZATION: 'Organization',
    ORGANIZATION_ADD_CONTACT: 'Add contact',
    ORGANIZATION_NAME: 'Organization name',
    ORGANIZATION_NAME_EXISTS: 'An organization with this name already exists',
    ORGANIZATION_VAT: 'Organization VAT',
    ORGANIZATION_VAT_WARNING:
        'Leaving VAT number empty results in VAT that will be charged to your client but is not deductible in the country of origin',
    ORGANIZATION_IBAN: 'Organization IBAN',
    ORGANIZATION_COC: 'Organization CoC',
    ORGANIZATION_COC_EXISTS: 'An organization with this Chamber of Commerce number already exists',
    ORGANIZATION_ADDRESSLINEONE: 'address line 1',
    ORGANIZATION_ADDRESSLINETWO: 'address line 2',
    ORGANIZATION_ZIPCODE: 'Organization zip code',
    ORGANIZATION_CITY: 'Organization city',
    ORGANIZATION_STATE: 'Organization state',
    ORGANIZATION_COUNTRY: 'Organization country',
    ORGANIZATION_COUNTRY_EX: 'Outside EU?',
    ORGANIZATION_LANGUAGE: 'Preferred language',
    ORGANIZATION_REQUIRED: 'A organization is required',
    ORGANIZATION_TEMPLATE: 'Template',
    ORGANIZATION_SETTINGS: 'Settings',
    ORGANIZATION_SETTINGS_TITLE: 'Organization settings',
    ORGANIZATION_SETTINGS_ALIASES: 'Organization aliases',
    ORGANIZATION_SETTINGS_UPDATE: 'Update settings',
    PURCHASE_REMIND: 'Remind',
    PURCHASE_REMIND_FILTERED: 'Reminders',
    PURCHASE_BATCH_REMINDER_SEND: 'Send reminders',
    PURCHASE_DATE_IN_PAST: 'This date is longer then 30 days ago, are you sure?',
    REF_TO_BIG: 'Invoice number extracted from this field is to big. It has to be smaller than 2 147 483 647',

    HIDDEN_COLUMNS: 'Hidden columns',
    HIDDEN_COLUMNS_NUMBER: 'column(s) hidden',

    LEAD: 'Lead',
    OPEN: 'Open',
    WON: 'Won',
    NEEDS_TO_BE_INVOICED: 'Needs to be invoiced',
    INVOICE_SENT: 'Invoiced (open) 😢',
    INVOICE_PAID: 'Invoiced (paid) 🥳',
    ARCHIVE: 'Archive',
    INBOX: '.inbox',
    INBOX_TITLE: 'Incoming documents',

    OPEN_INVOICES: 'Total open invoices',
    OPEN_EXPENSES: 'Total open expenses',
    PLACEHOLDER_ANYTHING_YOU_LIKE: 'Anything you like...',
    PLACEHOLDER_ORGANIZATION: 'Select organization...',
    PLACEHOLDER_OWNER: 'Select owner...',
    PURCHASES: 'Purchases',
    ECOSYSTEM_PURCHASES: 'Ecosystem purchases',
    PURCHASE_NAME: 'Purchase name',
    PURCHASE_PRODUCTS: 'Purchase products',
    APPROVAL_PRODUCTS: `Invoice lines, you're approval applies on the <b class="responsible">green highlighted</b> lines.`,
    PURCHASE_INVOICE_NUMBER: 'Invoice number',
    PURCHASE_REFERENCE: 'Reference/invoice number',
    PURCHASE_DATE: 'Purchase date',
    PURCHASE_SKIP_EXTERNAL_INVOICING: 'Skip external invoicing',
    PRODUCTS_TITLE_TEAM: `Team products`,
    PRODUCTS_TITLE: 'Other products',
    FOOTPRINT_PRODUCTS_TITLE: 'Footprints',
    FOOTPRINT_PRODUCT_NAME: 'Footprint name',
    FOOTPRINT_TYPE: 'Footprint type',
    FOOTPRINT_VALUE: 'Factor (ratio 1€)',
    PRODUCT_NAME: 'Product name',
    PRODUCT_PRICE: 'Product price',
    PHONE_NUMBER: 'Phone number',
    PHONE_NUMBERS: 'Phone numbers',
    PRICE: 'Price',
    PRICE_INC: 'Price incl.',
    PRICE_EXCL: 'Price excl.',
    CLIENT_REFERENCE_PURCHASE_ORDER: 'Client reference / purchase order',
    PURCHASE_ORDER: 'Client reference / purchase order',
    INVOICE_TEMPLATE: 'Template',
    PDF_DOWNLOAD: 'Download pdf',
    RECEIVER: 'Receiver',
    RELATIONS: 'Relations',
    TABLE_VIEW: 'Table view',
    TAX_RATE: 'Tax rate',
    TAX: 'Tax',
    TOTAL_EXPENSES: 'Total expenses',
    TOTAL_EXTRAS: 'Total extras',
    TOTALS: 'Totals',
    TOTAL: 'Total',
    BUDGET: 'Budget',
    TOTAL_WITH_TAX: 'Total inc. tax',
    TEAM_PROFILE: 'Team profile',
    TEAM_NAME: 'Team name',
    TEAMS_INCLUDED_IN_TOTALS: 'Teams included in totals',
    TRANSFERS: 'Transfers',
    QUARTERLY: 'Quarterly',
    ROLES: 'Roles',
    ROLE_MANAGEMENT: 'Role management',
    RECURRING_TRANSFER: 'Perform this transfer periodically',
    RECURS_EVERY: 'Recurs every...',
    RECURRING_TRANSFERS: 'Recurring transfers',
    RECURRING_COMPENSATIONS: 'Recurring compensations',
    SAVE: 'Save',
    SAVE_TEAM_PROFILE: 'Save team profile',
    SELECT_PERSON_GROUP: '--Select a person/activity--',
    SELECT_RECEIVER: '-- Select a receiver --',
    SENDER: 'Sender',
    SEARCH: 'Search',
    SET_DEAL_AS_WON: 'Set deal as won',
    SET_DEAL_AS_OPEN: 'Set deal as open',
    SET_DEAL_AS_TO_BE_INVOICED: 'Set deal as to be invoiced',
    SET_DEAL_AS_INVOICED: 'Set deal as invoiced',
    SET_DEAL_INVOICE_MAIL: 'Mail content',
    SET_PURCHASE_AS_BOOKED: 'Set purchase as booked',
    SWITCH_ECO: 'Switch between ecosystems',
    SWITCH_TO_ECO: 'Switch to another ecosystem',
    SWITCH_LEGALENTITY: 'Switch legal entity',
    SEND_INVOICE: 'Invoice mail',
    SEND_REMINDER: 'Send reminder',
    TEAM: 'Team',
    SUBTOTAL: 'Subtotal',
    UPDATE_DEAL: 'Update deal',
    UPDATE_PRODUCT: 'Update product',
    CREATE_PRODUCT: 'Create product',
    UPDATE_ORGANIZATION: 'Update organization',
    UPDATE_CONTACT: 'Update contact',
    UPDATE_PURCHASE: 'Save',
    UPDATE_DOCUMENT: 'Save',
    UPDATE_LEDGER: 'Update ledger',
    UPDATE: 'Update',
    CREATE_RULE: 'Create rule',
    UPDATE_RULE: 'Update rule',
    EDIT_RULE: 'Edit rule',
    RULE_NAME: 'Name',
    RULE_TAXRATE_LABEL: 'Tax rate',
    RULE_COUNTRY_LABEL: 'Country',
    RULE_COUNTRY_EX_LABEL: 'Country outside EU?',
    RULE_VAT_LABEL: 'Needs VAT number?',
    RULE_VAT_DESCRIPTION: 'Needs VAT number?',
    RULE_COUNTRY_EX_DESCRIPTION: 'Applies on organizations outside Europe',
    RULES: '.rules',
    NAV_RULES: 'All rules',
    NAV_CREATE_SUBSCRIPTION: 'Create subscription',
    PRODUCT_TAX_RATE: 'Tax rate',
    PRODUCT_FOOTPRINT_PRODUCTS: 'Coupled footprint products',
    LANGUAGE: 'Invoice language',
    INVOICE_DATE: 'Invoice date',
    INVOICE_BOOK_DATE: 'Invoice book date',
    APPLIED_TAXRATE: 'The applied tax rate rule is:',

    ATTACHMENTS_INFO: 'Attachments can be uploaded after the deal is created',
    ATTACHMENTS_PURCHASES_INFO: 'Attachments can be uploaded after the purchase is created',

    INVOICE_STATUS: 'Invoice status',
    INVOICE_DUEDATE: 'Invoice DueDate',
    INVOICE_LEGALENTITY: 'Invoice Legal entity',
    INVOICE_REFERENCE: 'Invoice Reference',
    INVOICE_PAYMENT_STATUS: 'Betaalstatus',
    INVOICE_CUSTOMER: 'Customer',
    INVOICE_CURRENCY: 'Currency',
    INVOICE_DATECREATED: 'Created on',
    INVOICE_DATEUPDATED: 'Updated on',
    INVOICE_DATEREMINDED: 'Reminded on',
    INVOICE_DELETED: 'Is deleted?',
    INVOICE_ATTACHMENTS: 'List of attachments',
    INVOICE_ECOSYSTEM: 'Ecosystem Id',

    // Settings
    SETTINGS_TRANSLATE_GENERAL: 'General',
    SETTINGS_ALERT_MESSAGES: 'Alerts',
    SETTINGS_REGISTER_MESSAGES: 'Register',
    SETTINGS_MAP_MESSAGES: 'Map',
    SETTINGS_MODAL_MESSAGES: 'Modals',
    SETTINGS_GRID_MESSAGES: 'Grids',
    SETTINGS_SETTING_MESSAGES: 'Settings',
    SETTINGS_ALERT_TIP: `Tip: search for 'pending', 'failed' or 'success'.`,
    SETTINGS_TRANSLATE_LANGUAGE: 'Languages',
    SETTINGS_ADD_LANGUAGE: 'Add language',
    MOVE_DEAL: 'Move deal',

    EDIT_SETTINGS: 'Edit team settings',
    UPDATE_TEAM_SETTINGS: 'Update',
    TEAM_DEFAULT_COUNTRY: 'Default country',
    TEAM_DEFAULT_CURRENCY: 'Default currency',

    WANT_TO_EDIT_INVOICE_DATA: 'Want to update invoice data?',
    EDIT_INVOICE_DATA: 'Edit invoice data',

    NEW_LEGAL_ENTITY: 'Create legal entity',
    EDIT_LEGAL_ENTITY: 'Edit legal entity',
    LEGAL_ENTITY_NAME: 'Name',
    LEGAL_ENTITY_REFERENCE: 'Reference',
    LEGAL_ENTITY_PREFIX: 'Invoice prefix',
    LEGAL_ENTITY_PAYMENT_TERM: 'Payment term',
    LEGAL_ENTITY_CHARGED_PURCHASE: 'Charged purchase ledger',
    LEGAL_ENTITY_CHARGED_SALES: 'Charged sales ledger',
    LEGAL_ENTITY_CHARGED_EXCEPTIONS: 'Charged ledger exceptions',
    LEGAL_ENTITY_CHARGED_EXCEPTION: 'Charged ledger exception',
    LEGAL_ENTITY_MOLLIE_KEY: 'Mollie key',
    LEGAL_ENTITY_DEFAULT_TAX_RATE: 'Default tax rate',
    LEGAL_ENTITY_TEMPLATE_TEXT: 'Email settings',
    LEGAL_ENTITY_WON_DEAL: 'Won deal',
    LEGAL_ENTITY_WON_DEAL_DESCRIPTION: 'This e-mail will be send when an deal is won.',
    LEGAL_ENTITY_SEND_INVOICE: 'Send invoice',
    LEGAL_ENTITY_SEND_INVOICE_DESCRIPTION: 'This e-mail will be send when an invoice will be send.',
    LEGAL_ENTITY_SEND_REMINDER: 'Send reminder',
    LEGAL_ENTITY_SEND_REMINDER_DESCRIPTION: 'This e-mail will be send when a reminder will be send.',
    LEGAL_ENTITY_SEND_APPROVAL: 'Send approval',
    LEGAL_ENTITY_SEND_APPROVAL_DESCRIPTION: 'This e-mail will be send when an approval will be send.',
    CREATE_NEW_LEGAL_ENTITY: 'Create legal entity',
    UPDATE_LEGAL_ENTITY: 'Update legal entity',

    NEW_INVOICE_DATA_TEMPLATE: 'New invoicing template',
    NEW_INVOICE_DATA: 'Edit invoice data',
    LEGAL_ENTITY_TEMPLATES_LOADING: 'Loading invoicing templates',
    LEGAL_ENTITY_TEMPLATES: 'Invoicing templates',
    CREATE_NEW_LEGAL_ENTITY_TEMPLATE: 'New template',
    CREATE: 'Create',

    TEMPLATE: 'Alternative invoice template',

    DEFINE_RELATION: 'Define relation',
    DEFINE_RELATION_LABEL: 'Relation type',
    UPDATE_DEFINE_RELATION: 'Update contact',

    ECOSYSTEM_SUBSCRIPTIONS: 'Ecosystem subscription',
    CREATE_NEW_SUBSCRIPTION: 'Create new subscription',
    NEW_SUBSCRIPTION: 'New subscription',
    NAME_SUBSCRIPTION: 'Subscription name',
    PARAM_EXPLANATION: 'You can use {!Month} as a parameter to add the current month in the generated deal name.',
    EDIT_SUBSCRIPTION: 'Edit subscription',
    SCHEDULE_DAY_OF_MONTH: 'Run on day of month',
    SCHEDULE_MONTH_OF_YEAR: 'Run on month of year',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    SUBSCRIPTION_PRODUCTS: 'Subscription products',
    SCHEDULE: 'Schedule',
    INTERVAL: 'Interval',
    SUBSCRIPTION_OUTPUT: 'Output status',
    PLACEHOLDER_ORGANIZATIONS_FILTER: 'Select country ({count})',

    ADMINISTRATIVE_CONTACT: 'Administrative contact',
    NO_ADMINISTRAIVE_CONTACTS: 'No administrative contacts defined in this organization',
    INVOICE_DIRECTLY: 'Yes, invoice directly',
    INVOICE_DIRECTLY_LABEL: 'Want to send invoice after creating deal automatically?',
    UPDATE_SUBSCRIPTION: 'Update subscription',

    INVOICE_RECEIVER_OPTIONAL: 'Send invoice to a contact automatically',

    EDIT_PURCHASE_LINE: 'Edit line',
    PREDEFINED_INVOICE_NUMBER: 'Predefined invoice number',

    APPROVALS_TITLE: 'Purchase approvals',
    DOCUMENT_APPROVALS_TITLE: 'Document approvals',

    GET_PURCHASE_APPROVAL: 'Get purchase approval',
    FORCE_PURCHASE_APPROVAL: 'Force approval and book',

    DECLINE_PURCHASE_APPROVAL: 'Decline approval',
    ACCEPT_PURCHASE_APPROVAL: 'Accept approval',

    EXPECTED_TOTAL: 'Expected total (inc. taxes)',
    EXPECTED_TOTAL_DIFFERENCE: 'Difference',
    CREATE_NEW_DOCUMENT: 'Create new declarations',
    DECLARATIONS_TITLE: 'My declarations',
    NEW_DOCUMENT: 'New declaration',
    UPLOAD_YOUR_ATTACHMENT: 'Upload your attachment',

    DOCUMENT_ACTIVITY: 'Which person or activity is accountable for theses costs?',
    DOCUMENT_TEAM: 'Which team is accountable for theses costs?',
    TEAM_DOCUMENT: 'Legal entity team',
    EDIT_DOCUMENT: 'New',
    DOCUMENT_PAYMENT_METHOD: 'Payment method',
    TEAM_NOTE: 'Note for admin/approver',
    OPTIONAL_INFO: 'Optional additional information',
    SUBMIT_DOCUMENT: 'Submit',
    CREATE_DOCUMENT: 'Save & submit',
    CREATE_DRAFT_DOCUMENT: 'Save',
    NO_ATTACHMENTS: 'No attachment uploaded',

    DOCUMENT_INITIATOR: 'Initiator',
    DOCUMENT_NAME: 'Name',
    DOCUMENT_TYPE: 'Type',
    DOCUMENT_PRODUCTS: 'Submitted lines',
    DOCUMENT_REFERENCE: 'Reference/Invoice number',

    CREATE_PURCHASE: 'Create purchase concept ',
    CREATE_PURCHASE_AND_BOOK: 'Book purchase',
    CREATE_PURCHASE_ASK_APPROVAL: 'Create purchase & ask approval(s)',
    FORCE_CREATE_PURCHASE: 'Force create purchase concept',
    FORCE_CREATE_PURCHASE_AND_BOOK: 'Force book purchase',
    DOCUMENT_NO_TEAM: 'No team selected',
    DOCUMENT_NO_ACTIVITY_PERSON: 'No person of activity selected',

    DECLINE_DOCUMENT: 'Decline document',
    APPROVE_DOCUMENT: 'Approve document',
    DOCUMENT_NOTE: 'Note',

    SEND_REMINDERS: 'Send reminders (to all pending approvals)',

    PREDICTIONS: 'Predictions',
    PREVIEW_PREDICTIONS: 'Preview predictions',

    CONGRATZ_PAYMENT: `Congrats, you're payment is received!`,
    PAYMENT_MESSAGE_EXPIRED: 'The payment process was started, but not completed within the time limit.',
    PAYMENT_EXPIRED: 'Payment expired',
    PAYMENT_OPEN: 'Payment status is still open',
    PAYMENT_MESSAGE_OPEN: `We haven't received any information from our payment provider about your payment yet.`,
    PAYMENT_FAILED: 'The payment failed',
    PAYMENT_MESSAGE_FAILED: `Something went wrong during the payment process. You can retry the payment by opening the link in your invoice again or contact us.`,
    PAYMENT_CANCELED: `Payment canceled by user`,
    PAYMENT_MESSAGE_CANCELED: `Payment canceled by user`,
    PAYMENT_MESSAGE: 'You will receive a confirmation over the mail.',
    INVALID_PAYMENT_LINK: 'Sorry, this link is not valid (anymore).',
    INVALID_PAYMENT_LINK_TEXT: 'Possibly you received an updated link which still is active.',
    PAYMENT: 'Payment',
    PAYMENT_INTRO: `You're about to make a payment for: <b>{description}</b>`,
    PAYMENT_AMOUNT: 'The remaining amount is: <span class="font-weight-bolder">{price}</span>',
    PAYMENT_PARTNER: `You're leaving our site and will be redirected to our payment partner`,
    MAKE_PAYMENT: 'Make payment',

    REMOVE_ATTACHMENT_CONFIRM_TEXT: 'Do you really want to delete this attachment?',
    REMOVE_ATTACHMENT_CONFIRM_TITLE: 'Delete attachment?',
    YES: 'Yes',
    NO: 'No',

    CONTACT_INFO: 'The following contact will receive an email when the payment is completed',
    CONTACT_NAME: 'Name',
    CONTACT_MAIL: 'Email address',

    ADD_PAYMENT: 'Add payment',
    ADD_PAYMENT_TITLE: 'Add payment',
    SEARCH_ORGANIZATION_MODAL_TEXT: ' Click on the organization from the results you want to use:',
    ADD_ORG_FROM_PREDICTION: 'Add organization from prediction',
    NO_ORGANIZATION_FOUND: 'No matching organization found.',
    UNKNOWN_CURRENCY: 'Unknown currency',
    GROUP_PERSON: 'Activity/Person',
    PRODUCT: 'Product',
    ADD_LINE_FROM_PREDICTIONS: 'Add line(s)',

    LEGAL_ENTITY: 'Legal entity',
    ADD_LEGAL_ENTITY_CHARGED_EXCEPTION: ' + add exceptions for legal entity',
    PREVIOUS_INVOICES: 'Historical invoice',
    USE_ALL_PREDICTION_LINES: 'Use all lines',
    TEAM_INVOICE_DIFFERENCE: 'Team is different from team of this inbox item, click here to change to {team}',

    GO_TO_LINKED_INVOICE: 'Go to linked invoice',
    GO_TO_LINKED_DEAL: 'Go to linked deal',
    GO_TO_LINKED_PURCHASE: 'Go to linked purchase',

    HIDE_RAW: 'Hide raw predictions',
    SHOW_RAW: 'Show raw predictions',

    CREATE_NEW_DOCUMENTS: 'Multi upload',
    MULTI_UPLOAD_SIDE_ACTION: 'Upload multiple documents',
    UPLOAD_MULTI_BUTTON: 'Upload',
    UPLOAD_BUTTON: 'Upload',

    USE_ORG: 'Use organization details',
    IMPORT_LEDGERS: 'Import ledgers',

    DUPLICATE_REF_ORG_TEXT: 'This reference is already booked in this team. Do you want to continue?',
    DUPLICATE_REF_ORG_TITLE: 'Duplicate found!',

    ADD_ALIAS: 'Add alias',
    SET_AS_DEFAULT: 'Set as default',

    APPROVAL_OVER_RULED: 'Approval over ruled by finance administrator',
    ORG_DOCUMENTS: 'Documents',
    UPLOAD_ORG_ATTACHMENT: 'Upload document',

    ORG_PRODUCTS: 'Allowed products',
    CONNECT_PRODUCT_ORG: 'Connect product to organization',

    SEND_REMIND_DEAL: 'Send reminder',
    NO_ADMINISTRATIVE_CONTACT: 'No administrative contact selected',
    SEND_INVOICE_REMINDER: 'Send reminder',

    NO_ORGANIZATION_SELECTED: 'No organization selected. To continue, please select an organization.',
    MAIL_LOG: 'Mail log',
    SHOW_MAIL_LOG: 'Show mail log',
    CONNECTED_ACTIVITIES: 'Connected activities',
    ADD_ACTIVITY: 'Add activity',
    MANUALLY_SELECT: 'Manually select',
    PRODUCT_CONNECTED_ACTIVITIES: 'Product connected activities',
    NON_CONNECTED_ACTIVITIES: 'Non connected activities',
    ADD_CONNECTED_ACTIVITY: 'Connect activity to product',
    CONNECT: 'Connect',
    CONNECT_TO_ORG: 'Connect to organization',
    TEAM_NOT_FOUND_PERIOD: 'Team not found for this period',

    GRID: {
        APPROVAL_STATE: 'State',
        AMOUNT_ALL: '(All)',
        AMOUNT: 'Amount',
        AMOUNT_LEFT: 'Amount left',
        AMOUNT_FULLY_DISTRIBUTED: 'Fully distributed',
        AMOUNT_NOT_YET_DISTRIBUTED: 'Not yet distributed',
        ACTIONS: 'Actions',
        BALANCE: 'Balance',
        COMPENSATION: 'Compensation',
        CUSTOMER: 'Customer',
        DATE: 'Date',
        DESCRIPTION: 'Description',
        DEPARTMENT: 'Department',
        DROP_DEAD_RATIO: 'Drop dead ratio',
        ENERGY_DEDICATION: 'Energy dedication',
        EMAIL: 'E-mail',
        EXTRAS: 'Extras',
        EXTRA_NON_RECURRING: 'Extra - non recurring expenses',
        EXPENSES: 'Expenses',
        EXPENSES_RECURRING: 'Expenses - Recurring expenses',
        END_BALANCE: 'End balance',
        EXTERNAL_REVENUE: 'External Revenue',
        ECOSYSTEM: 'Ecosystem',
        FIRST_NAME: 'First name',
        FILTER_BY: 'Filter by',
        FINANCIAL_COMPENSATION: 'Financial compensation',
        FINANCE_EMPLOYEE: 'Finance Employee',
        GROUP_NAME: 'Activity name',
        GROUP_PURPOSE: 'Purpose',
        GROUP_MEMBERS: 'Members',
        GROUP_LEAD: 'Activity lead',
        GROUPS: 'Activities',
        INVOICE: 'Invoice',
        ID: 'Id',
        INVOICE_DATE: 'Invoice date',
        IS_RECURRING: 'Is Recurring',
        INITIATOR: 'Initiator',
        LAST_NAME: 'Last name',
        LANGUAGE: 'Taal',
        LEDGER: 'Ledger',
        MEMBER: 'Member',
        MEMBERS_OF: 'Member of',
        NO_TASKS: 'No tasks',
        NO_RECORDS: 'No records found',
        NAME: 'Name',
        TRANSLATIONS: 'Available translations',
        OPEN_INVOICES: 'Open invoices',
        OPEN_INVOICE_AMOUNT: 'Open invoice amount',
        OPEN_EXPENSES: 'Open expenses',
        OPEN_EXPENSE_AMOUNT: 'Open expense amount',
        OPEN_TASKS: 'open tasks',
        PHONE: 'Phone number',
        PRICE: 'Price',
        PROJECT: 'Project',
        QUANTITY: 'Quantity',
        RECEIVER: 'Receiver',
        RESULT: 'Result',
        REFERENCE: 'Reference',
        SLACK: 'Slack',
        SENDER: 'Sender',
        SITE_ADMIN: 'Site Admin',
        START_BALANCE: 'Start balance',
        SCHEDULE: 'Schedule',
        STATUS: 'Status',
        TEAM: 'Team',
        VALUE: 'Value',
        PREFIX: 'Invoice number prefix',
        COUNTRY_CODE: 'Country code',
        EX_EUROPE: 'Outside Europe?',
        TAXRATE: 'Tax rate',
        VAT: 'Needs VAT?',
        ORGANIZATION: 'Organization',
        FOOTPRINT_TYPE: 'Footprint type',
        FOOTPRINT_VALUE: 'Factor (ratio of 1€)',
        DOCUMENT_NAME: 'Name',
        DOCUMENT_TYPE: 'Type',
        NUMBER: 'Number',
        OWNER: 'Owner',
    },

    MODALS: {
        MERGE_LINES_PREDICTION_TITLE: `Prefill the lines with default values`,
        MERGE_LINES_PREDICTION_DESC: `You're about to add one or more line to the purchase. Please choose which team, activity/person,
        product and price type are representative for most of the lines, this will be prefilled for you.`,
        SALES_LEDGER_ACCOUNT: 'Sales ledger account',
        PURCHASE_LEDGER_ACCOUNT: 'Purchase ledger account',
        INTELECTUAL_PROPERTY_LIMITATION: 'Intellectual property limitation',
        DEFINE_RELATIONSHIP: 'Define relationship',
        VIEW_RELATIONSHIP_SETTINGS: 'View settings',

        YOU_SCARED_ME: 'You scared me, cancel it!',
        DO_IT: 'Do it!',
        CREATE_RECURRING_TRANSFER: 'Create recurring transfer',

        SET_AS_WON_TITLE: `Congratulations! In the pocket`,
        SET_AS_WON_CONTENT: 'set as won content',

        OVERRIDED_TEMPLATE: 'The applied <b>overridden</b> invoice template is:',
        APPLIED_TEMPLATE: 'The default invoice template of the legal entity is applied: ',

        SET_AS_INVOICED_TITLE: `Set deal as invoiced`,
        SET_AS_INVOICED_CONTENT: `Confirm that you want to set the deal to invoiced. Paperless will automatically generate an invoice for the deal. If you're not sure this deal should be invoiced already, please hit the X on the top right of this window!`,

        SET_AS_BOOKED_TITLE: 'Set purchase as won',
        SET_AS_BOOKED_CONTENT: 'Set as booked content',

        MOVE_DEAL: 'Move deal',
        OTHER_TEAM: 'Other team',
        MOVE_DEAL_TITLE: 'Move deal',

        INVOICE_SEND_TITLE: 'Send {type}',
        INVOICE_SEND_NAME: 'Name',
        INVOICE_SEND_EMAIL: 'Email address',
        SEND_INVOICE_MAIL: 'Send mail',

        PENDING_APPROVALS: 'Approval status',
        REASON: 'Please try to provide a full and clear reason',
        DECLINE_APPROVAL_TITLE: 'What is your reason to decline this approval?',

        ASK_APPROVAL_MODAL: 'Create purchase and immediately ask for approval',
        ASK_APPROVAL_MODAL_CONTENT: 'When asking approval, the following person will be asked for approval:',
        CONFIRM_CROSS_LEGAL_ENTITY_TITLE: 'Cross legal entity purchase',
        CONFIRM_CROSS_LEGAL_ENTITY: 'A cross legal entity purchase is being made, are you sure you want to continue?',
        PURCHASE_BATCH_REMINDER_TITLE: 'Send reminders',
        PURCHASE_BATCH_REMINDER_CONTENT: 'Are you sure you want to send a reminder for the following purchases?',
        PURCHASE_BATCH_REMINDER_SUCCESS: 'Reminders sent',

        INVOICE_EMAIL_TEMPLATE: 'Template',
        INVOICE_EMAIL_SUBJECT: 'Subject',
        INVOICE_EMAIL_CONTENT: 'Mail content',

        REMINDER_DEAL: 'Send a reminder',
        REMIND_DEAL_CONTENT: 'This invoice has not been paid, bummer. Get some 💸 🤑 💵 💰 by reminding the client.',
        REMIND_DEAL_LAST: 'Already reminded this deal. The last reminder is sent on <strong>{date}</strong>',
    },

    // Alert messages
    ALERT_MESSAGES: {
        ERROR: 'Oh snap! Something has gone wrong...',
        WARNING: `Better check that thingy, this isn't valid...`,
        SUCCESS: 'Woohoo! Your changes are saved.',
        LOAD_BLOB: 'Loading attachment from storage',
        FAILED_TO_RESOLVE_PAYMENT_URL: 'Something failed in starting the payment..',
        CALCULATING_RESULT_PENDING: 'Calculating results...',
        ARCHIVE_DEAL_PENDING: 'Archiving deal..',
        ARCHIVE_DEAL_FAILED: 'Failed to archive deal',
        ARCHIVE_DEAL_SUCCESS: 'Deal archived',
        ARCHIVE_CONTACT_PENDING: 'Archiving contact...',
        ARCHIVE_CONTACT_FAILED: 'Failed to archive contact',
        ARCHIVE_CONTACT_SUCCESS: 'Contact archived',
        DECOUPLE_CONTACT_PENDING: 'Decouple contact...',
        DECOUPLE_CONTACT_FAILED: 'Failed to Decouple contact',
        DECOUPLE_CONTACT_SUCCESS: 'Contact Decoupled',
        CONTACT_ADDED: 'Contact added to organization',
        ARCHIVE_ORGANIZATION_PENDING: 'Archiving organization...',
        ARCHIVE_ORGANIZATION_FAILED: 'Failed to archive organization',
        ARCHIVE_ORGANIZATION_SUCCESS: 'Organization archived',
        ARCHIVE_PRODUCT_PENDING: 'Archiving product...',
        ARCHIVE_PRODUCT_FAILED: 'Failed to archive product',
        ARCHIVE_PRODUCT_SUCCESS: 'Product archived',
        ARCHIVE_INVOICE_PENDING: 'Archiving invoice...',
        ARCHIVE_INVOICE_SUCCESS: 'Invoice archived',
        ARCHIVE_INVOICE_FAILED: 'Failed to archive invoice',
        BOOKING_INVOICE_PENDING: 'Booking invoice...',
        BOOKING_INVOICE_SUCCESS: 'Invoice booked',
        BOOK_PURCHASE_INVALID: `The purchase didn't pass the validation.`,
        BOOK_PURCHASE_PENDING: 'Booking the purchase...',
        BOOK_PURCHASE_FAILED: 'Failed to book the purchase',
        BOOK_PURCHASE_SUCCESS: 'Purchase booked!',
        CREATE_ATTACHMENT_FAILED: 'Failed to create attachment',
        CREATE_OR_UPDATE_LEDGER_INVALID: `The ledger didn't pass the validation.`,
        CREATE_OR_UPDATE_LEDGER_PENDING: 'Creating/updating ledger account..',
        CREATE_OR_UPDATE_LEDGER_FAILED: 'Failed creating or updating ledger account',
        CREATE_OR_UPDATE_LEDGER_SUCCESS: 'Successfully created/updated ledger account',
        CREATE_CONTACT_FAILED: 'Failed to create contact',
        CREATE_DEAL_INVALID: `The deal didn't pass the validation.`,
        CREATE_DEAL_PENDING: 'Creating deal...',
        CREATE_DEAL_FAILED: 'Failed to create deal',
        CREATE_INVOICE_PENDING: 'Creating invoice...',
        CREATE_INVOICE_FAILED: 'Failed to create an invoice.',
        CREATE_INVOICE_SUCCESS: 'Invoice created!',
        CREATE_ORGANIZATION_INVALID: `The organization didn't pass the validation.`,
        CREATE_ORGANIZATION_PENDING: 'Creating organization...',
        CREATE_ORGANIZATION_FAILED: 'Failed to create organization',
        CREATE_PRODUCT_INVALID: `The product didn't pass the validation.`,
        CREATE_PRODUCT_PENDING: 'Creating product...',
        CREATE_PRODUCT_FAILED: 'Failed to create product',
        CREATE_PURCHASE_INVALID: `The purchase didn't pass the validation.`,
        CREATE_PURCHASE_PENDING: 'Creating purchase...',
        CREATE_PURCHASE_FAILED: 'Failed to create purchase',

        CREATE_RULE_INVALID: `The rule didn't pass the validation.`,
        CREATE_RULE_PENDING: 'Creating rule...',
        CREATE_RULE_FAILED: 'Failed to create rule',

        CREDITING_INVOICE_PENDING: 'Crediting invoice...',
        CREDITING_INVOICE_SUCCESS: 'Invoice credited',
        CREDITING_INVOICE_FAILED: 'Failed to credit invoice',
        CLONE_DEAL_FAILED: 'Failed to clone deal',
        CLONE_DEAL_SUCCESS: 'Deal copied',
        CLONE_SUBSCRIPTION_FAILED: 'Failed to clone subscription',
        CLONE_SUBSCRIPTION_SUCCESS: 'Subscription copied',
        CLONE_PURCHASE_PENDING: 'Cloning purchase..',
        CLONE_PURCHASE_FAILED: 'Failed to clone purchase',
        CLONE_PURCHASE_SUCCESS: 'Purchase copied',
        DEAL_WON_INVALID: `The deal didn't pass the validation.`,
        DEAL_WON_PENDING: 'Set deal as won...',
        DEAL_WON_FAILED: 'Failed to set deal as won',
        DEAL_WON_SUCCESS: 'Congratulations, this deal is set as won!',
        DEAL_INVOICED_INVALID: `The deal didn't pass the validation.`,
        DEAL_INVOICED_PENDING: 'Set deal as invoiced...',
        DEAL_INVOICED_FAILED: 'Failed to set deal as won',
        DEAL_INVOICED_SUCCESS: 'Congratulations, this deal is set as invoiced!',
        DELETE_INVOICE_PENDING: 'Deleting invoices..',
        DELETE_INVOICE_FAILED: 'Deleting invoices failed.',
        DELETE_INVOICE_SUCCESS: 'invoices deleted',
        INVALID_VALUE: 'Invalid value',
        LOAD_ATTACHMENTS_FAILED: 'Failed to load attachments',
        LOAD_CONTACTS_FAILED: 'Failed to load contacts',
        LOAD_CONTACT_FAILED: 'Failed to load contact',
        LOAD_DEAL_FAILED: 'Failed to load deal',
        LOAD_DEALS_FAILED: 'Failed to load deals',
        LOAD_DEAL_ATTACHMENTS_FAILED: 'Failed to load attachments of the deal',
        LOAD_ECOSYSTEM_FAILED: 'Failed to load ecosystem',
        LOAD_EXPENSES_FAILED: 'Failed to load expenses',
        LOAD_LEDGERS_FAILED: 'Failed to load ledgers',
        LOAD_ORGANIZATIONS_FAILED: 'Failed to load organizations',
        LOAD_ORGANIZATION_FAILED: 'Failed to load organization',
        LOAD_PROFILE_FAILED: 'Failed to load profile',
        LOAD_PRODUCTS_FAILED: 'Failed to load products',
        LOAD_PRODUCT_FAILED: 'Failed to load product',
        LOAD_PURCHASE_FAILED: 'Failed to load purchase',
        LOAD_PURCHASES_FAILED: 'Failed to load purchases',
        RELOAD_INVOICES_PENDING: 'Reloading invoices..',
        REMOVE_ATTACHMENT_PENDING: 'Removing attachment...',
        REMOVE_ATTACHMENT_FAILED: 'Failed to remove attachment',
        REMOVE_ATTACHMENT_SUCCESS: 'Attachment removed',
        UPDATE_CONTACT_FAILED: 'Failed to update contact',
        UPDATE_CONTACT_SUCCESS: 'Contact updated',
        UPDATE_DEAL_INVALID: `The deal didn't pass the validation.`,
        UPDATE_DEAL_PENDING: 'Updating deal...',
        UPDATE_DEAL_FAILED: 'Failed to update deal',
        UPDATE_DEAL_SUCCESS: 'Deal updated!',
        UPDATE_ORGANIZATION_INVALID: `The organization didn't pass the validation.`,
        UPDATE_ORGANIZATION_PENDING: 'Updating organization...',
        UPDATE_ORGANIZATION_FAILED: 'Failed to update organization',
        UPDATE_ORGANIZATION_SUCCESS: 'Organization updated',
        UPDATE_PRODUCT_INVALID: `The product didn't pass the validation.`,
        UPDATE_PRODUCT_PENDING: 'Updating product...',
        UPDATE_PRODUCT_FAILED: 'Failed to update product',
        UPDATE_PRODUCT_SUCCESS: 'Product updated',
        UPDATE_PURCHASE_INVALID: `The purchase didn't pass the validation.`,
        UPDATE_PURCHASE_PENDING: 'Updating purchase...',
        UPDATE_PURCHASE_FAILED: 'Failed to update purchase',
        UPDATE_PURCHASE_SUCCESS: 'Purchase updated!',

        UPDATE_RULE_INVALID: `The rule didn't pass the validation.`,
        UPDATE_RULE_PENDING: 'Updating rule...',
        UPDATE_RULE_FAILED: 'Failed to update rule',
        UPDATE_RULE_SUCCESS: 'Rule updated!',

        DEAL_LEAD_INVALID: `The deal didn't pass the validation.`,
        DEAL_LEAD_PENDING: 'Set deal as lead..',
        DEAL_LEAD_FAILED: 'Failed to set deal as lead',
        DEAL_LEAD_SUCCESS: 'Deal is set as lead',

        DEAL_OPEN_INVALID: `The deal didn't pass the validation.`,
        DEAL_OPEN_PENDING: 'Set deal to open..',
        DEAL_OPEN_FAILED: 'Failed to set deal to open',
        DEAL_OPEN_SUCCESS: 'Deal is set as open',

        MOVE_DEAL_FAILED: 'Moving deal failed',
        MOVE_DEAL_SUCCESS: 'Deal successfully moved.',

        DELETE_LEDGER_PENDING: 'Deleting ledger..',
        DELETE_LEDGER_FAILED: 'Failed deleting ledger',
        DELETE_LEDGER_SUCCESS: 'Ledger deleted',

        LOADING_LANGUAGE_OF_ORGANIZATION: 'Loading language of organization...',
        DELETE_PURCHASE_PENDING: 'Deleting purchase...',
        DELETE_PURCHASE_SUCCESS: 'Successfully deleted purchase',
        DELETE_PURCHASE_FAILED: 'Failed to delete purchase',
        DELETE_INBOX_DOCUMENT_PENDING: 'Deleting document...',
        DELETE_INBOX_DOCUMENT_SUCCESS: 'Successfully deleted document',
        DELETE_INBOX_DOCUMENT_FAILED: 'Failed to delete document',
        DOWNLOAD_INVOICE_PENDING: 'Downloading invoice...',
        DOWNLOAD_INVOICE_SUCCESS: 'Successfully downloaded invoice.',
        DOWNLOAD_INVOICE_FAILED: 'Failed to download invoice.',

        PURCHASE_BOOKED_INVALID: `The purchase didn't pass the validation and cannot be booked.`,
        PURCHASE_BOOKED_PENDING: 'Booking purchase...',
        PURCHASE_BOOKED_FAILED: 'Failed to book purchase',
        PURCHASE_BOOKED_SUCCESS: 'Successfully booked purchase',

        MUTATION_NOT_ALLOWED: 'Mutation not allowed',

        CREATE_LEGAL_ENTITY_INVALID: 'Legal entity is not valid',
        CREATE_LEGAL_ENTITY_PENDING: 'Creating legal entity..',
        CREATE_LEGAL_ENTITY_FAILED: 'Failed to create legal entity',
        UPDATE_LEGAL_ENTITY_INVALID: 'Legal entity is not valid',
        UPDATE_LEGAL_ENTITY_PENDING: 'Updating legal entity',
        UPDATE_LEGAL_ENTITY_FAILED: 'Failed to update legal entity',

        CREATE_SUBSCRIPTION_FAILED: 'Failed to create subscription',
        CREATE_SUBSCRIPTION_PENDING: 'Creating subscription',
        CREATE_SUBSCRIPTION_INVALID: 'Subscription is not valid',
        UPDATE_SUBSCRIPTION_INVALID: 'Subscription is not valid',
        UPDATE_SUBSCRIPTION_PENDING: 'Updating subscription..',
        UPDATE_SUBSCRIPTION_FAILED: 'Failed to update subscription',
        UPDATE_SUBSCRIPTION_SUCCESS: 'Subscription updated!',

        INVALID_INPUT: `Input isn't valid`,
        FAILED_SENDING_INVOICE: 'Failed sending invoice',
        INVOICE_SENT: 'Invoice sent',

        SAVING_TEMPLATE_FAILED: 'Failed to save template',
        LOADING_TEMPLATE_FAILED: 'Failed to load template',
        CREATING_TEMPLATE_FAILED: 'Failed to create template',

        DEAL_STATUS_CHANGE: 'Changing deal status..',
        DEAL_STATUS_CHANGE_SUCCESS: 'Deal status updated',
        DEAL_STATUS_CHANGE_FAILED: `Couldn't update deal status`,

        REGISTER_PAYMENT_PENDING: 'Processing payment..',
        FAILED_REGISTER_PAYMENT: 'Failed to register payment',
        REGISTER_PAYMENT_SUCCESS: 'Payment processed',

        ASK_APPROVAL_PURCHASE_INVALID: `The purchase didn't pass the validation`,
        ASK_APPROVAL_PURCHASE_PENDING: 'Initiating purchase approval flow..',
        ASK_APPROVAL_PURCHASE_FAILED: 'Failed to initiate purchase approval flow..',
        ASK_APPROVAL_PURCHASE_SUCCESS: 'Purchase approval flow initiated!',
        FORCE_PURCHASE_APPROVAL: 'Force approval & book',

        ACCEPT_APPROVAL_PENDING: 'Accepting approval..',
        ACCEPT_APPROVAL_FAILED: 'Failed to accept approval',
        ACCEPT_APPROVAL_SUCCESS: 'Approval accepted',

        DECLINE_APPROVAL_PENDING: 'Declining approval..',
        DECLINE_APPROVAL_FAILED: 'Failed to decline approval',
        DECLINE_APPROVAL_SUCCESS: 'Approval declined',

        LOAD_PURCHASE_APPROVALS_FAILED: 'Failed to load purchase approvals',

        DECLARATION_SUBMITTED: 'Declaration submitted, approvers notified.',

        APPROVE_DOCUMENT_PENDING: 'Approving document..',
        APPROVE_DOCUMENT_FAILED: 'Failed to approve document',
        APPROVE_DOCUMENT_SUCCESS: 'Document approved',

        DECLINE_DOCUMENT_PENDING: 'Declining document..',
        DECLINE_DOCUMENT_FAILED: 'Failed to decline document',
        DECLINE_DOCUMENT_SUCCESS: 'Document declined',

        ATTACHMENT_REQUIRED: 'At least one attachment is required',
        CREATE_DOCUMENT_SUCCESS: 'Draft document created',

        FINALIZE_DOCUMENT_INVALID: 'Document can not be finalized because some required field are not filled in yet',
        FINALIZE_DOCUMENT_PENDING: 'Finalizing document..',
        FINALIZE_DOCUMENT_FAILED: 'Failed to finalize document',
        FINALIZE_DOCUMENT_SUCCESS: 'Document finalized, purchase will be generated.',
        FINALIZE_DOCUMENT_INVALID_ORG: 'Document can not be booked, because the organization is not filled in',

        CREATE_TEAM_SETTINGS_PENDING: 'Updating team settings..',
        CREATE_TEAM_SETTINGS_FAILED: 'Failed to update team settings',
        LOAD_TEAM_SETTINGS_FAILED: 'Failed to load team settings',

        SENDING_REMINDER_PENDING: 'Sending reminders...',
        SENDING_REMINDER_FAILED: 'Failed send reminders',
        SENDING_REMINDER_SUCCESS: 'Reminders sent',

        UPLOADING_ATTACHMENTS: 'Uploading attachments. This can take a while..',
        PREDICTIONS_FOUND: 'This document has predictions, take a closer look to speed up your work :)',

        LOAD_GROUP_BUDGET_FAILED: 'Failed to load budget of activity',

        ORGANIZATION_ADDED_TO_DOCUMENT: 'Organization added to document',
        FAILED_TO_PARSE_DATE: `Date is in a format we don't recognize :(`,
        INVOICE_DATE_ADDED_TO_DOCUMENT: 'Invoice date added to document',
        LOOKING_FOR_MATCHING_ORGANIZATIONS: 'Looking for matching organizations..',

        ADDING_LINES_PENDING: 'Adding line(s)..',
        TEAM_CHANGED: 'Team changed',

        PROCESSING_DOCUMENTS: 'Uploading documents. This can take a while..',
        UPLOADED_DOCUMENTS: 'All attachments uploaded.',
        UPLOADS_DONE_AND_SUBMITTED_DOCUMENTS: 'All documents are uploaded and submitted',

        APPROVAL_ALREADY_COMPLETED: 'The approval you tried to open is already completed',
        REMOVE_ORG_DOCUMENT_SUCCESS: 'Removed organization document successfully',

        DEAL_REMINDER_PENDING: 'Sending the reminder mail..',
        DEAL_REMINDER_FAILED: 'Failed to send reminder mail',
        DEAL_REMINDER_SUCCESS: 'Reminder mail sent',

        INVALID: 'Not all form fields are filled correctly',

        ORGANIZATION_NAME_EXISTS: 'Organization name already exists, are you sure you want to create a new one with the exact same name?',
        ORGANIZATION_COC_EXISTS: 'Chamber of Commerce number already exists, cannot create a new organization with the same number',

        SEARCH_QUERY_EMPTY_OR_TOO_SHORT: 'Search query is empty or too short',
        LOAD_MESSAGE_LOG_FAILED: 'Failed to load message log',
        CONNECT_CONNECTED_ACTIVITY_FAILED: 'Failed to connect activity to product',

        DELETE_ORG_PRODUCT_PENDING: 'Deleting product from organization..',
        DELETE_ORG_PRODUCT_FAILED: 'Failed to delete product from organization',
        DELETE_ORG_PRODUCT_SUCCESS: 'Product deleted from organization',

        MERGE_ORGANIZATIONS_SUCCESS: 'Organizations merged',
    },

    SETTING: {
        EXCLUDED_SEARCH_TERMS: 'b.v.|bv|de|the',
    },
};
