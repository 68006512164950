import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class AttachmentLineActionComponent extends SideActionBase<any> {
    public attachAttachment(e): void {
        e[0].lineIndex = this.options.lineIndex;
        this.finished(e[0]);
    }
}
