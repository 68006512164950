import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { countryService, organizationsServiceCRM, teamHelper } from '@/main';
import { required } from 'vuelidate/lib/validators';
import Organization from '@/models/CRM/Organization';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { settingsModule } from '@/store/modules/settings';
import COCOrg from '@/models/Organizations/COCOrganization';

@Component({
    validations: {
        organization: {
            name: { required },
            countryIso2: { required },
            preferredLanguage: { required },
            addressLineOne: { required },
            zipCode: { required },
            city: { required },
        },
    },
} as any)
export default class CreateOrEditOrganizationComponent extends PageRender {
    @Prop({ default: () => new Organization() }) public organization: Organization;
    @Prop({ default: () => [] }) public organizations: Organization[];
    public isEdit: boolean = false;

    public selectedCountryIso2: any = { id: 'NL', name: 'Netherlands' };
    public selectedLanguage: any = { code: 'nl', language: 'Dutch' };
    public blurredVat: boolean = false;

    public hits: any[] = [];
    public reload: number = 0;

    public get cocExist() {
        return this.organizations
            .filter((x) => x.organizationId !== this.organization.organizationId)
            .find((org) => this.organization.coCNumber.length && org.coCNumber === this.organization.coCNumber);
    }

    public async created() {
        this.isEdit = this.organization && this.organization.organizationId > 0;
    }

    public async mounted() {
        this.isLoading = false;

        if (this.isEdit) {
            const country = this.countries.find((x) => x.id === this.organization.countryIso2);
            if (country) {
                this.selectedCountryIso2 = country;
            }

            const language = this.languages.find((x) => x.code === this.organization.preferredLanguage);
            if (language) {
                this.selectedLanguage = language;
            }
        } else {
            let teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await settingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }
            this.selectedCountryIso2 = this.countries.find((x) => x.id === teamSettings.defaultCountry);
        }

        if (!this.organizations.length) {
            this.organizations = (await organizationsServiceCRM.getOrganizations()).items;
        }

        this.selectCountry(this.selectedCountryIso2);
        this.selectLanguage(this.selectedLanguage);
    }

    public resetOrganization() {
        this.organization = new Organization();
    }

    public checkOrganizationName() {
        if (
            this.organization.name.length > 3 &&
            this.organizations
                .filter((x) => x.organizationId !== this.organization.organizationId)
                .find((x) => x.name.toLowerCase() === this.organization.name.toLowerCase())
        ) {
            this.showInfo('ORGANIZATION_NAME_EXISTS');
        } else {
            console.log('Organization name does not exist');
        }
    }

    public async createOrganization() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_ORGANIZATION_INVALID`);
            return;
        }

        if (this.cocExist) {
            return this.showError('ORGANIZATION_COC_EXISTS');
        }

        this.showPending('CREATE_ORGANIZATION_PENDING');
        const [err, response] = await to(organizationsServiceCRM.createOrganization(this.organization));
        if (err) {
            return this.clearAndShowError('CREATE_ORGANIZATION_FAILED', err);
        }

        const organization = new Organization(response.data);
        this.$emit('finished', organization);
        this.clearNotifications();
    }

    public async updateOrganization() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_ORGANIZATION_INVALID`);
            return;
        }

        this.showPending('UPDATE_ORGANIZATION_PENDING');

        const [err] = await to(organizationsServiceCRM.updateOrganization(this.organization));
        if (err) {
            return this.clearAndShowError('UPDATE_ORGANIZATION_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_ORGANIZATION_SUCCESS');
    }

    public get countries() {
        const countries = countryService.getCountries();
        const list = Object.keys(countries[0]).map((x) => {
            return {
                id: x,
                name: countries[0][x].name,
            };
        });

        return list;
    }

    public selectCountry(country) {
        this.selectedCountryIso2 = country;
        this.organization.countryIso2 = country.id;
        this.organization.country = country.name;

        const countries = countryService.getCountries();
        if (typeof this.organization.exEu === 'undefined') {
            this.organization.exEu = countries[0][this.selectedCountryIso2.id].continent !== 'EU';
        }
    }

    public get languages() {
        return countryService.getLanguages();
    }

    public selectLanguage(language) {
        this.organization.preferredLanguage = language.code;
    }

    public async search(query) {
        if (!query || query.length < 5) {
            return this.showWarning('SEARCH_QUERY_EMPTY_OR_TOO_SHORT');
        }

        this.hits = await organizationsServiceCRM.suggestCOCData(query);
        if (this.hits) {
            this.$sideActions.push('coc-data-suggest-result-side-action', { hits: this.hits }, async (organization: COCOrg) => {
                this.organization.name = organization.naam;
                // this.organization.vatNumber = organization.BTW;
                this.organization.city = organization.bezoeklocatie.plaats;
                this.organization.zipCode = organization.bezoeklocatie.postcode;
                this.organization.addressLineOne = organization.bezoeklocatie.straat + ' ' + organization.bezoeklocatie.huisnummer;
                this.organization.coCNumber = organization.kvknummer;

                this.reload++;
            });
        } else {
            this.showWarning('COC_API_NO_RESULTS');
        }
    }
}
