import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { organizationsServiceCRM, ecosystemHelper, dealsService, teamHelper } from '@/main';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import Vue from 'vue';
import to from 'await-to-js';
import Deal from '@/models/CRM/Deal';
import moment from 'moment';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import Attachment from '@/models/Attachment';

@Component
export default class OrganizationComponent extends PageRender {
    public organization: Organization = new Organization();
    public contacts: Contact[] = [];
    public deals: Deal[] = [];
    public attachments: Attachment[] = [];

    public relationEditContact: Contact;
    public relationType: any = { value: 'default', text: 'Default' };

    public attachmentsColumns: GridColumnProps[] = [
        { field: 'fileName', title: 'Name', editable: false, cell: this.renderLink },
        { cell: this.renderAttachmentActions, title: 'Actions', width: 100 },
    ];

    public contactsColumns: GridColumnProps[] = [
        { field: 'contactId', title: 'Id', width: '120px', editable: false },
        { field: 'firstName', title: 'First name', editable: false },
        { field: 'lastName', title: 'Last name', editable: false },
        { field: 'contactType', title: 'Type', editable: false, cell: this.renderType },
        // { field: 'emailAddresses', title: 'Email addresses', editable: false, cell: this.renderEmailGridList, width: 200 },
        // { field: 'phoneNumbers', title: 'Phone numbers', editable: false, cell: this.renderPhoneNumberGridList, width: 200 },
        { cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public dealsColumns: GridColumnProps[] = [
        { field: 'id', title: 'Id', width: 75, editable: false },
        { field: 'name', title: 'Naam', editable: false },
        { field: 'owner', title: 'OWNER', editable: false },
        { field: 'dealDate', title: 'Datum', editable: false, cell: this.renderDate, width: 125 },
        { field: 'dealState', title: 'Status', editable: false, cell: this.renderStatus, width: 100 },
        { field: 'value', title: 'Value', editable: false, cell: this.renderValue, width: 150 },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public async mounted() {
        const organizationId = parseInt(this.$route.params.organizationId);
        await this.loadOrganization(organizationId);
        await this.loadDeals(organizationId);
        await this.loadAttachments(organizationId);

        this.isLoading = false;
    }

    public async goToContact(e) {
        const contactId = e.dataItem.contactId;
        const organizationId = this.$route.params.organizationId;

        const params = {
            id: ecosystemHelper.currentEcosystem.id.toString(),
            key: ecosystemHelper.currentEcosystem.name,
            contactId,
            organizationId,
        };

        await this.$router.push({ name: 'crm-org-contact', params });
    }

    public async goToDeal(e) {
        const dealId = e.dataItem.id;
        const team = teamHelper.getTeam(e.dataItem.teamId);
        let params;

        if (!team || team.id !== e.dataItem.id) {
            return this.showWarning(`You're not part of this team. Can't navigate you to this deal`);
        }

        params = {
            dealId,
            teamId: team.id,
            teamKey: team.key,
        };
        teamHelper.setTeamByTeamId(e.dataItem.teamId);

        await this.$router.push({ name: 'crm-deal', params });
    }

    public createContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { organization: this.organization }, (result) => {
            this.setContact(result);
        });
    }

    public addContactSideAction() {
        this.$sideActions.push('add-contact-side-action', { organization: this.organization }, (result) => {
            this.setContact(result);
        });
    }

    public setContact(result) {
        this.organization.contacts.push(result.contact);
        this.showSuccess('CONTACT_ADDED');
        // this.contactSelected++;
    }

    public async loadContacts() {
        const contacts = await organizationsServiceCRM.getContacts(this.organization.organizationId);
        this.contacts = contacts.items;
    }

    public async loadOrganization(organizationId: number) {
        this.organization = await organizationsServiceCRM.getOrganization(organizationId);
        this.contacts = this.organization.contacts;
    }

    public async loadAttachments(organizationId: number) {
        this.attachments = await organizationsServiceCRM.getAttachments(organizationId);
    }

    public uploadAttachmentSideAction() {
        this.$sideActions.push(
            'upload-attachment-side-action',
            { organizationId: this.organization.organizationId, isCrm: true, team: teamHelper.currentTeam.id },
            (result) => {
                this.loadAttachments(this.organization.organizationId);
            },
        );
    }

    public async loadDeals(organizationId: number) {
        const deals = await dealsService.getDeals(true, organizationId);
        this.deals = deals.items;
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Decouple contact',
                function: this.decoupleContact,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderAttachmentActions(h, _, row) {
        const actions = [
            {
                title: 'Remove document',
                function: this.removeAttachment,
            },
            {
                title: 'Download document',
                function: this.downloadAttachment,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public downloadAttachment(item: Attachment) {
        const link = document.createElement('a');
        link.href = item.url;
        link.setAttribute('download', `${item.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async removeAttachment(item: Attachment) {
        await organizationsServiceCRM.deleteAttachment(this.organization.organizationId, item.fileId);
        this.showSuccess('REMOVE_ORG_DOCUMENT_SUCCESS');

        this.loadAttachments(this.organization.organizationId);
    }

    public renderEmailGridList(h, _, row) {
        const elements = row.dataItem.emailAddresses;
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public renderType(h, _, row) {
        return h('td', row.dataItem.contactType === 'administrative' ? 'Administrative' : 'Not specified');
    }

    public renderPhoneNumberGridList(h, _, row) {
        const elements = row.dataItem.phoneNumbers;
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public async decoupleContact(item: Contact) {
        this.showPending('DECOUPLE_CONTACT_PENDING');
        const [err] = await to(organizationsServiceCRM.decoupleContact(this.organization.organizationId, item));
        if (err) {
            this.clearAndShowError('DECOUPLE_CONTACT_FAILED', err);
            return;
        }

        this.clearAndShowSuccess('DECOUPLE_CONTACT_SUCCESS');

        this.contacts = this.contacts.filter((x) => x.contactId !== item.contactId);
    }

    public renderStatus(h, _, row) {
        return h('td', row.dataItem.dealState.name);
    }

    public renderDate(h, _, row) {
        return h('td', moment(row.dataItem.dealDate).format('DD-MM-YYYY'));
    }

    public renderValue(h, _, row) {
        const deal = new Deal(row.dataItem);
        return h('td', [Vue.filter('numberFormat')(deal.dealValue)]);
    }

    public renderLink(h, _, row) {
        const attachment = row.dataItem;
        return h(
            'td',
            {
                on: {
                    click: () => {
                        const link = document.createElement('a');
                        link.href = attachment.url;
                        link.setAttribute('download', `${attachment.fileName}`);
                        document.body.appendChild(link);
                        return link.click();
                    },
                },
                class: 'cursor-pointer',
            },
            attachment.fileName,
        );
    }

    public async createDeal() {
        await this.$router.push({ name: 'crm-create-deal' });
    }
}
