import Component from 'vue-class-component';
import Vue from 'vue';
import { ecosystemHelper } from '@/main';

@Component
export default class MobileSwitcherComponent extends Vue {
    get mobilePreferred() {
        const mobilePreferred = localStorage.getItem('mobilePreferred');
        return mobilePreferred === 'true';
    }
    get hasPreferenceSet() {
        const value = localStorage.getItem('mobilePreferred');
        return typeof value !== 'undefined' && value !== null;
    }

    public setMobilePreferred(preference: boolean) {
        localStorage.setItem('mobilePreferred', preference.toString());

        if (preference) {
            return this.$router.push({
                name: 'mobile-create-declaration',
                params: {
                    ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                    ecosystemKey: ecosystemHelper.currentEcosystem.key,
                },
            });
        }

        this.$router.go(0);
    }
}
