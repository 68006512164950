import { dealsService } from '@/main';
import Component from 'vue-class-component';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import to from 'await-to-js';
import Deal from '@/models/CRM/Deal';

@Component
export default class SplitDealAction extends SideActionBase<SplitDealActionOptions> {
    public splitDeal: number = 3;
    public dealPercentages: { percentage: number }[] = [{ percentage: 25 }, { percentage: 60 }, { percentage: 15 }];

    public updateSplittedParts() {
        if (this.splitDeal > this.dealPercentages.length) {
            this.dealPercentages.push({ percentage: 25 });
        }
        else {
            this.dealPercentages.splice(this.splitDeal);
        }

        this.dealPercentages.forEach((dealPercentage) => {
            dealPercentage.percentage = 100 / this.dealPercentages.length;
        });
    }

    public async split() {
        this.clearAndShowSuccess('SPLIT_DEAL');
        const [err, response] = await to(dealsService.splitDeal(this.options.deal.id, this.dealPercentages.map((dealPercentage) => dealPercentage.percentage)));
        if (err) {
            this.clearAndShowError('FAILED_TO_SPLIT_DEAL');
        }

        this.finished();
    }

}

export class SplitDealActionOptions {
    public deal: Deal;
}
