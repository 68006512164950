import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { subscriptionsService } from '@/main';
import Subscription from '@/models/CRM/Subscription';

@Component
export default class SubscriptionComponent extends PageRender {
    public subscription: Subscription = new Subscription();

    public async mounted() {
        const subscriptionId = parseInt(this.$route.params.subscriptionId);
        await this.loadSubscription(subscriptionId);
        if (this.subscription && !this.subscription.id) {
            await this.$router.push({ name: 'crm-subscriptions' });
        }
        this.isLoading = false;
    }

    public async loadSubscription(subscriptionId: number) {
        this.subscription = await subscriptionsService.getSubscription(subscriptionId);
    }
}
