import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import Deal from '@/models/CRM/Deal';
import { to } from 'await-to-js';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import Rule from '@/models/CRM/Rule';
import { teamHelper } from '@/main';
import { InvoiceEmailTemplate } from '@/models/LegalEntity';
import SendInvoiceMail from '@/models/SendInvoiceMail';

export default class DealsService extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}teams/:team/deals`;
    private ecoEndpoint = `${Vue.$env().crmApiEndpoint}deals`;
    private crmEndpoint = `${Vue.$env().crmApiEndpoint}`;

    public async getDeal(dealId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:dealId`, [['dealId', dealId]]));

        if (err) {
            this.clearAndShowError(`LOAD_DEAL_FAILED`, err);
            return new Deal();
        }

        return new Deal(response.data);
    }

    public async getDeals(ecoScope: boolean = true, organizationId?: number): Promise<Range<Deal>> {
        const orgString = `?OrganizationId=${organizationId}`;
        const [err, response] = await to(this.get(`${ecoScope ? this.ecoEndpoint : this.endpoint}${organizationId ? orgString : ''}`));

        if (err) {
            this.clearAndShowError(`LOAD_DEALS_FAILED`, err);
            return new Range<Deal>();
        }

        return new Range<Deal>(response.data);
    }

    public createDeal(deal: Deal) {
        const model = {
            lines: deal.lines.map((x) => {
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project: x.projectObj !== null ? x.projectObj.name : null,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : x.taxRateId ? x.taxRateId : null,
                    lineNumber: x.lineNumber,
                };
            }),
            organization: (deal.organization as Organization).organizationId,
            contact: (deal.contact as Contact).contactId,
            name: deal.name,
            owner: deal.owner,
            currency: deal.currency,
            clientReference: deal.clientReference,
            invoiceNumber: deal.invoiceNumber,
            template: deal.template,
            teamId: deal.teamId ? deal.teamId : teamHelper.currentTeamId,
        };

        return this.post(`${this.crmEndpoint}teams/${model.teamId}/deals`, model);
    }

    public updateDeal(deal: Deal) {
        const administrativeContact = deal.administrativeContact as Contact;
        const model = {
            lines: deal.lines.map((x) => {
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project: x.projectObj !== null ? x.projectObj.name : null,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : x.taxRateId ? x.taxRateId : null,
                    lineNumber: x.lineNumber,
                };
            }),
            organization: (deal.organization as Organization).organizationId,
            contact: (deal.contact as any).contactId,
            administrativeContact: administrativeContact
                ? administrativeContact.contactId
                    ? administrativeContact.contactId
                    : administrativeContact.id
                : null,
            name: deal.name,
            owner: deal.owner,
            currency: deal.currency,
            clientReference: deal.clientReference,
            invoiceDate: deal.invoiceDate,
            invoiceNumber: deal.invoiceNumber,
            template: deal.template,
            note: deal.note,
        };

        return this.put(`${this.endpoint}/${deal.id}`, model);
    }

    public changeDealState(dealId: number, newState: number, sendMail?: SendInvoiceMail) {
        const postModel = {
            newDealState: newState,
            ccEmail: null,
            receiver: null,
            emailTemplateOverride: null,
        };

        if (newState === 7 && sendMail && sendMail.body && sendMail.subject && sendMail.masterTemplate) {
            postModel.emailTemplateOverride = {
                masterTemplate: sendMail.masterTemplate,
                body: sendMail.body,
                subject: sendMail.subject,
            };
        }

        if (newState === 7 && sendMail && sendMail.contactEmail && sendMail.contactName) {
            postModel.receiver = {
                emailAddress: sendMail.contactEmail,
                name: sendMail.contactName,
            };
        }

        if (sendMail && sendMail.ccEmail) {
            postModel.ccEmail = sendMail.ccEmail;
        }

        return this.post(`${this.endpoint}/${dealId}/change-state`, postModel);
    }

    public cloneDeal(deal: Deal) {
        return this.post(`${this.endpoint}/${deal.id}/clone`, {
            name: `${deal.name} copy`,
        });
    }

    public moveDeal(dealId: number, newTeamId: number) {
        return this.post(`${this.endpoint}/${dealId}/move`, {
            newTeamId,
        });
    }

    public archiveDeal(deal: Deal) {
        return this.delete(`${this.endpoint}/${deal.id}`);
    }

    public splitDeal(dealId: number, percentages: number[]) {
        return this.post(`${this.endpoint}/${dealId}/split`, percentages);
    }

    public remindDeal(dealId: number, sendMail?: SendInvoiceMail) {
        const postModel = {
            ccEmail: null,
            emailTemplateOverride: null,
            receiver: null,
        };

        if (sendMail && sendMail.body && sendMail.subject && sendMail.masterTemplate) {
            postModel.emailTemplateOverride = {
                masterTemplate: sendMail.masterTemplate,

                body: sendMail.body,
                subject: sendMail.subject,
            };
        }

        if (sendMail && sendMail.contactEmail && sendMail.contactName) {
            postModel.receiver = {
                emailAddress: sendMail.contactEmail,
                name: sendMail.contactName,
            };
        }

        if (sendMail && sendMail.ccEmail) {
            postModel.ccEmail = sendMail.ccEmail;
        }

        return this.post(`${this.endpoint}/${dealId}/send-reminder`, postModel);
    }

    public async getAttachments(dealId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:dealId/attachments`, [['dealId', dealId]]));

        if (err) {
            this.clearAndShowError(`LOAD_DEAL_ATTACHMENTS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async deleteAttachment(dealId: number, fileId: string) {
        return this.delete(`${this.endpoint}/${dealId}/attachments/${fileId}`);
    }

    public async getTaxRateRules() {
        const [err, response] = await to(this.get(`${this.crmEndpoint}/tax-rate-rules`));

        if (err) {
            this.clearAndShowError(`LOAD_TAX_RULES_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async createRule(rule: Rule) {
        const model = {
            name: rule.name,
            taxRate: rule.taxRate.id,
            isExEu: rule.isExEu != null ? rule.isExEu : false,
            countryIso2: rule.countryIso2,
            needsVatNumber: rule.needsVatNumber != null ? rule.needsVatNumber : false,
        };

        return this.post(`${this.crmEndpoint}tax-rate-rules`, model);
    }

    public async updateRule(rule: Rule) {
        const model = {
            taxRateRuleId: rule.taxRateRuleId,
            name: rule.name,
            taxRate: rule.taxRate.id,
            isExEu: rule.isExEu != null ? rule.isExEu : false,
            countryIso2: rule.countryIso2,
            needsVatNumber: rule.needsVatNumber != null ? rule.needsVatNumber : false,
        };

        return this.put(`${this.crmEndpoint}tax-rate-rules/${rule.taxRateRuleId}`, model);
    }

    public async getMailTemplateContent(teamId: number, dealId: number, type: string = 'newInvoice'): Promise<InvoiceEmailTemplate> {
        const [err, response] = await to(this.get(`${this.crmEndpoint}teams/${teamId}/deals/${dealId}/email-template?email_type=${type}`));
        if (err) {
            return new InvoiceEmailTemplate();
        }

        return new InvoiceEmailTemplate(response.data);
    }
}
