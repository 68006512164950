import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class LabelValueComponent extends Vue {
    public object: any = null;

    @Prop(Object) public args!: any;

    public mounted() {
        const args = this.$data.templateArgs || this.args;
        if (args !== 'undefined') {
            this.object = args.object;
        }
    }
}
