import { appHelper, organizationsServicePurchases, productsServicePurchases } from '@/main';
import Organization from '@/models/CRM/Organization';
import Product from '@/models/Purchases/Product';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';

@Component
export default class ProductToOrgActionComponent extends SideActionBase<any> {
    public selectedProduct: any = null;
    public products: Product[] = [];
    public orgProducts: { organizationProductId: number; productId: number; productName: string; }[] = [];
    public organization: Organization = new Organization();

    public async mounted() {
        this.organization = this.options.organization;
        await this.loadOrganizationProducts();
        await this.loadProducts();
    }

    public async loadOrganizationProducts() {
        const orgProducts = await organizationsServicePurchases.getOrganizationProducts(this.organization.organizationId);
        this.orgProducts = [...orgProducts.data];
    }

    public async loadProducts() {
        const products = await productsServicePurchases.getProducts();
        this.products = [...products.items.filter(x => this.orgProducts.find(y => y.productId === x.productId) === undefined)];
    }


    public async connectProduct() {
        const [err, response] = await to(organizationsServicePurchases.connectProductToOrganization(this.selectedProduct.productId, this.organization.organizationId));

        this.finished({ productId: this.selectedProduct.productId, productName: this.selectedProduct.name });
    }
}
