import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { clientPurchasesApprovalService, inboxDocumentService } from '@/main';
import to from 'await-to-js';
import { orderBy } from '@progress/kendo-data-query';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import InboxDocument from '@/models/Costs/InboxDocument';
import { PurchaseApproval } from '@/models/Purchases/PurchaseApproval';
@Component
export default class DeclarationsComponent extends PageRender {
    public allDocuments: InboxDocument[] = [];
    public documents: InboxDocument[] = [];

    public declarationColumns: GridColumnProps[] = [
        { field: 'name', title: 'DOCUMENT_NAME', editable: false },
        { field: 'documentStatus', title: 'STATUS', editable: false },
        { field: 'documentType', title: 'DOCUMENT_TYPE', editable: false },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['documentType', 'name', 'documentStatus'],
    });

    public async mounted() {
        await this.loadDeclarations();
        this.isLoading = false;
    }

    public async createNewDocument() {
        await this.$router.push({ name: 'create-declaration' });
    }

    public async goToDocument(e) {
        const documentId = e.dataItem.id;
        await this.$router.push({ name: 'create-declaration', params: { documentId } });
    }

    public async loadDeclarations() {
        this.allDocuments = await inboxDocumentService.getPersonalInbox();
        this.documents = this.allDocuments;
    }

    public updateOtherGridData(dataItems) {
        this.documents = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public async goToDocumentItem(item) {
        const documentId = item.id;
        await this.$router.push({ name: 'create-declaration', params: { documentId } });
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Show details',
                function: this.goToDocumentItem,
            },
            {
                title: 'Archive',
                function: this.deleteItem,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async deleteItem(item) {
        const result = await this.$bvModal.msgBoxConfirm(`Do you really want to delete this document?`, {
            title: 'Delete document',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Ok',
            cancelTitle: 'Cancel',
            hideHeaderClose: false,
            centered: true,
        });

        if (result) {
            this.showPending('DELETE_INBOX_DOCUMENT_PENDING');
            const success = await inboxDocumentService.deleteDocument(item.id);
            if (success) {
                this.clearAndShowSuccess('DELETE_INBOX_DOCUMENT_SUCCESS');
                await this.loadDeclarations();
            } else {
                this.clearAndShowError('DELETE_INBOX_DOCUMENT_FAILED', null);
            }
        }
    }

    public async declineApproval(item: PurchaseApproval) {
        this.showPending('DECLINE_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.declineApproval(item.purchase.purchaseInvoiceId, 'Because!'));
        if (err) {
            return this.clearAndShowError('DECLINE_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('DECLINE_APPROVAL_SUCCESS');
    }

    public async acceptApproval(item: PurchaseApproval) {
        this.showPending('ACCEPT_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.approveApproval(item.purchase.purchaseInvoiceId));
        if (err) {
            return this.clearAndShowError('ACCEPT_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('ACCEPT_APPROVAL_SUCCESS');
    }
}
