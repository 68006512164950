import axios from 'axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import heic2any from 'heic2any';

@Component
export default class AttachmentPreview extends Vue {
    @Prop({ default: null }) public src: string;
    @Prop({ default: null }) public name: string;

    public pdfAPIReady: boolean = false;
    public $refs!: {
        embed: HTMLEmbedElement;
    };
    public adobeDCView: any = null;
    private blobSrc: Blob = null;

    @Watch('pdfAPIReady')
    public onPDFAPIReady(val) {
        if (val) {
            this.adobeDCView = new (window as any).AdobeDC.View({
                clientId: '95ff93f8aa644c9e91c72868254bd6e3',
                divId: 'pdf-view',
            });

            this.$watch('src', this.loadBlobSrc, { immediate: true });
        }
    }

    public mounted() {
        if (this.type === 'application/pdf' && !(window as any).AdobeDC) {
            const pdfScript = document.createElement('script');
            pdfScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/viewer.js');
            pdfScript.setAttribute('id', 'embed-script');

            pdfScript.onload = () => {
                document.addEventListener('adobe_dc_view_sdk.ready', () => {
                    this.pdfAPIReady = true;
                });
            };
            document.head.appendChild(pdfScript);
        } else if (this.type === 'application/pdf' && (window as any).AdobeDC) {
            this.adobeDCView = new (window as any).AdobeDC.View({
                clientId: '95ff93f8aa644c9e91c72868254bd6e3',
                divId: 'pdf-view',
            });

            this.$watch('src', this.loadBlobSrc, { immediate: true });
        } else {
            this.$watch('src', this.loadBlobSrc, { immediate: true });
        }
    }

    public beforeDestroy() {
        const script = document.getElementById('embed-script');
        if (script) {
            script.parentNode.removeChild(script);
        }
    }

    public get objectUrl() {
        if (this.blobSrc) {
            return URL.createObjectURL(this.blobSrc);
        }
        return null;
    }

    public get type() {
        switch (this.extension.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'heic':
                return 'image/heic';
            default:
                return 'application/pdf';
        }
    }

    private get extension() {
        if (this.name) {
            const index = this.name.lastIndexOf('.') + 1;
            return this.name.substr(index, this.name.length - index);
        }
    }

    public get isSafari() {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        return isSafari;
    }

    private async loadBlobSrc() {
        const response = await axios({
            url: this.src,
            method: 'GET',
            responseType: 'blob',
        });

        if (this.type === 'image/heic') {
            const heicFile = new Blob([response.data]);

            const resultBlob = await heic2any({
                blob: heicFile,
                toType: 'image/png',
            });

            this.blobSrc = resultBlob as Blob;
            this.$notify({
                clean: true,
            });

            this.setEmbedSize();

            return;
        }

        const file = new Blob([response.data], { type: this.type });
        this.blobSrc = file;
        this.$notify({
            clean: true,
        });

        if (this.type === 'application/pdf') {
            const viewer = this.adobeDCView;

            viewer.previewFile({
                content: { location: { url: this.src } },
                metaData: { fileName: this.name },
                divId: 'pdf-view',
            });

            return;
        }

        this.setEmbedSize();
    }

    private setEmbedSize() {
        const el: HTMLEmbedElement = this.$refs.embed;
        const elementHeight = this.$el.clientHeight;
        el.style.height = `${elementHeight}px`;
    }
}
