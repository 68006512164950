import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { ecosystemHelper } from '@/main';
import { profileModule } from '@/store/modules/profile';

@Component({
    components: {
        vueDropzone: vue2Dropzone,
    },
})
export default class FileUploader extends Vue {
    @Prop({ default: null }) public team: number;
    @Prop({ default: null }) public deal: number;
    @Prop({ default: null }) public purchase: number;
    @Prop({ default: null }) public document: number;
    @Prop({ default: null }) public organization: number;
    @Prop({ default: false }) public isCrm: boolean;
    @Prop({ default: false }) public isPurchase: boolean;
    @Prop({ default: false }) public isInboxDocument: boolean;
    @Prop({ default: true }) public autoProcessQueue: boolean;

    public reloadComp: number = 0;

    public $refs!: {
        myVueDropzone: vue2Dropzone;
    };

    public mounted() {
        if (!this.autoProcessQueue) {
            this.$refs.myVueDropzone.setOption('autoProcessQueue', false);
        }
    }

    public getDropZoneOptions() {
        return {
            url: this.getEndpoint(),
            thumbnailWidth: 150,
            maxFilesize: 10,
            uploadMultiple: false,
            autoProcessQueue: this.autoProcessQueue,
            headers: {
                'x-ecosystem': ecosystemHelper.currentEcosystem.id,
                Authorization: `Bearer ${profileModule.access_token}`,
            },
            paramName: 'files',
            duplicateCheck: true,
        };
    }

    public setId(document) {
        this.document = document.id;

        const url = `${this.isCrm ? Vue.$env().crmApiEndpoint : Vue.$env().purchasesApiEndpoint}${
            this.isCrm
                ? this.deal != null
                    ? '/deals/'
                    : '/organizations/'
                : this.isInboxDocument
                ? '/inbox-documents/'
                : `teams/${this.team}/purchases/`
        }${this.deal != null ? this.deal : ''}${this.purchase != null ? this.purchase : ''}${this.document != null ? this.document : ''}/attachments`;

        this.$refs.myVueDropzone.setOption('url', url);
    }

    public setEndpoint(endpoint) {
        this.$refs.myVueDropzone.setOption('url', endpoint);
    }

    public async processQueue() {
        return await this.$refs.myVueDropzone.processQueue();
    }

    public getQueuedFiles() {
        if (this.$refs.myVueDropzone) {
            return this.$refs.myVueDropzone.getQueuedFiles();
        }
    }

    public uploadDone(_, b) {
        this.$emit('upload-done', b);
    }

    public filesAdded(_, b) {
        this.$emit('files-added', b);
    }

    public complete(response) {
        this.$emit('complete', response);
    }

    public sending(file, xhr, formData) {
        this.$emit('sending', file, xhr, formData);
    }

    public processing(file) {
        this.$emit('processing', file);
    }

    public error(file, message, xhr) {
        this.$emit('error', file, message, xhr);
    }

    public queueComplete() {
        this.$emit('queue-complete');
    }

    private getEndpoint(): string {
        const endpoint = this.isCrm ? Vue.$env().crmApiEndpoint : Vue.$env().purchasesApiEndpoint;
        const path = `${!this.isInboxDocument && this.organization === null ? `teams/${this.team}/` : ''}`;
        const entity = this.isInboxDocument
            ? 'inbox-documents'
            : this.isCrm
            ? this.deal != null
                ? 'deals'
                : 'organizations'
            : this.isPurchase
            ? this.purchase != null
                ? 'purchases'
                : 'organizations'
            : 'purchases';
        const id =
            this.deal != null
                ? this.deal
                : this.purchase != null
                ? this.purchase
                : this.document != null
                ? this.document
                : this.organization != null
                ? this.organization
                : '';

        return `${endpoint}${path}${entity}/${id}/attachments`;
    }
}
