import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import IStoreModule from '@/interfaces/IStoreModule';
import to from 'await-to-js';
import store from '../index';
import { axiosHelper } from '@/main';
import Vue from 'vue';
import defaultLang from './lang/default';

@Module
class TranslateModule extends VuexModule implements IStoreModule {
    private failed: boolean = false;
    private loading: boolean = false;
    private dictionary: any = defaultLang;
    private editMode: boolean = false;
    private _language: string = 'en';
    private _languages: string[] = ['nl', 'en'];
    private _fetched: boolean = false;

    public get endpoint() {
        return `${Vue.$env().crmApiEndpoint}translations/?languageCode=${this.activeLanguage}`;
    }

    public get isFailed() {
        return this.failed;
    }

    public get isLoading() {
        return this.loading;
    }

    public get inEditMode() {
        return this.editMode;
    }

    public get activeLanguage() {
        return this._language;
    }

    public get languages() {
        return this._languages;
    }

    public get fetched() {
        return this._fetched;
    }

    public get all() {
        return this.dictionary;
    }

    public get value() {
        return (key: string, group: string = null, params: any = null) => {
            let translateString = '';
            if (group !== null) {
                translateString = this.dictionary[group] && this.dictionary[group][key] ? this.dictionary[group][key] : key;
            } else {
                translateString = this.dictionary[key] ? this.dictionary[key] : key;
            }

            if (params !== null) {
                Object.keys(params).forEach((paramKey: string) => {
                    translateString = translateString.split(`{${paramKey}}`).join(params[paramKey]);
                });
            }

            return translateString;
        };
    }

    @Action
    public async fetch(): Promise<void> {
        const axios = axiosHelper.getAuthAxiosInstance();
        this.SET_LOADING(true);
        const [err, response] = await to(axios.get(this.endpoint));
        this.SET_LOADING(false);
        this.SET_FETCHED(true);

        if (err) {
            return this.FAIL();
        }

        return this.SET(response.data);
    }

    @Action
    public async save(): Promise<boolean> {
        const axios = axiosHelper.getAuthAxiosInstance();
        const [err] = await to(axios.post(this.endpoint, { translations: JSON.stringify(this.dictionary).toString() }));

        if (err) {
            return false;
        }

        return true;
    }

    @Action
    public toggleEditMode() {
        this.SET_EDITMODE(!this.editMode);
    }

    @Action
    public setValue(payload: { key: string; value: string; group: string }) {
        this.SET_VALUE(payload);
        return this.save();
    }

    @Action
    public async setLanguage(payload: string) {
        this.SET_LANGUAGE(payload);
        return await this.fetch();
    }

    @Action
    public addLanguage(key: string) {
        this.SET_LANGUAGE(key);
        this.SET_DICTIONARY(defaultLang);
    }

    @Action
    public detectLanguage() {
        if (localStorage.getItem('language')) {
            this.SET_LANGUAGE(localStorage.getItem('language'));
        }
    }

    @Mutation
    public FAIL(): void {
        this.failed = true;
    }

    @Mutation
    public SET(payload: any): void {
        this.dictionary = defaultLang;
        Object.keys(payload).forEach((key) => {
            if (this.dictionary[key]) {
                if (typeof payload[key] === 'object') {
                    Object.assign(this.dictionary[key], payload[key]);
                } else {
                    this.dictionary[key] = payload[key];
                }
            }
        });
        this.failed = false;
    }

    @Mutation
    private SET_VALUE(payload: { key: string; value: string; group: string }) {
        if (payload.group) {
            this.dictionary[payload.group][payload.key] = payload.value;
        } else {
            this.dictionary[payload.key] = payload.value;
        }
    }

    @Mutation
    private SET_EDITMODE(payload: boolean) {
        this.editMode = payload;
    }

    @Mutation
    private SET_LANGUAGE(payload: string) {
        this._language = payload;
        localStorage.setItem('language', payload);
    }

    @Mutation
    private SET_LOADING(payload: boolean) {
        this.loading = payload;
    }

    @Mutation
    private SET_DICTIONARY(payload) {
        this.dictionary = payload;
    }

    @Mutation
    private SET_FETCHED(payload: boolean) {
        this._fetched = payload;
    }
}

export const translateModule = new TranslateModule({ store, name: 'translate' });
