export enum PurchaseStatusEnum {
    Unknown = 'Unknown',
    Concept = 'Concept',
    Booked = 'Booked',
    ApprovalRequested = 'ApprovalRequested',
    Declined = 'Declined',
}

export default class PurchaseStatus {
    public static getStatus(status: PurchaseStatusEnum = PurchaseStatusEnum.Unknown): string {
        if (this.status[PurchaseStatusEnum[status]]) {
            return this.status[PurchaseStatusEnum[status]];
        }

        return this.status[0];
    }

    public static getStatussen() {
        return [
            // PurchaseStatus.status.Unknown,
            PurchaseStatus.status.Concept,
            PurchaseStatus.status.Booked,
            PurchaseStatus.status.ApprovalRequested,
            PurchaseStatus.status.Declined,
        ];
    }

    private static status = {
        Unknown: 'Unknown',
        Concept: 'Concept',
        Booked: 'Booked',
        ApprovalRequested: 'Approval Requested',
        Declined: 'Declined',
    };
}
