import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import { AxiosPromise } from 'axios';
import Vue from 'vue';
import { loginService } from '@/main';
import { Person } from '@/models/Person';
import to from 'await-to-js';

export class MeService extends BaseService {
    private endpoint = `${Vue.$env().brightApiEndpoint}`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper, true);
    }

    public async profile(ecosystemId?: number): Promise<Person> {
        const config = { headers: {} };

        if (ecosystemId) {
            config.headers['x-ecosystem'] = ecosystemId;
        }

        if (ecosystemId) {
            const [profileErr, profileResponse] = await to(this.get(`${this.endpoint}me/profile`, null, config));
            if (profileErr) {
                this.clearAndShowError('LOAD_PROFILE_FAILED', profileErr);
                await loginService.logout();
                await this.$router.push({ name: 'login' });
                return new Person();
            }

            return new Person(profileResponse.data);
        }

        const [err, response] = await to(this.get(`${this.endpoint}me/profile`, null, config));
        if (err) {
            this.clearAndShowError('LOAD_PROFILE_FAILED', err);
            return new Person();
        }

        return new Person(response.data);
    }

    public updatePreferences(primaryEcosystem: number, primaryTeam: number): AxiosPromise {
        return this.put(`${this.endpoint}me/preferences`, { primaryEcosystem, primaryTeam });
    }

    public disableAccount(emailAddress: string) {
        return this.put(`${this.endpoint}me/disable?emailAddress=:emailAddress`, null, [['emailAddress', emailAddress]]);
    }
}
