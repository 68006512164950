import PredictionLine, { PredictionValue } from './PredictionLine';

export default class DocumentPrediction {
    public documentName: string;
    public currency: PredictionValue;
    public customer: PredictionCustomer;
    public invoiceDate: PredictionValue;
    public lines: PredictionLine[] = [];
    public organization: PredictionOrganization;
    public purchaseOrder: PredictionValue;
    public reference: PredictionValue;

    constructor(obj?: Partial<DocumentPrediction>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PredictionOrganization {
    public name: PredictionValue;
    public vatNumber: PredictionValue;
    public coCNumber: PredictionValue;
    public addressLineOne: PredictionValue;
    public addressLineTwo: PredictionValue;
    public city: PredictionValue;
    public zipCode: PredictionValue;
    public country: PredictionValue;
}

export class PredictionCustomer {
    public name: PredictionValue;
}
