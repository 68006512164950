import to from 'await-to-js';
import { appHelper, ecosystemHelper, legalEntityService, loginService, teamHelper } from '@/main';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';
import LegalEntity from '@/models/LegalEntity';
import StructureTeam from '@/models/Ecosystem/StructureTeam';

@Module
class LegalEntityModule extends VuexModule {
    private legalEntities: LegalEntity[] = [];
    private active: LegalEntity = new LegalEntity();

    public get all() {
        return this.legalEntities;
    }

    public get current() {
        return this.active;
    }

    public get first(): LegalEntity {
        return this.legalEntities && this.legalEntities.length > 0 ? this.legalEntities[0] : null;
    }

    @Action
    public async init() {
        const isLoggedIn = await loginService.isLoggedIn();
        if (isLoggedIn) {
            await this.fetchIfNeeded();

            if (!appHelper.isInvoice()) {
                let team = ecosystemHelper.structure.teams.find((t: StructureTeam) => {
                    return t.teamId === teamHelper.currentTeam.id;
                });

                if (!team) {
                    const fallbackTeam = ecosystemHelper.structure.teams[0];
                    teamHelper.setTeamByTeamId(fallbackTeam.teamId);
                    team = fallbackTeam;
                }
                const legalEntity = this.all.find((legal: LegalEntity) => {
                    return legal.name && team.legalEntityReference && legal.name.toLowerCase() === team.legalEntityReference.toLowerCase();
                });

                if (legalEntity) {
                    this.setLegalEntityById(legalEntity.legalEntityId);
                }
            } else {
                let legalEntityId = '';
                const urlSegments = window.location.pathname.split('/');

                if (typeof urlSegments === 'object' && urlSegments.length > 3) {
                    legalEntityId = urlSegments[3];
                }

                this.setLegalEntityById(legalEntityId);
            }
        }
    }

    public get find() {
        return (consumerGuid: string) => {
            return this.legalEntities.find((c: LegalEntity) => {
                return c.legalEntityId === consumerGuid;
            });
        };
    }

    @Action
    public setLegalEntityById(legalEntityId: string) {
        const entity = this.legalEntities.find((x) => x.legalEntityId === legalEntityId);
        if (entity) {
            this.setCurrentLegalEntity(entity);
            return;
        }

        const team = ecosystemHelper.structure.teams.find((t: StructureTeam) => {
            return t.teamId === teamHelper.currentTeam.id;
        });

        if (team) {
            const legalEntity = this.all.find((legal: LegalEntity) => {
                return legal.name && team.legalEntityReference && legal.name.toLowerCase() === team.legalEntityReference.toLowerCase();
            });

            if (legalEntity) {
                this.setCurrentLegalEntity(legalEntity);
                return;
            }
        }

        this.setCurrentLegalEntity(this.legalEntities[0]);
    }

    @Action
    public async fetch() {
        const [err, response] = await to(legalEntityService.getLegalEntities());
        if (err) {
            this.fail();
        }

        const items = response && response.data ? response.data : [];
        this.set(items);
    }

    @Action
    public async fetchIfNeeded() {
        if (this.legalEntities.length === 0) {
            await this.fetch();
        }
    }

    @Action
    public setCurrentLegalEntity(legalEntity: LegalEntity) {
        this.SET_CURRENT(legalEntity);
    }

    @Mutation
    private fail() {
        this.legalEntities = [];
    }

    @Mutation
    private set(payload) {
        this.legalEntities = payload;
    }

    @Mutation
    private SET_CURRENT(legalEntity: LegalEntity) {
        this.active = new LegalEntity(legalEntity);
    }
}

export const legalEntityModule = new LegalEntityModule({ store, name: 'LegalEntity' });
