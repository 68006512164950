export default class TeamSettings {
    public id: number;
    public defaultCountry: string;
    public defaultCurrency: string;

    constructor(obj?: Partial<TeamSettings>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
