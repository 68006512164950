import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';
import InboxDocument, { DocumentStatus } from '@/models/Costs/InboxDocument';
import DocumentPrediction from '@/models/Purchases/DocumentPrediction';
import { AxiosResponse } from 'axios';

export default class InboxDocumentService extends BaseService {
    private documentEndpoint = `${Vue.$env().purchasesApiEndpoint}inbox-documents`;
    private inboxEndpoint = `${Vue.$env().purchasesApiEndpoint}inbox-documents/my-inbox`;

    public async getInboxDocument(documentId: number) {
        const [err, response] = await to(this.get(`${this.documentEndpoint}/:documentId`, [['documentId', documentId]]));

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASE_FAILED`, err);
            return new InboxDocument();
        }

        return new InboxDocument(response.data);
    }

    public async getInboxDocumentPredictions(documentId: number): Promise<DocumentPrediction[]> {
        const [err, response] = await to(this.get(`${this.documentEndpoint}/:documentId/predictions`, [['documentId', documentId]]));

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASE_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new DocumentPrediction(x));
    }

    public async getPersonalPending(): Promise<InboxDocument[]> {
        const [err, response] = await to(this.get(`${Vue.$env().purchasesApiEndpoint}inbox-documents/personal-pending`));
        if (err) {
            this.clearAndShowError(`LOAD_PURCHASES_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new InboxDocument(x));
    }

    public async getPersonalInbox(): Promise<InboxDocument[]> {
        const [err, response] = await to(this.get(this.inboxEndpoint));
        if (err) {
            this.clearAndShowError(`LOAD_PURCHASES_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new InboxDocument(x));
    }

    public async getInboxDocuments(documentStatus?: DocumentStatus[], documentType?: DocumentType): Promise<InboxDocument[]> {
        let query = `?`;

        if (documentStatus) {
            documentStatus.forEach((x) => {
                query += `document_status=${x}&`;
            });
        }

        if (documentType) {
            query += `document_type=${documentType}&`;
        }

        const [err, response] = await to(this.get(`${this.documentEndpoint}${query}`));

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASES_FAILED`, err);
            return [];
        }

        return response.data.map((x) => new InboxDocument(x));
    }

    public createDocument(document: InboxDocument): Promise<AxiosResponse<InboxDocument>> {
        const model = {
            documentLines: document.documentLines.map((x) => {
                const project =
                    x.projectObj !== null ? x.projectObj.name : null;
                return {
                    description: x.description,
                    amount: x.amount !== null ? parseFloat(x.amount.toString()) : null,
                    price: x.price !== null ? parseFloat(x.price.toString()) : null,
                    project,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    lineNumber: x.lineNumber,
                    attachmentId: x.attachmentId,
                    product: x.product !== null && x.product.productId > 0 ? x.product.productId : null,
                };
            }),
            name: document.name,
            reference: document.reference,
            currency: document.currency,
            purchaseInvoiceDate: document.purchaseInvoiceDate,
            documentType: document.documentType,
            note: document.note,
            paymentMethod: document.paymentMethod ? document.paymentMethod.id : null,
            teamId: document.teamId,
        };

        if (!document.purchaseInvoiceDate) {
            delete model.purchaseInvoiceDate;
        }

        return this.post(`${this.documentEndpoint}`, model);
    }

    public updateDocument(document: InboxDocument) {
        const model = {
            documentLines: document.documentLines.map((x) => {
                const project =
                    x.projectObj !== null ? x.projectObj.name : null;
                return {
                    description: x.description,
                    amount: x.amount !== null ? parseFloat(x.amount.toString()) : null,
                    price: x.price !== null ? parseFloat(x.price.toString()) : null,
                    project,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    attachmentId: x.attachmentId,
                    lineNumber: x.lineNumber,
                    product: x.product ? x.product.productId : null,
                };
            }),
            organizationId: document.organizationId,
            name: document.name,
            reference: document.reference,
            currency: document.currency,
            purchaseInvoiceDate: document.purchaseInvoiceDate ? document.purchaseInvoiceDate : null,
            note: document.note,
            documentType: document.documentType,
            paymentMethod: document.paymentMethod.id,
            teamId: document.teamId,
        };

        if (!document.purchaseInvoiceDate) {
            delete model.purchaseInvoiceDate;
        }

        return this.put(`${this.documentEndpoint}/${document.id}`, model);
    }

    public changeDocumentStatus(documentId: number, newState: number) {
        return this.post(`${this.documentEndpoint}/${documentId}/change-state`, {
            newState,
        });
    }

    public submitInboxDocument(documentId: number) {
        return this.post(`${this.documentEndpoint}/${documentId}/submit`);
    }

    public approveInboxDocument(documentId: number) {
        return this.post(`${this.documentEndpoint}/${documentId}/approvals/approve`);
    }

    public declineInboxDocument(documentId: number, note: string) {
        return this.post(`${this.documentEndpoint}/${documentId}/approvals/decline`, { note });
    }

    public finalizeDocument(documentId: number, bookPurchase: boolean = false, askForApproval: boolean = false) {
        return this.post(`${this.documentEndpoint}/${documentId}/finalize`, { bookPurchase, askForApproval });
    }

    public cloneDocument(document: InboxDocument) {
        return this.post(`${this.documentEndpoint}/${document.id}/clone`, {
            name: `${document.name} copy`,
        });
    }

    public moveDocument(documentId: number, newTeamId: number) {
        return this.post(`${this.documentEndpoint}/${documentId}/move`, {
            newTeamId,
        });
    }

    public async deleteDocument(documentId: number): Promise<boolean> {
        const [err] = await to(this.delete(`${this.documentEndpoint}/${documentId}`));
        return !err;
    }

    public async getAttachments(documentId: number) {
        const [err, response] = await to(this.get(`${this.documentEndpoint}/:documentId/attachments`, [['documentId', documentId]]));

        if (err) {
            this.clearAndShowError(`LOAD_ATTACHMENTS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async deleteAttachment(dealId: number, fileId: string) {
        return this.delete(`${this.documentEndpoint}/${dealId}/attachments/${fileId}`);
    }

    public async getApprovals(documentInvoiceId: number) {
        const [err, response] = await to(
            this.get(`${this.documentEndpoint}/:documentInvoiceId/approvals`, [['documentInvoiceId', documentInvoiceId]]),
        );

        if (err) {
            this.clearAndShowError(`LOAD_DOCUMENT_APPROVALS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async remindApprovals(documentInvoiceId: number) {
        const [err] = await to(this.post(`${this.documentEndpoint}/${documentInvoiceId}/remind`));

        if (err) {
            this.clearAndShowError(`SENDING_REMINDER_FAILED`, err);
            return false;
        }

        return true;
    }
}
