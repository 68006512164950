import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import Organization from '@/models/CRM/Organization';
import { teamHelper } from '@/main';
import { Purchase } from '@/models/Purchases/Purchase';
import axios from 'axios';
import Attachment from '@/models/Attachment';

export default class PurchasesService extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}teams/:team/purchases`;
    private purchaseEndpoint = `${Vue.$env().purchasesApiEndpoint}`;
    private ecoEndpoint = `${Vue.$env().purchasesApiEndpoint}purchases`;

    public async getPurchase(purchaseId: number, purchaseTeamId: number = teamHelper.currentTeamId, showErrorMessage: boolean = true) {
        const [err, response] = await to(
            this.get(`${this.endpoint}/:purchaseId`, [
                ['purchaseId', purchaseId],
                ['team', purchaseTeamId],
            ]),
        );

        if (err) {
            if (showErrorMessage) {
                this.clearAndShowError(`LOAD_PURCHASE_FAILED`, err);
            }
            return new Purchase();
        }

        if (response.data && !response.data.teamId) {
            response.data.teamId = purchaseTeamId;
        }

        return new Purchase(response.data);
    }

    public async getPurchases(ecoScope: boolean = true, organizationId?: number, reference?: string): Promise<Range<Purchase>> {
        let queryString = '?';
        if (organizationId) {
            queryString = `${queryString}organizationId=${organizationId}`;
        }

        if (reference) {
            queryString = `${queryString}${queryString.length > 1 ? '&' : ''}reference=${reference}`;
        }

        const [err, response] = await to(this.get(`${ecoScope ? this.ecoEndpoint : this.endpoint}${queryString}`));

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASES_FAILED`, err);
            return new Range<Purchase>();
        }

        return new Range<Purchase>(response.data);
    }

    public createPurchase(purchase: Purchase) {
        const model = {
            lines: purchase.lines.map((x) => {
                const project =
                    x.projectObj !== null ? x.projectObj.name : null;
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : null,
                    metaData: x.metaData,
                    lineNumber: x.lineNumber,
                };
            }),
            organization: (purchase.organization as Organization).organizationId,
            name: purchase.name,
            reference: purchase.reference,
            currency: purchase.currency,
            purchaseDate: purchase.purchaseDate,
            teamId: purchase.teamId ? purchase.teamId : teamHelper.currentTeamId,
        };

        return this.post(`${this.purchaseEndpoint}teams/${model.teamId}/purchases`, model);
    }

    public updatePurchase(purchase: Purchase) {
        const model = {
            lines: purchase.lines.map((x) => {
                const project =
                    x.projectObj !== null ? x.projectObj.name : null;
                return {
                    description: x.description,
                    amount: parseFloat(x.amount.toString()),
                    price: parseFloat(x.price.toString()),
                    product: x.product !== null ? x.product.productId : null,
                    project,
                    department: x.departmentObj !== null ? x.departmentObj.name : null,
                    attachmentId: x.attachmentId,
                    taxRateId: x.taxRate !== null ? x.taxRate.id : null,
                    metaData: x.metaData,
                    lineNumber: x.lineNumber,
                };
            }),
            organization: (purchase.organization as Organization).organizationId,
            name: purchase.name,
            reference: purchase.reference,
            currency: purchase.currency,
            purchaseDate: purchase.purchaseDate,
            skipFromExternalInvoicing: purchase.skipFromExternalInvoicing,
        };

        return this.put(`${this.endpoint}/${purchase.id}`, model);
    }

    public changePurchaseState(purchaseId: number, newState: number) {
        return this.post(`${this.endpoint}/${purchaseId}/change-state`, {
            newState,
        });
    }

    public clonePurchase(purchase: Purchase) {
        return this.post(`${this.endpoint}/${purchase.id}/clone`, {
            name: `${purchase.name} copy`,
        });
    }

    public movePurchase(purchaseId: number, newTeamId: number) {
        return this.post(`${this.endpoint}/${purchaseId}/move`, {
            newTeamId,
        });
    }

    public async deletePurchase(purchaseId: number): Promise<boolean> {
        const [err] = await to(this.delete(`${this.endpoint}/${purchaseId}`));
        return !err;
    }

    public async getAttachments(dealId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/:dealId/attachments`, [['dealId', dealId]]));

        if (err) {
            this.clearAndShowError(`LOAD_ATTACHMENTS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async deleteAttachment(dealId: number, fileId: string) {
        return this.delete(`${this.endpoint}/${dealId}/attachments/${fileId}`);
    }

    public async getApprovals(purchaseInvoiceId: number) {
        const [err, response] = await to(
            this.get(`${this.purchaseEndpoint}purchase-approval/:purchaseInvoiceId`, [['purchaseInvoiceId', purchaseInvoiceId]]),
        );

        if (err) {
            this.clearAndShowError(`LOAD_PURCHASE_APPROVALS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async remindApprovals(purchaseInvoiceId: number) {
        const [err] = await to(this.post(`${this.purchaseEndpoint}purchase-approval/${purchaseInvoiceId}/remind`));

        if (err) {
            this.clearAndShowError(`SENDING_REMINDER_FAILED`, err);
            return false;
        }

        return true;
    }

    public async reUploadAttachment(teamId: number, purchaseId: number, attachment: Attachment) {
        const response = await axios({
            url: attachment.url,
            method: 'GET',
            responseType: 'blob',
        });

        if (!response) {
            return this.showError('FAILED_TO_REUPLOAD_ATTACHMENT');
        }

        const file = new Blob([response.data]);

        const formData = new FormData();
        formData.append('files', file, attachment.fileName);
        const [err2] = await to(
            this.post(`${this.purchaseEndpoint}/teams/${teamId}/purchases/${purchaseId}/attachments`, formData, null, {
                headers: { 'Content-Type': ' multipart/form-data' },
            }),
        );

        if (err2) {
            return this.showError('FAILED_TO_REUPLOAD_ATTACHMENT');
        }
    }
}
