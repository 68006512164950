import Vue from 'vue';
import { translateModule } from '@/store/modules/translate';

const getTranslateValue = (translateKey, translateParams = null) => {
    if (translateKey.indexOf('.') > -1) {
        const [group, key] = translateKey.split('.');
        return translateModule.value(key, group, translateParams);
    }
    return translateModule.value(translateKey, null, translateParams);
};

const translateDirective = {
    setAttribute(el: HTMLElement, binding: any) {
        const attribute = binding.arg ? binding.arg : 'innerHTML';
        const key = typeof(binding.value) === 'string' ? binding.value : binding.value.key;
        const params = binding.value && binding.value.params ? binding.value.params : {};
        el[attribute] = getTranslateValue(key, params);
    },
    setPlaceholder(el: HTMLInputElement, translateKey) {
        el.placeholder = getTranslateValue(translateKey);
    },
};

Vue.directive('translate', {
    bind(el: HTMLElement, binding) {
        translateDirective.setAttribute(el, binding);
    },
    unbind(el, binding) {
        const attribute = binding.arg ? binding.arg : 'innerHTML';
        el[attribute] = '';
    },
});

Vue.directive('translate-placeholder', {
    bind(el: HTMLInputElement, binding) {
        translateDirective.setAttribute(el, {
            arg: 'placeholder',
            value: binding.value,
        });
    },
});
