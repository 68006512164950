import SendInvoiceMail from './SendInvoiceMail';

// tslint:disable-next-line: max-classes-per-file
export class BankAccount {
    public accountNumber: string;
    public bic: string;
}

// tslint:disable-next-line: max-classes-per-file
export class Translation {
    public language: string = 'NL';

    public vat: string;
    public iban: string;
    public bic: string;
    public coc: string;
    public invoiceNumber: string;
    public invoiceDate: string;
    public description: string;
    public amount: string;
    public singlePrice: string;
    public totalPrice: string;
    public totalTaxDescription: string;
    public subTotalExVat: string;
    public totalAmount: string;
    public thankYou: string;
    public generatedBy: string;
    public dueDate: string;

    constructor(obj?: Partial<Translation>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
// tslint:disable-next-line: max-classes-per-file
export class InvoiceData {
    public coCNumber: string;
    public vatNumber: string;
    public registeredCompanyName: string;
    public streetName: string;
    public houseNumber: string;
    public postalCode: string;
    public city: string;
    public country: string;
    public emailAddress: string;
    public phoneNumber: string;
    public primaryColor: string;
    public secondaryColor: string;
    public translations: Translation[] = [];
    public bankAccounts: BankAccount[] = [{ accountNumber: null, bic: null }];
    public invoiceEmailTemplates: InvoiceEmailTemplate[] = [];
    public invoiceLogo: string = null;

    /**
     *
     */
    constructor() {
        if (this.translations.length === 0) {
            this.translations.push(new Translation());
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export default class LegalEntity {
    public legalEntityId: string = '';
    public name: string = '';
    public legalEntityReference: string = '';
    public invoiceNumberPrefix: string = '';
    public invoiceData: InvoiceData = new InvoiceData();
    public mollieApiKey?: string = null;
    public chargeExceptions: any[] = [];
    public defaultTaxRateId: string = '';
    public invoiceTemplateId?: any = null;

    public sendInvoiceText: SendInvoiceMail = {
        contactName: '',
        contactEmail: '',
    };

    public sendReminderText: SendInvoiceMail = {
        contactName: '',
        contactEmail: '',
    };

    constructor(obj?: Partial<LegalEntity>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class InvoiceTemplate {
    public name: string = '';
    public invoiceTemplateId: number = 0;
    public invoiceData: InvoiceData = new InvoiceData();

    constructor(obj?: Partial<InvoiceTemplate>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class InvoiceEmailTemplate {
    public body: string;
    public emailType: string;
    public language: string;
    public masterTemplate: string;
    public subject: string;

    constructor(obj?: Partial<InvoiceEmailTemplate>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
