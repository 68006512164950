import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { teamHelper, countryService, purchaseSettingsService } from '@/main';
import to from 'await-to-js';
import TeamSettings from '@/models/Finance/TeamSettings';
import { purchaseSettingsModule } from '@/store/modules/purchaseSettings';

@Component
export default class TeamSettingsComponent extends PageRender {
    public settings: TeamSettings = null;

    public selectedCurrency: any = null;
    public selectedCountry: any = {};

    public availableCurrencies = [
        { text: 'Euro', value: 'EUR' },
        { text: 'US Dollar', value: 'USD' },
        { text: 'Pound sterling', value: 'GBP' },
        { text: 'Saudi riyal', value: 'SAR' },
        { text: 'Swiss franc', value: 'CHF', sign: 'CHF' },
    ];

    public async created() {
        await purchaseSettingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
        await this.loadSettings();

        this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.settings.defaultCurrency);
        this.selectedCountry = this.countries.find((x) => x.id === this.settings.defaultCountry);

        this.isLoading = false;
    }

    public get teamName() {
        return teamHelper.currentTeam.name;
    }

    public async loadSettings() {
        let settings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);

        if (!settings) {
            settings = await purchaseSettingsService.getTeamSettings(teamHelper.currentTeamId);
        }
        this.settings = settings;
    }

    public selectCurrency(currency) {
        this.settings.defaultCurrency = currency.value;
    }

    public get countries() {
        const countries = countryService.getCountries();
        const list = Object.keys(countries[0]).map((x) => {
            return {
                id: x,
                name: countries[0][x].name,
            };
        });

        return list;
    }

    public selectCountry(country) {
        this.selectedCountry = country;
        this.settings.defaultCountry = country.id;
    }

    public async updateSettings() {
        this.showPending('CREATE_TEAM_SETTINGS_PENDING');

        const [err] = await to(purchaseSettingsService.upsertTeamSettings(this.settings, teamHelper.currentTeamId));
        if (err) {
            return this.clearAndShowError('CREATE_TEAM_SETTINGS_FAILED', err);
        }

        this.clearNotifications();

        // await this.$router.push({ name: 'crm-subscription', params: { subscriptionId: subscription.id.toString() } });
    }
}
