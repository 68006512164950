import BaseModal from '@/components/modals/BaseModal';
import { purchasesService } from '@/main';
import { Purchase } from '@/models/Purchases/Purchase';
import { PurchaseStateEnum } from '@/models/Purchases/PurchaseState';
import Component from 'vue-class-component';

@Component
export default class PurchaseBatchReminderModal extends BaseModal {
    public purchases: Purchase[] = [];

    public open(purchases: Purchase[]) {
        this.purchases = purchases.filter((p: Purchase) => {
            return p.purchaseState.purchaseStateId === PurchaseStateEnum.ApprovalRequested;
        });
        this.show();
    }

    public close() {
        this.purchases = [];
        this.hide();
    }

    public async sendReminders() {
        let countSuccess = 0;

        this.showPending('SENDING_REMINDER_PENDING');
        for (let i = 0; i < this.purchases.length; i++) {
            const id = this.purchases[i].id;
            const success = await purchasesService.remindApprovals(id);
            if (success) {
                countSuccess++;
            }
        }

        if (countSuccess > 0) {
            this.clearAndShowSuccess('SENDING_REMINDER_SUCCESS');
            this.close();
        }
    }
}
