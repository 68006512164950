import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import Vue from 'vue';
import { clientPurchasesApprovalService, inboxDocumentService, teamHelper } from '@/main';
import to from 'await-to-js';
import { orderBy } from '@progress/kendo-data-query';
import DeclineApprovalsModal from '@/components/modals/decline-approval-modal.vue';
import InboxDocument from '@/models/Costs/InboxDocument';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { PurchaseApproval } from '@/models/Purchases/PurchaseApproval';

@Component
export default class ApprovalsComponent extends PageRender {
    public allApprovals: PurchaseApproval[] = [];
    public approvals: PurchaseApproval[] = [];
    public allDocumentApprovals: InboxDocument[] = [];
    public documentApprovals: InboxDocument[] = [];

    public $refs!: {
        declineApprovalModal: DeclineApprovalsModal;
    };

    public purchaseApprovalsColumns: GridColumnProps[] = [
        { field: 'purchase.name', title: 'PURCHASE_NAME', editable: false },
        { field: 'receiverEmailAddress', title: 'RECEIVER', editable: false },
        { field: 'approvalState', title: 'APPROVAL_STATE', editable: false, width: 120 },
        { cell: this.renderPurchaseActions, title: 'Actions', width: 100, sortable: false },
    ];

    public approvalSearch: GridWrapperSearch = new GridWrapperSearch({
        properties: ['receiverEmailAddress', 'purchase.name', 'approvalState', 'price'],
    });

    public documentApprovalsColumns: GridColumnProps[] = [
        { field: 'name', title: 'PURCHASE_NAME', editable: false },
        { field: 'documentType', title: 'DOCUMENT_TYPE', editable: false },
        { field: 'approvalState', title: 'APPROVAL_STATE', editable: false, width: 120 },
        { cell: this.renderDeclarationActions, title: 'Actions', width: 100, sortable: false },
    ];

    public documentSearch: GridWrapperSearch = new GridWrapperSearch({
        properties: ['documentType', 'documentStatus', 'name', 'currency'],
    });

    public async mounted() {
        await this.loadApprovals();
        await this.loadDocumentApprovals();
        this.isLoading = false;
    }

    public async goToPurchase(e) {
        const purchaseId = e.dataItem.purchase.purchaseInvoiceId;
        const teamId = e.dataItem.purchase.teamId;

        const team = teamHelper.teams.find((x) => x.id === teamId);
        if (!team) {
            return this.showWarning(`Purchase details only available when you're part of its team`);
        }

        await this.$router.push({ name: 'approval', params: { purchaseId, teamId, teamKey: team.key } });
    }

    public async goToDocument(e) {
        const documentId = e.dataItem.inboxDocumentId;
        await this.$router.push({ name: 'document-approve', params: { documentId, teamId: e.dataItem.teamId } });
    }

    public async goToDocumentItem(item) {
        const documentId = item.inboxDocumentId;
        await this.$router.push({ name: 'document-approve', params: { documentId, teamId: item.teamId } });
    }

    public async loadApprovals() {
        this.allApprovals = this.approvals = await clientPurchasesApprovalService.getPersonalPending();
    }

    public async loadDocumentApprovals() {
        this.allDocumentApprovals = this.documentApprovals = await inboxDocumentService.getPersonalPending();
    }

    public updateOtherGridData(dataItems) {
        this.approvals = orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        this.refreshGrid++;
    }

    public updateDocumentApprovalsGridData(dataItems) {
        this.approvals = orderBy(dataItems, [{ field: 'documentStatus', dir: 'desc' }]);
        this.refreshGrid++;
    }

    public async goToPurchaseItem(item) {
        const purchaseId = item.purchase.purchaseInvoiceId;
        const teamId = item.purchase.teamId;
        await this.$router.push({ name: 'approval', params: { purchaseId, teamId } });
    }

    public renderPurchaseActions(h, _, row) {
        const actions = [
            {
                title: 'Show details',
                function: this.goToPurchaseItem,
            },
            {
                title: 'Decline',
                function: this.showPurchaseDeclineModal,
            },
            {
                title: 'Accept',
                function: this.acceptApproval,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderDeclarationActions(h, _, row) {
        const actions = [
            {
                title: 'Show details',
                function: this.goToDocumentItem,
            },
            {
                title: 'Decline',
                function: this.showDocumentDeclineModal,
            },
            {
                title: 'Accept',
                function: this.acceptDeclarationApproval,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public showDocumentDeclineModal(item: InboxDocument) {
        this.$refs.declineApprovalModal.show();
        this.$refs.declineApprovalModal.setId(item.inboxDocumentId);
    }

    public showPurchaseDeclineModal(item: PurchaseApproval) {
        this.$refs.declineApprovalModal.show();
        this.$refs.declineApprovalModal.setId(item.purchase.purchaseInvoiceId);
    }

    public async declineApproval(id: number, reason: string) {
        this.showPending('DECLINE_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.declineApproval(id, reason));
        if (err) {
            return this.clearAndShowError('DECLINE_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('DECLINE_APPROVAL_SUCCESS');
        await this.loadApprovals();
    }

    public async acceptApproval(item: PurchaseApproval) {
        this.showPending('ACCEPT_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.approveApproval(item.purchase.purchaseInvoiceId));
        if (err) {
            return this.clearAndShowError('ACCEPT_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('ACCEPT_APPROVAL_SUCCESS');
        await this.loadApprovals();
    }

    public async acceptDeclarationApproval(document: InboxDocument) {
        this.showPending('APPROVE_DOCUMENT_PENDING');
        const [errSubmit] = await to(inboxDocumentService.approveInboxDocument(document.inboxDocumentId));
        if (errSubmit) {
            return this.clearAndShowError('APPROVE_DOCUMENT_FAILED', errSubmit);
        }

        this.clearAndShowSuccess('APPROVE_DOCUMENT_SUCCESS');
    }
}
