import Vue from 'vue';
import { functions } from '@/helpers/functions';
import moment from 'moment';
import { translateModule } from '@/store/modules/translate';
import { ecosystemHelper, invoiceService, teamHelper } from '@/main';
import TaxRate from './CRM/TaxRate';
import { MetaData } from './CRM/DealLine';
import Structure from './Ecosystem/Structure';
import StructureTeam from './Ecosystem/StructureTeam';
import Project, { ProjectType } from './Project';

export default class PageRender extends Vue {
    public section = 'directory';
    public isLoading: boolean = true;
    public submitted: boolean = false;
    public editMode: boolean = false;
    public refreshGrid: number = 9999;
    public structure: Structure = new Structure();

    public taxRates: TaxRate[] = [];

    public get teams() {
        return teamHelper.teams;
    }

    public get myDepartments() {
        if (this.structure && teamHelper.teams) {
            return this.structure.teams.filter((team: StructureTeam) => {
                const index = teamHelper.teams.findIndex((t) => t.id === team.teamId);
                return index > -1;
            });
        }
        return [];
    }

    public async loadTaxRates() {
        if (this.taxRates && this.taxRates.length) {
            return;
        }
        const loadTaxRates = await invoiceService.getTaxRates();
        return (this.taxRates = loadTaxRates);
    }

    public getDepartments(): StructureTeam[] {
        if (this.structure && this.structure.teams.length > 0) {
            return this.structure.teams;
        }
        return [];
    }

    /** Render functions */
    public formatInvoiceAmountValue(h, _, row) {
        const props = { invoice: row.dataItem };
        return h(Vue.component('distribution-tree-amount'), { props });
    }

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.invoicedate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public formatImportedInvoiceAmountValue(h, _, row) {
        const props = { value: row.dataItem.amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderMember(h, member) {
        return h(Vue.component('member-name-native'), { props: { member } });
    }

    public renderTeamMemberships(h, _, row) {
        return h(Vue.component('memberships-component'), {
            props: { memberships: row.dataItem.teamMemberships, isTeam: true, disableOnClick: true },
        });
    }

    public renderMemberships(h, _, row) {
        return h(Vue.component('memberships-component'), { props: { memberships: row.dataItem.groupMemberships, disableOnClick: true } });
    }

    public renderGroups(h, _, row) {
        return h(Vue.component('memberships-component'), { props: { memberships: row.dataItem.groups, disableOnClick: true } });
    }

    public renderMembers(h, _, row) {
        const props = { members: row.dataItem.members };
        return h(Vue.component('members-component'), { props });
    }

    /** Notifications */
    public showValidationErrors(errors): void {
        const errorsHtml = errors
            .map((item) => {
                return `<li>${item}</li>`;
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'The form is invalid, please correct the following issues:',
            text: `<ul>${errorsHtml}</ul>`,
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error, consoleError = null): void {
        if (consoleError != null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(translateKey): void {
        this.$notify({
            title: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'loading',
            duration: -1,
        });
    }

    public showError(translateKey): void {
        if (this.$appInsights) {
            this.$appInsights.trackException({
                exception: translateModule.inEditMode ? new Error(translateKey) : new Error(translateModule.value(translateKey, 'ALERT_MESSAGES')),
            });
        }

        this.$notify({
            title: translateModule.value('ERROR', 'ALERT_MESSAGES'),
            text: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'error',
            duration: -1,
        });
    }

    public showWarning(translateKey): void {
        if (this.$appInsights) {
            this.$appInsights.trackTrace({
                message: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            });
        }
        this.$notify({
            title: translateModule.value('WARNING', 'ALERT_MESSAGES'),
            text: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'warning',
            duration: -1,
        });
    }

    public showInfo(translateKey): void {
        this.$notify({
            text: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'info',
        });
    }

    public clearAndShowWarning(translateKey) {
        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }

        Vue.nextTick(() => {
            this.showWarning(translateKey);
        });
    }

    public showSuccess(translateKey, duration = 3000): void {
        if (translateModule.inEditMode) {
            duration = -1;
        }
        this.$notify({
            title: translateModule.inEditMode ? translateKey : translateModule.value(translateKey, 'ALERT_MESSAGES'),
            type: 'success',
            duration,
        });
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(translateKey, duration = 3000): void {
        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }

        Vue.nextTick(() => {
            this.showSuccess(translateKey, duration);
        });
    }

    public clearAndShowError(translateKey, consoleError = null): void {
        if (consoleError != null) {
            console.error(consoleError);
        }

        if (!translateModule.inEditMode) {
            this.clearNotifications();
        }
        this.showError(translateKey);
    }

    public getCommunicationChannel(channels: any, channelName: string) {
        if (channels && channels.length) {
            const filteredChannel = channels.filter((channel: any) => {
                return channel.channelName === channelName;
            });

            if (filteredChannel && filteredChannel.length) {
                return filteredChannel[0].value;
            }
        }

        return 'N/A';
    }

    public calculateHeight() {
        return { height: `${window.innerHeight - 305}px` };
    }

    public calculateAnalyticsGridsHeight() {
        return { height: `${window.innerHeight - 315}px` };
    }

    public calculateInvoicesGridHeight(items = []) {
        if (items && items.length > 0) {
            return { height: `${items.length * 40 + 110}px` };
        }
        return { height: `${window.innerHeight - 110}px` };
    }

    public formatExpensesValue(h, _, row) {
        const props = { value: row.dataItem.expenses };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatExtrasValue(h, _, row) {
        const props = { value: row.dataItem.extras };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatResultValue(h, _, row) {
        const props = { value: row.dataItem.result };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatBalanceValue(h, _, row) {
        const props = { value: row.dataItem.balance };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatStartBalanceValue(h, _, row) {
        const props = {
            value: row.dataItem.startBalance
                ? row.dataItem.startBalance.round(2) - row.dataItem.result.round(2)
                : row.dataItem.balance.round(2) - row.dataItem.result.round(2),
        };
        return h(Vue.component('number-formatter'), { props });
    }

    public edit() {
        this.editMode = true;
    }

    public cancelEdit() {
        this.editMode = false;
    }

    public async loadStructure(period?: string) {
        if (period) {
            const monthString = moment(period, 'YYYY-MM-DD').format('YYYY-MM');
            await ecosystemHelper.loadStructure(monthString);
        }

        this.structure = ecosystemHelper.structure;
    }

    public async exclPriceInput(value, line: any) {
        if (!value) {
            return;
        }

        const inputValue = this.parsePriceAmount(value);
        const tax = await this.getProductTax(line);
        line.inclPrice = tax ? Math.round(inputValue * (1 + tax.percentage / 100) * 100) / 100 : inputValue;

        this.updatedValue(line.price, line);
    }

    public async inclPriceInput(value, line: any) {
        const inputValue = this.parsePriceAmount(value);
        const tax = await this.getProductTax(line);
        const taxRate = tax ? 1 + tax.percentage / 100 : 1;

        line.price = tax ? (inputValue / taxRate).toFixed(2) : inputValue;

        this.updatedValue(line.price, line);
    }

    public updatedValue(value, line: any) {
        if (!line || !value || !line.metaData || !line.metaData.length) {
            return;
        }

        line.metaData.forEach((metaDateFootprint: MetaData) => {
            if (line.product && line.product.footprintProducts && line.product.footprintProducts.length) {
                const footprintProduct = line.product.footprintProducts.find(
                    (x) => x.footprintType.footprintTypeId === metaDateFootprint.footPrintTypeId,
                );
                if (footprintProduct) {
                    metaDateFootprint.amount = value * footprintProduct.value;
                }
            }
        });
    }

    public parsePriceAmount(amount: string) {
        if (!amount) {
            return 0;
        }

        if (typeof amount === 'string' && amount.indexOf(',') > -1 && amount.indexOf('.') > -1 && amount.indexOf(',') > amount.indexOf('.')) {
            amount = amount.replace('.', '');
        }

        if (typeof amount === 'string' && amount.indexOf(',') > -1 && amount.indexOf('.') > -1 && amount.indexOf('.') > amount.indexOf(',')) {
            amount = amount.replace(',', '');
        }

        if (typeof amount === 'string' && amount.indexOf(',') > -1) {
            amount = amount.replace(',', '.');
        }

        if (typeof amount === 'string' && (amount.indexOf('$') > -1 || amount.indexOf('€') > -1)) {
            amount = amount.replace('$', '').replace('€', '');
        }

        return parseFloat(amount.toString());
    }

    public async getProductTax(line: any): Promise<TaxRate> {
        const taxRates = this.taxRates;
        if (typeof taxRates === 'undefined' || !taxRates || !taxRates.length) {
            this.taxRates = await this.loadTaxRates();
        }

        if (line.product && line.product.productId && line.product.taxRateId) {
            return this.taxRates.find((rate) => {
                return rate.id === line.product.taxRateId;
            });
        }
        return null;
    }

    public async getAndSetInclPrice(line: any) {
        const tax = await this.getProductTax(line);
        line.inclPrice = tax ? Math.round(line.price * (1 + tax.percentage / 100) * 100) / 100 : line.price;
    }

    public async getProjects(departmentObj): Promise<Project[]> {
        const projects: Project[] = [];
        if (departmentObj) {
            const teamId = departmentObj.teamId ?? departmentObj.id;
            const activities = this.structure.teams.find((x) => x.teamId === teamId).groups;
            projects.push(
                ...activities.map((x) => {
                    const id = x.groupId ?? x.id;
                    return { id, name: x.name, type: ProjectType.Activity };
                }),
            );

            projects.push({ $isDisabled: true, name: '---------------', id: -1, type: ProjectType.Unknown });
            const members = await teamHelper.getTeamMembers(teamId);
            projects.push(
                ...members.map((x) => {
                    const id = x.personId ?? x.id;
                    return {
                        id,
                        fullName: `${x.firstName} ${x.lastName}`,
                        name: x.emailAddress,
                        type: ProjectType.Member,
                    };
                }),
            );
        }

        return projects;
    }

    public getVariant(percentage) {
        if (percentage * 100 > 80) {
            return 'success';
        }

        if (percentage * 100 > 50) {
            return 'warning';
        }

        return 'danger';
    }

    protected translate(h, _, props) {
        return h(Vue.component('prd-translate-text'), {
            props: {
                translateKey: props.title,
                translateGroup: 'GRID',
                column: true,
                inline: true,
            },
        });
    }
}
