export default class FootprintProduct {
    public footprintProductId: number = null;
    public name: string = '';
    public value: number = null;
    public footprintType: FootprintType = null;
    public footprintProductTypeId: number = null;

    constructor(obj?: Partial<FootprintProduct>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

/* tslint:disable:max-classes-per-file */
export class FootprintType {
    public footprintTypeId: number = null;
    public name: string = '';
    public label: string = '';
}
