import Component from 'vue-class-component';
import Vue from 'vue';
import { applicationService } from '@/main';
import { Application } from '@/services/applicationService';

@Component
export default class AppsComponent extends Vue {
    public apps: Application[] = [];
    public isLoading: boolean = true;

    public async mounted() {
        this.apps = await applicationService.getApplications();
        this.isLoading = false;
    }

    public goToUrl(url: string) {
        window.open(url, '_blank');
    }
}
