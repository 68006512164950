import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import { FootprintType } from '@/models/Purchases/FootprintProduct';

export default class FootprintTypeServicePurchases extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}footprints-types`;

    public async getFootprintTypes(): Promise<Range<FootprintType>> {
        return new Range<FootprintType>({ items: [{ footprintTypeId: 1, name: 'CO2', label: 'KG' }] });

        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            // this.clearAndShowError(`LOAD_FOOTPRINT_TYPES_FAILED`, err);
            return new Range<FootprintType>();
        }

        return new Range<FootprintType>(response.data);
    }
}
