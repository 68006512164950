import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { ecosystemHelper, purchasesService, teamHelper } from '@/main';
import Vue from 'vue';
import moment from 'moment';
import to from 'await-to-js';
import PurchaseStatus from '@/models/PurchaseStatus';
import { Purchase } from '@/models/Purchases/Purchase';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import GridWrapper from '@prd/shared-ui/src/components/Grid/GridWrapper';
import { GridWrapperSearchType } from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearchOptions';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import { Membership } from '@/models/Interfaces';
import PurchaseBatchReminderModal from './components/purchase-batch-reminder-modal.vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { orderBy } from '@progress/kendo-data-query';
import GenericSearch from '@/components/generic-search/generic-search';

@Component({
    components: {
        ReminderModal: PurchaseBatchReminderModal,
    },
})
export default class PurchasesComponent extends PageRender {
    public isEcoScope: boolean = true;
    public searchValue: string = '';
    public loadingEcosystemPurchases: boolean = false;
    public ecosystemPurchasesCount: number = null;
    public purchases: Purchase[] = [];
    public allPurchases: Purchase[] = [];
    public purchasesColumns: GridColumnProps[] = [
        { field: 'id', title: 'ID', width: 75, editable: false, filterable: false },
        { field: 'name', title: 'NAME', editable: false, filterable: false, cell: this.renderNameWithLink },
        { field: 'organization.name', title: 'ORGANIZATION', editable: false, width: 300, filterable: false },
        { field: 'purchaseDate', title: 'DATE', editable: false, cell: this.renderDate, width: 115, filterable: false },
        { field: 'purchaseState', title: 'STATUS', editable: false, cell: this.renderStatus, filterCell: this.renderPaymentStatusFilter, width: 160 },
        { field: 'purchaseValue', title: 'Value', editable: false, cell: this.renderValue, width: 150, filterable: false },
        { field: 'reference', title: 'Reference', editable: false, width: 250, filterable: false },
        { cell: this.renderActions, title: 'ACTIONS', width: 85, sortable: false, filterable: false },
    ];

    public $refs!: {
        purchaseGrid: GridWrapper;
        reminderModal: PurchaseBatchReminderModal;
        genericSearch: GenericSearch;
    };

    public created() {
        this.isEcoScope = !this.$route.params.teamId;

        if (this.$route.query.search) {
            this.searchValue = this.$route.query.search as string;
        }

        if (this.isEcoScope) {
            this.purchasesColumns.splice(1, 0, { field: 'teamId', title: 'TEAM', width: 250, cell: this.renderTeam, filterable: false });
        }
    }

    public async mounted(){
        await this.loadPurchases();
    }

    public async loadPurchases() {
        this.isLoading = true;
        const purchases = await purchasesService.getPurchases(this.isEcoScope);
        this.allPurchases = this.purchases = purchases.items;

        if (this.searchValue) {
            this.$refs.genericSearch.setValue(this.searchValue);
        }

        this.isLoading = false;
    }

    public async updateGridData(dataItems, value) {
        this.loadingEcosystemPurchases = true;

        this.purchases = dataItems; // orderBy(dataItems, [{ field: 'name', dir: 'asc' }]);
        let teamResultsCount = dataItems.length;

        if (value) {
            const purchases = await purchasesService.getPurchases(true);

            var searcher = new GenericSearch();
            searcher.dataItems = purchases.items;
            searcher.properties = ['id', 'purchaseState.name', 'name', 'purchaseValue', 'organization.name', 'reference', 'team'];
            searcher.$on('finished-search', (dataItems) => {
                this.ecosystemPurchasesCount = dataItems.length - teamResultsCount;
                this.loadingEcosystemPurchases = false;
            });
            searcher.setValue(value);
            this.searchValue = value;
        } else {
            this.ecosystemPurchasesCount = null;
            this.loadingEcosystemPurchases = false;
        }

        this.refreshGrid++;
    }

    public async goToPurchase(e) {
        const purchaseId = e.dataItem ? e.dataItem.id : e.id;
        let params;

        if (this.isEcoScope) {
            const team = teamHelper.getTeam(e.dataItem.teamId);
            if (team.id !== e.dataItem.teamId) {
                return this.showWarning(`You're not part of this team. Can't navigate you to this purchase`);
            }

            params = {
                purchaseId,
                teamId: team.id,
                teamKey: team.key,
            };

            teamHelper.setTeam(team);
        } else {
            params = { purchaseId };
        }

        if (e.event.ctrlKey || e.event.metaKey) {
            const route = this.$router.resolve({ name: 'purchase', params });
            return window.open(route.href, '_blank');
        }

        await this.$router.push({ name: 'purchase', params });
    }

    public async goToAllPurchases() {
        await this.$router.push({ name: 'purchases-all-purchases', query: { search: this.searchValue } });
    }

    public async createPurchase() {
        await this.$router.push({ name: 'create-purchase' });
    }

    public async deletePurchase(purchase: Purchase) {
        this.showPending('DELETE_PURCHASE_PENDING');
        const success = await purchasesService.deletePurchase(purchase.id);
        if (success) {
            this.clearAndShowSuccess('DELETE_PURCHASE_SUCCESS');
            const index = this.purchases.findIndex((p: Purchase) => {
                return p.id === purchase.id;
            });
            this.purchases.splice(index);
        } else {
            this.clearAndShowError('DELETE_PURCHASE_FAILED', null);
        }
    }

    public async copyPurchase(item: Purchase) {
        const [err, response] = await to(purchasesService.clonePurchase(item));
        if (err) {
            return this.clearAndShowError('CLONE_PURCHASE_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_PURCHASE_SUCCESS');
        const purchase = new Purchase(response.data);
        await this.$router.push({ name: 'purchase', params: { purchaseId: purchase.id.toString() } });
    }

    public renderStatus(h, _, row) {
        return h('td', row.dataItem.purchaseState.name);
    }

    public renderValue(h, _, row) {
        const purchase = new Purchase(row.dataItem);
        return h('td', [Vue.filter('numberFormat')(purchase.purchaseValue)]);
    }

    public renderDate(h, _, row) {
        return h('td', moment(row.dataItem.purchaseDate).format('DD-MM-YYYY'));
    }

    public renderTeam(h, _, row) {
        let team = teamHelper.getTeam(row.dataItem.teamId);
        if (team.id !== row.dataItem.teamId) {
            const structure = ecosystemHelper.structure;
            const sTeam: StructureTeam = structure.teams.find((x) => x.teamId === row.dataItem.teamId);
            team = {
                id: sTeam.teamId,
                key: sTeam.teamKey,
                name: sTeam.name,
            } as Membership;
        }

        row.dataItem.team = team;

        return h('td', team ? team.name : '-');
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Delete purchase',
                function: this.deletePurchase,
            },
            {
                title: 'Copy purchase',
                function: this.copyPurchase,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderNonAccessiblePurchases(_h, tr, _row, item) {
        if (this.isEcoScope) {
            const isAccessible = teamHelper.getTeam(item.dataItem.teamId);
            tr.data.class += isAccessible.id !== item.dataItem.teamId ? ' bg-secondary' : '';
        }

        if ((item.dataItem as Purchase).purchaseState.name === 'Declined') {
            tr.data.class += ' bg-error';
        }

        return tr;
    }

    public openReminderModal() {
        this.$refs.reminderModal.open(this.$refs.purchaseGrid.dataItemsView);
    }

    private renderPaymentStatusFilter(h) {
        const props = {
            options: PurchaseStatus.getStatussen(),
            filter: this.$refs.purchaseGrid.filter,
            field: 'purchaseState.name',
            defaultSelected: [],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }

    private renderNameWithLink(h, _, row) {
        const item = row.dataItem;
        let params = {};
        const purchaseId = item.id;

        if (this.isEcoScope) {
            const team = teamHelper.getTeam(item.teamId);
            if (team.id !== item.teamId) {
                return h('td', item.name);
            }
            params = {
                purchaseId,
                teamId: team.id,
                teamKey: team.key,
            };
        } else {
            params = { purchaseId };
        }

        const route = this.$router.resolve({ name: 'purchase', params });
        const props = { text: item.name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }
}
