import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import Contact from '@/models/CRM/Contact';
import { Prop } from 'vue-property-decorator';
import { contactsServiceCRM } from '@/main';
import to from 'await-to-js';
import { required } from 'vuelidate/lib/validators';
import SideActionBase from '@/plugin/sideActions/sideActionBase';

@Component({
    validations: {
        fromContact: { required },
        toContact: { required },
    },
} as any)
export default class MergeContactsComponent extends SideActionBase<any> {
    public contacts: Contact[] = [];
    public submitted: boolean = false;

    public fromContact: Contact = null;
    public toContact: Contact = null;

    public working: boolean = true;

    public async showMergeWarning(): Promise<void> {
        this.submitted = true;

        if (this.fromContact && this.toContact) {
            this.$bvModal
                .msgBoxConfirm(`This action can't be undone! Please verify that you are merging the right contacts.`, {
                    title: `Merging contacts`,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: `Merge`,
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.mergeContacts();
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.log(err);
                });
        }
    }

    public mounted(): void {
        console.log('mounted');
        this.contacts.push(...this.options.contacts);
    }

    public async mergeContacts(): Promise<any> {
        const [err] = await to(
            contactsServiceCRM.mergeContacts(this.fromContact.contactId, this.toContact.contactId),
        );
        if (err) {
            return this.showError('Failed to merge contacts');
        }
        this.finished()
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.cancel();
    }

    public FromContactOptions(): any[] {
        return this.contacts.filter((contact) => {
            return !this.toContact || (this.toContact && contact.contactId !== this.toContact.contactId);
        });
    }

    public ToContactOptions(): any[] {
        return this.contacts.filter((contact) => {
            return !this.fromContact || (this.fromContact && contact.contactId !== this.fromContact.contactId);
        });
    }
}
