import Base from '@/pages/Base.vue';

export default [
    {
        name: 'legal-entities',
        path: '/invoices/:ecosystemId(\\d+)-:ecosystemKey/legal-entities/',
        component: require('@/pages/invoices/legalEntities.vue').default,
        meta: {
            title: 'Legal entities',
            scope: 'legal-entities',
            section: 'legal-entities',
            app: 'invoices',
            hidePicker: true,
            breadcrumb: {
                text: 'NAV_LEGAL_ENTITIES',
                items: ['invoices-purchases', 'invoices-sales'],
            },
        },
    },
    {
        name: 'legal-entity',
        path: '/invoices/:ecosystemId(\\d+)-:ecosystemKey/legal-entities/:legalEntityId',
        component: require('@/pages/invoices/legalEntity.vue').default,
        meta: {
            title: 'Legal entity',
            scope: 'legal-entity',
            section: 'legal-entity',
            app: 'invoices',
            hidePicker: true,
            breadcrumb: {
                text: 'NAV_LEGAL_ENTITY',
                items: ['invoices-purchases', 'invoices-sales'],
            },
        },
    },
    {
        path: '/invoices/:ecosystemId(\\d+)-:ecosystemKey/:legalEntity',
        component: Base,
        children: [
            {
                name: 'invoices-purchases',
                path: 'purchases/',
                component: require('@/pages/invoices/invoices.vue').default,
                meta: {
                    title: 'Invoices',
                    scope: 'invoices',
                    section: 'invoices',
                    app: 'invoices',
                    type: 'purchase',
                    breadcrumb: {
                        text: 'NAV_PURCHASES',
                        items: ['invoices-sales'],
                    },
                },
            },
            {
                name: 'invoices-sales',
                path: 'sales/',
                component: require('@/pages/invoices/invoices.vue').default,
                meta: {
                    title: 'Invoices',
                    scope: 'invoices',
                    section: 'invoices',
                    app: 'invoices',
                    type: 'sales',
                    breadcrumb: {
                        text: 'NAV_SALES',
                        items: ['invoices-purchases'],
                    },
                },
            },
            {
                name: 'invoice-sales',
                path: 'sales/:invoiceId',
                component: require('@/pages/invoices/invoice.vue').default,
                meta: {
                    title: 'Invoices',
                    scope: 'invoice',
                    section: 'invoice',
                    app: 'invoices',
                    type: 'sales',
                    breadcrumb: {
                        text: 'NAV_SALES',
                        items: ['invoice-purchases'],
                    },
                },
            },
            {
                name: 'invoice-purchases',
                path: 'purchases/:invoiceId',
                component: require('@/pages/invoices/invoice.vue').default,
                meta: {
                    title: 'Invoices',
                    scope: 'invoice',
                    section: 'invoice',
                    app: 'invoices',
                    type: 'purchase',
                    breadcrumb: {
                        text: 'NAV_PURCHASE',
                        items: ['invoice-sales'],
                    },
                },
            },
        ],
    },
];
