import Base from '@/pages/Base.vue';

export default [
    {
        name: 'base',
        path: '/purchases/:ecosystemId(\\d+)-:ecosystemKey/',
        component: Base,
        children: [
            {
                name: 'purchases-all-purchases',
                path: 'purchases/',
                component: require('@/pages/purchases/purchases/purchases.vue').default,
                meta: {
                    title: 'Ecosystem Purchases',
                    scope: 'purchases',
                    section: 'purchases',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_PURCHASES',
                        items: ['purchases'],
                    },
                },
            },
            {
                name: 'inbox',
                path: 'inbox/',
                component: require('@/pages/purchases/purchases/inbox.vue').default,
                meta: {
                    title: 'Inbox',
                    scope: 'purchases',
                    section: 'inbox',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_INBOX',
                        items: ['create-purchase', 'purchases-all-deals'],
                    },
                },
            },
            {
                name: 'inbox-item',
                path: 'inbox/:inboxDocumentId',
                component: require('@/pages/purchases/purchases/inbox-document.vue').default,
                meta: {
                    title: 'Inbox document',
                    scope: 'purchases',
                    section: 'inbox-document',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_INBOX',
                        items: ['create-purchase', 'purchases-all-deals'],
                    },
                },
            },
            {
                name: 'purchases',
                path: ':teamId(\\d+)-:teamKey/purchases/',
                component: require('@/pages/purchases/purchases/purchases.vue').default,
                meta: {
                    title: 'Purchases',
                    scope: 'purchases',
                    section: 'purchases',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_PURCHASES',
                        items: ['create-purchase', 'purchases-all-deals'],
                    },
                },
            },
            {
                name: 'create-purchase',
                path: ':teamId(\\d+)-:teamKey/purchases/new',
                component: require('@/pages/purchases/purchases/create.vue').default,
                meta: {
                    title: 'Purchases',
                    scope: 'purchases',
                    section: 'purchases',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_CREATE_PURCHASE',
                        items: ['purchases'],
                    },
                },
            },
            {
                name: 'purchase',
                path: ':teamId(\\d+)-:teamKey/purchases/:purchaseId',
                component: require('@/pages/purchases/purchases/purchase.vue').default,
                meta: {
                    title: 'Purchases',
                    scope: 'purchases',
                    section: 'purchases',
                    app: 'purchases',
                    breadcrumb: {
                        text: 'NAV_PURCHASE',
                        items: ['purchases'],
                    },
                },
            },
            {
                name: 'purchase-footprint-products',
                path: 'footprint-products/',
                component: require('@/pages/purchases/footprint/footprints.vue').default,
                meta: {
                    title: 'Footprint Products',
                    scope: 'footprint',
                    app: 'purchases',
                    section: 'footprint',
                    breadcrumb: {
                        text: 'NAV_FOOTPRINT_PRODUCTS',
                        items: ['purchase-create-product'],
                    },
                },
            },
            {
                name: 'purchase-products',
                path: 'products/',
                component: require('@/pages/purchases/products/products.vue').default,
                meta: {
                    title: 'Products',
                    scope: 'products',
                    app: 'purchases',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_PRODUCTS',
                        items: ['purchase-create-product'],
                    },
                },
            },
            {
                name: 'purchase-create-product',
                path: 'product/new',
                component: require('@/pages/purchases/products/create.vue').default,
                meta: {
                    title: 'New product',
                    scope: 'products',
                    app: 'purchases',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_CREATE_PRODUCT',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'purchase-product',
                path: 'products/:productId',
                component: require('@/pages/purchases/products/product.vue').default,
                meta: {
                    title: 'Product',
                    scope: 'product',
                    app: 'purchases',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_PRODUCT',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'purchase-organizations',
                path: 'organizations/',
                component: require('@/pages/purchases/organizations/organizations.vue').default,
                meta: {
                    title: 'Organizations',
                    scope: 'organizations',
                    app: 'purchases',
                    section: 'organizations',
                    breadcrumb: {
                        text: 'NAV_ORGANIZATIONS',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'purchase-create-organization',
                path: 'organizations/new',
                component: require('@/pages/purchases/organizations/create.vue').default,
                meta: {
                    title: 'New organization',
                    scope: 'organizations',
                    app: 'purchases',
                    section: 'organizations',
                    breadcrumb: {
                        text: 'NAV_CREATE_ORGANIZATIONS',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'purchase-ledgers',
                path: 'ledgers/',
                component: require('@/pages/purchases/ledgers/ledgers.vue').default,
                meta: {
                    title: 'Ledgers',
                    scope: 'ledgers',
                    app: 'purchases',
                    section: 'ledgers',
                    breadcrumb: {
                        text: 'NAV_LEDGERS',
                        items: ['purchase-create-ledger'],
                    },
                },
            },
            {
                name: 'purchase-team-settings',
                path: ':teamId(\\d+)-:teamKey/settings',
                component: require('@/pages/purchases/team-settings/create-or-edit-team-settings.vue').default,
                meta: {
                    title: 'Settings',
                    scope: 'team-settings',
                    app: 'purchases',
                    section: 'team-settings',
                    breadcrumb: {
                        text: 'NAV_TEAM_SETTINGS',
                        items: [],
                    },
                },
            },
        ],
    },
    {
        name: 'organization-base',
        path: '/purchases/:ecosystemId(\\d+)-:ecosystemKey/organizations/:organizationId',
        component: Base,
        children: [
            {
                name: 'purchase-organization',
                path: '/',
                component: require('@/pages/purchases/organizations/organization.vue').default,
                meta: {
                    title: 'Organization',
                    scope: 'organizations',
                    app: 'purchases',
                    section: 'overview',
                    breadcrumb: {
                        text: 'NAV_ORGANIZATION',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
        ],
    },
];
