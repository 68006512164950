import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import {
    purchasesService,
    organizationsServicePurchases,
    productsServicePurchases,
    teamHelper,
    footprintTypeServicePurchases,
    clientPurchasesApprovalService,
    brightService,
    loginHelper,
} from '@/main';
import Organization from '@/models/CRM/Organization';
import Product from '@/models/CRM/Product';
import Vue from 'vue';
import to from 'await-to-js';
import { DealLine } from '@/models/CRM/DealLine';
import TaxRate from '@/models/CRM/TaxRate';
import { FootprintType } from '@/models/Purchases/FootprintProduct';
import DeclineApprovalsModal from '@/components/modals/decline-approval-modal.vue';
import { Purchase } from '@/models/Purchases/Purchase';
import Structure from '@/models/Ecosystem/Structure';
import Attachment from '@/models/Attachment';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import { PurchaseApproval } from '@/models/Purchases/PurchaseApproval';
import { ProjectType } from '@/models/Project';

@Component
export default class CreatePurchaseComponent extends PageRender {
    public purchase: Purchase = new Purchase();
    public organizations: Organization[] = [];
    public products: Product[] = [];
    public isEdit: boolean = false;
    public allowedToEdit: boolean = true;
    public organizationSelected: number = 0;
    public tableRerender: number = 0;
    public invalidCombinationOrgRef: boolean = false;

    public attachments: Attachment[] = [];
    public projects: any[] = [];
    public approvals: PurchaseApproval[] = [];
    public availableCurrencies = [
        { text: 'Euro', value: 'EUR' },
        { text: 'US Dollar', value: 'USD' },
        { text: 'Pound sterling', value: 'GBP' },
        { text: 'Saudi riyal', value: 'SAR' },
    ];
    public selectedCurrency = { text: 'Euro', value: 'EUR' };
    public selectedDate: Date = null;
    public selectedDateChanged: boolean = false;
    public showInc: boolean = false;

    public footprintTypes: FootprintType[] = [];
    public isSplitted: boolean = false;
    public selectedAttachment = null;

    public selectedTeam: StructureTeam = null;
    public teamChanged: boolean = false;

    public currentLineIndex: number = 0;
    public teamSelectTabIndex: number = 101;
    public currencySelectTabIndex: number = 102;
    public orgSelectTabIndex: number = 103;

    public openPicker: boolean = false;
    public reason: string = '';

    public $refs!: {
        declineApprovalModal: DeclineApprovalsModal;
    };

    public async created() {
        const purchaseId = parseInt(this.$route.params.purchaseId);
        const purchaseTeamId = parseInt(this.$route.params.teamId);
        await this.loadPurchase(purchaseId, purchaseTeamId);

        this.isEdit = false;
        this.allowedToEdit = !(
            (this.purchase.purchaseState && this.purchase.purchaseState.purchaseStateId === 2) ||
            (this.purchase.purchaseState && this.purchase.purchaseState.purchaseStateId === 4)
        );

        await Promise.all([this.loadStructure(this.purchase.purchaseDate), this.loadTaxRates()]); // , this.loadOrganizations(), this.loadProducts()]);

        if (!this.purchase.teamId) {
            this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());
        } else {
            this.selectedTeam = this.structure.teams.find((y) => y.teamId === this.purchase.teamId);
        }

        for (let j = 0; j < this.purchase.lines.length; j++) {
            const x = this.purchase.lines[j];

            if (x.department) {
                x.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === x.department.toLowerCase());
                x.projects = await this.getProjects(x.departmentObj);
            }

            if (x.department && x.departmentObj && x.project) {
                x.projectObj = x.projects.find((y) => y.name && y.name.toLowerCase() === x.project.toLowerCase());;
                x.responsible = x.open = x.project === loginHelper.getUser().emailAddress;
            }

            if (x.project && x.projectObj && x.projectObj.type === ProjectType.Activity) {
                const group = await brightService.getGroup(x.projectObj.id);
                if (group) {
                    x.projectObj.groupLead = group.groupLead;
                    x.responsible = x.open = x.projectObj.groupLead && x.projectObj.groupLead.emailAddress === loginHelper.getUser().emailAddress;
                }
            }

            if (x.product) {
                x.product = await productsServicePurchases.getProduct(x.product.productId);
            }

            if (x.price) {
                await this.getAndSetInclPrice(x);
            }
        }

        this.isLoading = false;
    }

    public async loadPurchase(purchaseId: number, purchaseTeamId: number) {
        const approvals = await clientPurchasesApprovalService.getPersonalPending();
        const approval = approvals.find((x) => x.purchase.purchaseInvoiceId === purchaseId);
        if (!approval) {
            await this.$router.push({ name: 'approvals' });
            this.showInfo('APPROVAL_ALREADY_COMPLETED');
        }
        if (purchaseTeamId !== approval.purchase.teamId) {
            purchaseTeamId = approval.purchase.teamId;
        }
        const purchase = await purchasesService.getPurchase(approval.purchase.purchaseInvoiceId, purchaseTeamId, false);
        if (!purchase || !purchase.id) {
            this.purchase = approval.purchase;
        } else {
            this.purchase = purchase;
        }
    }

    public getPendingApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'pending').length;
    }

    public async loadOrganizations() {
        const organizations = await organizationsServicePurchases.getOrganizations();
        this.organizations = organizations.items;
    }

    public async loadProducts() {
        const products = await productsServicePurchases.getProducts();
        this.products = [...products.items];
    }

    public async loadFootprintTypes() {
        const types = await footprintTypeServicePurchases.getFootprintTypes();
        this.footprintTypes = types.items;
    }

    public createOrganizationSideAction() {
        this.$sideActions.push('create-organization-side-action', { data: true }, (result) => {
            this.setOrganization(result);
        });
    }

    public openAddPurchaseLineSideAction(index) {
        this.currentLineIndex = index;

        this.$sideActions.push('edit-purchase-line-side-action', { line: this.purchase.lines[index] }, (result) => {
            this.purchase.lines[index] = result;
            this.tableRerender++;
        });
    }

    public setOrganization(result) {
        Vue.set(this.purchase, 'organization', result);
        this.organizationSelected++;
    }

    public async loadAttachments() {
        const attachments = await purchasesService.getAttachments(this.purchase.id);
        this.attachments = attachments;
    }

    public async selectDepartment(department, purchaseLineIndex) {
        this.purchase.lines[purchaseLineIndex].project = null;
        this.purchase.lines[purchaseLineIndex].projects = await this.getProjects(department);
    }

    public getProductTaxRate(line: DealLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return '';
            }
            return taxRate.description;
        }

        return '';
    }

    public getProductTaxValue(line: DealLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return 0;
            }
            return line.price - line.price / ((100 + taxRate.percentage) / 100);
        }

        return 0;
    }

    public selectProject() {
        this.tableRerender++;
    }

    public selectCurrency() {
        this.purchase.currency = this.selectedCurrency.value;
    }

    public getTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal + this.getSubtotal();
    }

    public getTaxTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal;
    }

    public hasAnyProducts(tax) {
        return (
            this.purchase.lines.filter(
                (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
            ).length > 0
        );
    }

    public getTaxForType(tax: TaxRate) {
        const lines = this.purchase.lines.filter(
            (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
        );

        if (lines.length > 0) {
            const totalIncl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.inclPrice;
            }, 0);

            const totalExcl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.price;
            }, 0);

            return totalIncl - totalExcl;
        }

        return 0;
    }

    public getSubtotal() {
        let subTotal = 0;
        this.purchase.lines.forEach((line) => {
            subTotal += parseFloat(line.amount.toString()) * line.price;
        });

        return subTotal;
    }

    public async declineApproval(id: number, reason: string) {
        if (!id || !reason) {
            this.showWarning('ENTER_A_VALID_REASON');
        }

        this.showPending('DECLINE_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.declineApproval(id, reason));
        if (err) {
            return this.clearAndShowError('DECLINE_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('DECLINE_APPROVAL_SUCCESS');
        await this.$router.push({ name: 'approvals' });
    }

    public async acceptApproval() {
        this.showPending('ACCEPT_APPROVAL_PENDING');
        const [err] = await to(clientPurchasesApprovalService.approveApproval(this.purchase.id));
        if (err) {
            return this.clearAndShowError('ACCEPT_APPROVAL_FAILED', err);
        }

        this.clearAndShowSuccess('ACCEPT_APPROVAL_SUCCESS');
        await this.$router.push({ name: 'approvals' });
    }

    public showDeclineApprovalModal() {
        this.$refs.declineApprovalModal.show();
    }
}
