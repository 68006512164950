import Component from 'vue-class-component';
import Vue from 'vue';
import FileUploader from '@/components/attachments/file-uploader.vue';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import DocumentUploadActionOptions from './document-upload-action-options';

@Component
export default class DocumentUploadAction extends SideActionBase<DocumentUploadActionOptions> {
    public $refs!:{ 
        fileUploader: FileUploader
    };
    private hasError = false;

    public mounted() {
        this.$refs.fileUploader.setEndpoint(`${Vue.$env().purchasesApiEndpoint}inbox-documents/${this.options.document.id}/attachments`);
    }

    public handleError() {
        this.hasError = true;
        this.clearAndShowError('Failed to upload attachment');
    }

    public submitDocuments() {
        if (!this.hasError) {
            this.clearAndShowSuccess('UPLOADED_DOCUMENTS');
            this.finished();
        }
    }

    public async upload() {
        this.showPending('PROCESSING_DOCUMENTS');
        await this.$refs.fileUploader.processQueue();
    }
}
