import { AxiosHelper } from '@/helpers/axiosHelper';
import { Template } from '@/models/CRM/Template';
import to from 'await-to-js';
import Vue from 'vue';
import { BaseService } from './baseService';

export class TemplateService extends BaseService {
    private endpoint = `${Vue.$env().invoicesApiEndpoint}templates`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getTemplates(legalEntityName?: string): Promise<Template[]> {
        let url = `${this.endpoint}`;
        if (legalEntityName) {
            url += `?legal_entity_name=${legalEntityName}`;
        }
        const [error, response] = await to(this.get<Template[]>(url));

        return error ? [] : response.data;
    }
}
