import { render, staticRenderFns } from "./decline-approval-modal.vue?vue&type=template&id=66679eec"
import script from "./decline-approval-modal.ts?vue&type=script&lang=js&external"
export * from "./decline-approval-modal.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports