export const enum ProjectType {
    Unknown = '',
    Activity = 'Activity',
    Member = 'Member',
};

export default interface Project {
    id: number;
    name: string;
    $isDisabled?: boolean;
    type: ProjectType;
    groupLead?: any;
}
