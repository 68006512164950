import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ecosystemHelper, teamHelper } from '@/main';
import { Membership } from '@/models/Interfaces';
import { Route } from 'vue-router';
import { profileModule } from '@/store/modules/profile';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import Structure from '@/models/Ecosystem/Structure';

@Component
export default class NavigationTeam extends Vue {
    public isEcoScope: boolean = false;
    public structure: Structure = null;

    public async created() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
        this.structure = ecosystemHelper.structure;
    }

    public updated() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
    }

    public get currentTeam() {
        const profile = profileModule.brightProfile;
        if (profile && profile.teamMemberships) {
            return profile.teamMemberships.find((membership: Membership) => {
                return membership.key === teamHelper.currentTeam.key;
            });
        }

        return {} as Membership;
    }

    public get otherTeams(): any[] {
        const profile = profileModule.brightProfile;
        if (!profile || !profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)) {
            return [];
        }

        return profile.teamMemberships.filter(
            (x) => x.ecosystem === ecosystemHelper.currentEcosystem.id && this.structure.teams.some((y) => y.teamId === x.id),
        );
    }

    public async switchTo(team: Membership): Promise<Route> {
        teamHelper.setTeamByTeamId(team.id);
        await legalEntityModule.init();

        const route = this.$route;
        if (typeof route.params.teamId === 'undefined' && typeof route.params.teamKey === 'undefined') {
            // no routing needed, team isnt part of route.
            return;
        }

        const params = Object.assign({}, route.params);
        params.teamKey = team.key;
        params.teamId = team.id.toString();

        return this.$router.push({
            name: route.name,
            params,
        });
    }
}
