import { Component, Prop } from 'vue-property-decorator';
import PageRender from '@/models/PageRender';
import { Invoice } from '@/models/Invoice';

@Component
export default class InvoiceDetailComponent extends PageRender {
    @Prop() public dataItem: any;
    public editMode: boolean = false;
    public pathId: string = '';
    public lines: any[];

    public async created() {
        if (this.dataItem) {
            this.lines = this.dataItem.lines;

            this.lines.forEach((line) => {
                if (!line.project) {
                    line.project = { name: '' };
                }

                if (!line.department) {
                    line.department = { name: '' };
                }
            });
        }

        this.pathId = this.$route.params.id;
    }

    public get invoiceLines() {
        return this.lines;
    }

    public cancel() {
        this.editMode = false;
    }

    public edit() {
        this.editMode = true;
    }
}
