import Vue from 'vue';
import { UserManager, WebStorageStateStore, User, UserManagerSettings } from 'oidc-client';
import { profileModule } from '@/store/modules/profile';

export class LoginService {
    private userManager: UserManager;

    constructor() {
        const STS_DOMAIN: string = `${Vue.$env().identityEndpoint}`;

        const settings: UserManagerSettings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: STS_DOMAIN,
            client_id: 'bright-crm-portal',
            redirect_uri: `${Vue.$env().portalUrl}callback.html`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${Vue.$env().portalUrl}silent-renew.html`,
            response_type: 'code',
            scope: 'openid roles profile bright-crm-api.all directory.all finance.all Invoicing.Api bright-purchase-api.manage common-notifications.api',
            post_logout_redirect_uri: `${Vue.$env().portalUrl}login`,
            filterProtocolClaims: true,
            loadUserInfo: false,
        };

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserLoaded(async (user) => {
            profileModule.setUser(user);
        });

        this.userManager.events.addSilentRenewError((error) => {
            console.log(error);
            console.error('KAPOT');
            // custom logic here
        });

        this.userManager.events.addUserSignedOut(() => {
            console.log('user signed out');
            localStorage.removeItem(`oidc.user:${Vue.$env().identityEndpoint}:bright-crm-portal`);
            // window.location.href = '/';
        });
    }

    public async isLoggedIn(): Promise<boolean> {
        const user = await this.getUser();
        return user != null && !user.expired;
    }

    public async getUser(): Promise<User> {
        return await this.userManager.getUser();
    }

    public async login() {
        // Removes stale state entries in storage for incomplete authorize requests
        await this.clearStaleState();

        await this.userManager.signinRedirect();
    }

    public async accessTokenExpired(): Promise<boolean> {
        const user = await this.getUser();
        const accessTokenExpired = user != null && user.expired;
        return accessTokenExpired;
    }

    public async logout() {
        await this.userManager.signoutRedirect({ post_logout_redirect_uri: `${Vue.$env().portalUrl}login` });
    }

    public async refreshToken() {
        return await this.userManager.signinSilent().catch(async (error) => {
            await this.logout();
            localStorage.removeItem(`oidc.user:${Vue.$env().identityEndpoint}:bright-crm-portal`);
            window.location.href = '/';
        });
    }

    public async clearStaleState() {
        await this.userManager.clearStaleState();
    }

    public isInRole(role: string): boolean {
        return profileModule.getRoles().indexOf(role) > -1;
    }
}
