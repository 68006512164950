import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import LegalEntity, { InvoiceTemplate } from '@/models/LegalEntity';
import to from 'await-to-js';
import MailContent from '@/models/Purchases/MailContent';

export class LegalEntityService extends BaseService {
    private endpoint = Vue.$env().invoicesApiEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper, true);
    }

    public getLegalEntities() {
        return this.get<LegalEntity[]>(`${this.endpoint}legal-entities`);
    }

    public getLegalEntity(entityId: string) {
        return this.get<LegalEntity>(`${this.endpoint}legal-entities/${entityId}`);
    }

    public createLegalEntity(entity: LegalEntity) {
        return this.post(`${this.endpoint}legal-entities`, entity);
    }

    public updateLegalEntity(entity: LegalEntity) {
        return this.put(`${this.endpoint}legal-entities/${entity.legalEntityId}`, entity);
    }

    public async getTemplates(entityId: string) {
        const [err, response] = await to(this.get<LegalEntity[]>(`${this.endpoint}legal-entities/${entityId}/templates`));

        if (err) {
            this.clearAndShowError(`LOAD_PRODUCTS_FAILED`, err);
            return [];
        }

        return response.data;
    }

    public async getTemplate(entityId: string, templateId: number) {
        const [err, response] = await to(this.get<InvoiceTemplate>(`${this.endpoint}legal-entities/${entityId}/templates/${templateId}`));

        if (err) {
            this.clearAndShowError(`LOADING_TEMPLATE_FAILED`, err);
            return;
        }

        return response.data;
    }

    public async updateTemplate(entityId: string, template: InvoiceTemplate) {
        const [err] = await to(
            this.put<InvoiceTemplate>(`${this.endpoint}legal-entities/${entityId}/templates/${template.invoiceTemplateId}`, template),
        );

        if (err) {
            this.clearAndShowError(`SAVING_TEMPLATE_FAILED`, err);
            return false;
        }

        return true;
    }

    public async createTemplate(entityId: string, template: InvoiceTemplate) {
        const [err] = await to(this.post<InvoiceTemplate>(`${this.endpoint}legal-entities/${entityId}/templates`, template));

        if (err) {
            this.clearAndShowError(`CREATING_TEMPLATE_FAILED`, err);
            return false;
        }

        return true;
    }

    public async getMailContent(entityId: string, type: string, invoiceTemplateId?: number): Promise<MailContent> {
        const mailContent = new MailContent();

        if (type === 'reminder') {
            mailContent.subject = `Herinnering: ${mailContent.subject}`;
        }

        return mailContent;

        const [err, response] = await to(this.get(`${this.endpoint}legal-entities/${entityId}/templates/${invoiceTemplateId}`));
        if (err) {
            return new MailContent();
        }

        return new MailContent(response.data);
    }
}
