import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { required } from 'vuelidate/lib/validators';
import InboxDocument, { DocumentStatus } from '@/models/Costs/InboxDocument';
import to from 'await-to-js';
import { inboxDocumentService, loginHelper, teamHelper } from '@/main';
import moment from 'moment';
import FileUploader from '@/components/attachments/file-uploader.vue';
import PendingApprovalsModal from '@/components/modals/pending-approvals-modal.vue';
import { purchaseSettingsModule } from '@/store/modules/purchaseSettings';
import { translateModule } from '@/store/modules/translate';
import DocumentLine from '@/models/Costs/DocumentLine';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import Project from '@/models/Project';
import Attachment from '@/models/Attachment';

@Component({
    validations: {
        document: {
            paymentMethod: { required },
        },
    },
} as any)
export default class CreateInboxDocumentComponent extends PageRender {
    public document: InboxDocument = new InboxDocument();
    public isNew: boolean = false;
    public attachments: Attachment[] = [];
    public isSplitted: boolean = false;
    public selectedAttachment = null;
    public selectedTeam: StructureTeam = new StructureTeam();
    public teamChanged: boolean = false;
    public attachmentAdded: boolean = false;
    public alsoSubmit: boolean = false;

    public $refs!: {
        fileUploader: FileUploader;
        approvalModal: PendingApprovalsModal;
    };

    public async created() {
        this.isNew = typeof this.$route.params.documentId === 'undefined';
        if (this.isNew) {
            await this.loadStructure();
        }

        if (!this.isNew) {
            this.document = await this.loadDocument(this.$route.params.documentId);
            await this.loadStructure(this.document.purchaseInvoiceDate);

            if (this.document.teamId) {
                this.selectedTeam = this.structure.teams.find((y) => y.teamId === this.document.teamId);
            }

            if (!this.document.documentLines.length) {
                this.document.documentLines.push(new DocumentLine());
            }
            const line = this.document.documentLines[0];

            if (line.department) {
                line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
                line.projects = await this.getProjects(line.departmentObj);
            } else {
                this.document.documentLines[0].departmentObj = this.selectedTeam;
                this.document.documentLines[0].department = this.selectedTeam.name;
                await this.selectDepartment(this.selectedTeam);
            }

            if (line.department && line.departmentObj && line.project) {
                let project = line.projects.find((y) => y.name && y.name.toLowerCase() === line.project.toLowerCase());
                line.projectObj = project;
            }

            if (!this.document.paymentMethod) {
                this.document.paymentMethod = this.getPaymentMethodOptions().find((x) => x.id === 1);
            }

            await this.loadAttachments();
        } else {
            this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());
            this.document.teamId = this.selectedTeam.teamId;

            if (!this.document.documentLines || this.document.documentLines.length === 0) {
                this.document.documentLines.push(new DocumentLine());
            }

            if (sessionStorage.getItem('department')) {
                const selectedTeam = JSON.parse(sessionStorage.getItem('department'));
                this.document.documentLines[0].departmentObj = selectedTeam;
                this.document.documentLines[0].department = selectedTeam.name;
            } else {
                this.document.documentLines[0].departmentObj = this.selectedTeam;
                this.document.documentLines[0].department = this.selectedTeam.name;
            }

            if (!sessionStorage.getItem('project')) {
                await this.selectDepartment(this.selectedTeam);
            } else {
                const project = JSON.parse(sessionStorage.getItem('project'));
                this.document.documentLines[0].projectObj = this.document.documentLines[0].project = project;
                this.document.documentLines[0].projects = await this.getProjects(this.document.documentLines[0].departmentObj);
            }

            this.document.paymentMethod = this.getPaymentMethodOptions().find((x) => x.id === 1);

            let teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await purchaseSettingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }

            if (teamSettings && teamSettings.defaultCurrency) {
                this.document.currency = teamSettings.defaultCurrency;
            }
        }

        this.isLoading = false;
    }

    public async loadDocument(documentId) {
        const [err, document] = await to(inboxDocumentService.getInboxDocument(documentId));
        if (err) {
            return;
        }
        return document;
    }

    public async createInboxDocument(documentStatus: DocumentStatus = DocumentStatus.Draft) {
        this.submitted = true;
        if ((this as any).$v.$invalid && documentStatus === DocumentStatus.Submitted) {
            this.showWarning(`UPDATE_DOCUMENT_INVALID`);
            return;
        }

        if (!this.attachmentAdded) {
            this.showWarning('ATTACHMENT_REQUIRED');
            return;
        }

        this.document.teamId = this.selectedTeam.teamId;
        if (!this.document.name) {
            this.document.name = `Purchase - ${moment().format('DD-MM-YYYY HH:mm:ss')}`;
        }

        this.saveDepartmentAndProjectInSession(this.document.documentLines[0].departmentObj, this.document.documentLines[0].projectObj);

        const [err, response] = await to(inboxDocumentService.createDocument(this.document));
        if (err) {
            return this.clearAndShowError('CREATE_DOCUMENT_FAILED', err);
        } else {
            this.clearAndShowSuccess('CREATE_DOCUMENT_SUCCESS');
        }

        this.document.id = response.data.id;
        this.$refs.fileUploader.setId(response.data);
        this.alsoSubmit = !documentStatus || documentStatus === DocumentStatus.Submitted;

        await this.$refs.fileUploader.processQueue();
        this.showPending('UPLOADING_ATTACHMENTS');
    }

    public saveDepartmentAndProjectInSession(department, project) {
        sessionStorage.setItem('department', JSON.stringify(department));
        sessionStorage.setItem('project', JSON.stringify(project));
    }

    public async calledDone() {
        if (this.alsoSubmit) {
            await this.submitInboxDocument();
        } else {
            await this.$router.push({ name: 'create-declaration', params: { documentId: this.document.id.toString() } });
            this.clearNotifications();
        }
    }

    public async submitInboxDocument() {
        const [errSubmit] = await to(inboxDocumentService.submitInboxDocument(this.document.id));
        if (errSubmit) {
            return this.clearAndShowError('SUBMIT_DOCUMENT_FAILED', errSubmit);
        }
        await this.$router.push({ name: 'declarations' });
        this.clearAndShowSuccess('DECLARATION_SUBMITTED');
    }

    public async loadAttachments() {
        this.attachments = await inboxDocumentService.getAttachments(this.document.id);
    }

    public getPendingApprovals() {
        return this.document.approvals.filter((x) => x.approvalState === 'pending').length;
    }

    public getDeclinedApprovals() {
        return this.document.approvals.filter((x) => x.approvalState === 'declined').length;
    }

    public getApprovedApprovals() {
        return this.document.approvals.filter((x) => x.approvalState === 'approved').length;
    }

    public previewAttachment(attachment) {
        this.showPending('LOAD_BLOB');
        this.selectedAttachment = attachment;
        this.isSplitted = true;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            this.showPending('REMOVE_ATTACHMENT_PENDING');

            const [err] = await to(inboxDocumentService.deleteAttachment(this.document.id, attachment.fileId));
            if (err) {
                this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async updateInboxDocument() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_DOCUMENT_INVALID`);
            return;
        }

        if (!this.attachmentAdded && !this.attachments) {
            this.showWarning('ATTACHMENT_REQUIRED');
            return;
        }
        this.showPending('UPDATE_PURCHASE_PENDING');

        this.document.documentLines.forEach((line) => {
            delete line.product;
            delete line.productId;
        });

        const [err] = await to(inboxDocumentService.updateDocument(this.document));
        if (err) {
            return this.clearAndShowError('UPDATE_PURCHASE_FAILED', err);
        }

        this.$refs.fileUploader.setId(this.document.id);
        await this.$refs.fileUploader.processQueue();

        this.clearAndShowSuccess('UPDATE_PURCHASE_SUCCESS');
    }

    public async selectTeam() {
        this.teamChanged = true;

        this.document.documentLines[0].departmentObj = this.selectedTeam;
        this.document.documentLines[0].department = this.selectedTeam.name;
        await this.selectDepartment(this.selectedTeam);
    }

    public async selectDepartment(department) {
        this.document.documentLines[0].projectObj = this.document.documentLines[0].project = null;
        this.document.documentLines[0].projects = await this.getProjects(department);

        this.autoSelectCurrentUser();
    }

    public getPaymentMethodOptions() {
        return [
            { id: 1, name: 'Private paid in advance' },
            { id: 2, name: 'Still to be paid via bank' },
            { id: 3, name: 'Credit card' },
            { id: 4, name: 'Direct debit' },
        ];
    }

    public async deleteInboxDocument() {
        const result = await this.$bvModal.msgBoxConfirm(`Do you really want to delete this document?`, {
            title: 'Delete document',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Ok',
            cancelTitle: 'Cancel',
            hideHeaderClose: false,
            centered: true,
        });

        if (result) {
            this.showPending('DELETE_INBOX_DOCUMENT_PENDING');
            const success = await inboxDocumentService.deleteDocument(this.document.id);
            if (success) {
                this.clearAndShowSuccess('DELETE_INBOX_DOCUMENT_SUCCESS');
                await this.$router.push({ name: 'declarations' });
            } else {
                this.clearAndShowError('DELETE_INBOX_DOCUMENT_FAILED', null);
            }
        }
    }

    public openPendingApprovalsModal() {
        this.$refs.approvalModal.show();
    }

    private autoSelectCurrentUser() {
        const currentUserEmail = loginHelper.getUser().emailAddress;
        const project = this.document.documentLines[0].projects.find((p: Project) => p.name === currentUserEmail);
        if (project) {
            this.document.documentLines[0].projectObj = project;
            this.document.documentLines[0].project = project.name;
        }
    }
}
