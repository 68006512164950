import { appHelper } from '@/main';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class ProductActionComponent extends SideActionBase<any> {
    public get componentName() {
        return `${appHelper.isCrm() ? 'crm' : 'purchases'}-create-or-edit-product`;
    }

    public createProduct(e): void {
        this.finished(e);
    }
}
