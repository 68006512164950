import Component from 'vue-class-component';
import { Route } from 'vue-router';
import PageRender from '@/models/PageRender';
import { translateModule } from '@/store/modules/translate';
import { Membership } from '@/models/Interfaces';
import { loginHelper, ecosystemHelper, $router, teamHelper } from '@/main';
import EcosystemSwitcherModalComponent from '../modals/ecosystem-switcher-modal';
import { profileModule } from '@/store/modules/profile';
import { Person } from '@/models/Person';

@Component
export default class TopBarTeamSwitch extends PageRender {
    public key: number = 0;
    public profile: Person = profileModule.brightProfile;

    public $refs!: {
        ecosystemSwitcherModal: EcosystemSwitcherModalComponent;
    };

    public mounted() {
        this.profile = profileModule.brightProfile;
    }

    public updateTeamSwith() {
        this.key++;
    }

    public get currentUser(): string {
        if (this.profile) {
            let parts = [this.profile.firstName, this.profile.insertion, this.profile.lastName];
            parts = parts.filter((value) => !!value);
            return parts.join(' ');
        }
        return '';
    }

    public get currentTeam() {
        return teamHelper.currentTeam.name;
    }

    public async switchTo(team: Membership): Promise<Route> {
        teamHelper.setTeamByTeamId(team.id);
        const route = this.$route;
        const params = Object.assign({}, route.params);
        params.teamId = ecosystemHelper.currentEcosystem.id.toString();
        params.teamKey = ecosystemHelper.currentEcosystem.name;
        if (typeof route.params.groupKey !== 'undefined') {
            const sub =
                this.$route.fullPath.indexOf('finance') > -1
                    ? 'finances'
                    : this.$route.fullPath.indexOf('analytics') > -1
                    ? 'analytics'
                    : 'directory';
            const tab =
                this.$route.fullPath.indexOf('finance') > -1
                    ? 'group-overview'
                    : this.$route.fullPath.indexOf('analytics') > -1
                    ? 'group-results'
                    : 'groups';
            params.tab = tab;
            return this.$router.push({
                name: `team-${sub}`,
                params,
            });
        }
        return this.$router.push({
            name: route.name,
            params,
        });
    }

    public toggleEditMode() {
        translateModule.toggleEditMode();
    }

    public get editModeActive() {
        return translateModule.inEditMode;
    }

    public get languages() {
        return ['en', 'nl']; //  translateModule.languages;
    }

    public goToProfile(): Promise<Route> {
        return this.$router.push({
            name: 'me-profile',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.name,
                id: loginHelper.getUser().personId.toString(),
            },
        });
    }
    public get activeLanguage() {
        return translateModule.activeLanguage;
    }

    public async setLanguage(lang: string) {
        await translateModule.setLanguage(lang);
    }

    public async logOut() {
        await loginHelper.logOut();
        await $router.push({ name: 'login' });
    }

    public openEcosystemSwitcherModal() {
        return this.$refs.ecosystemSwitcherModal.show();
    }
}
