export default class Ledger {
    public ledgerId: number = null;
    public name: string = '';
    public value: string = '';
    public description: string = '';

    constructor(obj?: Partial<Ledger>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
