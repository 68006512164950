import { $router } from '@/main';

export class AppHelper {
    public isCrm(): boolean {
        return $router.currentRoute.meta.app === 'crm' || $router.currentRoute.path === '/';
    }

    public isInvoice(): boolean {
        return $router.currentRoute.meta.app === 'invoices';
    }

    public isCosts(): boolean {
        return $router.currentRoute.meta.app === 'costs';
    }

    public showLegalEntityPicker() {
        if (!this.isInvoice()) {
            return false;
        }
        return !$router.currentRoute.meta.hidePicker;
    }

    public isPurchase(): boolean {
        return $router.currentRoute.meta.app === 'purchases';
    }
}
