export default class Contact {
    public contactId: number = null;
    public id: number = null;
    public firstName: string = '';
    public lastName: string = '';
    public emailAddresses: any[] = [];
    public phoneNumbers: any[] = [];
    public contactType: string = '';

    constructor(obj?: Partial<Contact>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
