import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import {
    purchasesService,
    organizationsServicePurchases,
    productsServicePurchases,
    teamHelper,
    footprintTypeServicePurchases,
    brightService,
    roleHelper,
} from '@/main';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import Product from '@/models/CRM/Product';
import Vue from 'vue';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import TaxRate from '@/models/CRM/TaxRate';
import { FootprintType } from '@/models/Purchases/FootprintProduct';
import moment from 'moment';
import { Membership } from '@/models/Interfaces';
import { orderBy } from '@progress/kendo-data-query';
import { BModal } from 'bootstrap-vue';
import { Purchase } from '@/models/Purchases/Purchase';
import { purchaseSettingsModule } from '@/store/modules/purchaseSettings';
import { MetaData, PurchaseLine } from '@/models/Purchases/PurchaseLine';
import { translateModule } from '@/store/modules/translate';
import Organization from '@/models/Purchases/Organization';
import { PurchaseStateEnum } from '@/models/Purchases/PurchaseState';
import PurchaseDatePicker from './components/date-picker.vue';
import Structure from '@/models/Ecosystem/Structure';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import { ProductActivity } from '@/models/Purchases/Product';

@Component({
    validations: {
        purchase: {
            name: { required },
            organization: { required },
            purchaseDate: { required },
            currency: { required },
            lines: {
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                        productId: { minValue: minValue(1) },
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
    },
    components: {
        PurchaseDatePicker: PurchaseDatePicker,
    },
} as any)
export default class CreatePurchaseComponent extends PageRender {
    @Prop({ default: () => new Purchase() }) public purchase: Purchase;
    public organizations: Organization[] = [];
    public products: Product[] = [];
    public orgProducts: Product[] = [];
    public isEdit: boolean = false;
    public allowedToEdit: boolean = true;
    public organizationSelected: number = 0;
    public uploaded: number = 999;
    public tableRerender: number = 0;
    public invalidCombinationOrgRef: boolean = false;
    public fetchedProducts: Product[] = [];

    public attachments: any[] = [];
    public projects: any[] = [];
    public approvals: any[] = [];
    public availableCurrencies = [
        { text: 'Euro', value: 'EUR' },
        { text: 'US Dollar', value: 'USD' },
        { text: 'Pound sterling', value: 'GBP' },
        { text: 'Saudi riyal', value: 'SAR' },
        { text: 'Swiss franc', value: 'CHF', sign: 'CHF' },
    ];
    public selectedCurrency = { text: 'Euro', value: 'EUR' };
    public selectedDate: string = moment().format('DD-MM-YYYY');
    public selectedDateChanged: boolean = false;
    public showInc: boolean = false;

    public footprintTypes: FootprintType[] = [];
    public isSplitted: boolean = false;
    public selectedAttachment = null;

    public selectedTeam: StructureTeam = null;
    public teamChanged: boolean = false;

    public currentLineIndex: number = 0;
    public teamSelectTabIndex: number = 101;
    public currencySelectTabIndex: number = 102;
    public orgSelectTabIndex: number = 103;
    public sort: any[] = [{ field: 'lineNumber', dir: 'asc' }];

    public openPicker: boolean = false;
    public expectedTotal: string = '0';
    public reRenderButtons: number = 99;

    public $refs!: {
        approvalModal: BModal;
    };

    public get teamOptions() {
        const myTeams = teamHelper.teams;
        return this.structure.teams.filter((x) => myTeams.find((y) => y.id === x.teamId));
    }

    public async created() {
        this.isEdit = this.purchase && this.purchase.id > 0;
        this.allowedToEdit = !(this.purchase.purchaseState && this.purchase.purchaseState.purchaseStateId === 2);
        this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());

        await Promise.all([
            this.loadStructure(this.purchase.purchaseDate),
            this.loadTaxRates(),
            this.loadOrganizations(),
            this.loadProducts(),
            this.loadFootprintTypes(),
        ]);

        if (this.isEdit) {
            await this.loadAttachments();
            if (!this.purchase.teamId) {
                this.selectedTeam = this.structure.teams.find((y) => y.teamId === teamHelper.getTeamId());
            } else {
                this.selectedTeam = this.structure.teams.find((y) => y.teamId === this.purchase.teamId);
            }

            if (this.purchase.organization) {
                const [err, response] = await to(
                    organizationsServicePurchases.getOrganizationProducts((this.purchase.organization as Organization).organizationId),
                );
                if (err) {
                    console.log(err);
                }

                this.orgProducts = response.data.map((x) => new Product(x));
            }

            for (let i = 0; i < this.purchase.lines.length; i++) {
                const line = this.purchase.lines[i];

                if (line.department) {
                    line.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === line.department.toLowerCase());
                    line.projects = await this.getProjects(line.departmentObj);
                }

                if (line.department && line.departmentObj && line.project) {
                    let project = line.projects.find((y) => y.name.toLowerCase() === line.project.toLowerCase());
                    line.projectObj = project;
                }

                if (line.taxRateId) {
                    line.taxRate = this.taxRates.find((y) => y.id === line.taxRateId);
                }

                if (line.price) {
                    await this.getAndSetInclPrice(line);
                }

                for (let j = 0; j < this.footprintTypes.length; j++) {
                    const element = this.footprintTypes[j];
                    const metaData = line.metaData.find((y) => y.footPrintTypeId === element.footprintTypeId);
                    if (!metaData) {
                        line.metaData.push({ amount: 0, footPrintTypeId: element.footprintTypeId, label: element.label, name: element.name });
                    }
                }

                if (line.product) {
                    let product = this.fetchedProducts.find((x) => x.productId === line.product.productId);

                    if (!product) {
                        product = await productsServicePurchases.getProduct(line.product.productId);
                        this.fetchedProducts.push(product);
                    }

                    line.product = product;
                }

                if (line.price) {
                    await this.exclPriceInput(line.price, line);
                }

                if (line.attachmentId) {
                    const attachment = this.attachments.find((y) => y.fileId === line.attachmentId);
                    if (!attachment) {
                        line.attachmentId = null;
                    }
                }

                if (this.orgProducts.length) {
                    const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                    line.products = [...products, { $isDisabled: true, name: 'Limited by organization allow products', productId: -1 } as any];
                } else {
                    line.products = [...this.products];
                }
            }

            for (let i = 0; i < this.orderedLines.length; i++) {
                const line = this.orderedLines[i];
                if (!line.lineNumber) {
                    line.lineNumber = i + 1;
                }
            }

            this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.purchase.currency.toUpperCase());
            if (this.purchase.purchaseDate) {
                this.selectedDate = moment(this.purchase.purchaseDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
            }

            const approvalStates = [PurchaseStateEnum.ApprovalRequested, PurchaseStateEnum.Booked, PurchaseStateEnum.Declined];
            if (approvalStates.includes(this.purchase.purchaseState.purchaseStateId)) {
                this.approvals = await purchasesService.getApprovals(this.purchase.id);
            }
        } else {
            let teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await purchaseSettingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = purchaseSettingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }
            this.selectedCurrency = this.availableCurrencies.find((x) => x.value === teamSettings.defaultCurrency);
            this.selectCurrency();
        }

        this.isLoading = false;
    }

    public get hasOpenLines() {
        return this.purchase.lines.length > 0 && this.purchase.lines.filter((x) => x.open).length > 0;
    }

    public getPendingApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'pending').length;
    }

    public getDeclinedApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'declined').length;
    }

    public async sendReminders() {
        this.showPending('SENDING_REMINDER_PENDING');
        const success = await purchasesService.remindApprovals(this.purchase.id);
        if (success) {
            this.clearAndShowSuccess('SENDING_REMINDER_SUCCESS');
        }
    }

    public async loadOrganizations() {
        const organizations = await organizationsServicePurchases.getOrganizations();
        this.organizations = organizations.items;
    }

    public async loadProducts() {
        const products = await productsServicePurchases.getProducts();
        this.products = [{ name: translateModule.value('CREATE_PRODUCT'), productId: 0 } as any, ...products.items.map((x) => new Product(x))];
    }

    public async loadFootprintTypes() {
        const types = await footprintTypeServicePurchases.getFootprintTypes();
        this.footprintTypes = types.items;
    }

    public createOrganizationSideAction() {
        this.$sideActions.push('create-organization-side-action', { data: true, existingOrganizations: this.organizations }, (result) => {
            this.setOrganization(result);
        });
    }

    public openAddPurchaseLineSideAction(index) {
        this.currentLineIndex = index;

        this.$sideActions.push('edit-purchase-line-side-action', { line: this.orderedLines[index] }, (result) => {
            this.orderedLines[index] = result;
            this.tableRerender++;
        });
    }

    public setOrganization(result) {
        Vue.set(this.purchase, 'organization', result);
        this.organizationSelected++;
    }

    public async selectOrganization(org) {
        const [err, response] = await to(organizationsServicePurchases.getOrganizationProducts(org.organizationId));
        if (err) {
            console.log(err);
        }

        this.orgProducts = response.data.map((x) => new Product(x));
        if (this.orgProducts.length) {
            const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));

            this.purchase.lines.forEach(
                (x) =>
                    (x.products = [
                        ...products,
                        { $isDisabled: true, name: '-- This list is filtered based on the selected organization --', productId: -1 } as any,
                    ]),
            );
            // this.products = [
            //     ...products,
            //     // { isDisabled: true, name: '--- Not connected products ---', productId: -1 } as any,
            //     // ...nonOrgProducts,
            // ];
        }
    }

    public async loadAttachments() {
        const attachments = await purchasesService.getAttachments(this.purchase.id);
        this.attachments = attachments;
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async detachAttachment(attachment) {
        const answer = await this.$bvModal.msgBoxConfirm(translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TEXT'), {
            title: translateModule.value('REMOVE_ATTACHMENT_CONFIRM_TITLE'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: translateModule.value('YES'),
            cancelTitle: translateModule.value('NO'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            let updatePurchase = false;
            this.showPending('REMOVE_ATTACHMENT_PENDING');

            const line = this.purchase.lines.find((x) => x.attachmentId === attachment.fileId);
            if (line) {
                line.attachmentId = null;
                updatePurchase = true;
            }

            const [err] = await to(purchasesService.deleteAttachment(this.purchase.id, attachment.fileId));
            if (err) {
                this.clearAndShowError('REMOVE_ATTACHMENT_FAILED', err);
            }

            this.attachments = this.attachments.filter((x) => x.fileId !== attachment.fileId);
            if (updatePurchase) {
                await this.updatePurchase();
            }
            this.clearAndShowSuccess('REMOVE_ATTACHMENT_SUCCESS');
        }
    }

    public previewAttachment(attachment) {
        this.showPending('LOAD_BLOB');
        this.selectedAttachment = attachment;
        this.isSplitted = true;
        if (window.innerWidth < 2000) {
            this.$sideActions.push('preview-attachment-action', { attachment });
        }
    }

    public openCreateProductSideAction(index) {
        return this.$sideActions.push('create-product-side-action', {}, async (result) => {
            await this.selectProduct(result, index);
        });
    }

    public async selectProduct(product: Product, purchaseLineIndex) {
        if (product.productId <= 0) {
            return;
        }
        const productObject = await productsServicePurchases.getProduct(product.productId);
        if (
            productObject &&
            productObject.price &&
            (!this.orderedLines[purchaseLineIndex].price || this.orderedLines[purchaseLineIndex].price !== 0)
        ) {
            this.orderedLines[purchaseLineIndex].price = productObject.price;
        }
        this.orderedLines[purchaseLineIndex].product = productObject;
        this.orderedLines[purchaseLineIndex].taxRateId = productObject.taxRateId;

        this.orderedLines[purchaseLineIndex].metaData.forEach((x) => {
            x.amount = 0;
        });

        const [err, response] = await to(productsServicePurchases.getProductActivities(product.productId));
        this.orderedLines[purchaseLineIndex].product.connectedActivities = response.data;

        await this.getAndSetInclPrice(this.orderedLines[purchaseLineIndex]);
    }

    public async selectActivity(activity: ProductActivity, purchaseLineIndex: number) {
        this.orderedLines[purchaseLineIndex].department = activity.teamName;
        this.orderedLines[purchaseLineIndex].departmentObj = this.structure.teams.find((x) => x.name === activity.teamName);
        this.orderedLines[purchaseLineIndex].projects = await this.getProjects(this.orderedLines[purchaseLineIndex].departmentObj);

        this.orderedLines[purchaseLineIndex].project = activity.activityName;
        this.orderedLines[purchaseLineIndex].projectObj = this.orderedLines[purchaseLineIndex].projects.find(
            (x) => x.name.toLowerCase() === activity.activityName.toLowerCase(),
        );
    }

    public async selectDepartment(department, purchaseLineIndex) {
        this.orderedLines[purchaseLineIndex].department = department.name;
        this.orderedLines[purchaseLineIndex].projectObj = this.orderedLines[purchaseLineIndex].project = null;
        this.orderedLines[purchaseLineIndex].projects = await this.getProjects(department);
        this.orderedLines[purchaseLineIndex].connectedActivity = 'manual';
    }

    public getProductTaxRate(line: PurchaseLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return '';
            }
            return taxRate.description;
        }

        return '';
    }

    public getProductTaxValue(line: PurchaseLine) {
        if (line.product && line.product.productId > 0) {
            const taxRate = this.taxRates.find((x) => x.id === line.product.taxRateId);
            if (!taxRate) {
                return 0;
            }
            return line.price - line.price / ((100 + taxRate.percentage) / 100);
        }

        return 0;
    }

    public async selectProject(activity, purchaseLineIndex) {
        this.orderedLines[purchaseLineIndex].project = activity.name;
        const teamName = this.orderedLines[purchaseLineIndex].department;
        const connectedActivityProducts = await productsServicePurchases.getProductActivities(null, teamName, activity.name);

        const products =
            this.orgProducts && this.orgProducts.length
                ? this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId))
                : this.products;

        const connectedProducts = products.filter((x) => connectedActivityProducts.data.find((y) => y.productId === x.productId));
        const notConnectedProducts = products.filter((x) => !connectedProducts.find((y) => y.productId === x.productId));

        this.orderedLines[purchaseLineIndex].products = [
            ...connectedProducts,
            { $isDisabled: true, name: '--- Not connected products ---', productId: -1 } as any,
            ...notConnectedProducts,
        ];

        if (connectedProducts.length === 1 && this.orderedLines[purchaseLineIndex].product === null) {
            this.selectProduct(connectedProducts[0], purchaseLineIndex);
            this.orderedLines[purchaseLineIndex].connectedActivity = connectedActivityProducts.data.find(
                (x) => x.productId === connectedProducts[0].productId,
            );
        }

        this.tableRerender++;
        if (roleHelper.isBEFinanceEmployee()) {
            // tslint:disable-next-line: no-floating-promises
            await this.checkBudget(activity, purchaseLineIndex);
        }
    }

    public selectCurrency() {
        this.purchase.currency = this.selectedCurrency.value;
    }

    public resetPurchase() {
        this.purchase = this.purchase = new Purchase();
    }

    public getTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal + this.getSubtotal();
    }

    public getTaxTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal;
    }

    public hasAnyProducts(tax) {
        return (
            this.purchase.lines.filter(
                (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
            ).length > 0
        );
    }

    public getTaxForType(tax: TaxRate) {
        const lines = this.purchase.lines.filter(
            (x) => (x.product && x.product.taxRate && x.product.taxRate.id === tax.id) || (x.product && x.product.taxRateId === tax.id),
        );

        if (lines.length > 0) {
            const totalIncl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.inclPrice;
            }, 0);

            const totalExcl = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.price;
            }, 0);

            return totalIncl - totalExcl;
        }

        return 0;
    }

    public getSubtotal() {
        let subTotal = 0;
        this.purchase.lines.forEach((line) => {
            subTotal += parseFloat(line.amount.toString()) * line.price;
        });

        return subTotal;
    }

    public get numericExpectedTotal() {
        return parseFloat(this.expectedTotal);
    }

    public openCO2CalculatorAction(lineIndex: number) {
        this.$sideActions.push(
            'co2-calculator-side-action',
            { purchaseId: this.purchase.id, lineIndex, isCrm: false, team: teamHelper.currentTeam.id },
            async (result) => {
                await this.addAttachmentToLine(result);
            },
        );
    }

    public uploadAttachmentToLineSideAction(lineIndex: number) {
        this.$sideActions.push(
            'upload-attachment-side-action',
            { purchaseId: this.purchase.id, lineIndex, isCrm: false, team: teamHelper.currentTeam.id },
            async (result) => {
                await this.addAttachmentToLine(result);
            },
        );
    }

    public async addAttachmentToLine(result) {
        this.orderedLines[result.lineIndex].attachmentId = result.fileId;

        await this.updatePurchase();
    }

    public downloadAttachmentForLine(attachmentId) {
        const attachment = this.attachments.find((x) => x.fileId === attachmentId);
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public getLineNumberText(attachment) {
        if (attachment) {
            const index = this.orderedLines.findIndex((x) => x.attachmentId === attachment.fileId);
            const indexNumber = index + 1;
            if (index > -1) {
                return `(line: ${indexNumber})`;
            }
        }

        return '';
    }

    public lineUp(index: number) {
        const lineUp = this.orderedLines[index];
        const lineDown = this.orderedLines[index - 1];

        lineUp.lineNumber--;
        lineDown.lineNumber++;
    }

    public lineDown(index: number) {
        const lineDown = this.orderedLines[index];
        const lineUp = this.orderedLines[index + 1];

        lineUp.lineNumber--;
        lineDown.lineNumber++;
    }

    public findOrderLineIndex(lineNumber: number) {
        return this.purchase.lines.findIndex((x) => x.lineNumber === lineNumber);
    }

    public get orderedLines() {
        return orderBy(this.purchase.lines, this.sort);
    }

    public productHasFootprintProduct(line: PurchaseLine) {
        if (line.productId) {
            const product = this.products.find((x) => x.productId === line.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        if (line.product) {
            const product = this.products.find((x) => x.productId === line.product.productId);
            return product && product.footprintProducts && product.footprintProducts.length;
        }

        return false;
    }

    public async setPurchaseAsBooked() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('PURCHASE_BOOKED_INVALID');
            return;
        }

        this.showPending('PURCHASE_BOOKED_PENDING');

        await this.updatePurchase();

        const [err] = await to(purchasesService.changePurchaseState(this.purchase.id, 2));
        if (err) {
            return this.clearAndShowError('PURCHASE_BOOKED_FAILED', err);
        }

        this.purchase.purchaseState.name = 'Booked';
        this.allowedToEdit = false;
        this.clearAndShowSuccess('PURCHASE_BOOKED_SUCCESS');
    }

    public async createPurchase() {
        this.submitted = true;

        if (this.selectedDate) {
            this.purchase.purchaseDate = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_PURCHASE_INVALID`);

            for (let i = 0; i < this.purchase.lines.length; i++) {
                const line = this.purchase.lines[i];
                const line2 = (this as any).$v.purchase.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }

            return;
        }

        if (this.teamChanged) {
            this.purchase.teamId = this.selectedTeam.teamId;
        }

        this.showPending('CREATE_PURCHASE_PENDING');
        const [err, response] = await to(purchasesService.createPurchase(this.purchase));
        if (err) {
            return this.clearAndShowError('CREATE_PURCHASE_FAILED', err);
        }
        const purchase = new Purchase(response.data);
        this.clearNotifications();

        const params = { purchaseId: purchase.id.toString(), teamKey: this.selectedTeam.teamKey, teamId: this.selectedTeam.teamId.toString() };

        if (this.teamChanged) {
            teamHelper.setTeamByTeamId(this.selectedTeam.teamId);
        }

        await this.$router.push({ name: 'purchase', params });
    }

    public async updatePurchase() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_PURCHASE_INVALID`);
            for (let i = 0; i < this.purchase.lines.length; i++) {
                const line = this.purchase.lines[i];
                const line2 = (this as any).$v.purchase.lines.$each[this.findOrderLineIndex(line.lineNumber)];

                line.open = line2.$invalid;
            }

            return;
        }

        this.showPending('UPDATE_PURCHASE_PENDING');

        if (this.selectedDate) {
            this.purchase.purchaseDate = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if (this.teamChanged) {
            this.purchase.teamId = this.selectedTeam.teamId;
        }

        const [err] = await to(purchasesService.updatePurchase(this.purchase));
        if (err) {
            return this.clearAndShowError('UPDATE_PURCHASE_FAILED', err);
        }

        await this.checkTeamChange();
        // const purchase = new Purchase(response.data);
        this.clearAndShowSuccess('UPDATE_PURCHASE_SUCCESS');
        // await this.$router.push({ name: 'purchase', params: { purchaseId: purchase.id.toString() } });
    }

    public async askApproval() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`ASK_APPROVAL_PURCHASE_INVALID`);
            return;
        }

        this.showPending('ASK_APPROVAL_PURCHASE_PENDING');

        await this.updatePurchase();

        const [err] = await to(purchasesService.changePurchaseState(this.purchase.id, 4));
        if (err) {
            return this.clearAndShowError('ASK_APPROVAL_PURCHASE_FAILED', err);
        }

        this.purchase.purchaseState.name = 'Approval Requested';
        this.approvals = await purchasesService.getApprovals(this.purchase.id);
        this.reRenderButtons++;
        this.clearAndShowSuccess('ASK_APPROVAL_PURCHASE_SUCCESS');
    }

    public async checkTeamChange() {
        if (this.teamChanged) {
            const [err] = await to(purchasesService.movePurchase(this.purchase.id, this.selectedTeam.teamId));
            if (!err) {
                this.clearAndShowSuccess('UPDATE_DEAL_SUCCESS');

                teamHelper.setTeamByTeamId(this.selectedTeam.teamId);

                if (this.attachments.length > 0) {
                    this.showPending('MOVING_ATTACHMENTS');

                    for (let i = 0; i < this.attachments.length; i++) {
                        await purchasesService.reUploadAttachment(this.selectedTeam.teamId, this.purchase.id, this.attachments[i]);
                    }
                }

                const route = this.$route;
                if (typeof route.params.teamId === 'undefined' && typeof route.params.teamKey === 'undefined') {
                    // no routing needed, team isn't part of route.
                    return;
                }

                const params = Object.assign({}, route.params);
                params.teamKey = this.selectedTeam.teamKey;
                params.teamId = this.selectedTeam.teamId.toString();

                return this.$router.push({
                    name: route.name,
                    params,
                });
            } else {
                return this.clearAndShowError('MOVE_DEAL_FAILED', err);
            }
        }
    }

    public get goToLinkedInvoice() {
        return {
            name: 'invoice-purchases',
            params: { legalEntity: this.purchase.externalInvoice.legalEntityId, invoiceId: this.purchase.externalInvoice.invoiceId },
        };
    }

    public async copyPurchase() {
        this.showPending('CLONE_PURCHASE_PENDING');
        const [err, response] = await to(purchasesService.clonePurchase(this.purchase));
        if (err) {
            return this.clearAndShowError('CLONE_PURCHASE_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_PURCHASE_SUCCESS');
        const purchase = new Purchase(response.data);
        await this.$router.push({ name: 'purchase', params: { purchaseId: purchase.id.toString() } });
    }

    public async connectActivity(dealLineIndex: number) {
        const productId = this.orderedLines[dealLineIndex].product.productId;

        const activity = new ProductActivity({
            activityName: this.orderedLines[dealLineIndex].project,
            activityObj: this.orderedLines[dealLineIndex].projectObj,
            teamObj: this.orderedLines[dealLineIndex].departmentObj,
            teamName: this.orderedLines[dealLineIndex].department,
        });
        const [err] = await to(productsServicePurchases.saveConnectedActivity(productId, activity));
        if (err) {
            return this.clearAndShowError('CONNECT_CONNECTED_ACTIVITY_FAILED', err);
        }

        this.orderedLines[dealLineIndex].product.connectedActivities.push(activity);
        this.orderedLines[dealLineIndex].connectedActivity = activity;
    }

    public clearActivity(dealLineIndex: number) {
        this.orderedLines[dealLineIndex].project = null;
        this.orderedLines[dealLineIndex].projectObj = null;
        this.orderedLines[dealLineIndex].department = null;
        this.orderedLines[dealLineIndex].departmentObj = null;
    }

    public getActivities(line) {
        if (line.departmentObj) {
            return this.structure.teams.find((x) => x.teamId === line.departmentObj.teamId).groups;
        }

        return [];
    }

    public async checkReferenceOrgCombination() {
        if (!this.purchase.organization || !this.purchase.reference) {
            return;
        }

        this.clearNotifications();
        const purchases = await purchasesService.getPurchases(
            true,
            (this.purchase.organization as Organization).organizationId,
            this.purchase.reference,
        );

        // remove yourself
        purchases.items = purchases.items.filter((x) => x.id !== this.purchase.id);
        if (purchases && purchases.items && purchases.items.length > 0) {
            this.invalidCombinationOrgRef = true;
            return this.showWarning('Combination of reference and organization al ready present. Are you sure you want to book this purchase?');
        }

        this.invalidCombinationOrgRef = false;
    }

    public async checkBudget(project, index) {
        const line = this.orderedLines[index];
        const lineValue = line.price * +line.amount;
        let budget;

        const isGroup = line.departmentObj.groups.find((x) => x.groupId === project.id && x.name.toLowerCase() === project.name.toLowerCase());
        if (!isGroup) {
            budget = await brightService.getPersonBudget(project.id, line.departmentObj.id);
        } else {
            budget = await brightService.getActivityBudget(project.id);
        }

        line.budget = {
            available: budget.budget > 0,
            visible: true,
            text: `Budget of ${project.name} in ${line.department} is <b>${Vue.filter('numberFormat')(budget.budget)}</b> and will be <b>${Vue.filter(
                'numberFormat',
            )(budget.budget - lineValue)}</b>`,
        };
    }

    public updatedValue(value, line: PurchaseLine) {
        line.metaData.forEach((metaDateFootprint: MetaData) => {
            if (line.product && line.product.footprintProducts.length) {
                const footprintProduct = line.product.footprintProducts.find(
                    (x) => x.footprintType.footprintTypeId === metaDateFootprint.footPrintTypeId,
                );
                if (footprintProduct) {
                    metaDateFootprint.amount = value * footprintProduct.value;
                }
            }
        });
    }

    public async addLineToPurchase(showSideAction: boolean = false) {
        if (this.orderedLines.length > 0) {
            const line = Object.assign({}, this.orderedLines[this.orderedLines.length - 1]);
            line.open = true;
            line.lineNumber++;
            line.attachmentId = null;

            this.purchase.lines.push(line);
        } else {
            const newLine = new PurchaseLine();
            newLine.open = true;
            newLine.departmentObj = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeam.id);
            if (newLine.departmentObj) {
                newLine.department = newLine.departmentObj.name;
                newLine.projects = await this.getProjects(newLine.departmentObj);
            }
            newLine.lineNumber = 1;
            this.footprintTypes.forEach((element) => {
                newLine.metaData.push({ amount: 0, footPrintTypeId: element.footprintTypeId, label: element.label, name: element.name });
            });

            if (this.orgProducts.length) {
                const products = this.products.filter((x) => this.orgProducts.find((y) => y.productId === x.productId));
                newLine.products = [...products, { $isDisabled: true, name: 'This list is filtered based on the selected organization', productId: -1 } as any];
            } else {
                newLine.products = [...this.products];
            }

            this.purchase.lines.push(newLine);
        }

        if (showSideAction === true) {
            this.openAddPurchaseLineSideAction(this.purchase.lines.length - 1);
        }
    }

    public removePurchaseLine(lineNumber: number) {
        this.purchase.lines.splice(this.findOrderLineIndex(lineNumber), 1);
    }

    public async deletePurchase() {
        this.showPending('DELETE_PURCHASE_PENDING');
        const success = await purchasesService.deletePurchase(this.purchase.id);
        if (success) {
            this.clearAndShowSuccess('DELETE_PURCHASE_SUCCESS');
            await this.$router.push({ name: 'purchases' });
        } else {
            this.clearAndShowError('DELETE_PURCHASE_FAILED', null);
        }
    }

    public async selectTeam() {
        this.teamChanged = true;
    }

    public openUploadUBLAction() {
        this.$sideActions.push('upload-purchase-xml-side-action', {}, async (result) => {
            const newPurchase = new Purchase(result);

            newPurchase.lines.forEach((newLine) => {
                this.footprintTypes.forEach((element) => {
                    newLine.metaData.push({ amount: 0, footPrintTypeId: element.footprintTypeId, label: element.label, name: element.name });
                });
            });

            Object.assign(this.purchase, newPurchase);
            this.uploaded++;
        });
    }

    public openPendingApprovalsModal() {
        this.$refs.approvalModal.show();
    }

    public toggleLines(bool: boolean) {
        this.purchase.lines.forEach((_element) => {
            _element.open = !bool;
        });
    }
}
