import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import Ledger from '@/models/Finance/Ledger';
import Vue from 'vue';
import { crmLedgerService } from '@/main';
import to from 'await-to-js';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class LedgersComponent extends PageRender {
    public ledgers: Ledger[] = [];
    public allLedgers: Ledger[] = [];

    public ledgersColumns: GridColumnProps[] = [
        { field: 'ledgerId', title: 'Id', width: 125, editable: false },
        { field: 'name', title: 'Naam', editable: false },
        { field: 'description', title: 'Description', editable: false },
        { field: 'value', title: 'Number', editable: false, width: 125 },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['value', 'name'],
    });

    public async mounted() {
        await this.loadLedgers();
        this.isLoading = false;
    }

    public async goToLedger(e) {
        const ledgerId = e.ledgerId;
        this.$sideActions.push('create-ledger-side-action', { ledger: { ledgerId } }, (result) => {
            this.setLedger(result);
        });
    }

    public async loadLedgers() {
        const ledgers = await crmLedgerService.getLedgers();
        this.allLedgers = this.ledgers = ledgers.items;
    }

    public createLedgerSideAction() {
        this.$sideActions.push('create-ledger-side-action', {  }, (result) => {
            this.setLedger(result);
        });
    }

    public importLedgersSideAction() {
        this.$sideActions.push('import-ledgers-side-action', {}, async () => {
            await this.loadLedgers();
        });
    }

    public setLedger(result) {
        this.ledgers = this.ledgers.filter((x) => x.ledgerId !== result.ledgerId);
        this.ledgers.push(result);
        // this.contactSelected++;
    }

    public async deleteLedger(item: Ledger) {
        this.showPending('DELETE_LEDGER_PENDING');
        const [err] = await to(crmLedgerService.deleteLedger(item.ledgerId));
        if (err) {
            this.clearAndShowError('DELETE_LEDGER_FAILED', err);
            return;
        }

        this.clearAndShowSuccess('DELETE_LEDGER_SUCCESS');
        this.ledgers = this.ledgers.filter((x) => x.ledgerId !== item.ledgerId);
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Edit ledger',
                function: this.goToLedger,
            },
            {
                title: 'Delete ledger',
                function: this.deleteLedger,
            }
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }
}
