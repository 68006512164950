import Base from '@/pages/Base.vue';

export default [
    {
        name: 'base',
        path: '/costs/:ecosystemId(\\d+)-:ecosystemKey/',
        component: Base,
        redirect: 'declarations',
        children: [
            {
                name: 'approvals',
                path: ':teamId(\\d+)-:teamKey/approvals',
                component: require('@/pages/costs/approvals/approvals.vue').default,
                meta: {
                    title: 'Approvals',
                    scope: 'approvals',
                    section: 'approvals',
                    app: 'costs',
                    breadcrumb: {
                        text: 'NAV_APPROVALS',
                        items: ['declarations'],
                    },
                },
            },
            {
                name: 'approval',
                path: ':teamId(\\d+)-:teamKey/approvals/:purchaseId',
                component: require('@/pages/costs/approvals/approval.vue').default,
                meta: {
                    title: 'Approval',
                    scope: 'approval',
                    section: 'approvals',
                    app: 'costs',
                    breadcrumb: {
                        text: 'NAV_APPROVAL',
                        items: ['declarations'],
                    },
                },
            },
            {
                name: 'declarations',
                path: 'declarations',
                component: require('@/pages/costs/declarations/declarations.vue').default,
                meta: {
                    title: 'Declarations',
                    scope: 'declarations',
                    app: 'costs',
                    section: 'declarations',
                    breadcrumb: {
                        text: 'NAV_DECLARATIONS',
                        items: ['approvals'],
                    },
                },
            },
            {
                name: 'create-declaration',
                path: 'declarations/:documentId?',
                component: require('@/pages/costs/declarations/create-or-edit-declaration.vue').default,
                meta: {
                    title: 'Document',
                    scope: 'document',
                    section: 'declarations',
                    app: 'costs',
                    breadcrumb: {
                        text: 'NAV_DOCUMENT',
                        items: ['approvals', 'declarations'],
                    },
                },
            },
            {
                name: 'document-approve',
                path: 'declaration/:documentId/approve',
                component: require('@/pages/costs/declarations/document-approve.vue').default,
                meta: {
                    title: 'Document',
                    scope: 'document',
                    section: 'declarations',
                    app: 'costs',
                    breadcrumb: {
                        text: 'NAV_DOCUMENT',
                        items: ['approvals', 'declarations'],
                    },
                },
            },
        ],
    },
];
