import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import LegalEntity from '@/models/LegalEntity';
import _ from 'lodash';

@Component
export default class LegalEntitySelectorComponent extends Vue {
    public async created() {
        await legalEntityModule.fetchIfNeeded();
    }

    public legalEntities() {
        return legalEntityModule.all;
    }

    public otherLegalEntities(): LegalEntity[] {
        const items = legalEntityModule.all.filter((x) => x.legalEntityId !== legalEntityModule.current.legalEntityId);

        return _.orderBy(items, 'name');
    }

    public get legalEntity() {
        const entity = legalEntityModule.all.find((e: LegalEntity) => {
            return e.legalEntityId === this.$route.params.legalEntity;
        });
        return entity ? entity : new LegalEntity();
    }

    public async setSelectedLegalEntity(entity: LegalEntity) {
        const params = this.$route.params;
        params.legalEntity = entity.legalEntityId;
        await this.$router.push({ name: this.$route.name, params });
    }
}
