import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Invoice } from '../../models/Invoice';
import moment from 'moment';
import { roleHelper } from '@/main';

@Component
export default class GridPopoverComponent extends Vue {
    @Prop({ default: () => [] }) public invoice: Invoice;
    @Prop({ default: 'grid' }) public grid;

    public getContent(): string {
        let content = `
        <div class="p-2">
            <h6 class="mb-0">Client</h6>
            <p class="text-muted mb-2">${this.invoice.name}</p>

            <h6 class="mb-0">Description</h6>
            <p class="text-muted mb-2">${this.invoice.description}</p>
        `;

        if (this.invoice.reference) {
            content += `
                <h6 class="mb-0">Reference</h6>
                <p class="text-muted mb-2">${this.invoice.reference}</p>
            `;
        }

        content += `
            <h6 class="mb-0">Date</h6>
            <p class="text-muted mb-2">${this.getDate()}</p>
        `;

        if (this.invoice.originalAmount && this.invoice.parent) {
            content +=
                `<h6 class="mb-0">Original amount</h6>` +
                `<p class="text-muted mb-2">${Vue.filter('number-format')(this.invoice.originalAmount)}</p>`;
        }

        if (roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            content +=
                `<h6 class="mb-0">Legal entity reference</h6>` +
                `<p class="text-muted mb-2">${!this.invoice.legalEntityReferenceOrigin ? '-' : this.invoice.legalEntityReferenceOrigin}</p>`;
        }

        content += `<h6 class="mb-0">Invoice Id</h6>` + `<p class="text-muted mb-2">${this.invoice.invoiceId}</p>` + '</div>';

        return content;
    }

    public getDate(): string {
        return moment(this.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
}
