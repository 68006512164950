export enum InvoiceStatusEnum {
    Unknown = 'Unknown',
    Draft = 'Draft',
    Booked = 'Booked',
}

export default class InvoiceStatus {
    public static getStatus(status: InvoiceStatusEnum = InvoiceStatusEnum.Unknown): string {
        if (this.status[InvoiceStatusEnum[status]]) {
            return this.status[InvoiceStatusEnum[status]];
        }

        return this.status[0];
    }

    public static getStatussen() {
        return [
            // InvoiceStatus.status.Unknown,
            InvoiceStatus.status.Draft,
            InvoiceStatus.status.Booked,
        ];
    }

    private static status = {
        Unknown: 'Unknown',
        Draft: 'Draft',
        Booked: 'Booked',
    };
}
