import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import FootprintProduct from '@/models/Purchases/FootprintProduct';
import Vue from 'vue';
import { footprintProductsServicePurchases } from '@/main';
import to from 'await-to-js';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class FootprintsComponent extends PageRender {
    public footprintProducts: FootprintProduct[] = [];
    public allFootprintProducts: FootprintProduct[] = [];

    public productsColumns: GridColumnProps[] = [
        { field: 'footprintProductId', title: 'Id', width: 125, editable: false },
        { field: 'name', title: 'NAME', editable: false,  },
        { field: 'value', title: 'FOOTPRINT_VALUE', editable: false,  },
        { field: 'footprintProductType.name', title: 'FOOTPRINT_TYPE', editable: false,  },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['name', 'value', 'footprintProductType.name'],
    });

    public async mounted() {
        await this.loadProducts();
        this.isLoading = false;
    }

    public async loadProducts() {
        const products = await footprintProductsServicePurchases.getFootprintProducts();
        this.allFootprintProducts = this.footprintProducts = products.items;
    }

    public createProductSideAction() {
        this.$sideActions.push('create-footprint-side-action', {}, (result) => {
            this.setProduct(result);
        });
    }

    public setProduct(result: FootprintProduct) {
        this.footprintProducts = this.footprintProducts.filter((x) => x.footprintProductId !== result.footprintProductId);
        this.footprintProducts.push(result);
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Archive product',
                function: this.archiveProduct,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async archiveProduct(item: FootprintProduct) {
        this.showPending('ARCHIVE_PRODUCT_PENDING');
        const [err] = await to(footprintProductsServicePurchases.archiveFootprintProduct(item));
        if (err) {
            return this.clearAndShowError('ARCHIVE_PRODUCT_FAILED', err);
        }

        this.clearAndShowSuccess('ARCHIVE_PRODUCT_SUCCESS');

        this.footprintProducts = this.footprintProducts.filter((x) => x.footprintProductId !== item.footprintProductId);
    }

    public editFootprintProduct(row) {
        this.$sideActions.push('create-footprint-side-action', { footprintProduct: row.dataItem });
    }
}
