import { appHelper } from '@/main';
import Organization from '@/models/Purchases/Organization';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class MergeOrganizationsActionComponent extends SideActionBase<any> {
    public organizations: Organization[] = [];

    public get componentName() {
        return `${appHelper.isCrm() ? 'crm' : 'purchases'}-merge-organizations`;
    }

    public created() {
        if (this.options.organizations) {
            this.organizations = this.options.organizations;
        }
    }

    public organizationMerged(e): void {
        this.finished(e);
    }
}
