export default class COCDataResponse {
    public kvknummer: string = '';
    public naam: string = '';
    public vestigingsnummer: string = '';
    public link: string = '';
    public postcode: string = '';

    constructor(data?: Partial<COCDataResponse>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
