import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import LegalEntity from '@/models/LegalEntity';
import { IVuelidate } from 'vuelidate';
import to from 'await-to-js';
import { legalEntityService } from '@/main';

@Component
export default class LegalEntityPage extends PageRender implements IVuelidate<any> {
    public legalEntity: LegalEntity = null;

    public async mounted() {
        await this.loadLegalEntity();

        this.isLoading = false;
    }

    public async loadLegalEntity() {
        const [err, response] = await to(legalEntityService.getLegalEntity(this.$route.params.legalEntityId));
        if (err) {
            this.clearAndShowError('Failed to load legal entity details', err);
            return;
        }

        return (this.legalEntity = new LegalEntity(response.data));
    }

    public editInvoiceData() {
        this.$sideActions.push('create-or-edit-legal-entity-invoice-data', { legalEntity: this.legalEntity }, (result) => {
            console.log(result);
        });
    }
}
