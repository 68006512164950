export default class Attachment {
    public fileId: string;
    public fileName: string;
    public url: string;

    constructor(obj?: Partial<Attachment>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
