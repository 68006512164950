import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import Product from '@/models/CRM/Product';
import { ProductActivity } from '@/models/Purchases/Product';
import { AxiosResponse } from 'axios';

export default class ProductsServicePurchases extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}products`;
    private productActivitiesEndpoint = `${Vue.$env().purchasesApiEndpoint}product-activities`;

    public async getProducts(): Promise<Range<Product>> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_PRODUCTS_FAILED`, err);
            return new Range<Product>();
        }

        return new Range<Product>(response.data);
    }

    public async getProduct(productId: number): Promise<Product> {
        const [err, response] = await to(this.get(`${this.endpoint}/:productId`, [['productId', productId]]));

        if (err) {
            this.clearAndShowError(`LOAD_PRODUCT_FAILED`, err);
            return new Product();
        }

        return new Product(response.data);
    }

    public createProduct(product: Product) {
        const model = {
            name: product.name,
            ledger: product.ledger.ledgerId,
            taxRateId: product.taxRate.id,
            footprintProducts: product.footprintProducts.map((x) => x.footprintProductId),
            price: product.price,
        };

        return this.post(this.endpoint, model);
    }

    public updateProduct(product: Product) {
        const model = {
            name: product.name,
            ledger: product.ledger.ledgerId,
            productId: product.productId,
            taxRateId: product.taxRate.id,
            footprintProducts: product.footprintProducts.map((x) => x.footprintProductId),
            price: product.price,
        };

        return this.put(`${this.endpoint}/${model.productId}`, model);
    }

    public archiveProduct(product: Product) {
        return this.delete(`${this.endpoint}/${product.productId}`);
    }

    public async saveConnectedActivity(productId: number, activity: ProductActivity) {
        const model = {
            productId,
            activityName: activity.activityObj.name,
            teamName: activity.teamObj.name,
        };

        return this.post(`${this.productActivitiesEndpoint}`, model);
    }

    public async deleteConnectedActivity(productActivityId: number) {
        return this.delete(`${this.productActivitiesEndpoint}/${productActivityId}`);
    }

    public async getProductActivities(productId?: number, teamName?: string, activityName?: string): Promise<AxiosResponse<ProductActivity[]>> {
        const params = new URLSearchParams();
        if (productId) {
            params.append('product_id', productId.toString());
        }

        if (teamName) {
            params.append('team_name', teamName);
        }

        if (activityName) {
            params.append('activity_name', activityName);
        }
        
        return this.get(`${this.productActivitiesEndpoint}?${params}`);
    }
}
