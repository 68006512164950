import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';

export default class BrightService extends BaseService {
    private endpoint = `${Vue.$env().brightApiEndpoint}`;
    private financeEndpoint = `${Vue.$env().brightFinanceApiEndpoint}`;

    public async getStructure(ecosystem: number, period?: string) {
        const monthString = `0${new Date().getMonth() + 1}`.slice(-2);
        let periodString = `${new Date().getFullYear()}-${monthString}`;
        if (period) {
            periodString = period;
        }
        const [err, response] = await to(
            this.get(`${this.endpoint}ecosystems/:ecosystem`, [['ecosystem', ecosystem]], {
                headers: {
                    'x-period': periodString,
                },
            }),
        );

        if (err) {
            this.clearAndShowError(`LOAD_ECOSYSTEM_FAILED`, err);
            return { teams: [{ groups: [] }] };
        }

        return response.data;
    }

    public async getPersonBudget(personId: number, teamId: number) {
        const montString = `0${new Date().getMonth() + 1}`.slice(-2);

        const [err, response] = await to(
            this.get(
                `${this.financeEndpoint}budgets/persons/:person?team_id=:team`,
                [
                    ['person', personId],
                    ['team', teamId],
                ],
                {
                    headers: {
                        'x-period': `${new Date().getFullYear()}-${montString}`,
                    },
                },
            ),
        );

        if (err) {
            this.clearAndShowError(`LOAD_PERSON_BUDGET_FAILED`, err);
            return { teams: [{ groups: [] }] };
        }

        return response.data;
    }

    public async getActivityBudget(activityId: number) {
        const montString = `0${new Date().getMonth() + 1}`.slice(-2);

        const [err, response] = await to(
            this.get(`${this.financeEndpoint}budgets/activities/:activity`, [['activity', activityId]], {
                headers: {
                    'x-period': `${new Date().getFullYear()}-${montString}`,
                },
            }),
        );

        if (err) {
            this.clearAndShowError(`LOAD_GROUP_BUDGET_FAILED`, err);
            return { teams: [{ groups: [] }] };
        }

        return response.data;
    }

    public async getGroup(groupId: number, period?: string) {
        const monthString = `0${new Date().getMonth() + 1}`.slice(-2);
        let periodString = `${new Date().getFullYear()}-${monthString}`;
        if (period) {
            periodString = period;
        }
        const [err, response] = await to(
            this.get(`${this.endpoint}groups/:groupId`, [['groupId', groupId]], {
                headers: {
                    'x-period': periodString,
                },
            }),
        );

        if (err) {
            this.clearAndShowError(`LOAD_GROUP_FAILED`, err);
            return null;
        }

        return response.data;
    }
}
