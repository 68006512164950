import TeamSettings from '@/models/Finance/TeamSettings';
import to from 'await-to-js';
import Vue from 'vue';
import { BaseService } from './baseService';

export default class SettingService extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}team-settings`;

    public async getTeamSettings(teamId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}/${teamId}`));

        if (err) {
            this.clearAndShowError(`LOAD_TEAM_SETTINGS_FAILED`, err);
            return new TeamSettings();
        }

        return new TeamSettings(response.data);
    }

    public upsertTeamSettings(settings: TeamSettings, teamId: number) {
        return this.put(`${this.endpoint}/${teamId}`, settings);
    }

    public deleteTeamSettings(settingsId: number) {
        return this.delete(`${this.endpoint}/${settingsId}`);
    }
}
