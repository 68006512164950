import DateHelper from '@/helpers/dateHelper';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class PurchaseDatePicker extends Vue {
    @Prop() public value: string;
    @Prop() public allowedToEdit: boolean;

    public get dateInPast() {
        return DateHelper.daysAgo(moment(this.value, 'DD-MM-YYYY')) > 90;
    }

    public onInput(value) {
        this.$emit('input', value);
    }
}
