import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { dealsService, ecosystemHelper, teamHelper } from '@/main';
import Deal from '@/models/CRM/Deal';

@Component
export default class DealComponent extends PageRender {
    public deal: Deal = new Deal();

    public async mounted() {
        const dealId = parseInt(this.$route.params.dealId);

        ecosystemHelper.setEcosystemByEcosystemId(parseInt(this.$route.params.ecosystemId));
        teamHelper.setTeamByTeamId(parseInt(this.$route.params.teamId));

        await this.loadDeal(dealId);

        if (this.deal && !this.deal.id) {
            await this.$router.push({ name: 'crm-deals' });
        }
        this.isLoading = false;
    }

    public async loadDeal(dealId: number) {
        this.deal = await dealsService.getDeal(dealId);
    }
}
