import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { contactsService, organizationsServiceCRM } from '@/main';
import Contact from '@/models/CRM/Contact';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import Organization from '@/models/CRM/Organization';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        _contact: {
            firstName: { required },
            lastName: { required },
            emailAddresses: {
                $each: {
                    value: { required },
                },
            },
            phoneNumbers: {
                $each: {
                    value: { required },
                    label: { required },
                },
            },
        },
    },
} as any)
export default class CreateOrEditContactComponent extends PageRender {
    @Prop({ default: () => new Contact() }) public contact: Contact;
    @Prop({ default: () => new Organization() }) public organization: Organization;
    public _contact: Contact;
    public isEdit: boolean = false;
    public organizationId: number = null;
    public showSuccessMessage: boolean = false;
    public organizationScope: boolean = true;
    public relationType: { value: string; text: string } = { value: 'default', text: 'Default' };

    public async created() {
        if (this.organization && !this.organization.organizationId) {
            this.organizationId = parseInt(this.$route.params.organizationId);

            if (!this.organizationId) {
                this.organizationId = this.organization.id;
            }
        } else {
            this.showSuccessMessage = true;
            this.organizationId = this.organization.organizationId;
        }

        this._contact = this.contact;
        this.isEdit = this.contact && this.contact.contactId > 0;
        this.organizationScope = !!this.organizationId;

        if (this.isEdit) {
            const type = this.relationOptions().find((x) => x.value === this._contact.contactType);
            if (type) {
                this.relationType = type;
            }
        }
    }

    public async mounted() {
        this.isLoading = false;
    }

    public resetContact() {
        this._contact = new Contact();
    }

    public async createContact() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`The contact didn't pass the validation.`);
            return;
        }

        this.showPending('Creating contact..');

        const contact = await contactsService.createContact(this.contact);
        if (this.organizationScope) {
            const success = await organizationsServiceCRM.coupleContact(this.organizationId, contact, this.relationType.value);
            this.$emit('finished', { success, contact });
            this.clearNotifications();
        } else {
            this.clearAndShowSuccess('CREATE_CONTACT_SUCCESS');
        }
    }

    public async updateContact() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`The contact didn't pass the validation.`);
            return;
        }

        this.showPending('Updating contact..');

        const [err, response] = await to(contactsService.updateContact(this.contact));
        if (err) {
            return this.clearAndShowError('UPDATE_CONTACT_FAILED', err);
        }

        if (this.organizationScope) {
            await organizationsServiceCRM.decoupleContact(this.organizationId, this.contact);
            const success = await organizationsServiceCRM.coupleContact(this.organizationId, this.contact, this.relationType.value);

            if (this.showSuccessMessage) {
                this.clearAndShowSuccess('UPDATE_CONTACT_SUCCESS');
            } else {
                this.clearNotifications();
            }

            const contact = new Contact(response.data);
            this.$emit('finished', { success, contact });
        } else {
            this.clearAndShowSuccess('UPDATE_CONTACT_SUCCESS');
        }
    }

    public addMailAddress() {
        this._contact.emailAddresses.push({ isPrimary: this._contact.emailAddresses.length === 0, value: '' });
    }

    public removeMailAddress(index: number) {
        this._contact.emailAddresses.splice(index, 1);
    }

    public addPhoneNumber() {
        this._contact.phoneNumbers.push({ label: '', value: '' });
    }

    public removePhoneNumber(index: number) {
        this._contact.phoneNumbers.splice(index, 1);
    }

    public relationOptions() {
        return [
            { value: 'default', text: 'Default' },
            { value: 'administrative', text: 'Administrative' },
        ];
    }
}
