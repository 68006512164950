import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';

export default class NotificationsService extends BaseService {
    private endpoint = `${Vue.$env().notificationsEndpoint}`;

    public async getMessageLog(invoiceId: string, application: string = 'paperless') {
        const [err, response] = await to(this.get(`${this.endpoint}/message-log?application=${application}&external_identifier=${invoiceId}`));

        if (err) {
            this.clearAndShowError(`LOAD_MESSAGE_LOG_FAILED`, err);
            return [];
        }

        return response.data;
    }

}
