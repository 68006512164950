import { Component } from 'vue-property-decorator';
import { ecosystemHelper, teamHelper } from '@/main';
import PageRender from '@/models/PageRender';
import { profileModule } from '@/store/modules/profile';

@Component
export default class HomeComponent extends PageRender {
    public async created() {
        if (profileModule.isLoggedIn) {
            const redirectUriStorage = window.localStorage;
            const returnUrl = redirectUriStorage.getItem('redirectUri');

            const url = returnUrl !== null ? returnUrl.toString() : '';
            if (url === '/' || url === '' || !url) {
                const mobilePreferred = localStorage.getItem('mobilePreferred');
                if (mobilePreferred === 'true') {
                    return this.$router.push({
                        name: 'mobile-create-declaration',
                        params: {
                            ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                            ecosystemKey: ecosystemHelper.currentEcosystem.key,
                        },
                    });
                }

                return this.$router.push({
                    name: 'crm-deals',
                    params: {
                        ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                        ecosystemKey: ecosystemHelper.currentEcosystem.key,
                        teamId: teamHelper.currentTeam.id.toString(),
                        teamKey: teamHelper.currentTeam.key,
                    },
                });
            }
            const route = this.$router.resolve(url);
            if (route && route.resolved) {
                const ecosystemId = route.resolved.params.ecosystemId;
                const teamId = route.resolved.params.teamId;
                const ecosystemKey = route.resolved.params.ecosystemKey;
                const teamKey = route.resolved.params.teamKey;

                route.route.params.teamId = teamId;
                route.route.params.teamKey = teamKey;
                route.route.params.ecosystemKey = ecosystemKey;
                route.route.params.ecosystemId = ecosystemId;

                ecosystemHelper.setEcosystemByEcosystemId(parseInt(ecosystemId));
                teamHelper.setTeamByTeamId(parseInt(teamId));
            }
            await this.$router.push({ name: route.route.name, params: route.route.params, query: route.route.query });

            if (returnUrl) {
                redirectUriStorage.removeItem('redirectUri');
            }
        }
    }
}
