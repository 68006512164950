import { inboxDocumentService } from '@/main';
import { PurchaseApproval } from '@/models/Purchases/PurchaseApproval';
import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class PendingApprovalsModal extends BaseModal {
    @Prop({ default: () => [] }) public approvals: PurchaseApproval[];
    @Prop({ default: () => [] }) public id: number;

    public onHide() {
        this.approvals = [];
    }

    public getPendingApprovals() {
        return this.approvals.filter((x) => x.approvalState === 'pending').length;
    }

    public async sendReminders() {
        this.showPending('SENDING_REMINDER_PENDING');
        const success = await inboxDocumentService.remindApprovals(this.id);
        if (!success) {
            return;
            // return this.clearAndShowError('SENDING_REMINDER_FAILED', success);
        }

        this.clearAndShowSuccess('SENDING_REMINDER_SUCCESS');
    }
}
