import PaymentMethod from '../PaymentMethod';

export default class PurchaseOrganizationSettings {
    public defaultPaymentMethod?: PaymentMethod = new PaymentMethod();
    public aliases: string[] = [];

    constructor(obj?: Partial<PurchaseOrganizationSettings>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
