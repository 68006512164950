import { organizationsServicePurchases } from '@/main';
import Organization from '@/models/Purchases/Organization';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class OrganizationPredictionLinesComponent extends SideActionBase<any> {
    public foundOrganizations: Organization[] = [];

    public async mounted() {
        await Promise.all([this.loadStructure(), this.loadTaxRates()]);

        this.showPending('LOOKING_FOR_MATCHING_ORGANIZATIONS');

        const parts = this.options.organization.split(' ');
        const organizations = await organizationsServicePurchases.getOrganizations();
        this.foundOrganizations = [];

        parts.forEach((x) => {
            const foundOrgs = organizations.items.filter((y) => y.name.indexOf(x) > -1);
            this.foundOrganizations.push(...foundOrgs);
        });

        this.clearNotifications();

        this.isLoading = false;
    }

    public async closeAndUseOrganization(orgId) {
        this.finished(orgId);
    }
}
