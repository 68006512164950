import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { translateModule } from '@/store/modules/translate';

@Component
export default class TranslateBase extends Vue {
    @Prop() public translateKey: string;
    @Prop() public translateGroup: string;
    @Prop() public params: any;
    @Prop({ type: Boolean }) public inline: boolean;
    @Prop({ type: Boolean }) public column: boolean;
    @Prop() public updated: CallableFunction;
    public editMode: boolean = false;
    public virtualValue: string = '';
    public $refs!: {
        input: any,
    };

    public created() {
        this.virtualValue = this.rawValue;
    }

    public get rawValue() {
        return translateModule.value(this.translateKey, this.translateGroup);
    }

    public get value() {
        return translateModule.value(this.translateKey, this.translateGroup, this.params);
    }

    public get inEditMode() {
        return translateModule.inEditMode;
    }

    public edit(e) {
        this.editMode = true;
        e.preventDefault();

        this.$nextTick(() => {
            if (this.$refs.input) {
                this.$refs.input.$el.focus();
            }
        });
    }

    public setValue(e) {
        this.virtualValue = e;
    }

    public async save(e) {
        this.editMode = false;
        e.preventDefault();
        this.$emit('updated');
        if (this.updated) {
            this.updated();
        }
        await translateModule.setValue({
            key: this.translateKey,
            value: this.virtualValue,
            group: this.translateGroup,
        });
    }

    public get id() {
        const self = this as any;
        return `p${self._uid}`;
    }
}
