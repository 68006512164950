import { appHelper } from '@/main';
import Organization from '@/models/Purchases/Organization';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class OrganizationActionComponent extends SideActionBase<any> {
    public organization: Organization = new Organization();
    public existingOrganizations: Organization[] = [];

    public get componentName() {
        return `${appHelper.isCrm() ? 'crm' : 'purchases'}-create-or-edit-organization`;
    }

    public created() {
        if (this.options.organization) {
            this.organization = this.options.organization;
        }

        if (this.options.existingOrganizations) {
            this.existingOrganizations = this.options.existingOrganizations;
        }
    }

    public createOrganization(e): void {
        this.finished(e);
    }
}
