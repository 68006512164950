import { dealsService, legalEntityService, organizationsServiceCRM, teamHelper, templateService } from '@/main';
import Contact from '@/models/CRM/Contact';
import Deal from '@/models/CRM/Deal';
import Organization from '@/models/CRM/Organization';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import moment from 'moment';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { Template } from '@/models/CRM/Template';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import SendInvoiceMail from '@/models/SendInvoiceMail';
import { InvoiceEmailTemplate } from '@/models/LegalEntity';
import TaxRate from '@/models/CRM/TaxRate';
import { profileModule } from '@/store/modules/profile';

function requiredIfDealIsOpen() {
    // tslint-disable-next-line
    return this.deal.dealState.name !== 'Lead' || (this.deal.dealState.name === 'Lead' && this.deal.lines.length > 0);
}
@Component({
    validations: {
        deal: {
            name: { required },
            organization: { required },
            contact: { required },
            lines: {
                requiredIf: requiredIfDealIsOpen,
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
        sendMail: {
            contactName: { required },
            contactEmail: { required, email },
            ccEmail: { email },
            masterTemplate: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.body);
                }),
            },
            subject: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.masterTemplate || model.body);
                }),
            },
            body: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.masterTemplate);
                }),
            },
        },
    },
} as any)
export default class DealToInvoiceModalComponent extends BaseModal {
    @Prop({ default: () => new Deal() }) public deal: Deal;

    public appliedTaxRate: TaxRate = null;
    public administrativeContact: Contact = null;
    public sendInvoiceToContact: boolean = false;
    public organization: Organization = null;
    public canceled: boolean = true;
    public selectedDate: string = moment().format('DD-MM-YYYY');
    public templates: Template[] = [];
    public loading: boolean = true;
    public selectedCCContact: Contact = null;

    public sendMail: SendInvoiceMail = {
        contactName: '',
        contactEmail: '',
        ccEmail: '',
        masterTemplate: 'bright-default',
        overrideDefaultMailSettings: false,
    };

    public selectedCCOption = '';
    public CCOptions = [
        { text: 'None', value: '' },
        { text: `CC to myself (${profileModule.brightProfile.emailAddress})`, value: 'self' },
        { text: 'Type an email address / Select contact', value: 'other' },
    ];

    public get contacts() {
        return this.organization.contacts.filter((x) => typeof x.emailAddresses !== 'undefined' && x.emailAddresses.length);
    }

    public get ccContacts() {
        return this.contacts.filter(
            (x) => !this.administrativeContact || (this.administrativeContact && x.contactId !== this.administrativeContact.contactId),
        );
    }

    public async init(deal?: Deal) {
        this.canceled = true;
        this.show();

        if (deal) {
            this.deal = deal;
            await this.loadTemplates();
        }

        if (this.deal.organization) {
            this.showPending('LOADING_LANGUAGE_OF_ORGANIZATION');
            const organization = await organizationsServiceCRM.getOrganization((this.deal.organization as Organization).organizationId);
            if (organization && organization.countryIso2) {
                this.appliedTaxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);
            }

            this.administrativeContact = organization.contacts.find((x) => x.contactType === 'administrative');
            this.organization = organization;

            this.clearNotifications();
        }

        this.loading = false;
    }

    public onHide() {
        this.administrativeContact = null;
        this.selectedCCContact = null;
        this.organization = null;
        this.sendInvoiceToContact = false;
        this.selectedCCOption = '';

        this.$emit('hide', this.canceled);
    }

    public async loadTemplates() {
        const legalEntityReference = teamHelper.getLegalEntityReference();
        this.templates = await templateService.getTemplates(legalEntityReference);
    }

    public async loadMailContent(radioButtonValue?: string) {
        if (radioButtonValue === 'self' || radioButtonValue === 'other') {
            return;
        }

        this.isLoading = true;
        let mailContent: InvoiceEmailTemplate = await dealsService.getMailTemplateContent(teamHelper.currentTeamId, this.deal.id);
        this.isLoading = false;
        if (mailContent) {
            this.sendMail.contactEmail = this.getAdministrativeMail();
            this.sendMail.contactName = this.getAdministrativeName();
            this.sendMail.subject = mailContent.subject;
            this.sendMail.body = mailContent.body;
            this.sendMail.masterTemplate = mailContent.masterTemplate;
        }
    }

    public setContactDetails(contact: Contact) {
        this.sendMail.contactEmail = contact.emailAddresses[0].value;
        this.sendMail.contactName = `${contact.firstName} ${contact.lastName}`;
    }

    public setCCEmail(contact: Contact) {
        this.sendMail.ccEmail = contact.emailAddresses[0].value;
    }

    public async setDealAsInvoiced() {
        this.submitted = true;
        if ((this as any).$v.deal.$invalid || (this.sendInvoiceToContact && (this as any).$v.sendMail.$invalid)) {
            this.clearAndShowWarning(`INVALID`);
            return;
        }

        this.showPending('DEAL_INVOICED_PENDING');
        const success = await this.updateDeal();
        if (!success) {
            return;
        }

        if (this.selectedCCOption === 'self') {
            this.sendMail.ccEmail = profileModule.brightProfile.emailAddress;
        }

        const [err] = await to(dealsService.changeDealState(this.deal.id, 7, this.sendMail));
        if (err) {
            return this.clearAndShowError('DEAL_INVOICED_FAILED', err);
        }

        this.clearAndShowSuccess('DEAL_INVOICED_SUCCESS');
        this.canceled = false;
        this.hide();
    }

    public async updateDeal() {
        this.submitted = true;
        if ((this as any).$v.deal.$invalid) {
            this.showWarning('UPDATE_DEAL_INVALID');
            return false;
        }

        const taxRate = await organizationsServiceCRM.getTaxRate((this.deal.organization as Organization).organizationId);

        this.deal.lines.forEach((x) => {
            x.taxRateId = taxRate ? taxRate.taxRate : null;
        });

        if (this.selectedDate) {
            this.deal.invoiceDate = moment(this.selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        const [err] = await to(dealsService.updateDeal(this.deal));
        if (err) {
            return false;
        }

        return true;
    }

    public getAdministrativeName() {
        if (!this.organization || !this.administrativeContact) {
            return '';
        }

        return `${this.administrativeContact.firstName} ${this.administrativeContact.lastName}`;
    }

    public getAdministrativeMail() {
        if (!this.organization || !this.administrativeContact) {
            return '';
        }

        let email = this.administrativeContact.emailAddresses.find((x) => x.isPrimary);

        if (email) {
            return email.value;
        }

        email = this.administrativeContact.emailAddresses[0];

        if (email) {
            return email.value;
        }

        return null;
    }

    public getTemplateName(templateId) {
        return this.templates.find((x) => x.invoiceTemplateId === templateId).name;
    }

    public getLegalEntityTemplateName() {
        return legalEntityModule.current.name;
    }
}
