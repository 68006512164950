import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import Contact from '@/models/CRM/Contact';

export default class ContactsService extends BaseService {
    private endpoint = `${Vue.$env().crmApiEndpoint}contacts`;

    public async getContacts(): Promise<Range<Contact>> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACTS_FAILED`, err);
            return new Range<Contact>();
        }

        return new Range<Contact>(response.data);
    }

    public async getContact(contactId: number): Promise<Contact> {
        const [err, response] = await to(this.get(`${this.endpoint}/:contactId`, [['contactId', contactId]]));

        if (err) {
            this.clearAndShowError(`LOAD_CONTACT_FAILED`, err);
            return new Contact();
        }

        return new Contact(response.data);
    }

    public async createContact(contact: Contact) {
        const [err, response] = await to(this.post(`${this.endpoint}/`, contact));
        if (err) {
            this.clearAndShowError(`CREATE_CONTACT_FAILED`, err);
            return new Contact();
        }

        return new Contact(response.data);
    }

    public updateContact(contact: Contact) {
        return this.put(`${this.endpoint}/${contact.contactId}`, contact);
    }

    public copyContact(contact: Contact) {
        return this.put(`${this.endpoint}/${contact.contactId}`, contact);
    }
}
