import Attachment from '../Attachment';
import Product from '../CRM/Product';
import Organization from './Organization';
import { PurchaseLine } from './PurchaseLine';
import { PurchaseState } from './PurchaseState';

export class Purchase {
    public id: number;
    public purchaseInvoiceId: number;
    public teamId: number;
    public name: string;
    public product: Product = null;
    public purchaseDate: string = null;
    public skipFromExternalInvoicing: boolean = false;
    public purchaseValue: number = 0;
    public organization: number | Organization = null;
    public reference: string = '';
    public currency: string = 'EUR';
    public purchaseState: PurchaseState = new PurchaseState();
    public attachments: Attachment[] = [];
    public lines: PurchaseLine[] = [];
    public externalInvoice: any = null;

    constructor(obj?: Partial<Purchase>) {
        if (obj) {
            Object.assign(this, obj);
        }

        if (this.lines.length > 0) {
            this.lines = [];

            for (const line of obj.lines) {
                this.lines.push(new PurchaseLine(line));
            }
        }

        if (!this.currency) {
            this.currency = 'EUR';
        }
    }
}
