import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { footprintProductsServicePurchases, footprintTypeServicePurchases } from '@/main';
import { required } from 'vuelidate/lib/validators';
import Product from '@/models/CRM/Product';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import FootprintProduct from '@/models/Purchases/FootprintProduct';

@Component({
    validations: {
        localFootprintProduct: {
            name: { required },
            value: { required },
            footprintProductType: { required },
        },
    },
} as any)
export default class CreateOrEditFootprintProductComponent extends PageRender {
    @Prop({ default: () => new FootprintProduct() }) public footprintProduct: FootprintProduct;
    public localFootprintProduct: FootprintProduct = new FootprintProduct();
    public isEdit: boolean = false;

    public footprintProductTypesSelected: number = 0;
    public footprintProductTypes: any[] = [];

    public async created() {
        await Promise.all([this.loadFootprintTypes()]);

        this.localFootprintProduct = this.footprintProduct;
        this.isEdit = this.footprintProduct && this.footprintProduct.footprintProductId > 0;

        if (this.isEdit) {
            if (this.localFootprintProduct.footprintProductTypeId) {
                this.localFootprintProduct.footprintProductId = this.footprintProductTypes.find(
                    (x) => x.id === this.localFootprintProduct.footprintProductTypeId,
                );
            }
        }

        this.isLoading = false;
    }

    public async loadFootprintTypes() {
        const types = await footprintTypeServicePurchases.getFootprintTypes();
        this.footprintProductTypes = types.items;
    }

    public resetProduct() {
        this.localFootprintProduct = new FootprintProduct();
    }

    public async createFootprintProduct() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_PRODUCT_INVALID`);
            return;
        }

        this.showPending('CREATE_PRODUCT_PENDING');
        const [err, response] = await to(footprintProductsServicePurchases.createFootprintProduct(this.footprintProduct));
        if (err) {
            return this.clearAndShowError('CREATE_PRODUCT_FAILED', err);
        }

        const product = new Product(response.data);
        this.$emit('finished', product);
        this.clearNotifications();
    }

    public async updateFootprintProduct() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_PRODUCT_INVALID`);
            return;
        }

        this.showPending('UPDATE_PRODUCT_PENDING');
        const [err] = await to(footprintProductsServicePurchases.updateFootprintProduct(this.footprintProduct));
        if (err) {
            return this.clearAndShowError('UPDATE_PRODUCT_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_PRODUCT_SUCCESS');
        this.$emit('finished', this.footprintProduct);
        this.clearNotifications();
    }
}
