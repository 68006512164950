import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { subscriptionsService, ecosystemHelper, teamHelper, organizationsServiceCRM, countryService } from '@/main';
import Subscription from '@/models/CRM/Subscription';
import Vue from 'vue';
import to from 'await-to-js';
import moment from 'moment';
import { profileModule } from '@/store/modules/profile';
import Organization from '@/models/CRM/Organization';
import GridWrapperSearch from '@prd/shared-ui/src/components/Grid/models/GridWrapperSearch';
import { GridColumnProps } from '@progress/kendo-vue-grid';

@Component
export default class SubscriptionsComponent extends PageRender {
    public isEcoScope: boolean = true;
    public subscriptions: Subscription[] = [];
    public allSubscriptions: Subscription[] = [];
    public gridView: boolean = false;

    public subscription: Subscription = new Subscription();

    public newTeam: any = null;

    public selectedLanguage: string = 'NL';
    public selectedLanguageObj: any = null;
    public selectedDate: Date = new Date();
    public appliedTaxRate: any = null;
    public filterDate: boolean = true;
    public filterDateDays: number = 30;

    public subscriptionsColumns: GridColumnProps[] = [
        { field: 'id', title: 'Id', width: 75, editable: false },
        { field: 'name', title: 'Naam', editable: false },
        { field: 'owner', title: 'Eigenaar', editable: false },
        { field: 'organization.name', title: 'Organisatie', editable: false },
        { cell: this.renderSchedule, title: 'Schema', editable: false, width: 125 },
        { field: 'subscriptionDate', title: 'Einddatum', editable: false, cell: this.renderDate, width: 125 },
        { field: 'subscriptionValue', title: 'Waarde', editable: false, cell: this.renderValue, width: 150 },
        { field: 'inactive', title: 'Inactief', editable: false, cell: this.renderInactiveSubscriptionCell, width: 100 },
        { cell: this.renderActions, title: 'Actions', width: 100, sortable: false },
    ];

    public renderInactiveSubscriptionCell(h, _, row) {
        const subscription = new Subscription(row.dataItem);
        return h('td', [this.checkSubscriptionInactive(subscription) ? 'Yes' : 'No']);
    }

    public checkSubscriptionInactive(item: Subscription) {
        return (item.schedule && new Date(item.schedule.endDate) < new Date()) || item.schedule.startDate === null || (item.schedule && new Date(item.schedule.startDate) > new Date());
    }
    public renderInactiveSubscription(_h, tr, _row, item) {
        if (this.checkSubscriptionInactive(item.dataItem)) {
            tr.data.class += ' bg-warning';
        }
        return tr;
    }
    public search: GridWrapperSearch = new GridWrapperSearch({
        properties: ['clientReference', 'name', 'contact.firstName', 'contact.lastName', 'organization.name', 'owner'],
    });

    public async mounted() {
        this.isEcoScope = !this.$route.params.teamId;

        await Promise.all([this.loadSubscriptions(), this.loadStructure(), this.loadTaxRates()]);
        this.isLoading = false;
    }

    public async loadStructure() {
        this.structure = ecosystemHelper.structure;
    }

    public async loadSubscriptions() {
        this.allSubscriptions = this.subscriptions = await subscriptionsService.getSubscriptions(this.isEcoScope);
    }

    public async loadSubscription(subscriptionId) {
        const subscription = await subscriptionsService.getSubscription(subscriptionId);
        subscription.lines.forEach((x) => {
            if (x.department) {
                x.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === x.department.toLowerCase());
            }

            if (x.department && x.departmentObj && x.project) {
                x.projectObj = x.departmentObj.groups.find((y) => y.name.toLowerCase() === x.project.toLowerCase());
            }

            if (x.taxRateId) {
                x.taxRate = this.taxRates.find((y) => y.id === x.taxRateId);
            }
        });

        return (this.subscription = subscription);
    }

    public async goToSubscription(e) {
        const subscriptionId = e.dataItem ? e.dataItem.id : e.id;
        let params;

        if (this.isEcoScope) {
            const team = teamHelper.getTeam(e.dataItem.teamId);
            if (!team) {
                return this.showWarning(`You're not part of this team. Can't navigate you to this subscription`);
            }

            params = {
                subscriptionId,
                teamId: team.id,
                teamKey: team.key,
            };
        } else {
            params = { subscriptionId };
        }

        await this.$router.push({ name: 'crm-subscription', params });
    }

    public async goToAllSubscriptions() {
        await this.$router.push({ name: 'crm-all-subscriptions' });
    }

    public async createSubscription() {
        await this.$router.push({ name: 'crm-create-subscription' });
    }

    public async copySubscription(item: Subscription) {
        const [err, response] = await to(subscriptionsService.cloneSubscription(item));
        if (err) {
            this.clearAndShowError('CLONE_SUBSCRIPTION_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_SUBSCRIPTION_SUCCESS');
        const subscription = new Subscription(response.data);
        await this.$router.push({ name: 'crm-subscription', params: { subscriptionId: subscription.id.toString() } });
    }

    // public async moveSubscription() {
    //     const [err] = await to(subscriptionsService.moveSubscription(this._currentSubscriptionId, this.newTeam.id));
    //     if (err) {
    //         this.clearAndShowError('MOVE_SUBSCRIPTION_FAILED', err);
    //     }

    //     this.clearAndShowSuccess('MOVE_SUBSCRIPTION_SUCCESS');
    //     this.$refs.moveSubscriptionModal.hide();
    //     this.subscriptions = this.subscriptions.filter((x) => x.id !== this._currentSubscriptionId);
    // }

    // public async archiveSubscription(item: Subscription) {
    //     this.showPending('ARCHIVE_SUBSCRIPTION_PENDING');
    //     const [err] = await to(subscriptionsService.archiveSubscription(item));
    //     if (err) {
    //         this.clearAndShowError('ARCHIVE_SUBSCRIPTION_FAILED', err);
    //     }

    //     this.clearAndShowSuccess('ARCHIVE_SUBSCRIPTION_SUCCESS');
    //     this.subscriptions = this.subscriptions.filter((x) => x.id !== item.id);
    // }

    public renderDate(h, _, row) {
        if (!row.dataItem.schedule || !row.dataItem.schedule.endDate) {
            return h('td', '');
        }
        return h('td', moment(row.dataItem.schedule.endDate).format('DD-MM-YYYY'));
    }

    public renderSchedule(h, _, row) {
        if (!row.dataItem.schedule || !row.dataItem.schedule.endDate) {
            return h('td', '');
        }
        return h('td', row.dataItem.schedule.interval);
    }

    public renderValue(h, _, row) {
        const subscription = new Subscription(row.dataItem);
        return h('td', [Vue.filter('numberFormat')(subscription.subscriptionValue)]);
    }

    public get subscriptionActions() {
        const actions = [
            {
                title: 'Go to subscription',
                function: this.goToSubscription,
            },
            {
                title: 'Copy subscription',
                function: this.copySubscription,
            },
            // {
            //     title: 'Archive subscription',
            //     function: this.archiveSubscription,
            // },
        ];

        return actions;
    }

    public renderActions(h, _, row) {
        const actions = this.subscriptionActions;
        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async updateSubscription() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('UPDATE_SUBSCRIPTION_INVALID');
            return;
        }

        const taxRate = await organizationsServiceCRM.getTaxRate((this.subscription.organization as Organization).organizationId);

        this.subscription.lines.forEach((x) => {
            x.taxRateId = taxRate ? taxRate.taxRate : null;
        });

        if (this.subscription.invoiceDate) {
            this.subscription.invoiceDate = moment(this.subscription.invoiceDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00[Z]');
        }

        this.showPending('UPDATE_SUBSCRIPTION_PENDING');
        const [err] = await to(subscriptionsService.updateSubscription(this.subscription));
        if (err) {
            return this.clearAndShowError('UPDATE_SUBSCRIPTION_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_SUBSCRIPTION_SUCCESS');
    }

    public get languages() {
        return countryService.getLanguages();
    }

    public get otherTeams(): any[] {
        const profile = profileModule.brightProfile;
        if (!profile || !profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)) {
            return [];
        }

        return profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id && x.id !== teamHelper.currentTeam.id);
    }
}
