import FileUploader from '@/components/attachments/file-uploader.vue';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { inboxDocumentService } from '@/main';
import InboxDocument from '@/models/Costs/InboxDocument';
import to from 'await-to-js';
import { PaymentMethodOptions } from '@/models/PaymentMethod';

@Component
export default class DocumentPredictionsComponent extends SideActionBase<any> {
    public key: number = 0;
    public createdDocuments: InboxDocument[] = [];

    public $refs!: {
        fileUploader: FileUploader;
    };

    public async mounted() {
        this.isLoading = false;
    }

    public processing(file) {
        const document = this.createdDocuments[file.name];
        this.$refs.fileUploader.setEndpoint(`${Vue.$env().purchasesApiEndpoint}inbox-documents/${document.id}/attachments`);
    }

    public async upload() {
        this.showPending('PROCESSING_DOCUMENTS');
        const files = this.$refs.fileUploader.getQueuedFiles();

        for (let i = 0; i < files.length; i++) {
            await this.createNewDocument(files[i].name);
        }

        await this.$refs.fileUploader.processQueue();
    }

    public async handleError(file, _message, _xhr) {
        const document = this.createdDocuments[file.name];
        if (document) {
            await inboxDocumentService.deleteDocument(document.id);
        }
    }

    public async createNewDocument(name) {
        const [err, response] = await to(
            inboxDocumentService.createDocument(
                // tslint:disable-next-line: no-use-before-declare
                new InboxDocument({ name, documentType: DocumentType.Incoming, paymentMethod: PaymentMethodOptions[0] }),
            ),
        );
        if (err) {
            return this.showError('Failed to create document');
        }

        this.createdDocuments[name] = response.data;
    }

    public async submitDocuments() {
        for (const property in this.createdDocuments) {
            const document = this.createdDocuments[property];
            if (document && document.id) {
                await this.submitInboxDocument(document.id);
            }
        }

        this.clearAndShowSuccess('UPLOADS_DONE_AND_SUBMITTED_DOCUMENTS');
        this.finished();
    }

    public async submitInboxDocument(documentId: number) {
        const [errSubmit] = await to(inboxDocumentService.submitInboxDocument(documentId));
        if (errSubmit) {
            return this.clearAndShowError('SUBMIT_DOCUMENT_FAILED', errSubmit);
        }

        this.clearAndShowSuccess('DECLARATION_SUBMITTED');
    }
}

export enum DocumentType {
    Incoming = 'Incoming',
    Declaration = 'Declaration',
}
