import Component from 'vue-class-component';
import LegalEntity from '@/models/LegalEntity';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import TaxRate from '@/models/CRM/TaxRate';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        localLegalEntity: {
            name: { required },
            legalEntityReference: { required },
            invoiceNumberPrefix: { required },
        },
    },
} as any)
export default class LegalEntityPage extends SideActionBase<{ legalEntity: LegalEntity; exception: any }> {
    public selectedLegalEntity: { id: string; name: string } = null;
    public selectedTaxRate: TaxRate = null;
    public taxRates: TaxRate[] = [];

    public isEdit: boolean = false;

    public get legalEntities() {
        return legalEntityModule.all
            .filter((x) => x.legalEntityId !== this.options.legalEntity.legalEntityId)
            .map((legalEntity) => {
                return {
                    id: legalEntity.legalEntityId,
                    name: legalEntity.name,
                };
            });
    }

    public async created() {
        this.isEdit = this.options.exception;

        this.taxRates = await this.loadTaxRates();

        if (this.isEdit) {
            this.selectedLegalEntity = this.legalEntities.find((x) => x.id === this.options.exception.targetLegalEntityId);
            this.selectedTaxRate = this.taxRates.find((x) => x.id === this.options.exception.taxRateId);
        }
        this.isLoading = false;
    }

    public async save() {
        this.$emit('finished', {
            targetLegalEntityId: this.selectedLegalEntity.id,
            taxRateId: this.selectedTaxRate.id,
        });
    }
}
