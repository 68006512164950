import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridListComponent extends Vue {
    @Prop({ default: () => [] }) public elements: any;

    public elementsItems: any[] = [];

    public mounted() {
        if (this.elements) {
            this.elementsItems = this.elements;
        }
    }

    public get dropdownMembers() {
        return this.elementsItems;
    }

    public memberDropdownButtonTemplate() {
        return `<span class="icon-box mr-1"></span>${this.elements.length}`;
    }

    public memberDropdownTemplate(item) {
        return {
            template: Vue.component('grid-label-value'),
            templateArgs: { object: item },
        };
    }
}
