import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { purchasesService } from '@/main';
import { Purchase } from '@/models/Purchases/Purchase';

@Component
export default class PurchaseComponent extends PageRender {
    public purchase: Purchase = new Purchase();

    public async mounted() {
        const purchaseId = parseInt(this.$route.params.purchaseId);
        const teamId = parseInt(this.$route.params.teamId);

        await this.loadPurchase(purchaseId, teamId);

        if (this.purchase && !this.purchase.id) {
            await this.$router.push({ name: 'purchases' });
        }

        this.isLoading = false;
    }

    public async loadPurchase(purchaseId: number, teamId: number) {
        this.purchase = await purchasesService.getPurchase(purchaseId, teamId);
    }
}
