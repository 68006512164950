import PageRender from '../PageRender';

export default class ImportLedgersBase extends PageRender {
    public readonly delimiter: string = ';';
    public csvColumns: CsvColumn[] = [];
    public lineData: LedgerImportBase[] = [];

    public processInput(input: string, delimiter: string) {
        const properties = [];
        for (const prop of this.csvColumns) {
            properties.push(prop.label);
        }

        const lines = input.split('\n');
        const mapping = []; // Once to be used by a mailchimp style importer?

        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let i = 0; i < properties.length; i++) {
            const matchingColumn = headers.findIndex((h) => {
                return h.toLowerCase() === properties[i].toLowerCase();
            });
            if (matchingColumn === -1) {
                alert(`File's not matching the destination format. Missing column: ${properties[i]}`);
                break;
            } else {
                mapping.push({ index: matchingColumn, property: properties[i] });
            }
        }

        for (let li = 1; li < lines.length; li++) {
            // Skip first line
            if (lines[li] === null || lines[li] === '') {
                continue;
            }

            const columns = lines[li].replace('\r', '').split(delimiter);
            const itemLine = {} as LedgerImportBase;

            for (let m = 0; m < mapping.length; m++) {
                const currentMap = mapping[m];
                itemLine[currentMap.property] = columns[currentMap.index];
            }

            this.lineData.push(itemLine);
        }
    }

    public cancelImport(): void {
        this.$emit('cancel');
    }

    public downloadExample() {
        this.exportExampleCSV(this.csvColumns);
    }

    public fileUploaded(event) {
        // tslint:disable: no-use-before-declare
        this.loadCSV(event, new LedgerImportBase());
    }

    public exportExampleCSV(columns: CsvColumn[]): void {
        const properties = [];
        const values = [];
        const lines = [];
        for (const prop of columns) {
            values.push(prop.placeholder);
            properties.push(prop.label);
        }

        lines.push(properties.join(';'));
        lines.push(values.join(';'));

        const blob = new Blob([lines.join('\n')], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = 'ExampleImport.csv';
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    public loadCSV<T>(e: any, object: T): void {
        const self = this;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                self.processInput(csv, self.delimiter);
            };

            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert('Cannot read file !');
                }
            };

            reader.onloadend = () => {
                return object;
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }
}
// tslint:disable: max-classes-per-file
export class LedgerImportBase {
    public name: string = '';
    public value: string = '';
    public description: string = '';
}

// tslint:disable: max-classes-per-file
export class CsvColumn {
    public label: string = '';
    public placeholder: string = '';
}
