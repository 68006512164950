export default class Rule {
    public taxRateRuleId: number = null;
    public name: string = '';
    public taxRate: any = null;
    public isExEu: boolean = null;
    public needsVatNumber: boolean = null;
    public countryIso2: string = '';

    constructor(obj?: Partial<Rule>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
