import Vue from 'vue';
import { BaseService } from './baseService';
import Range from '@/models/Finance/Api/Range';
import { to } from 'await-to-js';
import FootprintProduct from '@/models/Purchases/FootprintProduct';

export default class FootprintProductsServicePurchases extends BaseService {
    private endpoint = `${Vue.$env().purchasesApiEndpoint}footprints-products`;

    public async getFootprintProducts(): Promise<Range<FootprintProduct>> {
        const [err, response] = await to(this.get(`${this.endpoint}`));

        if (err) {
            this.clearAndShowError(`LOAD_PRODUCTS_FAILED`, err);
            return new Range<FootprintProduct>();
        }

        return new Range<FootprintProduct>(response.data);
    }

    public async getFootprintProduct(productId: number): Promise<FootprintProduct> {
        const [err, response] = await to(this.get(`${this.endpoint}/:productId`, [['productId', productId]]));

        if (err) {
            this.clearAndShowError(`LOAD_PRODUCT_FAILED`, err);
            return new FootprintProduct();
        }

        return new FootprintProduct(response.data);
    }

    public createFootprintProduct(footprintProduct: FootprintProduct) {
        const model = {
            name: footprintProduct.name,
            value: parseFloat(footprintProduct.value.toString()),
            footprintProductType:
                footprintProduct.footprintType !== null && typeof footprintProduct.footprintType !== 'undefined'
                    ? footprintProduct.footprintType.footprintTypeId
                    : null,
        };

        return this.post(this.endpoint, model);
    }

    public updateFootprintProduct(footprintProduct: FootprintProduct) {
        const model = {
            name: footprintProduct.name,
            value: parseFloat(footprintProduct.value.toString()),
            FootprintProductType:
                footprintProduct.footprintType !== null && typeof footprintProduct.footprintType !== 'undefined'
                    ? footprintProduct.footprintType.footprintTypeId
                    : null,
            footprintProductId: footprintProduct.footprintProductId,
        };

        return this.put(`${this.endpoint}/${model.footprintProductId}`, model);
    }

    public archiveFootprintProduct(footprintProduct: FootprintProduct) {
        return this.delete(`${this.endpoint}/${footprintProduct.footprintProductId}`);
    }
}
