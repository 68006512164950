export class Customer {
    public id: number = 0;
    public customerId: number = 0;
    public name: string;
    public emailAddress: string;
    public vatNumber: string = '';
    public cocNumber: string = '';
    public addressLineOne: string = '';
    public addressLineTwo: string = '';
    public zipCode: string = '';
    public city: string = '';
    public state: string = '';
    public countryIso2: string = '';
    public iban: string = '';

    constructor(obj?: Partial<Customer>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
