export default class PaymentMethod {
    public id = null;
    public name = '';

    constructor(obj?: Partial<PaymentMethod>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export const PaymentMethodOptions = [
    { id: 1, name: 'Private paid in advance' },
    { id: 2, name: 'Still to be paid via bank' },
    { id: 3, name: 'Creditcard' },
    { id: 4, name: 'Direct debit' },
];
