import Vue from 'vue';
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid';
import DatePicker from 'vue2-datepicker';
import { Multiselect } from 'vue-multiselect';
import { Popup } from '@progress/kendo-vue-popup';
import { DropDownList } from '@progress/kendo-vue-dropdowns';

Vue.component('multiselect', Multiselect);
Vue.component('date-picker', DatePicker);
Vue.component('split', require('@/components/split/split.vue').default);

// Components
Vue.component('grid', Grid);
Vue.component('grid-toolbar', GridToolbar);
Vue.component('grid-norecords', GridNoRecords);

Vue.component('kendo-popup', Popup);
Vue.component('kendo-dropdownlist', DropDownList);

Vue.component('navigation-bar', require('@/components/navigation/bar.vue').default);
Vue.component('navigation-breadcrumb', require('@/components/navigation/breadcrumb.vue').default);
Vue.component('navigation-profile', require('@/components/navigation/profile.vue').default);
Vue.component('mobile-switcher', require('@/components/navigation/mobile-switcher.vue').default);
Vue.component('navigation-team', require('@/components/navigation/team.vue').default);
Vue.component('bright-apps-navigation', require('@/components/navigation/apps.vue').default);

Vue.component('grid-percentage', require('@/components/grid/grid-percentage.vue').default);
Vue.component('grid-popover-element', require('@/components/grid/grid-popover-element.vue').default);
Vue.component('grid-router-link', require('@/components/grid/grid-router-link.vue').default);
Vue.component('grid-actions', require('@/components/grid/grid-actions.vue').default);
Vue.component('grid-numeric-editor', require('@/components/grid/grid-numeric-editor.vue').default);
Vue.component('grid-amount-left-filter', require('@/components/grid/grid-amount-left-filter.vue').default);
Vue.component('grid-html-renderer', require('@/components/grid/grid-html-renderer.vue').default);
Vue.component('grid-list', require('@/components/grid/grid-list.vue').default);
Vue.component('grid-label-value', require('@/components/grid/grid-label-value.vue').default);
Vue.component('grid-filter-multi', require('@/components/grid/grid-filter-multi-select.vue').default);
Vue.component('grid-period-range-filter', require('@/components/grid/grid-period-range-filter.vue').default);

Vue.component('number-formatter', require('@/components/formatting/number-formatter').default);

Vue.component('create-ledger-account-modal', require('@/components/modals/create-ledger-account-modal.vue').default);
Vue.component('generic-search', require('@/components/generic-search/generic-search.vue').default);

Vue.component('create-organization-side-action', require('@/components/side-actions/organization-action/create-organization-action.vue').default);
Vue.component('merge-organizations-side-action', require('@/components/side-actions/organization-action/merge-organizations-side-action.vue').default);
Vue.component('create-contact-side-action', require('@/components/side-actions/contact-action/create-contact-action.vue').default);
Vue.component('add-contact-side-action', require('@/components/side-actions/contact-action/add-contact-action.vue').default);
Vue.component('create-product-side-action', require('@/components/side-actions/product-action/create-product-action.vue').default);
Vue.component(
    'create-footprint-side-action',
    require('@/components/side-actions/footprint-product-action/create-footprint-product-action.vue').default,
);
Vue.component('crm-create-rule-side-action', require('@/components/side-actions/rule-action/create-rule-action.vue').default);
Vue.component('create-ledger-side-action', require('@/components/side-actions/ledger-action/create-ledger-action.vue').default);
Vue.component('upload-attachment-side-action', require('@/components/side-actions/attachment-action/upload-attachment-action.vue').default);
Vue.component(
    'create-or-edit-legal-entity-invoice-data',
    require('@/components/side-actions/legal-entities-action/create-or-edit-legal-entity-invoice-data.vue').default,
);
Vue.component(
    'create-or-edit-legal-entity-action',
    require('@/components/side-actions/legal-entities-action/create-or-edit-legal-entity-action.vue').default,
);
Vue.component(
    'add-charge-exceptions-side-action',
    require('@/components/side-actions/legal-entities-action/add-charge-exceptions-side-action.vue').default,
);
Vue.component('co2-calculator-side-action', require('@/components/side-actions/co2/co2-calculator-side-action.vue').default);
Vue.component('preview-attachment-action', require('@/components/side-actions/attachment-action/preview-attachment-action.vue').default);
Vue.component('edit-purchase-line-side-action', require('@/components/side-actions/purchase-action/edit-purchase-line-action.vue').default);
Vue.component('upload-purchase-xml-side-action', require('@/components/side-actions/purchase-action/upload-purchase-xml-side-action.vue').default);
Vue.component('prediction-lines-side-action', require('@/components/side-actions/document-action/prediction-lines-side-action.vue').default);
Vue.component(
    'prediction-organization-side-action',
    require('@/components/side-actions/document-action/prediction-organization-side-action.vue').default,
);
Vue.component('multi-upload-side-action', require('@/components/side-actions/document-action/multi-upload-side-action.vue').default);
Vue.component('document-upload-action', require('@/components/side-actions/document-action/document-upload-action.vue').default);
Vue.component(
    'coc-data-suggest-result-side-action',
    require('@/components/side-actions/organization-action/coc-data-suggest-result-side-action.vue').default,
);
Vue.component('import-ledgers-side-action', require('@/components/side-actions/ledger-action/import-ledgers-side-action.vue').default);
Vue.component('split-deal-action', require('@/components/side-actions/deal-action/split-deal-action.vue').default);
Vue.component('mail-log-side-action', require('@/components/side-actions/mail-log-action/mail-log-side-action.vue').default);
Vue.component('connect-product-to-org-side-action', require('@/components/side-actions/product-action/connect-product-to-org-action.vue').default);
Vue.component('merge-contacts-side-action', require('@/components/side-actions/contact-action/merge-contacts-side-action.vue').default);

Vue.component('create-or-edit-deal', require('@/pages/crm/deals/create-or-edit-deal.vue').default);
Vue.component('create-or-edit-subscription', require('@/pages/crm/subscriptions/create-or-edit-subscription.vue').default);
Vue.component('crm-create-or-edit-organization', require('@/pages/crm/organizations/create-or-edit-organization.vue').default);
Vue.component('crm-merge-organizations', require('@/pages/crm/organizations/merge-organizations.vue').default);
Vue.component('crm-create-or-edit-product', require('@/pages/crm/products/create-or-edit-product.vue').default);
Vue.component('crm-create-or-edit-rule', require('@/pages/crm/rules/create-or-edit-rule.vue').default);
Vue.component('crm-create-or-edit-ledger', require('@/pages/crm/ledgers/create-or-edit-ledger.vue').default);
Vue.component('create-or-edit-contact', require('@/pages/crm/contacts/create-or-edit-contact.vue').default);
Vue.component('crm-import-ledgers', require('@/pages/crm/ledgers/import-ledgers.vue').default);

Vue.component('create-or-edit-purchase', require('@/pages/purchases/purchases/create-or-edit-purchase.vue').default);
Vue.component('purchases-edit-line', require('@/pages/purchases/purchases/lines/edit-line.vue').default);
Vue.component('purchases-create-or-edit-organization', require('@/pages/purchases/organizations/create-or-edit-organization.vue').default);
Vue.component('purchases-merge-organizations', require('@/pages/purchases/organizations/merge-organizations.vue').default);
Vue.component('purchases-create-or-edit-document', require('@/pages/purchases/purchases/create-or-edit-document.vue').default);
Vue.component('purchases-create-or-edit-product', require('@/pages/purchases/products/create-or-edit-product.vue').default);
Vue.component('purchases-create-or-edit-footprint', require('@/pages/purchases/footprint/create-or-edit-footprint.vue').default);
Vue.component('purchases-create-or-edit-ledger', require('@/pages/purchases/ledgers/create-or-edit-ledger.vue').default);
Vue.component('purchases-import-ledgers', require('@/pages/purchases/ledgers/import-ledgers.vue').default);

Vue.component('legal-entity-selector', require('@/components/legal-entity/legal-entity-selector.vue').default);
Vue.component('invoice-detail', require('@/components/invoice/invoice-detail.vue').default);
Vue.component('file-uploader', require('@/components/attachments/file-uploader.vue').default);
Vue.component('logo-uploader', require('@/components/attachments/logo-uploader.vue').default);
Vue.component('file-preview', require('@/components/attachments/preview.vue').default);

Vue.component('ecosystem-switcher-modal', require('@/components/modals/ecosystem-switcher-modal.vue').default);
Vue.component('deal-to-invoice-modal', require('@/components/modals/deal-to-invoice-modal.vue').default);
Vue.component('reminder-deal-modal', require('@/components/modals/send-reminder-deal-modal.vue').default);
Vue.component('pending-approvals-modal', require('@/components/modals/pending-approvals-modal.vue').default);
Vue.component('decline-approval-modal', require('@/components/modals/decline-approval-modal.vue').default);

// Translate
import '@/components/translate/directive';
Vue.component('prd-translate-text', require('@/components/translate/text.vue').default);
Vue.component('prd-translate-text-inline', require('@/components/translate/text-inline.vue').default);
Vue.component('prd-translate-button', require('@/components/translate/button.vue').default);
