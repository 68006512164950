import Ledger from '../Finance/Ledger';
import FootprintProduct from '../Purchases/FootprintProduct';

export default class Product {
    public productId: number = null;
    public name: string = '';
    public price: number = null;
    public ledger: Ledger = null;
    public taxRate: any = null;
    public taxRateId: string = '';

    public footprintProducts: FootprintProduct[] = [];
    public connectedActivities: ProductActivity[] = [];

    constructor(obj?: Partial<Product>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class ProductActivity {
    public productActivityId: number = null;
    public productId: number = null;
    public teamObj: { id: number; name: string; activities: any[] } = null;
    public teamName: string = '';
    public activityName: string = '';
    public activityObj: { id: number; name: string } = null;

    public constructor(obj?: Partial<ProductActivity>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

