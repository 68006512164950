import { UserRoleInterface } from '../models/Interfaces';

export enum UserRoles {
    SiteAdmin = 'BE_SiteAdmin',
    FinanceEmployee = 'FinanceEmployee',
    BEFinanceEmployee = 'BE_FinanceEmployee',
    Member = 'BE_Member',
}

export default class UserRole implements UserRoleInterface {
    public id: number;
    public role: UserRoles;
}
