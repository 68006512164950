import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { ecosystemHelper, navigationHelper, teamHelper } from '@/main';
import { profileModule } from '@/store/modules/profile';
import invoiceRoutes from './router-invoices';
import purchaseRoutes from './router-purchases';
import crmRoutes from './router-crm';
import costRoutes from './router-costs';

Vue.use(VueRouter);

const routes = [
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '../pages/account/login/login.vue'),
        meta: { title: 'Login' },
    },
    {
        name: 'payment',
        path: '/payments/:secret/:transactionId',
        component: () => import(/* webpackChunkName: "payment" */ '../pages/purchases/payment/payment.vue'),
        meta: { title: 'Payment' },
    },
    {
        name: 'payment-status',
        path: '/payments/:secret/:transactionId/status',
        component: () => import(/* webpackChunkName: "payment-status" */ '../pages/purchases/payment/status.vue'),
        meta: { title: 'Payment status' },
    },
    {
        name: 'mobile-base',
        path: '/mobile-costs/:ecosystemId(\\d+)-:ecosystemKey/',
        component: () => import(/* webpackChunkName: 'mobile' */ '../pages/MobileBase.vue'),
        children: [
            {
                name: 'mobile-create-declaration',
                path: 'declarations/:documentId?',
                component: require('@/pages/costs/declarations/create-or-edit-declaration.vue').default,
                meta: {
                    title: 'Document',
                    scope: 'document',
                    section: 'declarations',
                    app: 'costs',
                    breadcrumb: {
                        text: 'NAV_DOCUMENT',
                        items: ['approvals', 'declarations'],
                    },
                },
            },
        ],
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: 'menu' */ '../pages/Menu.vue'),
        children: [
            {
                name: 'home',
                path: '',
                component: require('@/pages/home/home.vue').default,
                meta: { title: 'Paperless' },
            },
            ...crmRoutes,
            ...purchaseRoutes,
            ...invoiceRoutes,
            ...costRoutes,
            {
                name: 'app-error',
                path: '/error',
                component: require('@/pages/error/error.vue').default,
                meta: { title: 'Error Page' },
            },
            {
                name: 'not-found',
                path: '*',
                component: require('@/pages/notfound/notfound.vue').default,
                meta: { title: 'Woops!?' },
            },
            {
                name: 'settings-base',
                path: '/settings',
                redirect: '/settings/translate',
            },
            {
                name: 'settings-translate',
                path: '/settings/translate',
                component: require('@/pages/settings/translate.vue').default,
                meta: { title: 'Settings', scope: 'settings' },
            },
        ],
    },
];

// tslint:disable-next-line:no-unused-variable
const ifAuthenticated = async (to: Route, from: Route, next: any) => {
    const anonymousRoutes = ['login', 'app-error', 'not-found', 'invite', 'payment', 'payment-status'];
    await profileModule.init();
    const loggedIn = profileModule.isLoggedIn;
    const matchedRoute = to.matched.length > 0 ? to.matched[to.matched.length - 1] : to;
    const matchedRouteName = matchedRoute && matchedRoute.name ? matchedRoute.name : '';

    if (loggedIn && !profileModule.isPaperlessUser && matchedRoute.meta.app !== 'costs') {
        await profileModule.loadBrightProfileIfNeeded();

        if (!ecosystemHelper.setupIsDone) {
            await ecosystemHelper.setup();
        }
        if (!teamHelper.setupIsDone) {
            await teamHelper.setup();
        }

        next({
            name: 'approvals',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
            },
        });
    }

    if (anonymousRoutes.indexOf(matchedRouteName) > -1 || loggedIn) {
        next();
        return;
    }

    next(`/login?redirectUrl=${to.fullPath}`);
};

const mobilePreference = async (to: Route, from: Route, next: any) => {
    const isMobilePage = to.name.indexOf('mobile') === 0;
    const mobilePreferred = localStorage.getItem('mobilePreferred');

    if (mobilePreferred === 'true' && !isMobilePage) {
        const mobileName = `mobile-${to.name}`;
        if (routeExists(mobileName)) {
            next({
                name: `mobile-${to.name}`,
                params: to.params,
            });
        }
    } else if (isMobilePage && (!mobilePreferred || mobilePreferred === 'false')) {
        const nonMobileRouteName = to.name.replace('mobile-', '');
        next({
            name: nonMobileRouteName,
            params: to.params,
        });
    }

    next();
};

const routeExists = (routeName) => {
    const link = router.resolve({
        name: routeName,
    });
    if (link && link.href !== '/') {
        return true;
    }
    return false;
};

const onRouteFinished = (to: Route) => {
    const meta = to.meta || {};
    const params = to.params || {};

    let title = 'Bright Paperless';

    if (params.team) {
        title += ` - ${params.team}`;
    }

    if (meta.title) {
        title += ` - ${meta.title}`;
    }

    Vue.nextTick(() => {
        document.title = title;
        navigationHelper.hideNavigation();
        navigationHelper.loadRouteMeta(meta, params);
    });
};

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(ifAuthenticated);
router.beforeEach(mobilePreference);
router.afterEach(onRouteFinished);

export default router;
