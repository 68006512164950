export default class MailContent {
    public id: number;
    public body: string;
    public masterTemplate;
    public subject: string;

    constructor(obj?: Partial<MailContent>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
