export class PurchaseState {
    public purchaseStateId: number;
    public name: string = 'Unknown';
    public order: number = 0;
}

export enum PurchaseStateEnum {
    Concept = 1,
    Booked = 2,
    Lead = 3,
    ApprovalRequested = 4,
    Declined = 5,
};
