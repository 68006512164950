import { organizationsServicePurchases } from '@/main';
import PurchaseOrganizationSettings from '@/models/Organizations/PurchaseOrganizationSettings';
import PageRender from '@/models/PageRender';
import { PaymentMethodOptions } from '@/models/PaymentMethod';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OrganizationSettingsTab extends PageRender {
    @Prop({ required: true }) public organizationId: number;
    public settings = new PurchaseOrganizationSettings({
        aliases: ['Alias 1'],
    });

    public async mounted() {
        this.settings = await organizationsServicePurchases.getSettings(this.organizationId);
    }

    public get paymentOptions() {
        return PaymentMethodOptions;
    }

    public addAlias() {
        this.settings.aliases.push('');
    }

    public updateAlias(index: number, value: string) {
        this.settings.aliases[index] = value;
    }

    public removeAlias(index: number) {
        this.settings.aliases.splice(index, 1);
    }

    public async saveSettings() {
        const [err] = await to(organizationsServicePurchases.saveSettings(this.organizationId, this.settings));
        if (err) {
            return this.clearAndShowWarning('Failed to save organization settings. Probably an alias is already used on another organization');
        }
        this.clearAndShowSuccess('UPDATE_ORGANIZATION_SUCCESS');
    }
}
