import { appHelper } from '@/main';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class PurchaseLineActionComponent extends SideActionBase<any> {
    public get componentName() {
        return `${appHelper.isCrm() ? 'crm' : 'purchases'}-edit-line`;
    }

    public savedPurchaseLine(e): void {
        this.finished(e);
    }
}
