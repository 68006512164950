import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { countryService, dealsService, ecosystemHelper } from '@/main';
import { required } from 'vuelidate/lib/validators';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import Rule from '@/models/CRM/Rule';
import Structure from '@/models/Ecosystem/Structure';

@Component({
    validations: {
        localRule: {
            name: { required },
            taxRate: { required },
        },
    },
} as any)
export default class CreateOrEditRuleComponent extends PageRender {
    @Prop({ default: () => new Rule() }) public rule: Rule;
    public localRule: Rule = null;
    public isEdit: boolean = false;

    public selectedCountryIso2: any = { id: 'NL' };
    public selectedTaxRate: any = null;

    public async created() {
        await this.loadTaxRates();

        this.localRule = this.rule;
        this.isEdit = this.rule && this.rule.taxRateRuleId > 0;

        if (this.isEdit) {
            this.selectedCountryIso2 = this.countries.filter((x) => x.id === this.rule.countryIso2);
            this.selectedTaxRate = this.taxRates.find((x) => x.id === this.rule.taxRate);
        }

        this.isLoading = false;
    }

    public resetProduct() {
        this.localRule = new Rule();
    }

    public async loadStructure() {
        this.structure = ecosystemHelper.structure;
    }

    public getTaxRates() {
        return [];
    }

    public selectTaxRate(taxRate) {
        this.selectedTaxRate = taxRate;
    }

    public get countries() {
        const countries = countryService.getCountries();
        const list = Object.keys(countries[0]).map((x) => {
            return {
                id: x,
                name: countries[0][x].name,
            };
        });

        return list;
    }

    public selectCountry(country) {
        this.selectedCountryIso2 = country;
        this.localRule.countryIso2 = country.id;

        const countries = countryService.getCountries();
        this.localRule.isExEu = countries[0][this.selectedCountryIso2.id].continent !== 'EU';
    }

    public async createRule() {
        this.submitted = true;
        this.localRule.taxRate = this.selectedTaxRate;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_RULE_INVALID`);
            return;
        }

        this.showPending('CREATE_RULE_PENDING');
        const [err, response] = await to(dealsService.createRule(this.localRule));
        if (err) {
            return this.clearAndShowError('CREATE_RULE_FAILED', err);
        }

        const rule = new Rule(response.data);

        this.$emit('finished', rule);
        this.clearNotifications();
    }

    public async updateRule() {
        this.submitted = true;
        this.localRule.taxRate = this.selectedTaxRate;

        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_RULE_INVALID`);
            return;
        }

        this.showPending('UPDATE_RULE_PENDING');
        const [err] = await to(dealsService.updateRule(this.localRule));
        if (err) {
            return this.clearAndShowError('UPDATE_RULE_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_RULE_SUCCESS');

        this.$emit('finished', this.rule);
    }
}
