import Base from '@/pages/Base.vue';

export default [
    {
        name: 'base',
        path: '/crm/:ecosystemId(\\d+)-:ecosystemKey/',
        component: Base,
        children: [
            {
                name: 'crm-all-deals',
                path: 'deals/',
                component: require('@/pages/crm/deals/deals.vue').default,
                meta: {
                    title: 'Deals',
                    scope: 'deals',
                    section: 'deals',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_ECO_DEALS',
                        items: ['crm-deals'],
                    },
                },
            },
            {
                name: 'crm-deals',
                path: ':teamId(\\d+)-:teamKey/deals/',
                component: require('@/pages/crm/deals/deals.vue').default,
                meta: {
                    title: 'Deals',
                    scope: 'deals',
                    section: 'deals',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_DEALS',
                        items: ['crm-create-deal', 'crm-all-deals'],
                    },
                },
            },
            {
                name: 'crm-create-deal',
                path: ':teamId(\\d+)-:teamKey/deals/new',
                component: require('@/pages/crm/deals/create.vue').default,
                meta: {
                    title: 'New deal',
                    scope: 'deals',
                    section: 'deals',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_CREATE_DEAL',
                        items: ['crm-deals'],
                    },
                },
            },
            {
                name: 'crm-deal',
                path: ':teamId(\\d+)-:teamKey/deals/:dealId',
                component: require('@/pages/crm/deals/deal.vue').default,
                meta: {
                    title: 'Deals',
                    scope: 'deals',
                    app: 'crm',
                    section: 'deals',
                    breadcrumb: {
                        text: 'NAV_DEAL',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'crm-products',
                path: 'products/',
                component: require('@/pages/crm/products/products.vue').default,
                meta: {
                    title: 'Products',
                    scope: 'products',
                    app: 'crm',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_PRODUCTS',
                        items: ['crm-create-product'],
                    },
                },
            },
            {
                name: 'crm-create-product',
                path: 'product/new',
                component: require('@/pages/crm/products/create.vue').default,
                meta: {
                    title: 'New product',
                    scope: 'products',
                    app: 'crm',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_CREATE_PRODUCT',
                        items: ['crm-products'],
                    },
                },
            },
            {
                name: 'crm-product',
                path: 'products/:productId',
                component: require('@/pages/crm/products/product.vue').default,
                meta: {
                    title: 'Product',
                    scope: 'product',
                    app: 'crm',
                    section: 'products',
                    breadcrumb: {
                        text: 'NAV_PRODUCT',
                        items: ['crm-products'],
                    },
                },
            },
            {
                name: 'crm-contacts',
                path: 'contacts/',
                component: require('@/pages/crm/contacts/contacts.vue').default,
                meta: {
                    title: 'Contacts',
                    scope: 'contacts',
                    app: 'crm',
                    section: 'contacts',
                    breadcrumb: {
                        text: 'NAV_CONTACTS',
                        items: ['crm-create-contact'],
                    },
                },
            },
            {
                name: 'crm-contact',
                path: 'contacts/:contactId',
                component: require('@/pages/crm/contacts/contact.vue').default,
                meta: {
                    title: 'Contact',
                    scope: 'contact',
                    app: 'crm',
                    section: 'contact',
                    breadcrumb: {
                        text: 'NAV_CONTACT',
                        items: ['crm-create-contact'],
                    },
                },
            },
            {
                name: 'crm-organizations',
                path: 'organizations/',
                component: require('@/pages/crm/organizations/organizations.vue').default,
                meta: {
                    title: 'Organizations',
                    scope: 'organizations',
                    app: 'crm',
                    section: 'organizations',
                    breadcrumb: {
                        text: 'NAV_ORGANIZATIONS',
                        items: ['crm-create-organization'],
                    },
                },
            },
            {
                name: 'crm-create-organization',
                path: 'organizations/new',
                component: require('@/pages/crm/organizations/create.vue').default,
                meta: {
                    title: 'New organization',
                    scope: 'organizations',
                    app: 'crm',
                    section: 'organizations',
                    breadcrumb: {
                        text: 'NAV_CREATE_ORGANIZATIONS',
                        items: ['crm-organizations'],
                    },
                },
            },
            {
                name: 'crm-rules',
                path: 'rules/',
                component: require('@/pages/crm/rules/rules.vue').default,
                meta: {
                    title: 'Rules',
                    scope: 'rules',
                    app: 'crm',
                    section: 'rules',
                    breadcrumb: {
                        text: 'NAV_RULES',
                        items: [],
                    },
                },
            },
            {
                name: 'crm-team-settings',
                path: ':teamId(\\d+)-:teamKey/settings',
                component: require('@/pages/crm/team-settings/create-or-edit-team-settings.vue').default,
                meta: {
                    title: 'Settings',
                    scope: 'team-settings',
                    app: 'crm',
                    section: 'team-settings',
                    breadcrumb: {
                        text: 'NAV_TEAM_SETTINGS',
                        items: [],
                    },
                },
            },
            {
                name: 'crm-all-subscriptions',
                path: 'subscriptions/',
                component: require('@/pages/crm/subscriptions/subscriptions.vue').default,
                meta: {
                    title: 'Subscriptions',
                    scope: 'subscriptions',
                    section: 'subscriptions',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_ECO_SUBSCRIPTIONS',
                        items: ['crm-subscriptions'],
                    },
                },
            },
            {
                name: 'crm-subscriptions',
                path: ':teamId(\\d+)-:teamKey/subscriptions/',
                component: require('@/pages/crm/subscriptions/subscriptions.vue').default,
                meta: {
                    title: 'Subscriptions',
                    scope: 'subscriptions',
                    app: 'crm',
                    section: 'subscriptions',
                    breadcrumb: {
                        text: 'NAV_SUBSCRIPTIONS',
                        items: [],
                    },
                },
            },
            {
                name: 'crm-create-subscription',
                path: ':teamId(\\d+)-:teamKey/subscriptions/new',
                component: require('@/pages/crm/subscriptions/create.vue').default,
                meta: {
                    title: 'New subscription',
                    scope: 'subscriptions',
                    section: 'subscriptions',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_CREATE_SUBSCRIPTION',
                        items: ['crm-subscriptions'],
                    },
                },
            },
            {
                name: 'crm-subscription',
                path: ':teamId(\\d+)-:teamKey/subscriptions/:subscriptionId',
                component: require('@/pages/crm/subscriptions/subscription.vue').default,
                meta: {
                    title: 'Subscriptions',
                    scope: 'subscription',
                    app: 'crm',
                    section: 'subscription',
                    breadcrumb: {
                        text: 'NAV_SUBSCRIPTION',
                    },
                },
            },
            {
                name: 'crm-ledgers',
                path: 'ledgers/',
                component: require('@/pages/crm/ledgers/ledgers.vue').default,
                meta: {
                    title: 'Ledgers',
                    scope: 'ledgers',
                    app: 'crm',
                    section: 'ledgers',
                    breadcrumb: {
                        text: 'NAV_LEDGERS',
                        items: ['crm-create-ledger'],
                    },
                },
            },
        ],
    },
    {
        name: 'organization-base',
        path: '/crm/:ecosystemId(\\d+)-:ecosystemKey/organizations/:organizationId',
        component: Base,
        children: [
            {
                name: 'crm-organization',
                path: '/',
                component: require('@/pages/crm/organizations/organization.vue').default,
                meta: {
                    title: 'Organization',
                    scope: 'organizations',
                    app: 'crm',
                    section: 'overview',
                    breadcrumb: {
                        text: 'NAV_ORGANIZATION',
                        items: ['ecosystem-directory', 'ecosystem-finances', 'ecosystem-graph', 'ecosystem-settings'],
                    },
                },
            },
            {
                name: 'crm-create-contact',
                path: 'contacts/new',
                component: require('@/pages/crm/contacts/create.vue').default,
                meta: {
                    title: 'New contact',
                    scope: 'organizations',
                    app: 'crm',
                    section: 'overview',
                    breadcrumb: {
                        text: 'NAV_CREATE_CONTACT',
                        items: [],
                    },
                },
            },
            {
                name: 'crm-org-contact',
                path: 'contacts/:contactId',
                component: require('@/pages/crm/contacts/contact.vue').default,
                meta: {
                    title: 'Organization',
                    scope: 'organizations',
                    section: 'overview',
                    app: 'crm',
                    breadcrumb: {
                        text: 'NAV_CONTACT',
                        items: [],
                    },
                },
            },
        ],
    },
];
