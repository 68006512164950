import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class DeclineApprovalsModal extends BaseModal {
    @Prop({ default: null }) public id: number;
    @Prop({ default: null }) public declineFunction: (id: number, reason: string) => CallableFunction;
    public reason: string = '';
    private _id: number = 0;

    public mounted() {
        if (this.id) {
            this.setId(this.id);
        }
    }

    public onHide() {
        this._id = 0;
        this.reason = '';
    }

    public setId(id: number) {
        this._id = id;
    }

    public async decline() {
        this.declineFunction(this._id, this.reason);
        this.hide();
    }
}
