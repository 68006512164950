import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';

export default class ApplicationService extends BaseService {
    private endpoint = `${Vue.$env().myBrightEndpoint}`;

    public async getApplications(): Promise<Application[]> {
        const [err, response] = await to(this.get(`${this.endpoint}applications`));

        if (err) {
            this.clearAndShowError(`LOAD_APPLICATIONS_FAILED`, err);
            return [];
        }

        return response.data;
    }
}

export class Application {
    public name: string;
    public iconUrl: string;
    public url: string;
}
