import Project from '@/models/Project';
import Product, { ProductActivity } from './Product';
import TaxRate from './TaxRate';

export class DealLine {
    public description: string = null;
    public amount: number | string = 1;
    public price: number = null;
    public inclPrice: number = null;
    public products: Product[] = [];
    public product: Product = null;
    public productId: number = null;
    public department: string = null;
    public departmentObj: any = null;
    public project: string = null;
    public projectObj: Project = null;
    public open: boolean = false;
    public taxRate: TaxRate = null;
    public taxRateId: string = '';
    public attachmentId: string;
    public lineNumber: number = null;
    public connectedActivity: string | ProductActivity = null;

    public metaData: MetaData[] = [];
    public projects: Project[] = [];

    constructor(obj?: Partial<DealLine>) {
        if (obj) {
            Object.assign(this, obj);

            // if (obj.product) {
            //     this.product = new Product(obj.product);
            // }
        }
    }
}

// tslint:disable max-classes-per-file
export class MetaData {
    public amount: number = 0;
    public footPrintTypeId: number;
    public label: string;
    public name: string;
}

export class Schedule {
    public dayOfMonth: number;
    public monthOfYear: number;
    public endDate: string;
    public interval: string;
    public startDate: string;
}
