import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { subscriptionsService, organizationsServiceCRM, productsServiceCRM, ecosystemHelper, teamHelper, templateService } from '@/main';
import Subscription from '@/models/CRM/Subscription';
import { DealLine } from '@/models/CRM/DealLine';
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import Product from '@/models/CRM/Product';
import Vue from 'vue';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { Person } from '@/models/Person';
import { profileModule } from '@/store/modules/profile';
import moment from 'moment';
import { Template } from '@/models/CRM/Template';
import { settingsModule } from '@/store/modules/settings';

@Component({
    validations: {
        selectedStartDate: { required },
        selectedEndDate: { required },
        subscription: {
            name: { required },
            organization: { required },
            contact: { required },
            schedule: {
                dayOfMonth: { required },
                monthOfYear: {
                    requiredIf: requiredIf((form) => {
                        return form.interval === 'Yearly';
                    }),
                    minValue: minValue(1),
                    maxValue: maxValue(12),
                },
                interval: { required },
            },
            lines: {
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                        productId: { minValue: minValue(1) },
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
    },
} as any)
export default class CreateSubscriptionComponent extends PageRender {
    @Prop({ default: () => new Subscription() }) public subscription: Subscription;
    public organizations: Organization[] = [];
    public contacts: Contact[] = [];
    public products: Product[] = [];
    public isEdit: boolean = false;
    public organizationSelected: number = 0;
    public contactSelected: number = 0;
    public administrativeContactSelected: number = 100;
    public tableRerender: number = 0;
    public selectedLanguage: string = 'NL';
    public templates: Template[] = [];

    public availableCurrencies = [
        { text: 'Euro', value: 'EUR' },
        { text: 'US Dollar', value: 'USD' },
        { text: 'Pound sterling', value: 'GBP' },
        { text: 'Saudi riyal', value: 'SAR' },
        { text: 'Swiss franc', value: 'CHF' },
    ];
    public selectedCurrency = { text: 'Euro', value: 'EUR' };
    public selectedTemplate: Template = null;
    public selectedOwner: any = null;
    public selectedStartDate: string = '';
    public selectedEndDate: string = '';

    public owners: any[] = [];
    public appliedTaxRate: any = null;

    public intervals: any[] = [
        { id: 0, interval: 'Weekly' },
        { id: 1, interval: 'Monthly' },
        { id: 2, interval: 'Quarterly' },
        { id: 3, interval: 'Yearly' },
    ];
    public selectedInterval = { id: 3, interval: 'Yearly' };
    public selectedOutputStatus = { id: 1, value: 'Concept' };
    public statuses: any[] = [
        { id: 1, text: 'Concept', value: 'Concept' },
        { id: 2, text: 'Book', value: 'Book' },
        { id: 3, text: 'Book & invoice', value: 'BookInvoice' },
    ];

    public async created() {
        this.isEdit = this.subscription && this.subscription.id > 0;

        await Promise.all([
            this.loadStructure(),
            this.loadOwners(),
            this.loadTaxRates(),
            this.loadOrganizations(),
            this.loadProducts(),
            this.loadTemplates(),
        ]);

        if (this.isEdit) {
            this.subscription.lines.forEach((x) => {
                if (x.department) {
                    x.departmentObj = this.structure.teams.find((y) => y.name.toLowerCase() === x.department.toLowerCase());
                }

                if (x.department && x.departmentObj && x.project) {
                    x.projectObj = x.departmentObj.groups.find((y) => y.name.toLowerCase() === x.project.toLowerCase());
                }

                if (x.taxRateId) {
                    x.taxRate = this.taxRates.find((y) => y.id === x.taxRateId);
                }

                if (x.product) {
                    x.product = this.products.find((y) => y.productId === (x.product as unknown as number));
                }
            });

            if (this.subscription.schedule && this.subscription.schedule.interval) {
                this.selectedInterval = this.intervals.find((y) => y.interval.toLowerCase() === this.subscription.schedule.interval);
            }

            this.selectedCurrency = this.availableCurrencies.find((x) => x.value === this.subscription.currency.toUpperCase());
            this.selectedTemplate = this.templates.find((x) => x.invoiceTemplateId === this.subscription.template);

            this.selectedStartDate = moment(this.subscription.schedule.startDate, 'YYYY-DD-MM').format('DD-MM-YYYY');
            this.selectedEndDate = moment(this.subscription.schedule.endDate, 'YYYY-DD-MM').format('DD-MM-YYYY');

            await this.subscriptionOrganizationSelected(this.subscription.organization as Organization);
        } else {
            let teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            if (!teamSettings) {
                await settingsModule.fetchTeamSettingsIfNeeded(teamHelper.currentTeamId);
                teamSettings = settingsModule.findTeamFinanceSettings(teamHelper.currentTeamId);
            }
            this.selectedCurrency = this.availableCurrencies.find((x) => x.value === teamSettings.defaultCurrency);
            this.selectInterval();
        }

        this.mapOwner();
        if (this.subscription.id > 0 && this.subscription.lines.length === 0) {
            this.addLineToSubscription();
        }
        this.isLoading = false;
    }

    public async loadOwners() {
        const owners = await teamHelper.getTeamMembers(teamHelper.currentTeamId);

        this.owners = owners
            .filter((x) => x.emailAddress)
            .map((x) => {
                return { emailAddress: x.emailAddress, name: `${x.firstName} ${x.lastName}` };
            });
    }

    public async loadTemplates() {
        const legalEntityReference = teamHelper.getLegalEntityReference();
        this.templates = await templateService.getTemplates(legalEntityReference);
    }

    public selectCurrency() {
        this.subscription.currency = this.selectedCurrency.value;
    }

    public selectInterval() {
        this.subscription.schedule.interval = this.selectedInterval.interval;
        if (this.selectedInterval.interval !== 'Yearly') {
            this.subscription.schedule.monthOfYear = null;
        }
    }

    public selectStatus() {
        this.subscription.schedule.output = this.selectedOutputStatus.value;
    }

    public selectOwner(person: Person) {
        this.subscription.owner = person.emailAddress;
    }

    public async loadOrganizations() {
        const organizations = await organizationsServiceCRM.getOrganizations();
        this.organizations = organizations.items;
    }

    public async loadProducts() {
        const products = await productsServiceCRM.getProducts();
        this.products = [...products.items];
    }

    public async loadStructure() {
        this.structure = ecosystemHelper.structure;
    }

    public createOrganizationSideAction() {
        this.$sideActions.push('create-organization-side-action', { data: true }, (result) => {
            this.setOrganization(result);
        });
    }

    public setOrganization(result) {
        Vue.set(this.subscription, 'organization', result);
        this.organizationSelected++;
    }

    public createContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { organization: this.subscription.organization }, (result) => {
            this.setContact(result);
        });
    }

    public createAdministrativeContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { organization: this.subscription.organization }, (result) => {
            this.setAdministrativeContact(result);
        });
    }

    public selectProduct(product: Product, subscriptionLineIndex) {
        if (!this.subscription.lines[subscriptionLineIndex].price || this.subscription.lines[subscriptionLineIndex].price === 0) {
            this.subscription.lines[subscriptionLineIndex].price = product.price;
        }
        this.subscription.lines[subscriptionLineIndex].product = product;
    }

    public selectDepartment(_, subscriptionLineIndex) {
        this.subscription.lines[subscriptionLineIndex].project = null;
    }

    public selectProject() {
        this.tableRerender++;
    }

    public updateTotalsKey() {
        this.tableRerender++;
    }

    public resetSubscription() {
        this.subscription = this.subscription = new Subscription();
    }

    public async createSubscription() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('CREATE_SUBSCRIPTION_INVALID');
            return;
        }

        this.showPending('CREATE_SUBSCRIPTION_PENDING');

        const org = this.subscription.organization as Organization;
        const orgId = org.organizationId ? org.organizationId : org.id;
        const taxRate = await organizationsServiceCRM.getTaxRate(orgId);

        this.subscription.lines.forEach((x) => {
            x.taxRateId = taxRate ? taxRate.taxRate : null;
        });

        if (this.selectedStartDate) {
            this.subscription.schedule.startDate = moment(this.selectedStartDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if (this.selectedEndDate) {
            this.subscription.schedule.endDate = moment(this.selectedEndDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        const [err, response] = await to(subscriptionsService.createSubscription(this.subscription));
        if (err) {
            return this.clearAndShowError('CREATE_SUBSCRIPTION_FAILED', err);
        }

        const subscription = new Subscription(response.data);
        this.subscription.id = subscription.id;
        this.clearNotifications();

        await this.$router.push({ name: 'crm-subscription', params: { subscriptionId: subscription.id.toString() } });
    }

    public async updateSubscription() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning('UPDATE_SUBSCRIPTION_INVALID');
            return;
        }
        const org = this.subscription.organization as Organization;
        const orgId = org.organizationId ? org.organizationId : org.id;
        const taxRate = await organizationsServiceCRM.getTaxRate(orgId);

        this.subscription.lines.forEach((x) => {
            x.taxRateId = taxRate ? taxRate.taxRate : null;
        });

        if (this.selectedStartDate) {
            this.subscription.schedule.startDate = moment(this.selectedStartDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        if (this.selectedEndDate) {
            this.subscription.schedule.endDate = moment(this.selectedEndDate, 'DD-MM-YYYY').format('YYYY-MM-DDT00:00:00[Z]');
        }

        this.showPending('UPDATE_SUBSCRIPTION_PENDING');
        const [err] = await to(subscriptionsService.updateSubscription(this.subscription));
        if (err) {
            return this.clearAndShowError('UPDATE_SUBSCRIPTION_FAILED', err);
        }

        this.clearAndShowSuccess('UPDATE_SUBSCRIPTION_SUCCESS');
    }

    public async copySubscription() {
        const [err, response] = await to(subscriptionsService.cloneSubscription(this.subscription));
        if (err) {
            return this.clearAndShowError('CLONE_SUBSCRIPTION_FAILED', err);
        }

        this.clearAndShowSuccess('CLONE_SUBSCRIPTION_SUCCESS');
        const subscription = new Subscription(response.data);
        await this.$router.push({ name: 'crm-subscription', params: { subscriptionId: subscription.id.toString() } });
    }

    public async deleteSubscription() {
        const [err] = await to(subscriptionsService.deleteSubscription(this.subscription.id));
        if (err) {
            return this.clearAndShowError('DELETE_SUBSCRIPTION_FAILED', err);
        }

        this.clearAndShowSuccess('DELETE_SUBSCRIPTION_SUCCESS');
        await this.$router.push({ name: 'crm-subscriptions' });
    }

    public hasAnyProducts(tax) {
        return this.subscription.lines.filter((x) => (x.taxRate && x.taxRate.id === tax.id) || x.taxRateId === tax.id).length > 0;
    }

    public getTaxForType(tax) {
        const lines = this.subscription.lines.filter((x) => (x.taxRate && x.taxRate.id === tax.id) || x.taxRateId === tax.id);

        if (lines.length > 0) {
            const total = lines.reduce((prev, cur) => {
                return prev + parseFloat(cur.amount.toString()) * cur.price;
            }, 0);

            return total * tax.percentage;
        }

        return 0;
    }

    public getTotal() {
        let taxesTotal = 0;
        this.taxRates.forEach((tax) => {
            taxesTotal += this.getTaxForType(tax);
        });

        return taxesTotal + this.getSubtotal();
    }
    public getSubtotal() {
        let subTotal = 0;
        this.subscription.lines.forEach((line) => {
            subTotal += parseFloat(line.amount.toString()) * line.price;
        });

        return subTotal;
    }

    public setContact(result) {
        Vue.set(this.subscription, 'contact', result.contact);
        this.contacts.push(result.contact);
        this.contactSelected++;
    }

    public setAdministrativeContact(result) {
        Vue.set(this.subscription, 'administrativeContact', result.contact);
        this.contacts.push(result.contact);
        this.administrativeContactSelected++;
    }

    public async subscriptionOrganizationSelected(selectedOrganization: Organization) {
        const orgId = selectedOrganization.organizationId ? selectedOrganization.organizationId : selectedOrganization.id;
        const organization = await organizationsServiceCRM.getOrganization(orgId);

        this.contacts = organization.contacts;

        if (!this.contacts.find((x) => x.contactId === (this.subscription.contact as Contact).id)) {
            this.subscription.contact = null;
        }
    }

    public addLineToSubscription() {
        const newLine = new DealLine();
        newLine.open = true;
        newLine.departmentObj = this.structure.teams.find((x) => x.teamId === teamHelper.currentTeam.id);
        newLine.department = newLine.departmentObj.name;

        this.subscription.lines.push(newLine);
    }

    public removeSubscriptionLine(index: number) {
        this.subscription.lines.splice(index, 1);
    }
    public getGroups(line) {
        if (line.departmentObj) {
            return this.structure.teams.find((x) => x.teamId === line.departmentObj.teamId).groups;
        }

        return [];
    }

    public get templateOptions() {
        return this.templates;
    }

    public selectTemplate(template: Template) {
        this.subscription.template = template.invoiceTemplateId;
    }

    public exportSubscription() {
        const csv = [];
        const headers = ['Item', 'Price', 'Amount', 'Total'];
        csv.push(headers.join(';'));

        for (let i = 0; i < this.subscription.lines.length; i++) {
            const row = [];
            const line = this.subscription.lines[i];

            const description = line.description ? ` - ${line.description.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')}` : '';

            let data =
                line.projectObj != null
                    ? `${line.projectObj.name}${description}`.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                    : description;
            data = data.replace(/"/g, '""');

            row.push(`"${data}"`);

            const price = line.price;
            row.push(`"${price}"`);

            const amount = line.amount;
            row.push(`"${amount}"`);

            const totalPrice = line.price * parseFloat(line.amount.toString());
            row.push(`"${totalPrice}"`);

            csv.push(row.join(';'));
        }

        csv.push('\n');
        csv.push(['\t', '\t', 'Total', this.getSubtotal()].join(';'));

        const csvString = csv.join('\n');
        // Download it
        const filename = 'export_' + this.subscription.name + '_' + new Date().toLocaleDateString() + '.csv';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private mapOwner() {
        if (this.subscription.owner) {
            const owner = this.owners.find((x) => x.emailAddress === this.subscription.owner);
            if (owner) {
                this.selectedOwner = owner;
            } else {
                this.selectedOwner = { emailAddress: this.subscription.owner, name: `${this.subscription.owner} (not present in current team)` };
            }
        } else {
            this.subscription.owner = profileModule.brightProfile.emailAddress;
            const owner = this.owners.find((x) => x.emailAddress === this.subscription.owner);
            if (owner) {
                this.selectedOwner = owner;
            } else {
                this.selectedOwner = { emailAddress: this.subscription.owner, name: `${this.subscription.owner} (not present in current team)` };
            }
        }
    }
}
