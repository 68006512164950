import { BModal } from 'bootstrap-vue';
import PageRender from '@/models/PageRender';
import Component from 'vue-class-component';

@Component
export default class BaseModal extends PageRender {
    public isLoading: boolean = true;

    public $refs!: {
        modal: BModal;
    };

    public show() {
        this.$refs.modal.show();
    }

    public hide() {
        this.$refs.modal.hide();
    }
}
