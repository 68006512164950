import { invoiceService, legalEntityService } from '@/main';
import { SalesInvoice } from '@/models/BrightInvoices/Invoice';
import LegalEntity, { InvoiceEmailTemplate } from '@/models/LegalEntity';
import PageRender from '@/models/PageRender';
import MailContent from '@/models/Purchases/MailContent';
import SendInvoiceMail from '@/models/SendInvoiceMail';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import { profileModule } from '@/store/modules/profile';
import to from 'await-to-js';
import { BModal } from 'bootstrap-vue';
import { Component, Prop } from 'vue-property-decorator';
import { email, required, requiredIf } from 'vuelidate/lib/validators';

@Component({
    validations: {
        sendMail: {
            contactName: { required },
            contactEmail: { required, email },
            ccEmail: { email },
            masterTemplate: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.body);
                }),
            },
            subject: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.masterTemplate || model.body);
                }),
            },
            body: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.masterTemplate);
                }),
            },
        },
    },
} as any)
export default class SendInvoiceModal extends PageRender {
    @Prop({ default: () => 'newInvoice' }) public type: string;
    public sendMail: SendInvoiceMail = {
        contactName: '',
        contactEmail: '',
        ccEmail: '',
        masterTemplate: 'bright-default',
        overrideDefaultMailSettings: false,
    };
    public invoice: SalesInvoice = null;
    public submitted = false;
    public $refs!: {
        modal: BModal;
    };

    public selected = '';
    public options = [
        { text: `Cc to myself (${profileModule.brightProfile.emailAddress})`, value: 'self' },
        { text: 'Type an email address', value: 'other' },
    ];

    public open(invoice) {
        this.invoice = invoice;
        this.sendMail = {
            contactName: '',
            contactEmail: '',
            ccEmail: '',
            masterTemplate: 'bright-default',
            overrideDefaultMailSettings: false,
        };

        this.show();
    }

    public async loadMailContent() {
        this.isLoading = true;
        let invoiceEmailTemplate: InvoiceEmailTemplate = null;

        if (this.invoice.template) {
            const [err, template] = await to(
                legalEntityService.getTemplate(this.invoice.template.legalEntityId, this.invoice.template.invoiceTemplateId),
            );

            this.isLoading = false;
            if (err) {
                return this.clearAndShowError('Failed to load mail content');
            }

            invoiceEmailTemplate = template.invoiceData.invoiceEmailTemplates.find(
                (x) => x.emailType === this.type && x.language === this.invoice.language.toUpperCase(),
            );
        } else {
            const [err, response] = await to(legalEntityService.getLegalEntity(legalEntityModule.current.legalEntityId));
            this.isLoading = false;
            if (err) {
                return this.clearAndShowError('Failed to load mail content');
            }

            const legalEntity: LegalEntity = new LegalEntity(response.data);
            invoiceEmailTemplate = legalEntity.invoiceData.invoiceEmailTemplates.find(
                (x) => x.emailType === this.type && x.language === this.invoice.language.toUpperCase(),
            );
        }

        this.isLoading = false;
        if (invoiceEmailTemplate) {
            this.sendMail.subject = invoiceEmailTemplate.subject;
            this.sendMail.body = invoiceEmailTemplate.body;
            this.sendMail.masterTemplate = invoiceEmailTemplate.masterTemplate;
        } else {
            this.showInfo(`Default mail settings for the ${this.type} mail not defined`);
        }
    }

    public async sendInvoice() {
        this.submitted = true;
        if ((this as any).$v.sendMail.$invalid) {
            return this.clearAndShowWarning('INVALID_INPUT');
        }

        if (this.selected === 'self') {
            this.sendMail.ccEmail = profileModule.brightProfile.emailAddress;
        }

        const success = await invoiceService.sendInvoice(this.invoice.invoiceId, legalEntityModule.current.legalEntityId, this.sendMail, this.type);

        if (success) {
            this.submitted = false;
            this.clearAndShowSuccess('INVOICE_SENT');
            this.hide();
            this.invoice = null;
        }
    }

    public show() {
        this.$refs.modal.show();
    }

    public hide() {
        this.$refs.modal.hide();
    }
}
