export enum PaymentStatusEnum {
    Unknown = 'Unknown',
    Open = 'Open',
    Partial = 'Partial',
    Full = 'Full',
}

export default class PaymentStatus {
    public static getStatus(status: PaymentStatusEnum = PaymentStatusEnum.Unknown): string {
        if (this.status[PaymentStatusEnum[status]]) {
            return this.status[PaymentStatusEnum[status]];
        }

        return this.status[0];
    }

    public static getStatussen() {
        return [
            // PaymentStatus.status.Unknown,
            PaymentStatus.status.Open,
            PaymentStatus.status.Partial,
            PaymentStatus.status.Full,
        ];
    }

    private static status = {
        Unknown: 'Unknown',
        Open: 'Open',
        Partial: 'Partial',
        Full: 'Full',
    };
}
