import PaymentMethod from '../PaymentMethod';
import { Customer } from './Customer';
import { InvoiceLine, SalesInvoiceLine } from './InvoiceLine';

export class Invoice {
    public id: string;
    public invoiceNumber: string;
    public invoiceDescription: string = '';
    public invoiceDate: Date = new Date();
    public dueDate: Date = new Date();
    public customer: Customer;
    public totalAmount: number;

    public invoiceLines: InvoiceLine[] = [];

    constructor(obj?: Partial<Invoice>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class SalesInvoice {
    public invoiceId: string;
    public invoiceNumber: string;
    public invoiceDescription: string = 'Empty';
    public invoiceDate: Date = new Date();
    public dueDate?: Date = null;
    public bookDate: Date = null;
    public customer: Customer = new Customer();
    public customerCocNumber: string = '';
    public customerVatNumber: string = '';
    public customerAddressLineOne: string = '';
    public customerAddressLineTwo: string = '';
    public customerZipCode: string = '';
    public customerCity: string = '';
    public customerState: string = '';
    public customerCountryIso2: string = '';

    public legalEntityId: string = '';
    public amountPaid: number = 0;
    public totalAmount: number;
    public paymentStatus: string;
    public reference: string;
    public currency: string;
    public attachments: any[];
    public language: string;
    public clientReference: string = '';
    public lines: SalesInvoiceLine[] = [];
    public legalEntityReference: string = '';
    public paymentMethod: PaymentMethod;

    public paperlessTeamId: number = null;
    public paperlessId: number = null;
    public selected: boolean = false;
    public template: InvoiceInvoiceTemplate = null;

    constructor(obj?: Partial<SalesInvoice>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class InvoiceInvoiceTemplate {
    public invoiceDataId: number;
    public invoiceTemplateId: number;
    public legalEntityId: string;
    public name: string;
}
