import DocumentLine from './DocumentLine';
import PaymentMethod from '@/models/PaymentMethod';
import { PurchaseApproval } from '../Purchases/PurchaseApproval';

export enum DocumentStatus {
    Draft = 'draft',
    Pending = 'pending',
    Approved = 'approved',
    Declined = 'declined',
    Submitted = 'submitted',
    Finalized = 'finalized',
}

export enum DocumentType {
    Incoming = 'Incoming',
    Declaration = 'Declaration',
}

export default class InboxDocument {
    public id: number = null;
    public inboxDocumentId: number = null;

    public teamId: number = null;
    public ecosystemId: number = null;
    public note?: string;
    public organizationId: number = null;
    public documentStatus: DocumentStatus = DocumentStatus.Draft;
    public documentType: DocumentType = DocumentType.Declaration;
    public name: string = '';
    public initiator: string = null;
    public paymentMethod: PaymentMethod = null;

    public documentLines: DocumentLine[] = [];
    public approvals: PurchaseApproval[] = [];

    public purchaseInvoiceDate: string = '';

    public reference: string = '';
    public currency: string;

    constructor(obj?: Partial<InboxDocument>) {
        if (obj) {
            Object.assign(this, obj);

            if (this.documentLines.length > 0) {
                this.documentLines = [];

                for (const line of obj.documentLines) {
                    this.documentLines.push(new DocumentLine(line));
                }
            }
        }
    }
}
