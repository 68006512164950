import PageRender from '@/models/PageRender';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GridPeriodRangeFilterComponent extends PageRender {
    @Prop({ default: false }) public noEndMonth: boolean;
    public disabledDates: any = { to: null, from: null };
    @Prop({ default: null }) public initialFromFilterMonth: string;
    public fromFilterMonth: string = '';
    @Prop({ default: null }) public initialToFilterMonth: string;
    public toFilterMonth: string = '';

    private monthString: string = `${new Date().getFullYear()}-` + `0${new Date().getMonth() + 1}`.slice(-2);

    public mounted() {
        if (this.initialFromFilterMonth) {
            this.fromFilterMonth = this.initialFromFilterMonth;
        } else {
            this.fromFilterMonth = this.monthString;
        }

        if (!this.noEndMonth) {
            if (this.initialToFilterMonth) {
                this.toFilterMonth = this.initialToFilterMonth;
            } else {
                this.toFilterMonth = this.monthString;
            }
        }

        this.$emit('filter-change', { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth });
    }

    public notAfterOrBeforeDate(date: Date) {
        return date >= this.disabledDates.from || date < this.disabledDates.to;
    }

    public reload() {
        this.$emit('filter-change', { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth });
    }
}
