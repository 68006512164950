import { dealsService, legalEntityService, notificationService, organizationsServiceCRM, teamHelper, templateService } from '@/main';
import Contact from '@/models/CRM/Contact';
import Deal from '@/models/CRM/Deal';
import Organization from '@/models/CRM/Organization';
import to from 'await-to-js';
import { Component, Prop } from 'vue-property-decorator';
import BaseModal from './BaseModal';
import moment from 'moment';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import { Template } from '@/models/CRM/Template';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import SendInvoiceMail from '@/models/SendInvoiceMail';
import { InvoiceEmailTemplate } from '@/models/LegalEntity';
import TaxRate from '@/models/CRM/TaxRate';
import { profileModule } from '@/store/modules/profile';
import { dateFormat } from '@vuejs-community/vue-filter-date-format';
import { MailLogItem } from '@/components/side-actions/mail-log-action/mail-log-side-action';

function requiredIfDealIsOpen() {
    // tslint-disable-next-line
    return this.deal.dealState.name !== 'Lead' || (this.deal.dealState.name === 'Lead' && this.deal.lines.length > 0);
}
@Component({
    validations: {
        deal: {
            name: { required },
            organization: { required },
            contact: { required },
            lines: {
                requiredIf: requiredIfDealIsOpen,
                $each: {
                    amount: { required },
                    price: { required },
                    product: {
                        requiredIf: requiredIf((form) => {
                            return form.description === null;
                        }),
                    },
                    description: {
                        requiredIf: requiredIf((form) => {
                            return form.product === null;
                        }),
                    },
                    departmentObj: { required },
                    projectObj: { required },
                },
            },
        },
        sendMail: {
            contactEmail: { required, email },
            contactName: { required },
            ccEmail: { email },
            masterTemplate: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.body);
                }),
            },
            subject: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.masterTemplate || model.body);
                }),
            },
            body: {
                required: requiredIf((model: SendInvoiceMail) => {
                    return model.overrideDefaultMailSettings && (model.subject || model.masterTemplate);
                }),
            },
        },
    },
} as any)
export default class RemindDealComponent extends BaseModal {
    @Prop({ default: () => new Deal() }) public deal: Deal;

    public organization: Organization = null;
    public canceled: boolean = true;
    public templates: Template[] = [];
    public loading: boolean = true;
    public selectedContact: Contact = null;
    public selectedCCContact: Contact = null;
    public log: MailLogItem[] = [];

    public sendMail: SendInvoiceMail = {
        contactName: '',
        contactEmail: '',
        ccEmail: '',
        masterTemplate: 'bright-default',
    };

    public selectedCCOption = '';
    public CCOptions = [
        { text: 'None', value: '' },
        { text: `CC to myself (${profileModule.brightProfile.emailAddress})`, value: 'self' },
        { text: 'Type an email address / Select contact', value: 'other' },
    ];

    public get contacts() {
        return this.organization.contacts.filter((x) => typeof x.emailAddresses !== 'undefined' && x.emailAddresses.length);
    }

    public get ccContacts() {
        return this.contacts.filter((x) => !this.selectedContact || (this.selectedContact && x.contactId !== this.selectedContact.contactId));
    }

    public async init(deal?: Deal) {
        this.canceled = true;
        this.show();

        if (deal) {
            this.deal = deal;
            await this.loadTemplates();
        }

        if (this.deal.organization) {
            this.showPending('LOADING_LANGUAGE_OF_ORGANIZATION');
            this.organization = await organizationsServiceCRM.getOrganization((this.deal.organization as Organization).organizationId);
            this.selectedContact = this.organization.contacts.find((x) => x.contactType === 'administrative');
            if (this.selectedContact) {
                this.setContactDetails(this.selectedContact);
            }

            this.clearNotifications();
        }

        if (this.deal.externalInvoice && this.deal.externalInvoice.invoiceId) {
            this.log = await notificationService.getMessageLog(this.deal.externalInvoice.invoiceId);
        }

        await this.loadMailContent();

        this.loading = false;
    }

    public onHide() {
        this.selectedCCOption = '';
        this.selectedContact = null;

        this.sendMail.contactEmail = '';
        this.sendMail.contactName = '';

        this.$emit('hide', this.canceled);
    }

    public async loadTemplates() {
        const legalEntityReference = teamHelper.getLegalEntityReference();
        this.templates = await templateService.getTemplates(legalEntityReference);
    }

    public async loadMailContent() {
        let mailContent: InvoiceEmailTemplate = await dealsService.getMailTemplateContent(teamHelper.currentTeamId, this.deal.id, 'reminder');

        if (mailContent) {
            this.sendMail.subject = mailContent.subject;
            this.sendMail.body = mailContent.body;
            this.sendMail.masterTemplate = mailContent.masterTemplate;
        }
    }

    public setContactDetails(contact: Contact) {
        this.sendMail.contactEmail = contact.emailAddresses[0].value;
        this.sendMail.contactName = `${contact.firstName} ${contact.lastName}`;
    }

    public setCCEmail(contact: Contact) {
        this.sendMail.ccEmail = contact.emailAddresses[0].value;
    }

    public async sendDealReminderMail() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.clearAndShowWarning(`INVALID`);
            return;
        }

        this.showPending('DEAL_REMINDER_PENDING');

        if (this.selectedCCOption === 'self') {
            this.sendMail.ccEmail = profileModule.brightProfile.emailAddress;
        }

        // TODO add administrative and defined mails to this call somehow
        const [err] = await to(dealsService.remindDeal(this.deal.id, this.sendMail));
        if (err) {
            return this.clearAndShowError('DEAL_REMINDER_FAILED', err);
        }

        this.clearAndShowSuccess('DEAL_REMINDER_SUCCESS');
        this.canceled = false;
        this.hide();
    }

    public formattedDate(date) {
        return dateFormat(date, 'DD-MM-YYYY');
    }

    public getTemplateName(templateId) {
        return this.templates.find((x) => x.invoiceTemplateId === templateId).name;
    }

    public getLegalEntityTemplateName() {
        return legalEntityModule.current.name;
    }
}
