import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Ledger from '@/models/Finance/Ledger';
import ImportLedgersBase, { CsvColumn, LedgerImportBase } from '@/models/Ledgers/ImportLedgersBase';
import { crmLedgerService } from '@/main';

enum ImportInputModes {
    Overview = 'overview',
    Import = 'import',
    Paste = 'paste',
}

@Component
export default class ImportCRMLedgersComponent extends ImportLedgersBase {
    @Prop({ default: () => [] }) public extraFields: CsvColumn[];
    @Prop({ default: 'Import participant(s)' }) public title: string;

    public inputMode: ImportInputModes = ImportInputModes.Overview;

    public async mounted() {
        this.csvColumns = new Array<CsvColumn>(
            { label: 'name', placeholder: 'revenue' },
            { label: 'value', placeholder: '8000' },
            { label: 'description', placeholder: '<optional>' },
        );
        this.isLoading = false;
    }

    public importLines(): void {
        const ledgers = new Array<Ledger>();
        this.lineData.forEach((x: Partial<LedgerImportBase>) => {
            const ledger = new Ledger(x);

            const extraData = {};
            if (this.extraFields.length > 0) {
                for (const field of this.extraFields) {
                    extraData[field.label] = x[field.label];
                }
            }

            ledgers.push(ledger);
        });
        this.importLedgers(ledgers);
    }

    public importLedgers(ledgers: Ledger[]) {
        this.showPending('Creating ledgers...');
        ledgers.forEach(async (ledger: Ledger) => {
            await crmLedgerService.createLedger(ledger);
        });

        this.clearAndShowSuccess('Done importing ledgers');

        this.$emit('finished');
    }
}
