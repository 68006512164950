import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAppInsights from 'vue-application-insights';
import Vuelidate from 'vuelidate';

import SharedUI from '@prd/shared-ui';
import './components/index';
import './registerServiceWorker';
import { Popup } from '@progress/kendo-vue-popup';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

import { AxiosHelper } from './helpers/axiosHelper';
import { LoginHelper } from './helpers/loginHelper';
import { MeService } from './services/meService';
import { TeamService } from './services/teamService';
import UserRoleService from './services/userRoleService';
import { ErrorHelper } from './helpers/errorHelper';
import { RoleHelper } from './helpers/roleHelper';
import { MemberHelper } from './helpers/memberHelper';
import NavigationHelper from './helpers/navigationHelper';
import { EventBus } from './events/EventBus';
import { LoginService } from './services/loginService';
import '@/extensions/numberExtensions';
import store from '@/store/index';
import { translateModule } from './store/modules/translate';
import LedgerService from './services/LedgerServicePurchases';
import DealsService from './services/dealsService';
import SideActionsPlugin from './plugin/sideActions/sideActions';
import ContactsService from './services/contactService';
import BrightService from './services/brightService';
import { InvoiceService } from './services/invoicesService';
import { EcosystemHelper } from './helpers/ecosystemHelper';
import { TeamHelper } from './helpers/teamHelper';
import PurchasesService from './services/purchasesService';
import { AppHelper } from './helpers/appHelper';
import { LegalEntityService } from './services/legalEntityService';
import ProductsServiceCRM from './services/productsServiceCRM';
import ProductsServicePurchases from './services/productsServicePurchases';
import OrganizationsServiceCRM from './services/organizationsServiceCRM';
import OrganizationsServicePurchases from './services/organizationsServicePurchases';
import VueDraggable from 'vue-draggable';
import { GuidHelper } from './helpers/guidHelper';
import moment from 'moment';
import CountryService from './services/countryService';
import FootprintProductsServicePurchases from './services/footprintProductsServicePurchases';
import FootprintTypeServicePurchases from './services/footprintTypesServicePurchases';
import { TemplateService } from './services/TemplateService';
import SubscriptionsService from './services/subscriptionService';
import ContactsServiceCRM from './services/contactsServiceCRM';
import ClientPurchasesApprovalService from './services/approvalServicePurchases';
import InboxDocumentService from './services/inboxDocumentService';
import SettingService from './services/settingsService';
import PurchaseSettingService from './services/settingsServicePurchases';
import CrmLedgerService from './services/ledgerServiceCrm';
import ApplicationService from './services/applicationService';
import BootstrapVue from 'bootstrap-vue';
import NotificationsService from '@/services/notificationsService';

Vue.use(SharedUI);
Vue.use(SideActionsPlugin);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueDraggable);
Vue.use(VueFilterDateFormat);

if (Vue.$env().instrumentationKey) {
    Vue.use(VueAppInsights, {
        id: Vue.$env().instrumentationKey,
        router,
    });
}

Vue.component('distribution-popup', Popup);

export const $router = router;
export const eventBus = new EventBus();
export const axiosHelper = new AxiosHelper({}, {});
export const loginService = new LoginService();
export const loginHelper = new LoginHelper(loginService);
export const meService = new MeService(axiosHelper);
export const teamService = new TeamService(axiosHelper);
export const userRoleService = new UserRoleService(axiosHelper);
export const settingService = new SettingService(axiosHelper);
export const ledgerService = new LedgerService(axiosHelper);
export const crmLedgerService = new CrmLedgerService(axiosHelper);
export const dealsService = new DealsService(axiosHelper);
export const templateService = new TemplateService(axiosHelper);
export const contactsService = new ContactsService(axiosHelper);
export const productsServiceCRM = new ProductsServiceCRM(axiosHelper);
export const productsServicePurchases = new ProductsServicePurchases(axiosHelper);
export const footprintProductsServicePurchases = new FootprintProductsServicePurchases(axiosHelper);
export const footprintTypeServicePurchases = new FootprintTypeServicePurchases(axiosHelper);
export const organizationsServiceCRM = new OrganizationsServiceCRM(axiosHelper);
export const contactsServiceCRM = new ContactsServiceCRM(axiosHelper);
export const organizationsServicePurchases = new OrganizationsServicePurchases(axiosHelper);
export const invoiceService = new InvoiceService(axiosHelper);
export const brightService = new BrightService(axiosHelper);
export const purchasesService = new PurchasesService(axiosHelper);
export const clientPurchasesApprovalService = new ClientPurchasesApprovalService(axiosHelper);
export const legalEntityService = new LegalEntityService(axiosHelper);
export const countryService = new CountryService(axiosHelper);
export const subscriptionsService = new SubscriptionsService(axiosHelper);
export const inboxDocumentService = new InboxDocumentService(axiosHelper);
export const settingsService = new SettingService(axiosHelper);
export const purchaseSettingsService = new PurchaseSettingService(axiosHelper);
export const notificationService = new NotificationsService(axiosHelper);
export const applicationService = new ApplicationService(axiosHelper);
export const errorHelper = new ErrorHelper(router);
export const roleHelper = new RoleHelper();
export const ecosystemHelper = new EcosystemHelper();
export const teamHelper = new TeamHelper();
export const memberHelper = new MemberHelper();
export const navigationHelper = new NavigationHelper();
export const appHelper = new AppHelper();
export const guidHelper = new GuidHelper();

Vue.config.productionTip = false;

Vue.filter('numberFormat', (n: number) => {
    if (!n || typeof n === 'undefined' || n == null) {
        return 0;
    }

    if (n > -1000 && n < 1000) {
        return n.round(2).replace('.', ',');
    }

    const parts = n.round(2).split('.');
    const chars = [];
    let i = 0;

    if (parts.length === 2) {
        chars.push(`,${parts[1]}`);
    }

    parts[0]
        .split('')
        .reverse()
        .forEach((c) => {
            chars.push(c);
            i++;
            if (i > 0 && i % 3 === 0) {
                chars.push(' ');
            }
        });

    return chars.reverse().join('');
});

Vue.filter('formatDate', (value: Date) => {
    if (value) {
        return moment(value).format('DD-MM-YYYY');
    }
    return '';
});

Vue.filter('translate', (value: string) => {
    if (value) {
        return translateModule.value(value);
    }
    return '';
});

Vue.filter('percentage', (value, decimals) => {
    if (!value) {
        value = 0;
    }

    if (!decimals) {
        decimals = 0;
    }

    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    value = value + '%';
    return value;
});

new Vue({
    store,
    data: {
        loginHelper,
        teamHelper,
        ecosystemHelper,
        roleHelper,
        navigationHelper,
        appHelper,
    },
    computed: {
        inEditMode() {
            return translateModule.inEditMode;
        },
    },
    router,
    render: (h) => h(App),
}).$mount('#app');
