import Product from '@/models/Purchases/Product';
import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { organizationsServicePurchases, ecosystemHelper, teamHelper } from '@/main';
import Organization from '@/models/CRM/Organization';
import Contact from '@/models/CRM/Contact';
import Vue from 'vue';
import organizationSettingsTabVue from './organization-settings-tab.vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import Attachment from '@/models/Attachment';
import to from 'await-to-js';

@Component({
    components: {
        SettingsTab: organizationSettingsTabVue,
    },
})
export default class OrganizationComponent extends PageRender {
    public organization: Organization = new Organization();
    public contacts: Contact[] = [];
    public attachments: Attachment[] = [];
    public products: { organizationProductId: number; productId: number; productName: string }[] = [];

    public attachmentsColumns: GridColumnProps[] = [
        { field: 'fileName', title: 'Name', editable: false, cell: this.renderLink },
        { cell: this.renderAttachmentActions, title: 'Actions', width: 100 },
    ];

    public productColumns: GridColumnProps[] = [
        { field: 'productId', title: 'Id', width: '120px', editable: false },
        { field: 'productName', title: 'Name', editable: false },
        { cell: this.renderProductActions, title: 'Actions', width: 150 },
    ];

    public contactsColumns: GridColumnProps[] = [
        { field: 'contactId', title: 'Id', width: '120px', editable: false },
        { field: 'firstName', title: 'First name', editable: false },
        { field: 'lastName', title: 'Last name', editable: false },
        { field: 'emailAddresses', title: 'Email addresses', editable: false, cell: this.renderEmailGridList, width: 200 },
        { field: 'phoneNumbers', title: 'Phone numbers', editable: false, cell: this.renderPhoneNumberGridList, width: 200 },
        { cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public async mounted() {
        const organizationId = parseInt(this.$route.params.organizationId);
        await this.loadOrganization(organizationId);
        await this.loadAttachments(organizationId);
        await this.loadProducts();

        this.isLoading = false;
    }

    public async loadProducts() {
        const products = await organizationsServicePurchases.getOrganizationProducts(this.organization.organizationId);
        this.products = [...products.data];
    }

    public async goToContact(e) {
        const contactId = e.dataItem.contactId;
        const organizationId = this.$route.params.organizationId;

        const params = {
            id: ecosystemHelper.currentEcosystem.id.toString(),
            key: ecosystemHelper.currentEcosystem.name,
            contactId,
            organizationId,
        };

        await this.$router.push({ name: 'purchase-org-contact', params });
    }

    public createContactSideAction() {
        this.$sideActions.push('create-contact-side-action', { organization: this.organization }, (result) => {
            this.setContact(result);
        });
    }

    public setContact(result) {
        if (result.success) {
            this.organization.contacts.push(result.contact);
        }
        // this.contactSelected++;
    }

    public async loadAttachments(organizationId: number) {
        this.attachments = await organizationsServicePurchases.getAttachments(organizationId);
    }

    public async loadContacts() {
        const contacts = await organizationsServicePurchases.getContacts(this.organization.organizationId);
        this.contacts = contacts.items;
    }

    public async loadOrganization(organizationId: number) {
        this.organization = await organizationsServicePurchases.getOrganization(organizationId);
        this.contacts = this.organization.contacts;
    }

    public uploadAttachmentSideAction() {
        this.$sideActions.push(
            'upload-attachment-side-action',
            { organizationId: this.organization.organizationId, isCrm: false, isPurchase: true, team: teamHelper.currentTeam.id },
            (result) => {
                this.loadAttachments(this.organization.organizationId);
            },
        );
    }

    public connectProductToOrgSideAction() {
        this.$sideActions.push(
            'connect-product-to-org-side-action',
            { organization: this.organization },
            (result: { organizationProductId: number; productId: number; productName: string }) => {
                this.products.push(result);
            },
        );
    }

    public renderActions(h, _, row) {
        const actions = [
            // {
            //     title: 'Copy deal',
            //     function: this.copyDeal,
            // },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderProductActions(h, _, row) {
        const actions = [
            {
                title: 'Remove product',
                function: this.removeProduct,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public async removeProduct(item: { organizationProductId: number; productId: number; productName: string }) {
        this.showPending('DELETE_ORG_PRODUCT_PENDING');
        const [err] = await to(organizationsServicePurchases.deleteProduct(item.organizationProductId, this.organization.organizationId));
        if (err) {
            this.clearAndShowError('DELETE_ORG_PRODUCT_FAILED', err);
            return;
        }

        this.clearAndShowSuccess('DELETE_ORG_PRODUCT_SUCCESS');
        this.products = this.products.filter((x) => x.organizationProductId !== item.organizationProductId);
    }

    public renderEmailGridList(h, _, row) {
        const elements = row.dataItem.emailAddresses;
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public renderPhoneNumberGridList(h, _, row) {
        const elements = row.dataItem.phoneNumbers;
        const props = { elements };

        return h(Vue.component('grid-list'), { props });
    }

    public renderLink(h, _, row) {
        const attachment = row.dataItem;
        return h(
            'td',
            {
                on: {
                    click: () => {
                        const link = document.createElement('a');
                        link.href = attachment.url;
                        link.setAttribute('download', `${attachment.fileName}`);
                        document.body.appendChild(link);
                        return link.click();
                    },
                },
                class: 'cursor-pointer',
            },
            attachment.fileName,
        );
    }

    public renderAttachmentActions(h, _, row) {
        const actions = [
            {
                title: 'Remove document',
                function: this.removeAttachment,
            },
            {
                title: 'Download document',
                function: this.downloadAttachment,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public downloadAttachment(item: Attachment) {
        const link = document.createElement('a');
        link.href = item.url;
        link.setAttribute('download', `${item.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async removeAttachment(item: Attachment) {
        await organizationsServicePurchases.deleteAttachment(this.organization.organizationId, item.fileId);
        this.showSuccess('REMOVE_ORG_DOCUMENT_SUCCESS');

        this.loadAttachments(this.organization.organizationId);
    }
}
