import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { productsServiceCRM } from '@/main';
import Product from '@/models/CRM/Product';

@Component
export default class ProductComponent extends PageRender {
    public product: Product = new Product();

    public async mounted() {
        const productId = parseInt(this.$route.params.productId);
        await this.loadProduct(productId);
        this.isLoading = false;
    }

    public async loadProduct(productId: number) {
        this.product = await productsServiceCRM.getProduct(productId);
    }
}
