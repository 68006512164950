import { appHelper, ledgerService, crmLedgerService } from '@/main';
import Ledger from '@/models/Finance/Ledger';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';

@Component
export default class LedgerActionComponent extends SideActionBase<any> {
    public isLoading: boolean = true;
    public ledger: Ledger = null;
    public async created() {
        if (this.options.ledger) {
            const ledgerId = this.options.ledger.ledgerId;
            if(appHelper.isCrm()) {
                this.ledger = await crmLedgerService.getLedger(ledgerId);
            } else {
                this.ledger = await ledgerService.getLedger(ledgerId);
            }
        } else {
            this.ledger = new Ledger();
        }

        this.isLoading = false;
    }

    public get componentName() {
        return `${appHelper.isCrm() ? 'crm' : 'purchases'}-create-or-edit-ledger`;
    }

    public createLedger(e): void {
        this.finished(e);
    }
}
