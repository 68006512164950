import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { IVuelidate } from 'vuelidate';
import to from 'await-to-js';
import { countryService, ecosystemHelper, invoiceService, teamHelper, templateService } from '@/main';
import { SalesInvoice } from '@/models/BrightInvoices/Invoice';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import { required } from 'vuelidate/lib/validators';
import fileDownload from 'js-file-download';
import { BModal } from 'bootstrap-vue';
import { orderBy } from '@progress/kendo-data-query';
import sendInvoiceModalVue from '@/components/invoice/send-invoice-modal.vue';
import { Template } from '@/models/CRM/Template';

@Component({
    components: {
        SendInvoiceModal: sendInvoiceModalVue,
    },
    validations: {
        invoice: {
            name: { required },
            customer: { required },
            status: { required },
            invoiceDate: { required },
            legalEntityId: { required },
            dueDate: { required },
            paymentStatus: { required },
            lines: {
                $each: {
                    // amount: { required },
                    // price: { required },
                    // product: {
                    //     requiredIf: requiredIf((form) => {
                    //         return form.description === null;
                    //     }),
                    //     productId: { minValue: minValue(1) },
                    // },
                    // description: {
                    //     requiredIf: requiredIf((form) => {
                    //         return form.product === null;
                    //     }),
                    // },
                    // departmentObj: { required },
                    // projectObj: { required },
                },
            },
        },
    },
} as any)
export default class InvoicePage extends PageRender implements IVuelidate<any> {
    public isSales: boolean = true;
    public invoice: SalesInvoice = null;
    public headerTitle: string;
    public paymentAmount: number = 0;
    public paymentFullyPaid = false;
    public tableRerender: number = 0;
    public templates: Template[] = [];
    public selectedTemplate: any = null;

    public $refs!: {
        customerEditModal: BModal;
        paymentModal: BModal;
    };

    public selectedCountryIso2: any = { id: 'NL' };
    public sort: any[] = [{ field: 'lineNumber', dir: 'asc' }];

    public async mounted() {
        this.isSales = this.$route.meta.type === 'sales';
        await this.loadInvoice();
        const invoiceNumber = this.invoice.invoiceNumber ?? this.invoice.reference;
        this.headerTitle = `${invoiceNumber} - ${this.invoice.invoiceDescription}`;

        if (this.invoice.customer && this.invoice.customer.countryIso2) {
            this.selectedCountryIso2 = this.countries.filter((x) => x.id === this.invoice.customer.countryIso2);
        }

        this.paymentAmount = this.invoice.lines.reduce((a, b) => a + b.price * b.quantity, 0);

        await this.loadTemplates();
        this.isLoading = false;
    }

    public async loadTemplates() {
        const legalEntityReference = legalEntityModule.all.find(x => x.legalEntityId == this.invoice.legalEntityId)?.legalEntityReference;
        this.templates = await templateService.getTemplates(legalEntityReference);
        this.selectedTemplate = this.templates.find((x) => x.invoiceTemplateId === this.invoice.template?.invoiceTemplateId);
    }

    public async loadInvoice() {
        const [err, response] = await to(
            invoiceService.getInvoice(legalEntityModule.current.legalEntityId, this.$route.params.invoiceId, this.isSales),
        );
        if (err) {
            this.clearAndShowError('Failed to load legal entity details', err);
            return;
        }

        return (this.invoice = new SalesInvoice(response));
    }

    public selectTemplate(template) {
        this.selectedTemplate = template;
    }

    public async downloadAttachment(attachment) {
        const link = document.createElement('a');
        link.href = attachment.url;
        link.setAttribute('download', `${attachment.fileName}`);
        document.body.appendChild(link);
        return link.click();
    }

    public async downloadPdf() {
        this.showPending('DOWNLOAD_INVOICE_PENDING');
        const pdf = await invoiceService.pdfInvoice(this.invoice.invoiceId, legalEntityModule.current.legalEntityId);
        if (pdf !== null) {
            fileDownload(pdf, `${this.invoice?.customer?.countryIso2?.toLowerCase() === 'nl' ? 'Factuur' : 'Invoice'}-${this.invoice.invoiceNumber}-${this.invoice.invoiceDescription}.pdf`);
            this.clearAndShowSuccess('DOWNLOAD_INVOICE_SUCCESS');
        } else {
            this.clearAndShowError('DOWNLOAD_INVOICE_FAILED', null);
        }
    }

    public editCustomer() {
        this.$refs.customerEditModal.show();
    }

    public async updateInvoice() {
        this.showPending('Saving invoice..');
        const [err] = await to(invoiceService.updateInvoice(this.invoice, legalEntityModule.current.legalEntityId, this.isSales));
        if (err) {
            return this.clearAndShowError('Failed to save invoice', err);
        }

        this.clearAndShowSuccess('Invoice saved');
        this.editMode = false;
        this.$refs.customerEditModal.hide();
    }

    public async registerPayment() {
        this.showPending('Registering payment on invoice..');

        const totalAmount = this.invoice.lines.reduce((a, b) => a + b.price * b.quantity, 0);
        const fullyPaid = this.paymentFullyPaid || Math.abs(this.paymentAmount) >= Math.abs(totalAmount);
        const [err] = await to(
            invoiceService.registerPayment(this.invoice, legalEntityModule.current.legalEntityId, this.isSales, this.paymentAmount, fullyPaid),
        );

        if (err) {
            return this.clearAndShowError('Failed to add payment', err);
        }

        this.clearAndShowSuccess('Payment added');
        this.$refs.paymentModal.hide();
    }

    public get orderedLines() {
        return orderBy(this.invoice.lines, this.sort);
    }

    public get countries() {
        const countries = countryService.getCountries();
        const list = Object.keys(countries[0]).map((x) => {
            return {
                id: x,
                name: countries[0][x].name,
            };
        });

        return list;
    }

    public toggleLine(line) {
        line.open = !line.open;
        this.tableRerender++;
    }

    public selectCountry(country) {
        this.selectedCountryIso2 = country;
        this.invoice.customer.countryIso2 = country.id;
    }

    public getLegalEntityName(legalEntityId) {
        const legalEntity = legalEntityModule.all.find((x) => x.legalEntityId === legalEntityId);
        if (legalEntity) {
            return legalEntity.name;
        }

        return 'Legal entity not found';
    }

    public getEcosystemName(ecosystemId) {
        return ecosystemHelper.getEcosystemById(ecosystemId).name;
    }

    public async convertCurrency() {
        await invoiceService.convertCurrency(this.isSales, this.invoice.invoiceId, legalEntityModule.current.legalEntityId);
    }

    public showPaymentModal() {
        this.$refs.paymentModal.show();
    }

    public formatCurrency(amount: number, currency: string) {
        const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency });
        return formatter
            .formatToParts(amount)
            .map((val) => val.value)
            .join('');
    }

    public get goToLinkedDeal() {
        return {
            name: 'crm-deal',
            params: {
                teamId: this.invoice.paperlessTeamId.toString(),
                teamKey: teamHelper.teams.find((x) => x.id === this.invoice.paperlessTeamId).key,
                dealId: this.invoice.paperlessId.toString(),
            },
        };
    }

    public get goToLinkedPurchase() {
        return {
            name: 'purchase',
            params: {
                teamId: this.invoice.paperlessTeamId.toString(),
                teamKey: teamHelper.teams.find((x) => x.id === this.invoice.paperlessTeamId).key,
                purchaseId: this.invoice.paperlessId.toString(),
            },
        };
    }
}
