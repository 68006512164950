import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { Prop } from 'vue-property-decorator';
import { organizationsServiceCRM, organizationsServicePurchases } from '@/main';
import to from 'await-to-js';
import { required } from 'vuelidate/lib/validators';
import Organization from '@/models/Purchases/Organization';

@Component({
    validations: {
        fromOrganization: { required },
        toOrganization: { required },
    },
    } as any)
export default class PurchaseMergeOrganizationsComponent extends PageRender {
    @Prop({ default: () => [] }) public organizations: Organization[];
    public submitted: boolean = false;

    public fromOrganization: Organization = null;
    public toOrganization: Organization = null;

    public working: boolean = true;

    public async showMergeWarning(): Promise<void> {
        this.submitted = true;

        if (this.fromOrganization && this.toOrganization) {
            this.$bvModal
                .msgBoxConfirm(`This action can't be undone! Please verify that you are merging the right organizations.`, {
                    title: `Merging organizations`,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: `Merge`,
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.mergeOrganizations();
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.log(err);
                });
        }
    }

    public async mergeOrganizations(): Promise<any> {
        const [err] = await to(
            organizationsServicePurchases.mergeOrganizations(this.fromOrganization.organizationId, this.toOrganization.organizationId),
        );
        if (err) {
            return this.showError('Failed to merge organizations');
        }
        this.$emit('finished', true);
    }

    public close(evt: any): void {
        evt.preventDefault();

        this.$emit('cancel', false);
    }

    public FromOrganizationOptions(): any[] {
        return this.organizations.filter((organization) => {
            return !this.toOrganization || (this.toOrganization && organization.organizationId !== this.toOrganization.organizationId);
        });
    }

    public ToOrganizationOptions(): any[] {
        return this.organizations.filter((organization) => {
            return !this.fromOrganization || (this.fromOrganization && organization.organizationId !== this.fromOrganization.organizationId);
        });
    }
}
