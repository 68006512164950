import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { contactsServiceCRM, organizationsServiceCRM } from '@/main';
import Contact from '@/models/CRM/Contact';

@Component
export default class ContactComponent extends PageRender {
    public contact: Contact = new Contact();

    public async mounted() {
        const contactId = parseInt(this.$route.params.contactId);
        const organizationId = parseInt(this.$route.params.organizationId);
        await this.loadContact(organizationId, contactId);

        this.isLoading = false;
    }

    public async loadContact(organizationId: number, contactId: number) {
        if (organizationId) {
            const organization = await organizationsServiceCRM.getOrganization(organizationId);
            this.contact = organization.contacts.find((x) => x.contactId === contactId);
        } else {
            this.contact = await contactsServiceCRM.getContact(contactId);
        }
    }
}
