import Component from 'vue-class-component';
import PageRender from '@/models/PageRender';
import { dealsService } from '@/main';
import Vue from 'vue';
import Rule from '@/models/CRM/Rule';
import { GridColumnProps } from '@progress/kendo-vue-grid';
@Component
export default class RulesComponent extends PageRender {
    public rules: Rule[] = [];

    public rulesColumns: GridColumnProps[] = [
        // { field: 'taxRateRuleId', title: 'Id', editable: false },
        { field: 'name', title: 'NAME', editable: false },
        { field: 'countryIso2', title: 'COUNTRY_CODE', editable: false },
        { field: 'isExEu', title: 'EX_EUROPE', editable: false, cell: this.renderEuropeBoolean },
        { field: 'needsVatNumber', title: 'VAT', editable: false, cell: this.renderVatBoolean },
        { field: 'taxRate', title: 'TAXRATE', editable: false, cell: this.renderTaxRates },
        // { cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public async mounted() {
        await this.loadRules();
        await this.loadTaxRates();
        this.isLoading = false;
    }

    // public async goToProduct(e) {
    //     const productId = e.dataItem.productId;
    //     await this.$router.push({ name: 'crm-product', params: { productId } });
    // }

    public async loadRules() {
        const products = await dealsService.getTaxRateRules();
        this.rules = products;
    }

    public createRuleSideAction() {
        this.$sideActions.push('crm-create-rule-side-action', {}, (result) => {
            this.setRule(result);
        });
    }

    public createRuleSideActionFromRow(item) {
        this.$sideActions.push('crm-create-rule-side-action', { rule: item.dataItem }, (result) => {
            this.setRule(result);
        });
    }

    public setRule(result: Rule) {
        this.rules = this.rules.filter((x) => x.taxRateRuleId !== result.taxRateRuleId);
        this.rules.push(result);
    }

    public renderActions(h, _, row) {
        const actions = [];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public renderTaxRates(h, _, row) {
        const taxRate = this.taxRates.find((x) => x.id === row.dataItem.taxRate);
        return h('td', taxRate.description);
    }

    public renderEuropeBoolean(h, _, row) {
        return h('td', row.dataItem.isExEu ? 'Yes' : 'No');
    }

    public renderVatBoolean(h, _, row) {
        return h('td', row.dataItem.needsVatNumber ? 'Yes' : 'No');
    }
}
