import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class NumberFormatter extends Vue {
    @Prop({ default: null }) public value: any;
    @Prop({ default: '' }) public classNames: string;
    @Prop({ default: true }) public renderTd: boolean;

    public getFormattedValue() {
        return Vue.filter('numberFormat')(this.value);
    }
}
