import { notificationService } from '@/main';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import moment from 'moment';
import { Component } from 'vue-property-decorator';

@Component
export default class MailLogActionComponent extends SideActionBase<MailLogSideActionParameters> {
    public log: MailLogItem[] = [];
    public loading: boolean = true;
    public columns: any[] = [
        {
            title: 'Date',
            field: 'created',
            width: 250,
            cell: this.renderDate
        },
        {
            title: 'Subject',
            field: 'title',
        },
        {
            title: 'Receiver',
            field: 'receiverName',
            width: 250,
        },
        {
            title: 'Email address',
            field: 'receiverAddress',
            width: 250,
        },
        {
            title: 'Template',
            field: 'notificationTemplate',
            width: 250,
        },
    ];

    public async mounted() {
        if (!this.options.invoiceId) {
            return this.loading = false;
        }

        this.log = await notificationService.getMessageLog(this.options.invoiceId);
        this.loading = false;
    }

    public renderDate(h: any, _, row: any) {
        return h('td', moment(row.dataItem.created).format('DD-MM-YYYY hh:mm:ss'));
    }

    public close(){
        this.finished();
    }
}

export class MailLogSideActionParameters {
    public invoiceId: string;
}

export class MailLogItem {
    public created: Date;
    public title: string;
    public receiverName: string;
    public receiverAddress: string;
    public notificationTemplate: string;
    public message: string;
    public templateParameters: any;
}
