import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { ecosystemHelper } from '@/main';
import { profileModule } from '@/store/modules/profile';

@Component({
    components: {
        vueDropzone: vue2Dropzone,
    },
})
export default class FileUploader extends Vue {
    @Prop({ default: null }) public legalEntityId: number;
    @Prop({ default: null }) public templateId: number;

    public getDropZoneOptions() {
        return {
            url: this.templateId
                ? `${Vue.$env().invoicesApiEndpoint}legal-entities/${this.legalEntityId}/templates/${this.templateId}/logo`
                : `${Vue.$env().invoicesApiEndpoint}legal-entities/${this.legalEntityId}/logo`,
            thumbnailWidth: 150,
            maxFilesize: 10,
            uploadMultiple: false,
            headers: {
                'x-ecosystem': ecosystemHelper.currentEcosystem.id,
                'Authorization': `Bearer ${profileModule.access_token}`,
            },
            paramName: 'file',
        };
    }

    public uploadDone(_, b) {
        this.$emit('upload-done', b);
    }
}
