import Component from 'vue-class-component';
import LegalEntity from '@/models/LegalEntity';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { legalEntityService } from '@/main';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { legalEntityModule } from '@/store/modules/LegalEntity';
import TaxRate from '@/models/CRM/TaxRate';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        localLegalEntity: {
            name: { required },
            legalEntityReference: { required },
            invoiceNumberPrefix: { required },
        },
    },
} as any)
export default class LegalEntityPage extends SideActionBase<{ legalEntity: LegalEntity }> {
    @Prop({ default: () => new LegalEntity() }) public legalEntity: LegalEntity;
    public localLegalEntity: LegalEntity = new LegalEntity();
    public isEdit: boolean = false;
    public templates: any[] = [];
    public taxRates: TaxRate[] = [];
    public selectedTaxRate: TaxRate = null;
    public templateColumns = [
        { field: 'invoiceTemplateId', title: 'ID', width: 100},
        { field: 'name', title: 'Template name',  cell: this.renderName },
        { field: 'invoiceData', title: 'Registered company name', cell: this.renderCompanyName },
        { field: 'translations', title: 'TRANSLATIONS',  cell: this.renderTranslations },
        { field: 'action', title: 'ACTIONS', cell: this.renderActions, width: 100 },
    ];

    public async created() {
        this.isEdit = this.options.legalEntity && this.options.legalEntity.legalEntityId !== '';
        this.taxRates = await this.loadTaxRates();

        if (this.isEdit) {
            const [error, resp] = await to(legalEntityService.getLegalEntity(this.options.legalEntity.legalEntityId));
            if (!error) {
                this.localLegalEntity = resp.data;
            }

            this.templates = await legalEntityService.getTemplates(this.localLegalEntity.legalEntityId);
            this.selectedTaxRate = this.taxRates.find((x) => x.id === this.localLegalEntity.defaultTaxRateId);

            this.localLegalEntity.invoiceTemplateId = 'default';
            this.templates.unshift(this.localLegalEntity);
        }

        this.isLoading = false;
    }

    public resetOrganization() {
        this.localLegalEntity = new LegalEntity();
    }

    public async createLegalEntity() {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`CREATE_LEGAL_ENTITY_INVALID`);
            return;
        }

        this.showPending('CREATE_LEGAL_ENTITY_PENDING');
        this.localLegalEntity.defaultTaxRateId = this.selectedTaxRate.id;
        const [err, response] = await to(legalEntityService.createLegalEntity(this.localLegalEntity));
        if (err) {
            return this.clearAndShowError('CREATE_LEGAL_ENTITY_FAILED', err);
        }

        const legalEntity = new LegalEntity(response.data);
        this.$emit('finished', legalEntity);
        this.clearNotifications();
    }

    public async updateLegalEntity(closeSideAction: boolean = true) {
        this.submitted = true;
        if ((this as any).$v.$invalid) {
            this.showWarning(`UPDATE_LEGAL_ENTITY_INVALID`);
            return;
        }

        this.showPending('UPDATE_LEGAL_ENTITY_PENDING');
        this.localLegalEntity.defaultTaxRateId = this.selectedTaxRate.id;
        const [err] = await to(legalEntityService.updateLegalEntity(this.localLegalEntity));
        if (err) {
            return this.clearAndShowError('UPDATE_LEGAL_ENTITY_FAILED', err);
        }

        if (closeSideAction) {
            this.$emit('finished', this.localLegalEntity);
        }
        this.clearNotifications();
    }

    public editInvoiceData() {
        this.$sideActions.push('create-or-edit-legal-entity-invoice-data', { legalEntity: this.localLegalEntity }, (result) => {
            this.clearAndShowSuccess('Updated successfully');
        });
    }

    public editTemplateInvoiceData(item) {
        if (item.invoiceTemplateId === 'default') {
            return this.$sideActions.push(
                'create-or-edit-legal-entity-invoice-data',
                { legalEntity: this.localLegalEntity, isTemplate: false },
                (result) => {
                    this.clearAndShowSuccess('Updated successfully');
                },
            );
        }

        this.$sideActions.push(
            'create-or-edit-legal-entity-invoice-data',
            { legalEntity: this.localLegalEntity, templateId: item.invoiceTemplateId, isTemplate: true },
            async () => {
                this.templates = await legalEntityService.getTemplates(this.localLegalEntity.legalEntityId);

                this.localLegalEntity.invoiceTemplateId = 'default';
                this.templates.unshift(this.localLegalEntity);

                this.clearNotifications();
            },
        );
    }

    public createTemplateInvoiceData() {
        this.$sideActions.push('create-or-edit-legal-entity-invoice-data', { legalEntity: this.localLegalEntity, isTemplate: true }, async () => {
            this.templates = await legalEntityService.getTemplates(this.localLegalEntity.legalEntityId);

            this.localLegalEntity.invoiceTemplateId = 'default';
            this.templates.unshift(this.localLegalEntity);

            this.clearNotifications();
        });
    }

    public openSideActionChargeExceptions(exception) {
        this.$sideActions.push('add-charge-exceptions-side-action', { legalEntity: this.localLegalEntity, exception }, async (chargeException) => {
            if (!exception) {
                this.localLegalEntity.chargeExceptions.push(chargeException);
            } else {
                this.localLegalEntity.chargeExceptions.find((x) => x.targetLegalEntityId === exception.targetLegalEntityId).taxRateId =
                    chargeException.taxRateId;
            }

            await this.updateLegalEntity(false);
        });
    }

    public getTaxRateName(taxRateId) {
        if (!taxRateId) {
            return '';
        }
        const taxRate = this.taxRates.find((x) => x.id === taxRateId);
        if (taxRate) {
            return taxRate.description;
        }

        return '';
    }

    public getLegalEntityName(legalEntityId) {
        return legalEntityModule.all.find((x) => x.legalEntityId === legalEntityId).name;
    }

    public editTemplate(item) {
        this.editTemplateInvoiceData(item.dataItem);
    }

    public renderName(h, _, row) {
        return h('td', `${row.dataItem.name} ${row.dataItem.invoiceTemplateId === 'default' ? '(default)' : ''}`);
    }
    public renderTranslations(h, _, row) {
        if (!row.dataItem.invoiceData || !row.dataItem.invoiceData.translations) {
            return h('td', 'N/A');
        }

        return h('td', row.dataItem.invoiceData.translations.map((x) => x.language).join(', '));
    }

    public renderCompanyName(h, _, row) {
        if (!row.dataItem.invoiceData || !row.dataItem.invoiceData.registeredCompanyName) {
            return h('td', 'N/A');
        }

        return h('td', row.dataItem.invoiceData.registeredCompanyName);
    }

    public renderActions(h, _, row) {
        const actions = [
            {
                title: 'Edit template',
                function: this.editTemplateInvoiceData,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public removeChargeException(index) {
        this.localLegalEntity.chargeExceptions.splice(index, 1);
    }
}
