import Group from '../Group';

export default class StructureTeam {
    public ecosystem: number = 0;
    public groups: Group[] = [];
    public legalEntityReference: string = '';
    public name: string = '';
    public teamId: number = 0;
    public teamKey: string = '';
}
