import DocumentLine from '@/models/Costs/DocumentLine';
import StructureTeam from '@/models/Ecosystem/StructureTeam';
import Project from '@/models/Project';
import DocumentPredictionLinesActionOptions from '@/models/Purchases/DocumentPredictionLinesActionOptions';
import PredictionLine from '@/models/Purchases/PredictionLine';
import Product, { ProductActivity } from '@/models/Purchases/Product';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import { Component } from 'vue-property-decorator';
import to from 'await-to-js';
import { productsServicePurchases } from '@/main';

@Component
export default class DocumentPredictionLinesComponent extends SideActionBase<DocumentPredictionLinesActionOptions> {
    public predictionLines: PredictionLine[] = [];
    public products: Product[] = [];

    public exampleLine: DocumentLine = null;

    public lineTeam: any = null;
    public lineProjectPerson: any = null;
    public lineProduct: Product = null;
    public lineGroupsPersons: Project[] = [];
    public incTax: boolean = null;
    public team: StructureTeam = null;
    public connectedActivity: ProductActivity = null;

    public async mounted() {
        await Promise.all([this.loadStructure(), this.loadTaxRates()]);

        this.predictionLines = this.options.lines;
        this.exampleLine = this.options.exampleLine;
        this.products = this.options.products.map((x) => new Product(x));
        this.team = this.options.team;

        if (this.exampleLine && this.exampleLine.departmentObj) {
            if (this.exampleLine.departmentObj) {
                this.lineTeam = Object.assign({}, this.exampleLine.departmentObj);
                const projects = await this.getProjects(this.exampleLine.departmentObj);
                this.lineGroupsPersons = projects;
            }
            if (this.exampleLine.projectObj) {
                this.lineProjectPerson = Object.assign({}, this.exampleLine.projectObj);
            }
            if (this.exampleLine.product && this.exampleLine.product.productId > 0) {
                this.lineProduct = Object.assign({}, this.exampleLine.product);
            }
        } else if (this.team) {
            this.lineTeam = this.team;
            const projects = await this.getProjects(this.team);
            this.lineGroupsPersons = projects;
        }

        this.isLoading = false;
    }

    public async selectProduct(product: Product) {
        const [err, response] = await to(productsServicePurchases.getProductActivities(product.productId));
        this.lineProduct.connectedActivities = response.data;
    }

    public async selectActivity(activity) {
        this.lineTeam = activity.teamName;
        this.lineTeam = this.structure.teams.find((x) => x.name === activity.teamName);
        this.lineGroupsPersons = await this.getProjects(this.lineTeam);

        this.lineProjectPerson = activity.activityName;
        this.lineProjectPerson = this.lineGroupsPersons.find((x) => x.name.toLowerCase() === activity.activityName.toLowerCase());
    }

    public clearActivity() {
        this.lineProjectPerson = null;
        this.lineGroupsPersons = [];
        this.lineTeam = null;
    }

    public async addLinesWithPrefills() {
        this.showPending('ADDING_LINES_PENDING');

        const lines: DocumentLine[] = [];

        for (let i = 0; i < this.predictionLines.length; i++) {
            const predictionLine = this.predictionLines[i];
            const line = new DocumentLine();

            line.departmentObj = this.lineTeam;
            line.department = this.lineTeam.name;

            line.projects = await this.getProjects(line.departmentObj);
            line.projectObj = this.lineProjectPerson;
            line.project = this.lineProjectPerson.emailAddress;

            line.lineNumber = i;
            line.index = predictionLine.index;
            line.connectedActivity = this.connectedActivity;

            if (this.lineProduct && this.lineProduct.productId) {
                line.product = this.lineProduct;
                line.productId = this.lineProduct.productId;
            }

            if (predictionLine.amount && predictionLine.amount.value) {
                const price = this.parsePriceAmount(predictionLine.amount.value);
                line.amount = 1;

                if (!this.incTax) {
                    line.price = price;
                    await this.exclPriceInput(price, line);
                } else {
                    line.inclPrice = price;
                    await this.inclPriceInput(price, line);
                }
            }

            if (!line.description && predictionLine.description && predictionLine.description.value) {
                line.description = predictionLine.description.value;
            }

            lines.push(line);
        }

        this.clearNotifications();
        this.finished({
            lines,
            clearFirst:
                this.exampleLine &&
                !this.exampleLine.price &&
                !this.exampleLine.description &&
                this.predictionLines.every((x) => typeof x.index === 'undefined'),
        });
    }

    public async connectActivity() {
        const productId = this.lineProduct.productId;

        const activity = new ProductActivity({
            activityName: this.lineProjectPerson.name,
            activityObj: this.lineProjectPerson,
            teamObj: this.lineTeam,
            teamName: this.lineTeam.name,
        });
        const [err] = await to(productsServicePurchases.saveConnectedActivity(productId, activity));
        if (err) {
            return this.clearAndShowError('CONNECT_CONNECTED_ACTIVITY_FAILED', err);
        }

        this.lineProduct.connectedActivities.push(activity);
        this.connectedActivity = activity;
    }


    public async selectDepartment(department) {
        this.lineProjectPerson = null;
        this.lineGroupsPersons = await this.getProjects(department);
    }
}
